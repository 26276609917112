import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FormGroup, Col, Row, Table, Modal, Button, Input } from "reactstrap";
import { ActivityTypeOption } from "variables/productOption";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import { FetchAllActivityApi, AddGroupActivityApi } from "store/AddGroupAction";
import { useDispatch, useSelector } from "react-redux";
import { GET_ADD_GROUP_ACTIVITY_RESET } from "store/GroupLeadOpportunitiesAction";
import ReactDatetime from "react-datetime";
import moment from "moment";

const GroupActivity = (props) => {
  const notificationAlert = React.useRef();
  const [alert, setAlert] = React.useState(null);
  const [activtyObj, setactivtyObj] = React.useState([]);
  const getActivityUrl =
    process.env.REACT_APP_API_URL + "/get-activities/" + props.client_id;
  const saveActivityUrl = process.env.REACT_APP_API_URL + "/save-activity/";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadingData, setLoadingData] = React.useState(true);
  const [addModal, setaddModal] = React.useState(false);
  const [consultantOptions, setconsultantOptions] = useState([]);
  const [Type, setType] = useState([]);
  const [Consultant, setConsultant] = useState();
  const [Description, setDescription] = useState();
  const [EnableFollowUpDate, setEnableFollowUpDate] = useState(false);
  const [FollowUpDateModal, setFollowUpDateModal] = React.useState(false);
  const [FollowUpDate, setFollowUpDate] = useState();

  // const [FollowUpDate, setFollowUpDate] = useState(
  //   moment().set({ hour: 9, minute: 0, second: 0 })
  // );

  const [DefaultStatus, setDefaultStatus] = useState({
    value: "Follow Up Date",
    label: "Follow Up Date",
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const openDropdown = () => {
    setDropdownOpen(true);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 99999999999, // Ensure the menu appears above other components
    }),
  };
  const toggleAddModal = () => {
    setaddModal(!addModal);
    setLoadingData(true);
  };

  // const toggleFollowUpDateModal = () => {
  //   setFollowUpDateModal(!FollowUpDateModal);
  //   setLoadingData(true);
  // };

  const { GetAllActivityDataSuccess } = useSelector(
    (state) => state.getAllActivityDataRe
  );

  const { AddGroupActivityDataSuccess } = useSelector(
    (state) => state.getAddGroupActivityDataRe
  );
  // console.log("GetAllActivityDataSuccess",GetAllActivityDataSuccess);
  useEffect(() => {
    if (AddGroupActivityDataSuccess?.data?.success == true) {
      notify(AddGroupActivityDataSuccess?.data?.message, 2, notificationAlert);
      setLoadingData(true);

      setDescription("");
      setConsultant("");
      setType([]);
      setaddModal(false);
      // setFollowUpDateModal(false);

      dispatch({ type: GET_ADD_GROUP_ACTIVITY_RESET, payload: { data: "" } });
    }

    // else{
    //   notify(AddGroupActivityDataSuccess?.data?.message , 3, notificationAlert);

    // }
  }, [AddGroupActivityDataSuccess]);

  useEffect(() => {
    if (GetAllActivityDataSuccess?.data?.success == true) {
      setactivtyObj(GetAllActivityDataSuccess?.data?.activities);
      setconsultantOptions(GetAllActivityDataSuccess?.data?.users);
      setLoadingData(false);
    }
    if (GetAllActivityDataSuccess?.data?.success == false) {
      setLoadingData(false);
    }
  }, [GetAllActivityDataSuccess]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getActivity = async () => {
      await dispatch(FetchAllActivityApi(props.group_id, loggedUser.token));
    };
    if (loadingData) {
      getActivity();
    }
  }, [loadingData]);
  const saveActivity = async (check, priority = false) => {
    var typevalue = "";
    if (check == "FollowUpDate") {
      typevalue = "Follow Up Date";
    }
    if (check == "save") {
      typevalue = Type?.value;
    }
    if (!priority) {
      if (
        !typevalue ||
        !Description ||
        // !Consultant?.value ||
        !props.group_id 
       
      ) {
        notify("Fields makerd with (*) are required.", 3, notificationAlert);
      } else {
        const data = {
          type: typevalue,
          description: Description,
          // user_id: Consultant?.value,
          user_id: loggedUser?.data?.id,
          group_id: props.group_id,
          // FollowUpDate: FollowUpDate,
          FollowUpDate: moment(FollowUpDate)?.format("DD-MM-YYYY hh:mm A"),
        };

        await dispatch(AddGroupActivityApi(data, loggedUser.token));
      }
    } else {
      const data = {
        type: "Priority Change",
        description: `Priority changed to "${check}"`,
        updatedByUserName: loggedUser?.data?.name,
        user_id: loggedUser?.data?.id,
        group_id: props.group_id,
        priority: check,
      };

      await dispatch(AddGroupActivityApi(data, loggedUser.token));
    }
  };
  useEffect(() => {
    if (Type.value == "Follow Up Date") {
      setEnableFollowUpDate(true);
    } else {
      setEnableFollowUpDate(false);
    }
  }, [Type]);
  const handleItemClick = (item) => {
    if (item.trim() !== "") {
      saveActivity(item, true);
    }
    console.log(`Selected: ${item}`);
    closeDropdown();
  };
  const dropdownStyle = {
    backgroundColor: "#dee2e6",
  };
  return (
    <Row>
      <NotificationAlert ref={notificationAlert} />
      {alert}
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <Col md="12">
          {loggedUser.data.role === 1 ||
          loggedUser.data.role === 2 ||
          loggedUser.data.role === 3 ||
          loggedUser.data.role === 6 ||
          loggedUser.data.role === 7 ||
          loggedUser.data.role === 5 ? (
            <div style={{ display: "flex" }}>
              <Button
                onClick={(e) => {
                  toggleAddModal();
                }}
                color="primary"
              >
                Add Activity
              </Button>

              {/* <Button
                onClick={(e) => {
                  toggleFollowUpDateModal();
                }}
                color="primary"
              >
                Add Follow Up date
              </Button> */}

              <div className="position-relative">
                <button
                  className="btn btn-primary"
                  onClick={dropdownOpen ? closeDropdown : openDropdown}
                >
                  Priority
                </button>
                {dropdownOpen && (
                  <div
                    className="position-absolute dropdown-menu dropdown-menu-custom show"
                    style={dropdownStyle}
                  >
                    <button
                      className="dropdown-item dropdown-item-custom"
                      onClick={() => handleItemClick("VERY HIGH")}
                    >
                      <p style={{ backgroundColor: "#3fa9f5" , fontWeight: 400  }}>VERY HIGH</p>
                    </button>
                    <button
                      className="dropdown-item dropdown-item-custom"
                      onClick={() => handleItemClick("HIGH")}
                    >
                      <p style={{ backgroundColor: "#0fdf0f"  , fontWeight: 400 }}>HIGH</p>
                    </button>
                    <button
                      className="dropdown-item dropdown-item-custom"
                      onClick={() => handleItemClick("MEDIUM")}
                    >
                      <p style={{ backgroundColor: "orange" , fontWeight: 400 }}>MEDIUM</p>
                    </button>
                    <button
                      className="dropdown-item dropdown-item-custom"
                      onClick={() => handleItemClick("LOW")}
                    >
                      <p style={{ backgroundColor: "yellow" , fontWeight: 400  }}>LOW</p>
                    </button>
                  </div>
                  // <div className="position-absolute dropdown-menu show" style={dropdownStyle}>
                  //   <button className="dropdown-item" onClick={() => handleItemClick('High')}>High</button>
                  //   <button className="dropdown-item" onClick={() => handleItemClick('Medium')}>Medium</button>
                  //   <button className="dropdown-item" onClick={() => handleItemClick('Low')}>Low</button>
                  // </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="table-responsive">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Consultant</th>
                  <th>Type</th>
                  <th>Description</th>
                  {/* <th>Follow Up Date</th> */}
                </tr>
              </thead>
              <tbody>
                {activtyObj.length > 0 ? (
                  activtyObj.map((element, index) => (
                    <tr key={index}>
                      <td>{activtyObj[index]["date"]}</td>
                      <td>{activtyObj[index]["name"]}</td>
                      <td>
                        {activtyObj[index]["type"]}{" "}
                        {activtyObj[index]["type"] == "Follow Up Date"
                          ? activtyObj[index]["follow_up_date"]
                          : ""}
                      </td>
                      <td>{activtyObj[index]["description"]}</td>
                      {/* <td>{activtyObj[index]["follow_up_date"]}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Record Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Modal size="large" isOpen={addModal} toggle={toggleAddModal}>
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleAddModal}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h4 className="title title-up">Add Activity</h4>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <FormGroup className={`has-label`}>
                    <label>Status</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setType(e);
                      }}
                      options={ActivityTypeOption}
                      defaultValue={Type}
                      placeholder="Type"
                      menuPortalTarget={document.body} // Mount the menu portal to the body
                      // Use a fixed position for the menu
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
                {/* <Col className="ml-auto mr-auto" md="6">
                  <FormGroup className={`has-label`}>
                    <label>Consultant</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setConsultant(e);
                      }}
                      options={consultantOptions}
                      defaultValue={Consultant}
                      placeholder="Select Consultant"
                    />
                  </FormGroup>
                </Col> */}

                {/* {EnableFollowUpDate ? ( */}
                <Col className="ml-auto mr-auto" md="6">
                  <FormGroup>
                    <label>Follow Up Date</label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        // value: startDate || "",
                      }}
                      // isValidDate={disablePastDt}
                      // timeFormat={false}
                      value={FollowUpDate}
                      onChange={(e) => {
                        // console.log("first",moment(e.toDate()).format("DD-MM-YYYY hh:mm A"))
                        // setFollowUpDate(
                        //   moment(e.toDate()).format("DD-MM-YYYY hh:mm A")
                        // );
                        setFollowUpDate(moment(e.toDate()));
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* ) : (
                  ""
                )} */}

                <Col className="ml-auto mr-auto" md="12">
                  <FormGroup className={`has-label`}>
                    <label>Description</label>
                    <Input
                      className="textarea"
                      type="textarea"
                      cols="80"
                      rows="4"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="ml-auto mr-auto" md="12">
                  <Button
                    color="primary"
                    onClick={() => {
                      saveActivity("save");
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>

          {/* <Modal
            size="large"
            isOpen={FollowUpDateModal}
            toggle={toggleFollowUpDateModal}
          >
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleFollowUpDateModal}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h4 className="title title-up">Add Follow Up Date</h4>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <FormGroup className={`has-label`}>
                    <label>Status</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      // onChange={(e) => {
                      //   setType(e);
                      // }}
                      // options={ActivityTypeOption}
                      defaultValue={DefaultStatus}
                      placeholder="Type"
                    />
                  </FormGroup>
                </Col>

                <Col className="ml-auto mr-auto" md="6">
                  <FormGroup className={`has-label`}>
                    <label>Consultant</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setConsultant(e);
                      }}
                      options={consultantOptions}
                      defaultValue={Consultant}
                      placeholder="Select Consultant"
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label>Follow Up Date</label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        // value: startDate || "",
                      }}
                      // isValidDate={disablePastDt}
                      // timeFormat={false}
                      onChange={(e) => {
                        // console.log("first",moment(e.toDate()).format("DD-MM-YYYY hh:mm A"))

                        setFollowUpDate(
                          moment(e.toDate()).format("DD-MM-YYYY  hh:mm A")
                        );
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="ml-auto mr-auto" md="12">
                  <FormGroup className={`has-label`}>
                    <label>Description</label>
                    <Input
                      className="textarea"
                      type="textarea"
                      cols="80"
                      rows="4"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="ml-auto mr-auto" md="12">
                  <Button
                    color="primary"
                    onClick={() => {
                      saveActivity("FollowUpDate");
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal> */}
        </Col>
      )}
    </Row>
  );
};
export default GroupActivity;
