import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import classnames from "classnames";
import "components/ReactTable/styles.css";

const AppTable = ({
  columns,
  data,
  isLoading,
  manualPagination = false,
  sorting,
  page,
  onRowClick,
}) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columnData,
      data: rowData,
      manualPagination,
    });

  const getTrProps = (rowInfo) => {
    if (onRowClick) {
      return {
        // onClick: () => {
        //   onRowClick(rowInfo?.original);
        // },
        style: {
          cursor: "pointer",
          background: rowInfo?.index % 2 === 0 ? "rgba(0, 0, 0, 0.05)" : "", // Add striped background color
        },
      };
    }
    return {};
  };
  return (
    <>
      {isLoading ? null : (
        <>
          <div className="-striped -highlight primary-pagination table-responsive">
            <table
              {...getTableProps()}
              className="table table-striped server-data"
            >
              <thead className="">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="">
                    {headerGroup.headers.map((column, key) => (
                      <th
                        key={"th" + key}
                        onClick={() =>
                          sorting(
                            page,
                            columns[key].sortdir === "ASC"
                              ? "DESC"
                              : columns[key].sortdir === "DESC"
                              ? "ASC"
                              : "ASC",
                            columns[key].accessor
                          )
                        }
                        className={classnames("rt-th rt-resizable-header", {
                          "-cursor-pointer":
                            // headerGroup.headers.length - 1 !== key,
                            headerGroup.headers.length,
                          "-sort-asc":
                            columns[key].sorCol === "yes" &&
                            columns[key].sortdir === "ASC",
                          "-sort-desc":
                            columns[key].sorCol === "yes" &&
                            columns[key].sortdir === "DESC",
                        })}
                      >
                        <div
                          className={
                            columns[key].accessor != "enable_disable"
                              ? "rt-resizable-header-content"
                              : ""
                          }
                        >
                          {column.render("Header")}
                        </div>
                        {/* Render the columns filter UI */}
                        <div>
                          {headerGroup.headers.length - 1 === key
                            ? null
                            : column.canFilter
                            ? column.render("Filter")
                            : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className=""
                style={{ lineHeight: 2.5 }}
              >
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps(getTrProps(row))}
                      className={classnames(
                        "",
                        { " -odd": i % 2 === 0 },
                        { " -even": i % 2 === 1 }
                      )}
                    >
                      {row.cells.map((cell) => {
                             

                        return (
                          <td {...cell.getCellProps()} className="">
                            {/* {console.log("cell",cell)} */}

                            {/* {cell.render("Cell")} */}

                            {cell?.column?.Header === "Actions" ? (
                              cell.render("Cell")
                            ) : (
                              <a
                                href={cell?.row?.original?.client_id ? cell?.row?.original?.url : cell?.row?.original?.groupurl }
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onRowClick(cell?.row?.original);
                                }}
                                // style="text-decoration: none; color: inherit;"
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {/* {console.log("cell?.column?.Heade",cell?.column?.Header)} */}
                                {/* props.cell.row.original */}
                                {/* {console.log( "sdbhfghjf" , cell?.row?.original?.groupAllPriority)} */}

                                {cell?.column?.Header === "Priority" ? (
                                  <p
                                    className="table-priority-column"
                                    style={{
                                      backgroundColor:
                                        cell?.row?.original
                                          ?.priority === "MEDIUM"
                                          ? "darkorange"
                                          : cell?.row?.original
                                              ?.priority === "HIGH"
                                          ? "#0fdf0f"
                                          : cell?.row?.original
                                              ?.priority ===
                                            "VERY HIGH"
                                          ? "#3fa9f5"
                                          : cell?.row?.original
                                              ?.priority === "LOW"
                                          ? "yellow"
                                          : "",
                                    }}
                                  >
                                    {" "}
                                    {cell.render("Cell")}
                                  </p>
                                ) : (
                                  cell.render("Cell")
                                )}
                              </a>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {manualPagination && (
            <div>
              {/* YOUR CLIENT SIDE PAGINATION COMPONENT BUILT USING REACT-TABLE UTILITY FUNCTIONS */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AppTable;
