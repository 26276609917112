import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import { TableSliceAction } from "store/TableDataSlice";
const ProductFilter = props => {
    const dispatch = useDispatch();
    const { insurerList } = useSelector(state => state.tableData);
    return (
        <>
            <Row>
                <Col lg="3">
                    <FormGroup className={`has-label`}>
                        <label>Insurer</label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="medicalType"
                            options={insurerList}
                            placeholder="Select Insurer"
                            onChange={(e) => {
                                dispatch(TableSliceAction.updateFilterParam({ field: 'insurer', value: e.value }));
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col lg="3">
                    <FormGroup className={`has-label`}>
                        <label>Product Name</label>
                        <Input type="text"
                            onChange={(e) => {
                                if (e.target.value.length > 3 || e.target.value.length === 0)
                                    dispatch(TableSliceAction.updateFilterParam({ field: 'product', value: e.target.value }));

                            }} />
                    </FormGroup>
                </Col>

                <Col lg="3">
                    <FormGroup className={`has-label`}>
                        <label>Product type</label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="medicalType"
                            options={[
                                {
                                    value: "",
                                    label: "Select Product type",
                                    isDisabled: true,
                                },
                                { value: "", label: "All" },
                                { value: "medical", label: "Medical" },
                                { value: "travel", label: "Travel" },
                            ]}
                            placeholder="Select Product Type"

                            onChange={(e) => {
                                dispatch(TableSliceAction.updateFilterParam({ field: 'type', value: e.value }));

                            }}

                        />
                    </FormGroup>
                </Col>

                <Col lg="3">
                    <FormGroup className={`has-label`}>
                        <label>Status</label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="medicalType"
                            options={[
                                {
                                    value: "",
                                    label: "Select Product Status",
                                    isDisabled: true,
                                },
                                { value: "", label: "All" },
                                { value: "1", label: "Enabled" },
                                { value: "0", label: "Disabled" },
                            ]}
                            placeholder="Select Product Status"

                            onChange={(e) => {
                                dispatch(TableSliceAction.updateFilterParam({ field: 'status', value: e.value }));

                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </>
    )
}

export default ProductFilter;