import * as groupOpportunityAction from "../GroupLeadOpportunitiesAction";

export const getAddGrpoupLeadReducer = (
  state = { AddGroupLeads: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.ADD_GROUP_OPPORTUNITIES_SUCCESS:
      return { AddGroupLeads: action.payload };

    case groupOpportunityAction.ADD_GROUP_OPPORTUNITIES_RESET:
      return { AddGroupLeads: state };
    case groupOpportunityAction.ADD_GROUP_OPPORTUNITIES_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getSingleGroupLeadDataReducer = (
  state = { GetSingleLeadData: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_SINGLE_LEAD_DATA_SUCCESS:
      return { GetSingleLeadData: action.payload };
    case groupOpportunityAction.GET_SINGLE_LEAD_DATA_RESET:
      return { GetSingleLeadData: state };
    case groupOpportunityAction.GET_SINGLE_LEAD_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getUpdateGroupDataReducer = (
  state = { UpdateGroupDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.UPADTE_GROUP_DATA_SUCCESS:
      return { UpdateGroupDataSuccess: action.payload };
    case groupOpportunityAction.UPADTE_GROUP_DATA_RESET:
      return { UpdateGroupDataSuccess: state };
    case groupOpportunityAction.UPADTE_GROUP_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getGroupProductsReducer = (
  state = { GroupProductData: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_GROUP_PRODUCT_DATA_SUCCESS:
      return { GroupProductData: action.payload };
    case groupOpportunityAction.GET_GROUP_PRODUCT_DATA_RESET:
      return { GroupProductData: state };
    case groupOpportunityAction.GET_GROUP_PRODUCT_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAllOptionsReducer = (
  state = { AllOptionsData: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ALL_OPTIONS_DATA_SUCCESS:
      return { AllOptionsData: action.payload };
    case groupOpportunityAction.GET_ALL_OPTIONS_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const postAddGroupProductReducer = (
  state = { AddGroupProductData: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.POST_ADD_GROUP_PRODUCT_DATA_SUCCESS:
      return { AddGroupProductData: action.payload };
    case groupOpportunityAction.POST_ADD_GROUP_PRODUCT_DATA_RESET:
      return { AddGroupProductData: state };
    case groupOpportunityAction.POST_ADD_GROUP_PRODUCT_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getSingleGroupProductDataReducer = (
  state = { SingleGroupProductData: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_SINGLE_GROUP_PRODUCT_DATA_SUCCESS:
      return { SingleGroupProductData: action.payload };
    case groupOpportunityAction.GET_SINGLE_GROUP_PRODUCT_DATA_RESET:
      return { SingleGroupProductData: state };
    case groupOpportunityAction.GET_SINGLE_GROUP_PRODUCT_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getUpdateGroupProductDataReducer = (
  state = { UpdateGroupProductSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_UPDATE_GROUP_PRODUCT_DATA_SUCCESS:
      return { UpdateGroupProductSuccess: action.payload };
    case groupOpportunityAction.GET_UPDATE_GROUP_PRODUCT_DATA_RESET:
      return { UpdateGroupProductSuccess: state };
    case groupOpportunityAction.GET_UPDATE_GROUP_PRODUCT_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getDeleteAdditionalGroupDataReducer = (
  state = { DeleteAdditionalGroupDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_DELETE_ADDITIONAL_GROUP_DATA_SUCCESS:
      return { DeleteAdditionalGroupDataSuccess: action.payload };
    case groupOpportunityAction.GET_DELETE_ADDITIONAL_GROUP_DATA_RESET:
      return { DeleteAdditionalGroupDataSuccess: state };
    case groupOpportunityAction.GET_DELETE_ADDITIONAL_GROUP_DATA_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAddGroupPremiumDetailDataReducer = (
  state = { AddGroupPremiumDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ADD_GROUP_PREMIUMM_DETAILS_SUCCESS:
      return { AddGroupPremiumDataSuccess: action.payload };
    case groupOpportunityAction.GET_ADD_GROUP_PREMIUMM_DETAILS_RESET:
      return { AddGroupPremiumDataSuccess: state };
    case groupOpportunityAction.GET_ADD_GROUP_PREMIUMM_DETAILS_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getDeleteGroupPremiumDetailDataReducer = (
  state = { DeleteroupPremiumDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_DELETE_GROUP_PREMIUMM_DETAILS_SUCCESS:
      return { DeleteroupPremiumDataSuccess: action.payload };
    case groupOpportunityAction.GET_DELETE_GROUP_PREMIUMM_DETAILS_RESET:
      return { DeleteroupPremiumDataSuccess: state };
    case groupOpportunityAction.GET_DELETE_GROUP_PREMIUMM_DETAILS_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAllGroupPremiumDetailDataReducer = (
  state = { GetAllGroupPremiumDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_SUCCESS:
      return { GetAllGroupPremiumDataSuccess: action.payload };
    case groupOpportunityAction.GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_RESET:
      return { GetAllGroupPremiumDataSuccess: state };
    case groupOpportunityAction.GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAllActivityDataReducer = (
  state = { GetAllActivityDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ALL_GROUP_FETCH_ACTIVITY_SUCCESS:
      return { GetAllActivityDataSuccess: action.payload };
    case groupOpportunityAction.GET_ALL_GROUP_FETCH_ACTIVITY_RESET:
      return { GetAllActivityDataSuccess: state };
    case groupOpportunityAction.GET_ALL_GROUP_FETCH_ACTIVITY_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAddGroupActivityDataReducer = (
  state = { AddGroupActivityDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ADD_GROUP_ACTIVITY_SUCCESS:
      return { AddGroupActivityDataSuccess: action.payload };
    case groupOpportunityAction.GET_ADD_GROUP_ACTIVITY_RESET:
      return { AddGroupActivityDataSuccess: state };
    case groupOpportunityAction.GET_ADD_GROUP_ACTIVITY_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAddGroupDocumentReducer = (
  state = { AddGroupDocumentSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ADD_GROUP_DOCUMENT_SUCCESS:
      return { AddGroupDocumentSuccess: action.payload };
    case groupOpportunityAction.GET_ADD_GROUP_DOCUMENT_RESET:
      return { AddGroupDocumentSuccess: state };
    case groupOpportunityAction.GET_ADD_GROUP_DOCUMENT_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAllDocumentDataReducer = (
  state = { GetAllDocumentDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ALL_GROUP_FETCH_DOCUMENT_SUCCESS:
      return { GetAllDocumentDataSuccess: action.payload };
    case groupOpportunityAction.GET_ALL_GROUP_FETCH_DOCUMENT_RESET:
      return { GetAllDocumentDataSuccess: state };
    case groupOpportunityAction.GET_ALL_GROUP_FETCH_DOCUMENT_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getDeleteGroupDocumentDataReducer = (
  state = { DeletegroupDocumentDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_DELETE_GROUP_DOCUMENT_SUCCESS:
      return { DeletegroupDocumentDataSuccess: action.payload };
    case groupOpportunityAction.GET_DELETE_GROUP_DOCUMENT_RESET:
      return { DeletegroupDocumentDataSuccess: state };
    case groupOpportunityAction.GET_DELETE_GROUP_DOCUMENT_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getDeleteGroupOpportunityDataReducer = (
  state = { DeletegroupOpportunityDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_DELETE_GROUP_OPPORTUNITY_SUCCESS:
      return { DeletegroupOpportunityDataSuccess: action.payload };
    case groupOpportunityAction.GET_DELETE_GROUP_OPPORTUNITY_RESET:
      return { DeletegroupOpportunityDataSuccess: state };
    case groupOpportunityAction.GET_DELETE_GROUP_OPPORTUNITY_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getQuotingDataReducer = (
  state = { QuotingDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_QUOTING_SUCCESS:
      return { QuotingDataSuccess: action.payload };
    case groupOpportunityAction.GET_QUOTING_RESET:
      return { QuotingDataSuccess: state };
    case groupOpportunityAction.GET_QUOTING_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAccountDataReducer = (
  state = { AccountDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ACCOUNT_SUCCESS:
      return { AccountDataSuccess: action.payload };
    case groupOpportunityAction.GET_ACCOUNT_RESET:
      return { AccountDataSuccess: state };
    case groupOpportunityAction.GET_ACCOUNT_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getOpportunityDataReducer = (
  state = { OpportunityDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_OPPORTUNITY_SUCCESS:
      return { OpportunityDataSuccess: action.payload };
    case groupOpportunityAction.GET_OPPORTUNITY_RESET:
      return { OpportunityDataSuccess: state };
    case groupOpportunityAction.GET_OPPORTUNITY_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getStatusLogsDataReducer = (
  state = { StatusLogsDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_STATUS_LOGS_SUCCESS:
      return { StatusLogsDataSuccess: action.payload };
    case groupOpportunityAction.GET_STATUS_LOGS_RESET:
      return { StatusLogsDataSuccess: state };
    case groupOpportunityAction.GET_STATUS_LOGS_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getIndividualStatusLogsDataReducer = (
  state = { IndividualStatusLogsDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_INDIVIDUAL_SEARCH_SUCCESS:
      return { IndividualStatusLogsDataSuccess: action.payload };
    case groupOpportunityAction.GET_INDIVIDUAL_SEARCH_RESET:
      return { IndividualStatusLogsDataSuccess: state };
    case groupOpportunityAction.GET_INDIVIDUAL_SEARCH_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getAllProductCountDataReducer = (
  state = { AllProductCountDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_ALL_PRODUCT_COUNT_SUCCESS:
      return { AllProductCountDataSuccess: action.payload };
    case groupOpportunityAction.GET_ALL_PRODUCT_COUNT_RESET:
      return { AllProductCountDataSuccess: state };
    case groupOpportunityAction.GET_ALL_PRODUCT_COUNT_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getGroupSearchDataReducer = (
  state = { GroupSearchDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_GROUP_SEARCH_SUCCESS:
      return { GroupSearchDataSuccess: action.payload };
    case groupOpportunityAction.GET_GROUP_SEARCH_RESET:
      return { GroupSearchDataSuccess: state };
    case groupOpportunityAction.GET_GROUP_SEARCH_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const getIndividualSearchDataReducer = (
  state = { IndividualSearchDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_INDIVIDUAL_SEARCH_SUCCESS:
      return { IndividualSearchDataSuccess: action.payload };
    case groupOpportunityAction.GET_INDIVIDUAL_SEARCH_RESET:
      return { IndividualSearchDataSuccess: state };
    case groupOpportunityAction.GET_INDIVIDUAL_SEARCH_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};


export const getGroupMemberDataReducer = (
  state = { GroupMembersDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_GROUP_MEMBERS_SUCCESS:
      return { GroupMembersDataSuccess: action.payload };
    case groupOpportunityAction.GET_GROUP_MEMBERS_RESET:
      return { GroupMembersDataSuccess: state };
    case groupOpportunityAction.GET_GROUP_MEMBERS_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};



export const getProductDeleteDataReducer = (
  state = { ProductDeleteDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_PRODUCT_DELETE_SUCCESS:
      return { ProductDeleteDataSuccess: action.payload };
    case groupOpportunityAction.GET_PRODUCT_DELETE_RESET:
      return { ProductDeleteDataSuccess: state };
    case groupOpportunityAction.GET_PRODUCT_DELETE_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};



export const getGroupProductDeleteDataReducer = (
  state = { GroupProductDeleteDataSuccess: {} },
  action
) => {
  switch (action.type) {
    case groupOpportunityAction.GET_GROUP_PRODUCT_DELETE_SUCCESS:
      return { GroupProductDeleteDataSuccess: action.payload };
    case groupOpportunityAction.GET_GROUP_PRODUCT_DELETE_RESET:
      return { GroupProductDeleteDataSuccess: state };
    case groupOpportunityAction.GET_GROUP_PRODUCT_DELETE_FAIELD:
      return { errorsss: action.payload };
    default:
      return state;
  }
};
