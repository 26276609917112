import React, { useEffect, useState } from "react";
import EditProducts from "components/Leads/EditProducts.js";
import AddProducts from "./AddProducts";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Col, Row, Table, Modal, Button } from "reactstrap";
import PremiumDetails from "./PremiumDetails";
import { useDispatch, useSelector } from "react-redux";
import { DeleteProductsApi } from "store/AddGroupAction";
function ClientProducts(props) {
  const [productObj, setproductObj] = React.useState([]);
  const getProductUrl =
    process.env.REACT_APP_API_URL + "/get-products/" + props.client_id;
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadingData, setLoadingData] = React.useState(true);
  const [editModal, seteditModal] = React.useState(false);
  const [addModal, setaddModal] = React.useState(false);
  const [PremiumModal, setPremiumModal] = React.useState(false);
  const [clikedProduct, setclikedProduct] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [delteProductId, setDelteProductId] = React.useState();


  const dispatch = useDispatch();
  const { ProductDeleteDataSuccess } = useSelector(
    (state) => state.getProductDeleteDataRe
  );
  console.log("ProductDeleteDataSuccess", ProductDeleteDataSuccess)


  const toggleEditModal = () => {
    seteditModal(!editModal);
    setLoadingData(true);
  };
  const toggleAddModal = () => {
    setaddModal(!addModal);
    setLoadingData(true);
  };
  const togglePremiumModal = () => {
    setPremiumModal(!PremiumModal);
    setLoadingData(true);
  };
  useEffect(() => {
    console.log("1111")
    async function getProducts() {
      console.log("22222")
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(getProductUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("data.products" , data.products)
          console.log("data" , data)
          console.log("data.success" , data.success)
          
          setproductObj(data.products);
          setLoadingData(false);
          
          // if (data.success) {

          //   setproductObj(data.products);
          //   setLoadingData(false);
          // } else {
          //   setLoadingData(false);
          // }
        });
    }
    if (loadingData) {
      getProducts();
    }

    if(ProductDeleteDataSuccess?.data?.success){
      console.log("3333")
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert("no")}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {ProductDeleteDataSuccess?.data?.message}
        </ReactBSAlert>
    
      );
      console.log("4444")
      getProducts();
    }else{
      // notify("Something Wrong", 3, notificationAlert);
    }

  }, [loadingData , ProductDeleteDataSuccess]);


  

  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };

  const successDelete = async (productId) => {
    console.log("sjghdgfshdgfsghdfgsdhgfshfjgsd" ,productId)
    if(productId){
      await dispatch(DeleteProductsApi(loggedUser?.token, productId));

    }
  };

  const deleteProduct = (productId) => {
    console.log("delteProductId", productId)
      console.log("working in deleteproduct");
      // setDelteProductId(productId);
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete(productId)}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this!
        </ReactBSAlert>
      );
    // } else {
    //   notify("Please select opportunity.", 3, notificationAlert);
    // }
  };



  return (
    <>
    
    {alert}
    <Row>
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <Col md="12">
          {loggedUser.data.role === 1 ||
          loggedUser.data.role === 2 ||
          loggedUser.data.role === 3 ||
          loggedUser.data.role === 5 ||
          loggedUser.data.role === 7 ||
          loggedUser.data.role === 8 ||
          loggedUser.data.role === 6 ? (
            <Button
              onClick={(e) => {
                toggleAddModal();
              }}
              color="primary"
            >
              Add Product
            </Button>
          ) : null}
          <div className="table-responsive">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Status</th>
                  <th>Consultant</th>
                  <th>Insurer</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>

                  <th>Premiums</th>
                </tr>
              </thead>
              <tbody>
                {productObj.map((element, index) => (
                  <tr key={index}>
                    <td>{productObj[index]["product"]}</td>
                    <td>{productObj[index]["status"]}</td>
                    <td>{productObj[index]["name"]}</td>
                    <td>{productObj[index]["title"]}</td>
                    <td>{productObj[index]["start-date"]}</td>
                    <td>{productObj[index]["end-date"]}</td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={(e) => {
                          toggleEditModal();
                          setclikedProduct(productObj[index]["id"]);
                        }}
                      >
                        View
                      </Button>

                      <Button
                        color="danger"
                        size="sm"
                        onClick={(e) => {
                    
                          deleteProduct(productObj[index]["id"]);
                          
                        }}
                      >
                        Delete
                      </Button>
   
                    </td>
    
                    {process.env.REACT_APP_ENVIROMENT === "development" ? (
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={(e) => {
                            togglePremiumModal();
                            setclikedProduct(productObj[index]["id"]);
                          }}
                        >
                          Premiums
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={(e) => {
                            togglePremiumModal();
                            setclikedProduct(productObj[index]["id"]);
                          }}
                        >
                          Premiums
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      )}
      <Modal size="large" isOpen={editModal} toggle={toggleEditModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleEditModal}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Edit Product</h4>
        </div>
        <div className="modal-body">
          <EditProducts
            product_id={clikedProduct}
            client_id={props.client_id}
          ></EditProducts>
        </div>
      </Modal>
      <Modal size="large" isOpen={addModal} toggle={toggleAddModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleAddModal}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Add Product</h4>
        </div>
        <div className="modal-body">
          <AddProducts
            client_id={props.client_id}
            setaddModal={setaddModal}
            setLoadingData={setLoadingData}
          ></AddProducts>
        </div>
      </Modal>

      <Modal size="large" isOpen={PremiumModal} toggle={togglePremiumModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={togglePremiumModal}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Commission Details</h4>
        </div>
        <div className="modal-body">
          <PremiumDetails
            product_id={clikedProduct}
            client_id={props.client_id}
          ></PremiumDetails>
        </div>
      </Modal>
    </Row>
    </>
  );
}
export default ClientProducts;
