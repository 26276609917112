/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Opportunities from "components/Leads/Opportunities";
import { monthNames } from "variables/productOption";
import SearchGroupOpportunity from "components/GroupLeads/SearchGroupOpportunity";
import GroupOpportunities from "components/GroupLeads/GroupOpportunities";
import { notify } from "components/functions";
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

function GroupSearch() {
  const notificationAlert = React.useRef();
  const [loadingData, setLoadingData] = React.useState(false);
  const [GroupName, setGroupName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [PolicyNumber, setPolicyNumber] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Email, setEmail] = useState("");

  const [searchParams, setsearchParams] = useState({});
  const [searchResult, setsearchResult] = useState(false);

  const loggedUser = JSON.parse(localStorage.getItem("current_users"));

  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);

  const backToSearchForm = () => {
    setsearchResult(false);
    setGroupName("");
    setFirstName("");
    setLastName("");
    setPolicyNumber("");
    setPhoneNumber("");
    setEmail("");
  };
  const searchGroup = () => {
    let data = {
      email: Email,
      group_name: GroupName,
      first_name: FirstName,
      last_name: LastName,
      policy_number: PolicyNumber,
      phone_number: PhoneNumber,
    };

    const restrictedDomains = [
      "@outlook.com",
      "@yahoo.com",
      "@gmail.com",
      "@icloud.com",
      "@aol.com",
      "@protonmail.com",
      "@mail.com",
      "@zoho.com",
      ".com",
      "com",
      "outlook",
      "yahoo",
      "gmail",
      "aol",
      "protonmail",
      "mail",
      "zoho",
      "@live.com",
      "@live.co.uk",
      "@hotmail.com",
      "@hotmail.co.uk",
      "@me.com",
      "@outlook.co.uk",
      ".com.au",
      ".sg",
      ".com.hk",
      ".th",
    ];

    let success = false;

    // for (let key in data) {
    //   if(data[key].length > 0){
    //     if (data[key].length >= 3) {
    //       if(key == "email"){
    //         let val = restrictedDomains.includes(data[key])
    //         console.log("data[key]", data[key])
    //         console.log("val" , val)
    //         if(val){
    //         success = false;
    //         notify(`Searching for email addresses with the domain ${data[key]} is not allowed.`, 3, notificationAlert);
    //          break
    //         }else{
    //           success = true;
    //         }
    //       }
    //       success = true;

    //     }else{
    //       success = false;
    //       break;
    //     }
    //   }
     
    // }



    for (let key in data) {
      if(data[key].length > 0){
        if(key == "policy_number" || key == "phone_number"){
          
          if (data[key].length >= 5) {
            success = true;
          }else{
            notify("A minimum of 5 characters is required for field Policy Number and Phone Number", 3, notificationAlert);
            success = false;
            break;
          }
          
        }
        if (data[key].length >= 3) {
          if(key == "email"){
            let val = restrictedDomains.includes(data[key])
            console.log("data[key]", data[key])
            console.log("val" , val)
            if(val){
            success = false;
            notify(`Searching for email addresses with the domain ${data[key]} is not allowed.`, 3, notificationAlert);
             break
            }else{
              success = true;
            }
          }
          success = true;

        }else{
          success = false;
          break;
        }
      }
     
    }

   
console.log("success", success)
    if (success) {
      setsearchParams(data);
      setsearchResult(true);
    } else {
      notify("A minimum of 3 characters is required per field to search", 3, notificationAlert);
    }

    // setsearchParams(data);
    // setsearchResult(true);
  };

  return (
    <>
     <NotificationAlert ref={notificationAlert} />
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <div className="content">
          {searchResult ? (
            <Row className={searchResult ? "" : "d-none"}>
              <Col md="12">
                <Card>
                  <CardHeader style={{ display: "flex" }}>
                    <CardTitle tag="h4">Search Result</CardTitle>
                    <Button
                      onClick={() => {
                        backToSearchForm();
                      }}
                      className="btn btn-primary float-right"
                      style={{ marginLeft: "75%" }}
                    >
                      Back to Search Form
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <SearchGroupOpportunity
                      api="group/search"
                      leadType="search"
                      method="POST"
                      filterData={searchParams}
                    ></SearchGroupOpportunity>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className={searchResult ? "d-none" : ""}>
              <Col md="12">
                <Card className="card-stats">
                  <CardHeader>
                    <CardTitle tag="h4">Search Group Opportunity</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="mr-auto" md="6">
                        <Row>
                          <Col className="mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>Group Name</label>
                              <Input
                                type="text"
                                // placeholder="Group Name"
                                onChange={(e) => {
                                  setGroupName(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>
                                Last Name{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <Input
                                type="text"
                                // placeholder="Last Name"
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  // setGroupPolicyNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>
                                Phone Number{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <Input
                                type="text"
                                // placeholder="Phone Number"
                                onChange={(e) => {
                                  setPhoneNumber(e.target.value);
                                  // setGroupPolicyNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="mr-auto" md="6">
                        <Row>
                          <Col className="mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>First Name</label>
                              <Input
                                type="text"
                                // placeholder="First Name"
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>
                                Email{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <Input
                                type="text"
                                // placeholder="Email"
                                onChange={(e) => {
                                  setEmail(e.target.value);

                                  // setGroupPolicyNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>
                                Policy Number{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <Input
                                type="text"
                                // placeholder="Policy Number"
                                onChange={(e) => {
                                  setPolicyNumber(e.target.value);

                                  // setGroupPolicyNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="ml-auto mr-auto text-center" md="12">
                        <Button
                          color="primary"
                          onClick={() => {
                            searchGroup();
                          }}
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default GroupSearch;
