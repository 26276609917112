/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Login from "views/pages/Login.js";
import ForgotPassword from "views/pages/ForgotPassword.js";
import VerifyOTP from "views/pages/VerifyOTP";
import SetupFA from "views/pages/SetupFA";

const routes = [
  
  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        mini: "F",
        component: ForgotPassword,
        layout: "/auth",
      },

      {
        path: "/setup-fa",
        name: "SetupFA",
        mini: "s",
        component: SetupFA,
        layout: "/auth",
      },
      {
        path: "/verify-otp",
        name: "VerifyOTP",
        mini: "v",
        component: VerifyOTP,
        layout: "/auth",
      },
      
    ],
  },
    
];

export default routes;
