import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { monthNames } from "variables/productOption";
import { addGroupOpportunity } from "store/AddGroupAction";
import { useDispatch, useSelector } from "react-redux";
import { ADD_GROUP_OPPORTUNITIES_RESET } from "store/GroupLeadOpportunitiesAction";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
} from "reactstrap";
const AddGroupLead = () => {
  const [loadingData, setLoadingData] = React.useState(true);
  const [searchParams, setsearchParams] = useState({});
  const [searchResult, setsearchResult] = useState(false);

  const [AdminTwo, setAdminTwo] = useState(false);
  const [AdminThree, setAdminThree] = useState(false);
  const [AdminFour, setAdminFour] = useState(false);
  const [AdminFive, setAdminFive] = useState(false);

  const [Form2, setForm2] = useState("");
  const [Form3, setForm3] = useState("");
  const [Form4, setForm4] = useState("");
  const [Form5, setForm5] = useState("");

  //// For group form states ////

  const [GroupName, setGroupName] = useState("");
  const [GroupAddress, setGroupAddress] = useState("");
  const [GroupPolicyNumber, setGroupPolicyNumber] = useState("");
  const [GroupLocation, setGroupLocation] = useState("");

  const [GroupNameState, setGroupNameState] = useState("");
  const [GroupAddressState, setGroupAddressState] = useState("");
  const [GroupPolicyNumberState, setGroupPolicyNumberState] = useState("");
  const [GroupLocationState, setGroupLocationState] = useState("");

  /// First  //
  const [AdministratorFirstNameOne, setAdministratorFirstNameOne] =
    useState("");
  const [AdministratorLastNameOne, setAdministratorLastNameOne] = useState("");
  const [AdministratorCountryCodeOne, setAdministratorCountryCodeOne] =
    useState("");
  const [AdministratorContactNumberOne, setAdministratorContactNumberOne] =
    useState("");
  const [AdministratorEmailOne, setAdministratorEmailOne] = useState("");

  /// Two  //

  const [AdministratorFirstNameTwo, setAdministratorFirstNameTwo] =
    useState("");
  const [AdministratorLastNameTwo, setAdministratorLastNameTwo] = useState("");
  const [AdministratorCountryCodeTwo, setAdministratorCountryCodeTwo] =
    useState("");
  const [AdministratorContactNumberTwo, setAdministratorContactNumberTwo] =
    useState("");
  const [AdministratorEmailTwo, setAdministratorEmailTwo] = useState("");

  /// Three  //

  const [AdministratorFirstNameThree, setAdministratorFirstNameThree] =
    useState("");
  const [AdministratorLastNameThree, setAdministratorLastNameThree] =
    useState("");
  const [AdministratorCountryCodeThree, setAdministratorCountryCodeThree] =
    useState("");
  const [AdministratorContactNumberThree, setAdministratorContactNumberThree] =
    useState("");
  const [AdministratorEmailThree, setAdministratorEmailThree] = useState("");

  //Four//
  const [AdministratorFirstNameFour, setAdministratorFirstNameFour] =
    useState("");
  const [AdministratorLastNameFour, setAdministratorLastNameFour] =
    useState("");
  const [AdministratorCountryCodeFour, setAdministratorCountryCodeFour] =
    useState("");
  const [AdministratorContactNumberFour, setAdministratorContactNumberFour] =
    useState("");
  const [AdministratorEmailFour, setAdministratorEmailFour] = useState("");

  const [AdministratorFirstNameFive, setAdministratorFirstNameFive] =
    useState("");
  const [AdministratorLastNameFive, setAdministratorLastNameFive] =
    useState("");
  const [AdministratorCountryCodeFive, setAdministratorCountryCodeFive] =
    useState("");
  const [AdministratorContactNumberFive, setAdministratorContactNumberFive] =
    useState("");
  const [AdministratorEmailFive, setAdministratorEmailFive] = useState("");

  /// state for the showing the error in the all fields
  const [AdministratorFirstNameStateOne, setAdministratorFirstNameStateOne] =
    useState("");
  const [AdministratorLastNameStateOne, setAdministratorLastNameStateOne] =
    useState("");
  const [
    AdministratorCountryCodeStateOne,
    setAdministratorCountryCodeStateOne,
  ] = useState("");
  const [
    AdministratorContactNumberStateOne,
    setAdministratorContactNumberStateOne,
  ] = useState("");
  const [AdministratorEmailStateOne, setAdministratorEmailStateOne] =
    useState("");

  const [AdministratorFirstNameStateTwo, setAdministratorFirstNameStateTwo] =
    useState("");
  const [AdministratorLastNameStateTwo, setAdministratorLastNameStateTwo] =
    useState("");
  const [
    AdministratorCountryCodeStateTwo,
    setAdministratorCountryCodeStateTwo,
  ] = useState("");
  const [
    AdministratorContactNumberStateTwo,
    setAdministratorContactNumberStateTwo,
  ] = useState("");
  const [AdministratorEmailStateTwo, setAdministratorEmailStateTwo] =
    useState("");

  const [
    AdministratorFirstNameStateThree,
    setAdministratorFirstNameStateThree,
  ] = useState("");
  const [AdministratorLastNameStateThree, setAdministratorLastNameStateThree] =
    useState("");
  const [
    AdministratorCountryCodeStateThree,
    setAdministratorCountryCodeStateThree,
  ] = useState("");
  const [
    AdministratorContactNumberStateThree,
    setAdministratorContactNumberStateThree,
  ] = useState("");
  const [AdministratorEmailStateThree, setAdministratorEmailStateThree] =
    useState("");

  const [AdministratorFirstNameStateFour, setAdministratorFirstNameStateFour] =
    useState("");
  const [AdministratorLastNameStateFour, setAdministratorLastNameStateFour] =
    useState("");
  const [
    AdministratorCountryCodeStateFour,
    setAdministratorCountryCodeStateFour,
  ] = useState("");
  const [
    AdministratorContactNumberStateFour,
    setAdministratorContactNumberStateFour,
  ] = useState("");
  const [AdministratorEmailStateFour, setAdministratorEmailStateFour] =
    useState("");

  const [AdministratorFirstNameStateFive, setAdministratorFirstNameStateFive] =
    useState("");
  const [AdministratorLastNameStateFive, setAdministratorLastNameStateFive] =
    useState("");
  const [
    AdministratorCountryCodeStateFive,
    setAdministratorCountryCodeStateFive,
  ] = useState("");
  const [
    AdministratorContactNumberStateFive,
    setAdministratorContactNumberStateFive,
  ] = useState("");
  const [AdministratorEmailStateFive, setAdministratorEmailStateFive] =
    useState("");

  const dispatch = useDispatch();
  const { AddGroupLeads } = useSelector((state) => state?.addGroupLeadData);
  // console.log("hjhj" ,AddGroupLeads)
  /// For group form states ////
  useEffect(() => {
    if (AddGroupLeads?.data && AddGroupLeads?.data?.success) {

      const location = {
        pathname:
          "/admin/group-opportunities/" + AddGroupLeads?.data?.client_id,
        state: { fromDashboard: true },
      };
      history.push(location);
      dispatch({ type: 
        ADD_GROUP_OPPORTUNITIES_RESET, payload: { data: "" } });
      
    }
  }, [AddGroupLeads]);
  useEffect(() => {
    if (
      AdministratorFirstNameTwo ||
      AdministratorLastNameTwo ||
      AdministratorCountryCodeTwo ||
      AdministratorContactNumberTwo ||
      AdministratorEmailTwo
    ) {
      if (AdministratorFirstNameStateTwo !== "has-success") {
        setAdministratorFirstNameStateTwo("has-danger");
      }
      if (AdministratorLastNameStateTwo !== "has-success") {
        setAdministratorLastNameStateTwo("has-danger");
      }
      if (AdministratorCountryCodeStateTwo !== "has-success") {
        setAdministratorCountryCodeStateTwo("has-danger");
      }
      if (AdministratorContactNumberStateTwo !== "has-success") {
        setAdministratorContactNumberStateTwo("has-danger");
      }
      if (AdministratorEmailStateTwo !== "has-success") {
        setAdministratorEmailStateTwo("has-danger");
      }

      if (
        AdministratorFirstNameStateTwo !== "has-success" ||
        AdministratorLastNameStateTwo !== "has-success" ||
        AdministratorCountryCodeStateTwo !== "has-success" ||
        AdministratorContactNumberStateTwo !== "has-success" ||
        AdministratorEmailStateTwo !== "has-success"
      ) {
        setForm2(false);
      }
      if (
        AdministratorFirstNameStateTwo == "has-success" &&
        AdministratorLastNameStateTwo == "has-success" &&
        AdministratorCountryCodeStateTwo == "has-success" &&
        AdministratorContactNumberStateTwo == "has-success" &&
        AdministratorEmailStateTwo == "has-success"
      ) {
        setForm2(true);
      }

      if (
        AdministratorFirstNameTwo == "" &&
        AdministratorLastNameTwo == "" &&
        AdministratorCountryCodeTwo == "" &&
        AdministratorContactNumberTwo == "" &&
        AdministratorEmailTwo == ""
      ) {
        setForm2(true);
      }
      if (
        AdministratorFirstNameTwo == "" ||
        AdministratorLastNameTwo == "" ||
        AdministratorCountryCodeTwo == "" ||
        AdministratorContactNumberTwo == "" ||
        AdministratorEmailTwo == ""
      ) {
        setForm2(false);

        if (AdministratorFirstNameTwo == "") {
          setAdministratorFirstNameStateTwo("has-danger");
        }
        if (AdministratorLastNameTwo == "") {
          setAdministratorLastNameStateTwo("has-danger");
        }
        if (AdministratorCountryCodeTwo == "") {
          setAdministratorCountryCodeStateTwo("has-danger");
        }
        if (AdministratorContactNumberTwo == "") {
          setAdministratorContactNumberStateTwo("has-danger");
        }
        if (AdministratorEmailTwo == "") {
          setAdministratorEmailStateTwo("has-danger");
        }
      }
    } else {
      setAdministratorFirstNameStateTwo("");
      setAdministratorLastNameStateTwo("");
      setAdministratorCountryCodeStateTwo("");
      setAdministratorContactNumberStateTwo("");
      setAdministratorEmailStateTwo("");
      setForm2(true);
    }
  }, [
    AdministratorFirstNameTwo,
    AdministratorLastNameTwo,
    AdministratorCountryCodeTwo,
    AdministratorContactNumberTwo,
    AdministratorEmailTwo,
  ]);

  useEffect(() => {
    if (
      AdministratorFirstNameThree ||
      AdministratorLastNameThree ||
      AdministratorCountryCodeThree ||
      AdministratorContactNumberThree ||
      AdministratorEmailThree
    ) {
      if (AdministratorFirstNameStateThree !== "has-success") {
        setAdministratorFirstNameStateThree("has-danger");
      }
      if (AdministratorLastNameStateThree !== "has-success") {
        setAdministratorLastNameStateThree("has-danger");
      }
      if (AdministratorCountryCodeStateThree !== "has-success") {
        setAdministratorCountryCodeStateThree("has-danger");
      }
      if (AdministratorContactNumberStateThree !== "has-success") {
        setAdministratorContactNumberStateThree("has-danger");
      }
      if (AdministratorEmailStateThree !== "has-success") {
        setAdministratorEmailStateThree("has-danger");
      }

      if (
        AdministratorFirstNameStateThree !== "has-success" ||
        AdministratorLastNameStateThree !== "has-success" ||
        AdministratorCountryCodeStateThree !== "has-success" ||
        AdministratorContactNumberStateThree !== "has-success" ||
        AdministratorEmailStateThree !== "has-success"
      ) {
        setForm3(false);
      }
      if (
        AdministratorFirstNameStateThree == "has-success" &&
        AdministratorLastNameStateThree == "has-success" &&
        AdministratorCountryCodeStateThree == "has-success" &&
        AdministratorContactNumberStateThree == "has-success" &&
        AdministratorEmailStateThree == "has-success"
      ) {
        setForm3(true);
      }

      if (
        AdministratorFirstNameThree == "" &&
        AdministratorLastNameThree == "" &&
        AdministratorCountryCodeThree == "" &&
        AdministratorContactNumberThree == "" &&
        AdministratorEmailThree == ""
      ) {
        setForm3(true);
      }
      if (
        AdministratorFirstNameThree == "" ||
        AdministratorLastNameThree == "" ||
        AdministratorCountryCodeThree == "" ||
        AdministratorContactNumberThree == "" ||
        AdministratorEmailThree == ""
      ) {
        setForm3(false);

        if (AdministratorFirstNameThree == "") {
          setAdministratorFirstNameStateThree("has-danger");
        }
        if (AdministratorLastNameThree == "") {
          setAdministratorLastNameStateThree("has-danger");
        }
        if (AdministratorCountryCodeThree == "") {
          setAdministratorCountryCodeStateThree("has-danger");
        }
        if (AdministratorContactNumberThree == "") {
          setAdministratorContactNumberStateThree("has-danger");
        }
        if (AdministratorEmailThree == "") {
          setAdministratorEmailStateThree("has-danger");
        }
      }
    } else {
      setAdministratorFirstNameStateThree("");
      setAdministratorLastNameStateThree("");
      setAdministratorCountryCodeStateThree("");
      setAdministratorContactNumberStateThree("");
      setAdministratorEmailStateThree("");
      setForm3(true);
    }
  }, [
    AdministratorFirstNameThree,
    AdministratorLastNameThree,
    AdministratorCountryCodeThree,
    AdministratorContactNumberThree,
    AdministratorEmailThree,
  ]);

  useEffect(() => {
    if (
      AdministratorFirstNameFour ||
      AdministratorLastNameFour ||
      AdministratorCountryCodeFour ||
      AdministratorContactNumberFour ||
      AdministratorEmailFour
    ) {
      if (AdministratorFirstNameStateFour !== "has-success") {
        setAdministratorFirstNameStateFour("has-danger");
      }
      if (AdministratorLastNameStateFour !== "has-success") {
        setAdministratorLastNameStateFour("has-danger");
      }
      if (AdministratorCountryCodeStateFour !== "has-success") {
        setAdministratorCountryCodeStateFour("has-danger");
      }
      if (AdministratorContactNumberStateFour !== "has-success") {
        setAdministratorContactNumberStateFour("has-danger");
      }
      if (AdministratorEmailStateFour !== "has-success") {
        setAdministratorEmailStateFour("has-danger");
      }

      if (
        AdministratorFirstNameStateFour !== "has-success" ||
        AdministratorLastNameStateFour !== "has-success" ||
        AdministratorCountryCodeStateFour !== "has-success" ||
        AdministratorContactNumberStateFour !== "has-success" ||
        AdministratorEmailStateFour !== "has-success"
      ) {
        setForm4(false);
      }
      if (
        AdministratorFirstNameStateFour == "has-success" &&
        AdministratorLastNameStateFour == "has-success" &&
        AdministratorCountryCodeStateFour == "has-success" &&
        AdministratorContactNumberStateFour == "has-success" &&
        AdministratorEmailStateFour == "has-success"
      ) {
        setForm4(true);
      }

      if (
        AdministratorFirstNameFour == "" &&
        AdministratorLastNameFour == "" &&
        AdministratorCountryCodeFour == "" &&
        AdministratorContactNumberFour == "" &&
        AdministratorEmailFour == ""
      ) {
        setForm4(true);
      }
      if (
        AdministratorFirstNameFour == "" ||
        AdministratorLastNameFour == "" ||
        AdministratorCountryCodeFour == "" ||
        AdministratorContactNumberFour == "" ||
        AdministratorEmailFour == ""
      ) {
        setForm4(false);

        if (AdministratorFirstNameFour == "") {
          setAdministratorFirstNameStateFour("has-danger");
        }
        if (AdministratorLastNameFour == "") {
          setAdministratorLastNameStateFour("has-danger");
        }
        if (AdministratorCountryCodeFour == "") {
          setAdministratorCountryCodeStateFour("has-danger");
        }
        if (AdministratorContactNumberFour == "") {
          setAdministratorContactNumberStateFour("has-danger");
        }
        if (AdministratorEmailFour == "") {
          setAdministratorEmailStateFour("has-danger");
        }
      }
    } else {
      setAdministratorFirstNameStateFour("");
      setAdministratorLastNameStateFour("");
      setAdministratorCountryCodeStateFour("");
      setAdministratorContactNumberStateFour("");
      setAdministratorEmailStateFour("");
      setForm4(true);
    }
  }, [
    AdministratorFirstNameFour,
    AdministratorLastNameFour,
    AdministratorCountryCodeFour,
    AdministratorContactNumberFour,
    AdministratorEmailFour,
  ]);

  useEffect(() => {
    if (
      AdministratorFirstNameFive ||
      AdministratorLastNameFive ||
      AdministratorCountryCodeFive ||
      AdministratorContactNumberFive ||
      AdministratorEmailFive
    ) {
      if (AdministratorFirstNameStateFive !== "has-success") {
        setAdministratorFirstNameStateFive("has-danger");
      }
      if (AdministratorLastNameStateFive !== "has-success") {
        setAdministratorLastNameStateFive("has-danger");
      }
      if (AdministratorCountryCodeStateFive !== "has-success") {
        setAdministratorCountryCodeStateFive("has-danger");
      }
      if (AdministratorContactNumberStateFive !== "has-success") {
        setAdministratorContactNumberStateFive("has-danger");
      }
      if (AdministratorEmailStateFive !== "has-success") {
        setAdministratorEmailStateFive("has-danger");
      }

      if (
        AdministratorFirstNameStateFive !== "has-success" ||
        AdministratorLastNameStateFive !== "has-success" ||
        AdministratorCountryCodeStateFive !== "has-success" ||
        AdministratorContactNumberStateFive !== "has-success" ||
        AdministratorEmailStateFive !== "has-success"
      ) {
        setForm5(false);
      }
      if (
        AdministratorFirstNameStateFive == "has-success" &&
        AdministratorLastNameStateFive == "has-success" &&
        AdministratorCountryCodeStateFive == "has-success" &&
        AdministratorContactNumberStateFive == "has-success" &&
        AdministratorEmailStateFive == "has-success"
      ) {
        setForm5(true);
      }

      if (
        AdministratorFirstNameFive == "" &&
        AdministratorLastNameFive == "" &&
        AdministratorCountryCodeFive == "" &&
        AdministratorContactNumberFive == "" &&
        AdministratorEmailFive == ""
      ) {
        setForm5(true);
      }
      if (
        AdministratorFirstNameFive == "" ||
        AdministratorLastNameFive == "" ||
        AdministratorCountryCodeFive == "" ||
        AdministratorContactNumberFive == "" ||
        AdministratorEmailFive == ""
      ) {
        setForm5(false);

        if (AdministratorFirstNameFive == "") {
          setAdministratorFirstNameStateFive("has-danger");
        }
        if (AdministratorLastNameFive == "") {
          setAdministratorLastNameStateFive("has-danger");
        }
        if (AdministratorCountryCodeFive == "") {
          setAdministratorCountryCodeStateFive("has-danger");
        }
        if (AdministratorContactNumberFive == "") {
          setAdministratorContactNumberStateFive("has-danger");
        }
        if (AdministratorEmailFive == "") {
          setAdministratorEmailStateFive("has-danger");
        }
      }
    } else {
      setAdministratorFirstNameStateFive("");
      setAdministratorLastNameStateFive("");
      setAdministratorCountryCodeStateFive("");
      setAdministratorContactNumberStateFive("");
      setAdministratorEmailStateFive("");
      setForm5(true);
    }
  }, [
    AdministratorFirstNameFive,
    AdministratorLastNameFive,
    AdministratorCountryCodeFive,
    AdministratorContactNumberFive,
    AdministratorEmailFive,
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { value: "", label: "Country", isDisabled: true },
  ]);
  const [dialCodeOptions, setdialCodeOptions] = useState([
    { value: "", label: "Dial Code", isDisabled: true },
  ]);
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  let history = useHistory();
  let dayOptions = [];
  let monthOptions = [];
  let yearOptions = [];
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  const date = new Date();
  const GenderOptions = [
    { value: "", label: "Gender", isDisabled: true },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  for (let index = 1; index <= 31; index++) {
    dayOptions.push({ value: index, label: index });
  }
  for (let index = 1; index <= 12; index++) {
    monthOptions.push({ value: index, label: monthNames[index] });
  }
  for (let index = date.getFullYear(); index >= 1901; index--) {
    yearOptions.push({ value: index, label: index });
  }

  useEffect(() => {
    async function getOptions() {
      fetch(process.env.REACT_APP_API_URL + "/get-options", {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            setcountryOptions(data.countries);
            setdialCodeOptions(data.dial_code);
            setLoadingData(false);
           
          } else {
          }
        });
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getOptions();
    }
  }, []);

  const creatLead = async () => {
    if (GroupNameState !== "has-success") {
      setGroupNameState("has-danger");
    }
    // if (GroupAddressState !== "has-success") {
    //   setGroupAddressState("has-danger");
    // }
    if (GroupLocationState !== "has-success") {
      setGroupLocationState("has-danger");
    }
    if (AdministratorFirstNameStateOne !== "has-success") {
      setAdministratorFirstNameStateOne("has-danger");
    }
    if (AdministratorLastNameStateOne !== "has-success") {
      setAdministratorLastNameStateOne("has-danger");
    }
    if (AdministratorCountryCodeStateOne !== "has-success") {
      setAdministratorCountryCodeStateOne("has-danger");
    }
    if (AdministratorContactNumberStateOne !== "has-success") {
      setAdministratorContactNumberStateOne("has-danger");
    }
    if (AdministratorEmailStateOne !== "has-success") {
      setAdministratorEmailStateOne("has-danger");
    }
    if (
      GroupNameState === "has-success" &&
      // GroupAddressState === "has-success" &&
      GroupLocationState === "has-success" &&
      AdministratorFirstNameStateOne === "has-success" &&
      AdministratorLastNameStateOne === "has-success" &&
      AdministratorCountryCodeStateOne === "has-success" &&
      AdministratorContactNumberStateOne === "has-success" &&
      AdministratorEmailStateOne === "has-success" &&
      Form2 &&
      Form3 &&
      Form4 &&
      Form5
    ) {
      let GroupData = {
        group_name: GroupName,
        group_address: GroupAddress,
        group_location: GroupLocation,
        policy_number: GroupPolicyNumber,
        group_administrator1_firstname: AdministratorFirstNameOne,
        group_administrator1_lastname: AdministratorLastNameOne,
        group_administrator1_country_code: AdministratorCountryCodeOne,
        group_administrator1_phone: AdministratorContactNumberOne,
        group_administrator1_email: AdministratorEmailOne,
        group_administrator2_firstname: AdministratorFirstNameTwo,
        group_administrator2_lastname: AdministratorLastNameTwo,
        group_administrator2_country_code: AdministratorCountryCodeTwo,
        group_administrator2_phone: AdministratorContactNumberTwo,
        group_administrator2_email: AdministratorEmailTwo,
        group_administrator3_firstname: AdministratorFirstNameThree,
        group_administrator3_lastname: AdministratorLastNameThree,
        group_administrator3_country_code: AdministratorCountryCodeThree,
        group_administrator3_phone: AdministratorContactNumberThree,
        group_administrator3_email: AdministratorEmailThree,
        group_administrator4_firstname: AdministratorFirstNameFour,
        group_administrator4_lastname: AdministratorLastNameFour,
        group_administrator4_country_code: AdministratorCountryCodeFour,
        group_administrator4_phone: AdministratorContactNumberFour,
        group_administrator4_email: AdministratorEmailFour,
        group_administrator5_firstname: AdministratorFirstNameFive,
        group_administrator5_lastname: AdministratorLastNameFive,
        group_administrator5_country_code: AdministratorCountryCodeFive,
        group_administrator5_phone: AdministratorContactNumberFive,
        group_administrator5_email: AdministratorEmailFive,
      };
      await dispatch(addGroupOpportunity(GroupData, loggedUser.token));
    }
  };

  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  return (
    <>
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <div className="content">
          {searchResult ? (
            <Row className={searchResult ? "" : "d-none"}>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Search Result</CardTitle>
                    <Button
                      onClick={() => {
                        setsearchResult(false);
                      }}
                      className="btn btn-primary float-right"
                    >
                      Back to Search Form
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <Opportunities
                      api="search"
                      leadType="search"
                      method="POST"
                      filterData={searchParams}
                    ></Opportunities>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className={searchResult ? "d-none" : ""}>
              <Col md="12">
                <Card className="card-stats">
                  <CardHeader>
                    <CardTitle tag="h4">Create New Group Opportunity</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label ${GroupNameState}`}
                            >
                              <label>
                                Group Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                type="text"
                                placeholder="Group Name"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setGroupNameState("has-danger");
                                  } else {
                                    setGroupNameState("has-success");
                                  }
                                  setGroupName(e.target.value);
                                }}
                              />
                              {GroupNameState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>
                                Policy Number{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <Input
                                type="text"
                                placeholder="Policy Number"
                                onChange={(e) => {
                                  setGroupPolicyNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label ${GroupAddressState}`}
                            >
                              <label>
                                {/* Address <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <Input
                                type="text"
                                placeholder="Address"
                                onChange={(e) => {
                                  // if (e.value === "") {
                                  //   setGroupAddressState("has-danger");
                                  // } else {
                                  //   setGroupAddressState("has-success");
                                  // }
                                  setGroupAddress(e.target.value);
                                }}
                              />
                              {/* {GroupAddressState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null} */}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label  ${GroupLocationState}`}
                            >
                              <label>
                                Location <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setGroupLocationState("has-danger");
                                  } else {
                                    setGroupLocationState("has-success");
                                  }
                                  setGroupLocation(e.value);
                                }}
                                options={countryOptions}
                                placeholder="Select Location"
                              />
                              {GroupLocationState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      {/* Group1 start */}
                      <div style={{ display: "flex", marginRight: "25%" }}>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorFirstNameStateOne}`}
                          >
                            <label>
                              Group Administrator 1{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              type="text"
                              placeholder="First Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorFirstNameStateOne(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorFirstNameStateOne(
                                    "has-success"
                                  );
                                }
                                setAdministratorFirstNameOne(e.target.value);
                              }}
                            />
                            {AdministratorFirstNameStateOne === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorLastNameStateOne}`}
                          >
                            <label>&nbsp; </label>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorLastNameStateOne(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorLastNameStateOne(
                                    "has-success"
                                  );
                                }
                                setAdministratorLastNameOne(e.target.value);
                              }}
                            />
                            {AdministratorLastNameStateOne === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup
                            className={`has-label  ${AdministratorCountryCodeStateOne}`}
                          >
                            <label>
                              {" "}
                              Contact Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="title"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorCountryCodeStateOne(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorCountryCodeStateOne(
                                    "has-success"
                                  );
                                }
                                setAdministratorCountryCodeOne(e.value);
                              }}
                              options={dialCodeOptions}
                              placeholder="Select Dialing code"
                            />
                            {AdministratorCountryCodeStateOne ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorContactNumberStateOne}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="number"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorContactNumberStateOne(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorContactNumberStateOne(
                                    "has-success"
                                  );
                                }
                                setAdministratorContactNumberOne(
                                  e.target.value
                                );
                              }}
                            />
                            {AdministratorContactNumberStateOne ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorEmailStateOne}`}
                          >
                            <label>
                              Email <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              onChange={(e) => {
                                if (!verifyEmail(e.target.value)) {
                                  setAdministratorEmailStateOne("has-danger");
                                } else {
                                  setAdministratorEmailStateOne("has-success");
                                }
                                setAdministratorEmailOne(e.target.value);
                              }}
                              style={{"width":"135%"}}
                            />
                            {AdministratorEmailStateOne === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </div>
                      {/* Group1 end */}

                      {/* Group2 start */}
                      <div style={{ display: "flex", marginRight: "25%" }}>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorFirstNameStateTwo}`}
                          >
                            <label>Group Administrator 2 </label>
                            <Input
                              type="text"
                              placeholder="First Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorFirstNameStateTwo(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorFirstNameStateTwo(
                                    "has-success"
                                  );
                                }
                                setAdministratorFirstNameTwo(e.target.value);
                              }}
                            />
                            {AdministratorFirstNameStateTwo === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorLastNameStateTwo}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorLastNameStateTwo(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorLastNameStateTwo(
                                    "has-success"
                                  );
                                }
                                setAdministratorLastNameTwo(e.target.value);
                              }}
                            />
                            {AdministratorLastNameStateTwo === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup
                            className={`has-label  ${AdministratorCountryCodeStateTwo}`}
                          >
                            <label>
                              {" "}
                              Contact Number{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="title"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorCountryCodeStateTwo(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorCountryCodeStateTwo(
                                    "has-success"
                                  );
                                }
                                setAdministratorCountryCodeTwo(e.value);
                              }}
                              options={dialCodeOptions}
                              placeholder="Select Dialing code"
                            />
                            {AdministratorCountryCodeStateTwo ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorContactNumberStateTwo}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="number"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorContactNumberStateTwo(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorContactNumberStateTwo(
                                    "has-success"
                                  );
                                }
                                setAdministratorContactNumberTwo(
                                  e.target.value
                                );
                              }}
                            />
                            {AdministratorContactNumberStateTwo ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorEmailStateTwo}`}
                          >
                            <label>
                              Email
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Input
                              onChange={(e) => {
                                if (!verifyEmail(e.target.value)) {
                                  setAdministratorEmailStateTwo("has-danger");
                                } else {
                                  setAdministratorEmailStateTwo("has-success");
                                }
                                setAdministratorEmailTwo(e.target.value);
                              }}
                              style={{"width":"135%"}}
                            />
                            {AdministratorEmailStateTwo === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </div>
                      {/* Group2 end */}

                      {/* Group3 start */}
                      <div style={{ display: "flex", marginRight: "25%" }}>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorFirstNameStateThree}`}
                          >
                            <label>Group Administrator 3 </label>
                            <Input
                              type="text"
                              placeholder="First Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorFirstNameStateThree(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorFirstNameStateThree(
                                    "has-success"
                                  );
                                }
                                setAdministratorFirstNameThree(e.target.value);
                              }}
                            />
                            {AdministratorFirstNameStateThree ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorLastNameStateThree}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorLastNameStateThree(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorLastNameStateThree(
                                    "has-success"
                                  );
                                }
                                setAdministratorLastNameThree(e.target.value);
                              }}
                            />
                            {AdministratorLastNameStateThree ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup
                            className={`has-label  ${AdministratorCountryCodeStateThree}`}
                          >
                            <label>
                              {" "}
                              Contact Number{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="title"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorCountryCodeStateThree(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorCountryCodeStateThree(
                                    "has-success"
                                  );
                                }
                                setAdministratorCountryCodeThree(e.value);
                              }}
                              options={dialCodeOptions}
                              placeholder="Select Dialing code"
                            />
                            {AdministratorCountryCodeStateThree ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorContactNumberStateThree}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="number"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorContactNumberStateThree(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorContactNumberStateThree(
                                    "has-success"
                                  );
                                }
                                setAdministratorContactNumberThree(
                                  e.target.value
                                );
                              }}
                            />
                            {AdministratorContactNumberStateThree ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorEmailStateThree}`}
                          >
                            <label>
                              Email
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Input
                              onChange={(e) => {
                                if (!verifyEmail(e.target.value)) {
                                  setAdministratorEmailStateThree("has-danger");
                                } else {
                                  setAdministratorEmailStateThree(
                                    "has-success"
                                  );
                                }
                                setAdministratorEmailThree(e.target.value);
                              }}
                              style={{"width":"135%"}}
                            />
                            {AdministratorEmailStateThree === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </div>
                      {/* Group3 end */}

                      {/* Group4 start */}
                      <div style={{ display: "flex", marginRight: "25%" }}>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorFirstNameStateFour}`}
                          >
                            <label>Group Administrator 4 </label>
                            <Input
                              type="text"
                              placeholder="First Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorFirstNameStateFour(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorFirstNameStateFour(
                                    "has-success"
                                  );
                                }
                                setAdministratorFirstNameFour(e.target.value);
                              }}
                            />
                            {AdministratorFirstNameStateFour ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorLastNameStateFour}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorLastNameStateFour(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorLastNameStateFour(
                                    "has-success"
                                  );
                                }
                                setAdministratorLastNameFour(e.target.value);
                              }}
                            />
                            {AdministratorLastNameStateFour === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup
                            className={`has-label  ${AdministratorCountryCodeStateFour}`}
                          >
                            <label>
                              {" "}
                              Contact Number{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="title"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorCountryCodeStateFour(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorCountryCodeStateFour(
                                    "has-success"
                                  );
                                }
                                setAdministratorCountryCodeFour(e.value);
                              }}
                              options={dialCodeOptions}
                              placeholder="Select Dialing code"
                            />
                            {AdministratorCountryCodeStateFour ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorContactNumberStateFour}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="number"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorContactNumberStateFour(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorContactNumberStateFour(
                                    "has-success"
                                  );
                                }
                                setAdministratorContactNumberFour(
                                  e.target.value
                                );
                              }}
                            />
                            {AdministratorContactNumberStateFour ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorEmailStateFour}`}
                          >
                            <label>
                              Email
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Input
                              onChange={(e) => {
                                if (!verifyEmail(e.target.value)) {
                                  setAdministratorEmailStateFour("has-danger");
                                } else {
                                  setAdministratorEmailStateFour("has-success");
                                }
                                setAdministratorEmailFour(e.target.value);
                              }}
                              style={{"width":"135%"}}
                            />
                            {AdministratorEmailStateFour === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </div>
                      {/* Group4 end */}

                      {/* Group5 start */}
                      <div style={{ display: "flex", marginRight: "25%" }}>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorFirstNameStateFive}`}
                          >
                            <label>Group Administrator 5 </label>
                            <Input
                              type="text"
                              placeholder="First Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorFirstNameStateFive(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorFirstNameStateFive(
                                    "has-success"
                                  );
                                }
                                setAdministratorFirstNameFive(e.target.value);
                              }}
                            />
                            {AdministratorFirstNameStateFive ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorLastNameStateFive}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorLastNameStateFive(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorLastNameStateFive(
                                    "has-success"
                                  );
                                }
                                setAdministratorLastNameFive(e.target.value);
                              }}
                            />
                            {AdministratorLastNameStateFive === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup
                            className={`has-label  ${AdministratorCountryCodeStateFive}`}
                          >
                            <label>
                              {" "}
                              Contact Number{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="title"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorCountryCodeStateFive(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorCountryCodeStateFive(
                                    "has-success"
                                  );
                                }
                                setAdministratorCountryCodeFive(e.value);
                              }}
                              options={dialCodeOptions}
                              placeholder="Select Dialing code"
                            />
                            {AdministratorCountryCodeStateFive ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorContactNumberStateFive}`}
                          >
                            <label>
                              <br></br>{" "}
                            </label>
                            <Input
                              type="number"
                              onChange={(e) => {
                                if (e.value === "") {
                                  setAdministratorContactNumberStateFive(
                                    "has-danger"
                                  );
                                } else {
                                  setAdministratorContactNumberStateFive(
                                    "has-success"
                                  );
                                }
                                setAdministratorContactNumberFive(
                                  e.target.value
                                );
                              }}
                            />
                            {AdministratorContactNumberStateFive ===
                            "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup
                            className={`has-label ${AdministratorEmailStateFive}`}
                          >
                            <label>
                              Email
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Input
                              onChange={(e) => {
                                if (!verifyEmail(e.target.value)) {
                                  setAdministratorEmailStateFive("has-danger");
                                } else {
                                  setAdministratorEmailStateFive("has-success");
                                }
                                setAdministratorEmailFive(e.target.value);
                              }}
                              style={{"width":"135%"}}
                            />
                            {AdministratorEmailStateFive === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </div>
                      {/* Group5 end */}

                      <Col className="ml-auto mr-auto text-center" md="12">
                        {/* {loggedUser.data.role != 2 ? ( */}
                        <Button
                          color="primary"
                          onClick={() => {
                            creatLead();
                          }}
                        >
                          Add New
                        </Button>
                        {/* ) : null} */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default AddGroupLead;
