/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import Statistics from "components/Statistics";
import SubmitLoader from "components/SubmitLoader";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

function Dashboard() {
  const [showLoader, setShowLoader] = useState(true);
  const [statObj, setstatObj] = useState([]);
  const [graphObj, setgraphObj] = useState([]);
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  useEffect(() => {
    async function getDashboardData() {
      fetch(process.env.REACT_APP_API_URL + "/get-dashboard-data", {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            console.log(data)
            setgraphObj(data.lead_data);            
            setstatObj(data.stat_data);
            setShowLoader(false);
          }
        });

    }
    if (showLoader) {
      getDashboardData();
    }
  }, []);
  const graphData = (type) => {
    let options = {
      type:Line,
      data: (canvas) => {
        return {
          labels: graphObj.labels,
          datasets: [
            {
              label: "Opportunities",
              borderColor: "#3fa9f5",
              backgroundColor: "#3fa9f5",
              pointRadius: 5,
              pointHoverRadius: 10,
              borderWidth: 1,
              tension: 0.4,
              fill: false,
              data: graphObj.values,
            },
          ],
        };
      },
      options: {
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: true },
          
        },
        scales: {
          y: {
            ticks: {
              color: "#9f9f9f",
              beginAtZero: true,
              maxTicksLimit: 5,
            },
            grid: {
              drawBorder: true,
              display: true,
            },
          },
          x: {
            barPercentage: 1.6,
            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              padding: 20,
              color: "#9f9f9f",
            },
          },
        },
      },
    };
    if (type === "data") {
      return options.data;
    } else {
      return options.options;
    }
  };
  return (
    <>

      <SubmitLoader showLoader={showLoader} />


      <div className="content">
        {
          showLoader ?
            null
            :
            <Row>
              {statObj.map((element, index) => (
                <Statistics stat={element.stat} icon={element.icon} head={element.head} foot={element.foot} color={element.color} />
              ))}

              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle>Opportunities</CardTitle>
                    <p className="card-category">Last 12 Month Performace</p>
                  </CardHeader>
                  <CardBody>
                    <Line
                      data={graphData('data')}
                      options={graphData('options')}
                      width={400}
                      height={100}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
        }
      </div>
    </>
  );

}

export default Dashboard;
