/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import SubmitLoader from "components/SubmitLoader";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Col,
  Row,
} from "reactstrap";

function SetupFA() {
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  const [errorMsg, seterrorMsg] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('userData'));

  let history = useHistory();
  const ConfirmScan = () => {
    var data = {
      "user": "otp"
    }
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.user.token);
    fetch(process.env.REACT_APP_API_URL + "/confirm-scan", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if(data.success){
          history.push("/verify-otp");
        }
      });

  };

  return (
    <div className="login-page">
      <SubmitLoader showLoader={showLoader} />
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="8" md="8">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>

                  <CardHeader>
                    <h3 className="header text-center">Set up Microsoft Authenticator</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody className="text-center">
                  <p>Set up you 2FA by scanning the barcode below. Alternatively, you can use the code {loggedUser.user.secretKey}</p>

                  <FormGroup className={`has-label text-center`}>
                    <SVG src={loggedUser.user.qr_code} />
                  </FormGroup>
                  <p>You must set up your Google Authenticator app before continuing. You will be unable to login otherwise</p>
                  <Button color="primary" onClick={ConfirmScan}>
                    Confirm QR Code Scanning
                  </Button>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
     
    </div>
  );
}

export default SetupFA;
