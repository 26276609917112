/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState, useEffect, useCallback } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
import AppTable from "components/ReactTable/AppTable";
import ServerPagination from "components/ReactTable/ServerPagination";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "store/TableAction";
import { TableSliceAction } from "store/TableDataSlice";
import InsurerFilters from "components/Filters/insurer-filter";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

function AllInsurer() {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [loadingData, setLoadingData] = useState(true);
  const [alert, setAlert] = React.useState(null);
  const [RefreshTable, setRefreshTable] = useState();
  let history = useHistory();
  const { pageData, currentPage, sortColumn, sortDirection, InsurerFilter } = useSelector(state => state.tableData);
  const columns = useMemo(() => [
    {
      Header: "Insurer Logo",
      accessor: "logo",
      sortdir: "",
      sorCol: ""
    },
    {
      Header: "Insurer Company Name",
      accessor: "title",
      sortdir: sortColumn === "title" ? sortDirection : '',
      sorCol: sortColumn === "title" ? 'yes' : 'no',
    },
    {
      Header: "Type Medical",
      accessor: "medical_type",
      sortdir: sortColumn === "medical_type" ? sortDirection : '',
      sorCol: sortColumn === "medical_type" ? 'yes' : 'no',
    },
    {
      Header: "Type Travel",
      accessor: "travel_type",
      sortdir: sortColumn === "travel_type" ? sortDirection : '',
      sorCol: sortColumn === "travel_type" ? 'yes' : 'no',
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ]);
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);

  useEffect(() => {
    if (loadingData) {
     
      dispatch(getProducts('list-insurer', 'title', 'ASC',headers));
    }
  }, [currentPage, sortDirection, InsurerFilter, RefreshTable]);

  useEffect(() => {
    dispatch(TableSliceAction.reset() ) ;
  }, [])
  const warningWithConfirmMessage = (insurer_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(insurer_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this Insurer!
      </ReactBSAlert>
    );
  };
  const successDelete = (insurer_id) => {
    const deleteInsurerUrl = process.env.REACT_APP_API_URL + "/delete-insurer";
    fetch(deleteInsurerUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ "insurer_id": insurer_id })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {

        } else {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert("no")}
              onCancel={() => hideAlert("no")}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
          setRefreshTable(insurer_id);
        }
      });

  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };
  const editInsurer = (insurer_id) => {
    history.push("/admin/edit-insurer/" + insurer_id);
  }
  const formatRowData = (rawData) =>
    rawData.map((info) => ({
      id: info[0],
      logo: (<img src={process.env.REACT_APP_STORAGE_URL + "insurer/" + info[2]} alt="" />),
      title: info[1],
      medical_type: info[3],
      travel_type: info[4],
      actions: (
        // we've added some custom button actions
        <div className="actions-right">

          {/* use this button to add a edit kind of action */}
          <Button
            onClick={() => {
              editInsurer(info[0])
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              warningWithConfirmMessage(info[0])
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" />
          </Button>{" "}
        </div>
      ),
    }));

  const getData = async (pageNo = 1, sortOrder = 'ASC', column = 'title') => {
    dispatch(TableSliceAction.updateTableConfig({ sCol: column, sDir: sortOrder }));
  };
  const updatePage = async (pageNo = 1) => {
    dispatch(TableSliceAction.updatePage(pageNo));
  };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">All Insurer</CardTitle>
              <Link to="/admin/add-insurer" className="btn btn-primary float-right">Add New Insurer</Link>
            </CardHeader>
            <CardBody>
              {alert}
              <InsurerFilters />
              <Row>


                <Col md="12">
                  <AppTable
                    columns={columns}
                    data={formatRowData(pageData.rowData)}
                    isLoading={pageData.isLoading}
                    sorting={getData}
                    page={currentPage}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="12">
                  <ServerPagination
                    totalRows={pageData.totalRecords}
                    pageChangeHandler={updatePage}
                    rowsPerPage={100}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default AllInsurer;
