/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useReducer, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
import { Button, Card, CardHeader, CardBody, CardTitle, Label, FormGroup, Input, Row, Col } from "reactstrap";

const defaultValues = {
    policy_type: "",
    deductible_excess: "",
    annual_limit: "",
    hospital_cover: "",
    network: "",
    outpatient_limit: "",
    oncology_cancer: "",
    vaccinations: "",
    physiotherapy: "",
    dental_limit: "",
    annual_physical: "",
    maternity_limit: "",
    insurer_id: "",
    plan_name: "",
    dental_wait_period: "",
    evacuation: "",
    maternity_wait_period: "",
    customer_rating: "",
    area_of_cover: "",
    product_type: "medical",
    outpatient_radio: "cross",
    outpatient_text: "",
    dental_radio: "cross",
    dental_text: "",
    hospitalization_radio: "cross",
    hospitalization_text: "",
    maternity_radio: "cross",
    maternity_text: "",
    exclude_countries : "",

};

const mFormReducer = (state, action) => {
    if (action.type === 'VALIDCHECK') {
        let currentField = action.fieldName;
        const updatedVals = { ...state, [currentField]: action.fieldValue }
        return updatedVals;
    }
    if (action.type === 'INVALID') {
        return state;
    }

    return defaultValues;
};
function AddMedical() {
    const notificationAlert = React.useRef();
    const [showLoader, setShowLoader] = React.useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [mFormVals, dispatchMFormVals] = useReducer(mFormReducer, defaultValues);
    const getFormDataUrl = process.env.REACT_APP_API_URL + "/get-insurer-and-doctype/country";
    const [insurerOptions, setinsurerOptions] = useState("");
    const [doctypeOptions, setdoctypeOptions] = useState([]);
    const [countryOptions, setcountryOptions] = useState([]);
    const [mReadonly, setmReadonly] = useState(true);
    const [opReadonly, setopReadonly] = useState(true);
    const [dReadonly, setdReadonly] = useState(true);
    const [hReadonly, sethReadonly] = useState(true);
    const [fileState, setFileState] = React.useState(null);
    const [formValues, setFormValues] = useState([{ doctype: "", description: "", docfile: "" }])
    const [btnClicked, setBtnClicked] = useState(false);
    let history = useHistory();

    const saveMedicalDraft = () => {
        const allFormValues = { ...mFormVals, 'status': "0", "product_id": 0 };
        let formData = new FormData();
        formData.append('medicalProduct', JSON.stringify(allFormValues));
        formData.append('excle_doc', fileState === null ? "" : fileState);
        for (var i = 0; i < formValues.length; i++) {
            formData.append('document_type[' + i + ']', formValues[i].doctype);
            formData.append('document[' + i + ']', formValues[i].docfile);
            formData.append('description[' + i + ']', formValues[i].description);
        }
        sendData(formData, 'save-medical-draft');
    };
    const saveMedical = () => {
        Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));
        const emptyFields = Object.filter(mFormVals, ([key, val]) => val === "" && key !== 'hospitalization_text' && key !== 'maternity_text' && key !== 'dental_text' && key !== 'outpatient_text' && key !== 'exclude_countries');
        var keyCount = Object.keys(emptyFields).length;
        console.log(mFormVals);
        if (keyCount > 0) {
            setBtnClicked(true);
            dispatchMFormVals({ type: 'INVALID' });
        } else {
            const allFormValues = { ...mFormVals, 'status': "1" };
            let formData = new FormData();
            formData.append('medicalProduct', JSON.stringify(allFormValues));
            formData.append('excle_doc', fileState);
            for (var i = 0; i < formValues.length; i++) {
                formData.append('document_type[' + i + ']', formValues[i].doctype);
                formData.append('document[' + i + ']', formValues[i].docfile);
                formData.append('description[' + i + ']', formValues[i].description);
            }
            sendData(formData, 'save-medical');
        }
    };
    const sendData = (data, url) => {
        setShowLoader(true);
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + loggedUser.token);
        fetch(process.env.REACT_APP_API_URL + "/" + url, {
            method: "POST",
            headers: headers,
            body: data
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (!data.success) {
                    notify(data.message, 3, notificationAlert);
                } else {
                    notify(data.message, 2, notificationAlert);
                    setTimeout(function () { history.push('/admin/all-products') }, 3000);

                }
                setShowLoader(false);
            });
    }
    useEffect(() => {
        async function getFormData() {
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + loggedUser.token);
            fetch(getFormDataUrl, {
                method: "GET",
                headers: headers,
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    console.log(data);
                    if (!data.success) {

                    } else {
                        setLoadingData(false);
                        setinsurerOptions(data.insurer_list);
                        setdoctypeOptions(data.document_list);
                        setcountryOptions(data.country_list)
                    }
                });

        }
        if (loadingData) {
            // if the result is not ready so you make the axios call
            getFormData();
        }
    }, []);

    const loggedUser = JSON.parse(localStorage.getItem('current_users'));

    const getReadOnlyStatus = (field_type, status) => {
        console.log(field_type, status);
        if (status) {
            if (field_type == "MaternityText") {
                setmReadonly(true);
            }
            if (field_type == "OutpatientText") {
                setopReadonly(true);
            }
            if (field_type == "HospitalizationText") {
                sethReadonly(true);
            }
            if (field_type == "DentalText") {
                setdReadonly(true);
            }

        } else {
            if (field_type == "MaternityText") {
                setmReadonly(false);
            }
            if (field_type == "OutpatientText") {
                setopReadonly(false);
            }
            if (field_type == "HospitalizationText") {
                sethReadonly(false);
            }
            if (field_type == "DentalText") {
                setdReadonly(false);
            }
        }
    };

    let handleChange = (i, e, type) => {
        let newFormValues = [...formValues];
        if (type === "file") {
            newFormValues[i][e.target.name] = e.target.files[0];
        } else {
            newFormValues[i][e.target.name] = e.target.value;
        }

        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, { doctype: "", description: "", docfile: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const fileInput = React.useRef();
    const handleImageChange = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        setFileState(file);
    };
    const checkLenghtHandler = (value, field) => {
        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: field, fieldValue: value });

    }

    return (
        <>
            <SubmitLoader showLoader={showLoader} />
            <NotificationAlert ref={notificationAlert} />
            <div className="content">
                <Row>
                    <Col className="ml-auto mr-auto" lg="11" md="11" sm="11">
                        <Card className="card-stats">
                            <CardHeader>
                                <Row>
                                    <Col md="10">
                                        <CardTitle tag="h4">Add New Medical </CardTitle>
                                    </Col>
                                    <Col md="2">
                                        <Link to="/admin/all-products" className="btn btn-primary float-right">Back to List</Link>
                                    </Col>
                                </Row>


                            </CardHeader>
                            <CardBody>
                                <h3>Section 1: Benefit Details</h3>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.insurer_id === "" ? "has-danger" : "has-success"}`}>
                                            <label>Insurer *</label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="medicalType"
                                                onChange={(e) => {
                                                    dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "insurer_id", fieldValue: e.value });

                                                }}
                                                options={insurerOptions}
                                                placeholder="Select Insurer"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.policy_type === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Policy Type *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "policy_type") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.plan_name === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Plan Name *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "plan_name") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.deductible_excess === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Deductible/ Excess *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "deductible_excess") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.annual_limit === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Annual Limit *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "annual_limit") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.hospital_cover === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Hospital Cover *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "hospital_cover") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.network === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Network *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "network") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.outpatient_limit === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Outpatient Limit *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "outpatient_limit") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.oncology_cancer === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Oncology/ Cancer *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "oncology_cancer") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.vaccinations === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Vaccinations *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "vaccinations") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.physiotherapy === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Physiotherapy *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "physiotherapy") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.dental_limit === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Dental Limit *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "dental_limit") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.annual_physical === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Annual Physical *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "annual_physical") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.maternity_limit === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Maternity Limit *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "maternity_limit") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.dental_wait_period === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Dental Wait Period *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "dental_wait_period") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.evacuation === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Evacuation *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "evacuation") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.maternity_wait_period === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Maternity Wait Period *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "maternity_wait_period") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.customer_rating === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Customer Rating *</label>
                                            <Input type="number" onChange={(e) => { checkLenghtHandler(e.target.value, "customer_rating") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${mFormVals.area_of_cover === "" && btnClicked ? "has-danger" : "has-success"}`}>
                                            <label>Area of Cover *</label>
                                            <Input type="text" onChange={(e) => { checkLenghtHandler(e.target.value, "area_of_cover") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4">
                                    <label>Exclude Local Nationals</label>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="medicalType"
                                            isMulti                                           
                                            onChange={(e) => {
                                                dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "exclude_countries", fieldValue: e });

                                            }}
                                            options={countryOptions}
                                            placeholder="Select Countries to exclude"
                                        />
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="4"></Col>
                                </Row>
                                <h3>Section 2: Benefits</h3>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="2">
                                        Hospitalization
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultChecked defaultValue="cross" id="exampleRadios12" name="Hospitalization_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "hospitalization_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("HospitalizationText", true);
                                                    }}
                                                />
                                                No(show cross icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="tick" id="exampleRadios12" name="Hospitalization_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "hospitalization_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("HospitalizationText", true);
                                                    }}
                                                />
                                                Yes(show tick icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="text" id="exampleRadios12" name="Hospitalization_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "hospitalization_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("HospitalizationText", false);
                                                    }}
                                                />
                                                Yes(show text) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <Input type="text" readOnly={hReadonly} onChange={(e) => { checkLenghtHandler(e.target.value, "hospitalization_text") }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="2">
                                        Outpatient
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultChecked defaultValue="cross" id="exampleRadios12" name="Outpatient_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "outpatient_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("OutpatientText", true);
                                                    }}
                                                />
                                                No(show cross icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="tick" id="exampleRadios12" name="Outpatient_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "outpatient_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("OutpatientText", true);
                                                    }}
                                                />
                                                Yes(show tick icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="text" id="exampleRadios12" name="Outpatient_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "outpatient_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("OutpatientText", false);
                                                    }}
                                                />
                                                Yes(show text) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <Input type="text" readOnly={opReadonly} onChange={(e) => { checkLenghtHandler(e.target.value, "outpatient_text") }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="2">
                                        Dental
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input

                                                    defaultChecked defaultValue="cross" id="exampleRadios12" name="Dental_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "dental_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("DentalText", true);
                                                    }}
                                                />
                                                No(show cross icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="tick" id="exampleRadios12" name="Dental_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "dental_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("DentalText", true);
                                                    }}
                                                />
                                                Yes(show tick icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="text" id="exampleRadios12" name="Dental_radio" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "dental_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("DentalText", false);
                                                    }}
                                                />
                                                Yes(show text) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <Input type="text" readOnly={dReadonly} onChange={(e) => { checkLenghtHandler(e.target.value, "dental_text") }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="ml-auto mr-auto" md="2">
                                        Maternity
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultChecked defaultValue="cross" id="exampleRadios12" name="Maternity_repatriation" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "maternity_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("MaternityText", true);
                                                    }}
                                                />
                                                No(show cross icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="tick" id="exampleRadios12" name="Maternity_repatriation" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "maternity_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("MaternityText", true);
                                                    }}
                                                />
                                                Yes(show tick icon) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <div className="form-check-radio inline">
                                            <Label check>
                                                <Input
                                                    defaultValue="text" id="exampleRadios12" name="Maternity_repatriation" type="radio"
                                                    onChange={(e) => {
                                                        dispatchMFormVals({ type: 'VALIDCHECK', fieldName: "maternity_radio", fieldValue: e.target.value });
                                                        getReadOnlyStatus("MaternityText", false);
                                                    }}
                                                />
                                                Yes(show text) <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="2">
                                        <Input type="text" readOnly={mReadonly} onChange={(e) => { checkLenghtHandler(e.target.value, "maternity_text") }} />
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col className="" md="9">
                                        <h3>Please Upload a Document</h3>

                                    </Col>
                                    <Col className="" md="3">
                                        <Button color="primary" onClick={() => addFormFields()}>Add More Document</Button>
                                    </Col>
                                </Row>
                                {formValues.map((element, index) => (
                                    <Row key={index}>
                                        <Col className="ml-auto mr-auto" md="3">
                                            <FormGroup>
                                                <label>Document Type </label>
                                                <select className="form-control" onChange={e => handleChange(index, e, "input")} name="doctype">
                                                    <option>Select Document Type</option>
                                                    {doctypeOptions.map((ele, i) => (
                                                        <option key={"doctype" + ele.value} value={ele.value}>{ele.label}</option>
                                                    ))}

                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col className="ml-auto mr-auto" md="3">
                                            <FormGroup>
                                                <label>Document Description </label>
                                                <Input name="description" type="text" onChange={e => handleChange(index, e, "input")} />
                                            </FormGroup>

                                        </Col>
                                        <Col className="ml-auto mr-auto" md="3">
                                            <label>Upload Document </label>
                                            <input type="file" className="form-control" name="docfile" onChange={e => handleChange(index, e, "file")} />
                                        </Col>
                                        <Col className="ml-auto mr-auto" md="3">
                                            <label> </label>
                                            {
                                                index ?

                                                    <Button color="danger" className="button remove" onClick={() => removeFormFields(index)}>Remove</Button>

                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                ))}

                                <Row>
                                    <Col className="" md="12">
                                        <FormGroup>
                                            <Label>Please select an excel document with prices*</Label>
                                            <input type="file" onChange={e => handleImageChange(e)} ref={fileInput} />
                                        </FormGroup>

                                        <p className="">This columns are required in excel file:</p>
                                        <ul>
                                            <li><p>Regular Monthly Price M or Regular Monthly Premium M or Regular Annual Price M</p></li>
                                            <li><p>Regular Monthly Price F or Regular Monthly Premium F or Regular Annual Price F</p></li>
                                            <li><p>Age</p></li>
                                            <li><p>Location</p></li>
                                        </ul>


                                    </Col>
                                </Row>
                            </CardBody>
                            <Row>
                                <Col className="ml-auto mr-auto text-center m-2" md="12">
                                    <Button color="primary" size="lg" onClick={() => saveMedical()}>
                                        Save & Publish
                                    </Button>
                                    <Button color="info" size="lg" onClick={() => saveMedicalDraft()}>
                                        Save as Draft
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AddMedical;