/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState, useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
import AppTable from "components/ReactTable/AppTable";
import ServerPagination from "components/ReactTable/ServerPagination";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";




function AllConsultants() {
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [loadingData, setLoadingData] = useState(true);
  const [alert, setAlert] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setsortColumn] = useState("name");
  const [sortDirection, setsortDirection] = useState("ASC");
  const [RefreshTable, setRefreshTable] = useState();
  let history = useHistory();
  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      sortdir: sortColumn === "name" ? sortDirection : '',
      sorCol: sortColumn === "name" ? 'yes' : 'no',
    },
    {
      Header: "Email",
      accessor: "email",
      sortdir: sortColumn === "email" ? sortDirection : '',
      sorCol: sortColumn === "email" ? 'yes' : 'no',
    },
    {
      Header: "Role",
      accessor: "role_name",
      sortdir: sortColumn === "role_name" ? sortDirection : '',
      sorCol: sortColumn === "role_name" ? 'yes' : 'no',
    },
    {
      Header: "Added By",
      accessor: "added_by",
      sortdir: sortColumn === "added_by" ? sortDirection : '',
      sorCol: sortColumn === "added_by" ? 'yes' : 'no',
    },
    {
      Header: "Added On",
      accessor: "created_at",
      sortdir: sortColumn === "created_at" ? sortDirection : '',
      sorCol: sortColumn === "created_at" ? 'yes' : 'no',
    },
    {
      Header: "Last Login",
      accessor: "last_login",
      sortdir: sortColumn === "last_login" ? sortDirection : '',
      sorCol: sortColumn === "last_login" ? 'yes' : 'no',
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ]);
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalRecords: 0,
  });

  useEffect(() => {
    async function getUser() {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(process.env.REACT_APP_API_URL + "/list-user/" + currentPage + "/" + sortDirection + "/" + sortColumn, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (!data.success) {

          } else {
            setPageData({
              isLoading: false,
              rowData: formatRowData(data.users),
              totalPages: data.totalPages,
              totalRecords: data.totalRecords,
            });
          }
        })
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getUser();
    }


  }, [currentPage, sortDirection, RefreshTable]);

  const warningWithConfirmMessage = (user_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(user_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this User!
      </ReactBSAlert>
    );
  };
  const successDelete = (user_id) => {
    const deleteUser = process.env.REACT_APP_API_URL + "/delete-user";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    fetch(deleteUser, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ "user_id": user_id })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {

        } else {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert("no")}
              onCancel={() => hideAlert("no")}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
          setRefreshTable(user_id);
        }
      });

  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };
  const formatRowData = (rawData) =>
    rawData.map((info) => ({
      id: info[0],
      name: info[1],
      email: info[2],
      role_name: info[3],
      added_by: info[4],
      last_login: info[5],
      created_at: info[6],
      actions: (
        // we've added some custom button actions
        <div className="actions-right">

          {/* use this button to add a edit kind of action */}
          <Button
            onClick={() => {
              editUser(info[0])
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" />
          </Button>{" "}
          {/* use this button to remove the data row */}
          {
            loggedUser.data.role === "1" || loggedUser.data.role === "2"
              ?
              <Button
                onClick={() => {
                  warningWithConfirmMessage(info[0])
                }}
                color="danger"
                size="sm"
                className="btn-icon btn-link remove"
              >
                <i className="fa fa-times" />
              </Button>
              :
              null
          }
          {" "}
        </div>
      ),
    }));
  const editUser = (user_id) => {
    history.push("/admin/edit-consultants/" + user_id);
  };
  const getData = async (pageNo = 1, sortOrder = 'ASC', column = 'name') => {
    setsortColumn(column);
    setsortDirection(sortOrder);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">All Users</CardTitle>
              <Link to="/admin/add-consultants" className="btn btn-primary float-right">Add New Users</Link>
            </CardHeader>
            <CardBody>
              {alert}

              <Row>
                <Col md="12">
                  <AppTable
                    columns={columns}
                    data={pageData.rowData}
                    isLoading={pageData.isLoading}
                    sorting={getData}
                    page={currentPage}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="12">
                  <ServerPagination
                    totalRows={pageData.totalRecords}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={100}
                  />
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default AllConsultants;
