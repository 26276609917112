/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";


function Download() { 
  const [showLoader, setShowLoader] = useState(true);
  const params = useParams();
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  useEffect(() => {
    async function getDashboardData() {
      fetch(process.env.REACT_APP_API_URL +'/download-excel/' + params.id, {
        method: 'GET',
        headers: headers,
      })
        .then((response) => response.blob() 
        )
        .then((blob) => {
          console.log(blob);
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `FileName.xlsx`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
      

    }
    if (showLoader) {
      getDashboardData();
    }
  }, []);
  const graphData = (type) => {
    let options = {
      type: Line,
      data: (canvas) => {
        return {
          labels: graphObj.labels,
          datasets: [
            {
              label: "Opportunities",
              borderColor: "#6bd098",
              backgroundColor: "#6bd098",
              pointRadius: 5,
              pointHoverRadius: 10,
              borderWidth: 1,
              tension: 0.4,
              fill: false,
              data: graphObj.values,
            },
          ],
        };
      },
      options: {
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: true },

        },
        scales: {
          y: {
            ticks: {
              color: "#9f9f9f",
              beginAtZero: true,
              maxTicksLimit: 5,
            },
            grid: {
              drawBorder: true,
              display: true,
            },
          },
          x: {
            barPercentage: 1.6,
            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              padding: 20,
              color: "#9f9f9f",
            },
          },
        },
      },
    };
    if (type === "data") {
      return options.data;
    } else {
      return options.options;
    }
  };
  return (
    <>

      <div className="content">

      </div>
    </>
  );

}

export default Download;
