import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Row, Col } from "reactstrap";
import { TableSliceAction } from "store/TableDataSlice";
import {
  statusOptions,
  GroupStatusOptions,
  productOptions,
  productGroupOptions,
  generatorOption,
  priorityOptions,
} from "variables/productOption";

const OpportunityFilter = (props) => {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadStatus, setLoadStatus] = useState();
  let optArr;
  // if((loggedUser.data.role === 2 || loggedUser.data.role === 3 || loggedUser.data.role === 4  || loggedUser.data.role === 5 || loggedUser.data.role === 6) && (props.leadType === "my")){
  //     optArr = [

  //         { value: "Opportunity Open", label: "Opportunity Open" },
  //         { value: "Account Pending", label: "Account Pending" },
  //       ];
  // }else if((loggedUser.data.role === 2 || loggedUser.data.role === 3 || loggedUser.data.role === 4  || loggedUser.data.role === 5 || loggedUser.data.role === 6) && (props.leadType === "all")){
  //     optArr = [

  //         { value: "Online Application", label: "Online Application" },
  //         { value: "Opportunity", label: "Opportunity" },
  //       ];
  // }else{
  //     optArr = [ { value:"",label:"All"}, ...statusOptions];
  // }
  // if (
  //   (loggedUser.data.role === 2 ||
  //     loggedUser.data.role === 3 ||
  //     loggedUser.data.role === 4 ||
  //     loggedUser.data.role === 5 ||
  //     loggedUser.data.role === 6) &&
  //   props.leadType === "my"
  // ) {
  //   optArr = [
  //     { value: "Opportunity Open", label: "Opportunity Open" },
  //     { value: "Account Pending", label: "Account Pending" },
  //   ];
  // } else if (
  //   (loggedUser.data.role === 2 ||
  //     loggedUser.data.role === 3 ||
  //     loggedUser.data.role === 4 ||
  //     loggedUser.data.role === 5 ||
  //     loggedUser.data.role === 6) &&
  //   props.leadType === "all"
  // ) {
  //   if (loggedUser.data.role === 2) {
  //     optArr = [
  //       { value: "Online Application", label: "Online Application" },
  //       { value: "Opportunity", label: "Opportunity" },
  //       { value: "Account Pending", label: "Account Pending" },
  //     ];
  //   } else {
  //     optArr = [
  //       { value: "Online Application", label: "Online Application" },
  //       { value: "Opportunity", label: "Opportunity" },
  //     ];
  //   }
  // } else {
  //   optArr = [{ value: "", label: "All" }, ...statusOptions];
  // }
  //console.log('===========================+++++++++> ', loggedUser.data.role, props.leadType);

  if (
    (loggedUser.data.role === 3 ||
      loggedUser.data.role === 4 ||
      loggedUser.data.role === 6 ||
      loggedUser.data.role === 8
      
      ) &&
    props.leadType === "my"
  ) {
    optArr = [
      { value: "Opportunity Open", label: "Opportunity Open" },
      { value: "Account Pending", label: "Account Pending" },
    ];
  } else if (
    (loggedUser.data.role === 1 ||
      loggedUser.data.role === 2 ||
      loggedUser.data.role === 3 ||
      loggedUser.data.role === 4 ||
      loggedUser.data.role === 5 ||
      loggedUser.data.role === 6 ||
      loggedUser.data.role === 8) &&
    props.leadType === "all"
  ) {
    //   if (loggedUser.data.role === 2) {
    //     optArr = [
    //       { value: "Online Application", label: "Online Application" },
    //       { value: "Opportunity", label: "Opportunity" },
    //       { value: "Account Pending", label: "Account Pending" },
    //     ];
    //   } else {
    //     optArr = [
    //       { value: "Online Application", label: "Online Application" },
    //       { value: "Opportunity", label: "Opportunity" },
    //     ];
    //   }
    optArr = [
      { value: "All", label: "All" },
      { value: "Opportunity", label: "Opportunity" },
      { value: "Online Application", label: "Online Application" },
    ];
  } else if (
    (loggedUser.data.role === 1 || loggedUser.data.role === 5) &&
    props.leadType === "my"
  ) {
    optArr = [
      { value: "Account Pending", label: "Account Pending" },
      { value: "Opportunity Open", label: "Opportunity Open" },
    ];
  } else if (loggedUser.data.role === 2 && props.leadType === "my") {
    optArr = [{ value: "Account Pending", label: "Account Pending" }];
  } else if (props.leadType === "report-group") {
    optArr = [{ value: "", label: "All" }, ...GroupStatusOptions];
  } else {
    optArr = [{ value: "", label: "All" }, ...statusOptions];
  }

  useEffect(() => {
    if (props.leadType === "my") {
      if (
        loggedUser.data.role === 3 ||
        loggedUser.data.role === 4 ||
        loggedUser.data.role === 6 ||
        loggedUser.data.role === 1 ||
        loggedUser.data.role === 5
      ) {
        dispatch(
          TableSliceAction.updateFilterParam({
            field: "o_status",
            value: "Opportunity Open",
          })
        );

        setLoadStatus([
          { value: "Opportunity Open", label: "Opportunity Open" },
        ]);
      }

      optArr = [
        { value: "Opportunity Open", label: "Opportunity Open" },
        { value: "Account Pending", label: "Account Pending" },
      ];
    }
  }, []);


  return (
    <>
      {console.log("props.leadType", props)}

      <Row>
        {props.leadType == "all" && (
          <>
            <Col
              lg={props.leadType === "report-group" ? 2 : 4}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Status*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "o_status",
                        value: e.value,
                      })
                    );
                    setLoadStatus({ value: e.value, label: e.label });
                  }}
                  value={loadStatus}
                  options={optArr}
                  placeholder="Status"
                />
              </FormGroup>
            </Col>
            <Col
              lg={props.leadType === "report-group" ? 2 : 4}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Product*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "product_type",
                        value: e.value,
                      })
                    );
                  }}
                  // options={[{ value: "", label: "All" }, ...productOptions]}
                  options={[
                    { value: "", label: "All" },
                    ...(props.leadType === "report-group"
                      ? productGroupOptions
                      : productOptions),
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col lg={props.leadType === "report-group" ? 2 : 4}>
              <FormGroup className={`has-label`}>
                <label>Generator*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "generator",
                        value: e.value,
                      })
                    );
                  }}
                  options={[{ value: "", label: "All" }, ...generatorOption]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </>
        )}

        {props.leadType == "report" && (
          <>
            <Col
              lg={props.leadType === "report-group" ? 2 : 2}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Status*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "o_status",
                        value: e.value,
                      })
                    );
                    setLoadStatus({ value: e.value, label: e.label });
                  }}
                  value={loadStatus}
                  options={optArr}
                  placeholder="Status"
                />
              </FormGroup>
            </Col>
            <Col
              lg={props.leadType === "report-group" ? 2 : 2}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Product*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "product_type",
                        value: e.value,
                      })
                    );
                  }}
                  // options={[{ value: "", label: "All" }, ...productOptions]}
                  options={[
                    { value: "", label: "All" },
                    ...(props.leadType === "report-group"
                      ? productGroupOptions
                      : productOptions),
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col lg={props.leadType === "report-group" ? 2 : 2}>
              <FormGroup className={`has-label`}>
                <label>Generator*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "generator",
                        value: e.value,
                      })
                    );
                  }}
                  options={[{ value: "", label: "All" }, ...generatorOption]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Client Relationship Manager*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    console.log("e.value", e.value);

                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "client_relatioship_manager",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Staffoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Support Manager*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "support_manager",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Supportoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </>
        )}

        {props.leadType == "my" && (
          <>
            <Col
              lg={props.leadType === "report-group" ? 2 : 3}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Status*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "o_status",
                        value: e.value,
                      })
                    );
                    setLoadStatus({ value: e.value, label: e.label });
                  }}
                  value={loadStatus}
                  options={optArr}
                  placeholder="Status"
                />
              </FormGroup>
            </Col>
            <Col
              lg={props.leadType === "report-group" ? 2 : 3}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Product*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "product_type",
                        value: e.value,
                      })
                    );
                  }}
                  // options={[{ value: "", label: "All" }, ...productOptions]}
                  options={[
                    { value: "", label: "All" },
                    ...(props.leadType === "report-group"
                      ? productGroupOptions
                      : productOptions),
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col lg={props.leadType === "report-group" ? 2 : 3}>
              <FormGroup className={`has-label`}>
                <label>Generator*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "generator",
                        value: e.value,
                      })
                    );
                  }}
                  options={[{ value: "", label: "All" }, ...generatorOption]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col lg={props.leadType === "report-group" ? 2 : 3}>
              <FormGroup className={`has-label`}>
                <label>Priority*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "priority",
                        value: e.value,
                      })
                    );
                  }}
                  options={[{ value: "", label: "All" }, ...priorityOptions]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </>
        )}

        {/* {props.leadType !== "report-group" && (
          <Col lg={props.leadType === "report-group" ? 2 : 4}>
            <FormGroup className={`has-label`}>
              <label>Generator*</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={(e) => {
                  dispatch(
                    TableSliceAction.updateFilterParam({
                      field: "generator",
                      value: e.value,
                    })
                  );
                }}
                options={[{ value: "", label: "All" }, ...generatorOption]}
                placeholder="Select"
              />
            </FormGroup>
          </Col>
        )} */}

        {props.leadType === "report-group" && (
          <>
            <Col
              lg={2}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Status*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "o_status",
                        value: e.value,
                      })
                    );
                    setLoadStatus({ value: e.value, label: e.label });
                  }}
                  value={loadStatus}
                  options={optArr}
                  placeholder="Status"
                />
              </FormGroup>
            </Col>
            <Col
              lg={props.leadType === "report-group" ? 2 : 2}
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Product*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "product_type",
                        value: e.value,
                      })
                    );
                  }}
                  // options={[{ value: "", label: "All" }, ...productOptions]}
                  options={[
                    { value: "", label: "All" },
                    ...(props.leadType === "report-group"
                      ? productGroupOptions
                      : productOptions),
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Client Relationship Manager*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    console.log("e.value", e.value);

                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "client_relatioship_manager",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Staffoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Account Manager*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "account_manager",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Staffoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Account Director*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "account_director",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Staffoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Support Manager*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "support_manager",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Supportoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>

            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Client Development Manager*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "client_development_manager",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Staffoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Support Manager 2*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "support_manager2",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.Staffoptions,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
            <Col
              lg="2"
              className={props.leadType === "report-group" ? "rowFilter" : ""}
            >
              <FormGroup className={`has-label`}>
                <label>Location*</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    dispatch(
                      TableSliceAction.updateFilterParam({
                        field: "location",
                        value: e.value,
                      })
                    );
                  }}
                  options={[
                    { value: "", label: "All" },
                    ...props?.countryOption,
                  ]}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default OpportunityFilter;
