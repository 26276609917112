import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import StatusLogs from "./StatusLogs";
const StatusLogsPage = () => {
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Status Logs</CardTitle>
            </CardHeader>
            <CardBody>
              <StatusLogs api="all-leads" leadType="all" method="GET" filterData=""></StatusLogs>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StatusLogsPage