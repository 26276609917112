import React, { useEffect, useState , useMemo} from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import { Doughnut } from "react-chartjs-2";
import sortImg from "assets/img/sort.png";
import { notify } from "components/functions";
import NotificationAlert from "react-notification-alert";
import SubmitLoader from "components/SubmitLoader";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Collapse,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";
import { sortList, drawCanvas } from "components/functions";

function CreateTravelProposal() {
    const [loadingData, setLoadingData] = React.useState(true);
    const getOptionUrl = process.env.REACT_APP_API_URL + "/get-options/";
    const loggedUser = JSON.parse(localStorage.getItem('current_users'));
    const [countrytOptions, setcountrytOptions] = useState([]);
    const [person, setperson] = useState();
    const [destination, setdestination] = useState();
    const [requirement, setrequirement] = useState([{ value: "medical_expenses", label: "Medical Expenses" }]);
    const [personAge, setpersonAge] = useState();
    const [gender, setgender] = useState();
    const [nationality, setnationality] = useState();
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [additonalpersonform, setadditonalpersonform] = useState([]);
    const [PolicyType, setPolicyType] = useState();
    const [PersonInfoState, setPersonInfoState] = useState("has-success");
    const [PolicyTypeState, setPolicyTypeState] = useState("");
    const [personNoState, setpersonNoState] = useState("");
    const [destinationState, setdestinationState] = useState("");
    const [nationalityState, setnationalityState] = useState("");
    const [startDate, setstartDate] = useState("");
    const [startDateState, setstartDateState] = useState("");
    const [endDate, setendDate] = useState("");
    const [endDateState, setendDateState] = useState("");
    const [productResult, setproductResult] = useState({ data: {} });
    const [openedCollapses, setOpenedCollapses] = React.useState([]);
    const yesterday = moment().subtract(1, 'day');
    const [showLoader, setShowLoader] = React.useState(false);
    const notificationAlert = React.useRef();
    let history = useHistory();
    const params = useParams();
    // with this function we create an array with the opened collapses
    // it is like a toggle function for all collapses from this page
    const collapsesToggle = (collapse) => {
        if (openedCollapses.includes(collapse)) {
            setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
        } else {
            openedCollapses.push(collapse);
            setOpenedCollapses([...openedCollapses, collapse]);
        }
    };
    let proposal_products = useMemo(() =>[],[]);
    let personOptions = [];
    let additionlInfo = [];
    
    let policyHolderAgeOption = [];
    let additionalPersonAge = [];
    for (let index = 1; index <= 8; index++) {
        personOptions.push({ "value": index, "label": index });
    }
    for (let index = 18; index <= 69; index++) {
        policyHolderAgeOption.push({ "value": index, "label": index });
    }
    for (let index = 0; index <= 74; index++) {
        additionalPersonAge.push({ "value": index, "label": index });
    }
    const requirementOptions = [
        { value: "medical_expenses", label: "Medical Expenses", isFixed: true, isDisabled: true },
        { value: "flight_cancelation", label: "Flight Cancelation" },
        { value: "baggage", label: "Baggage" },
        { value: "evacuation", label: "Evacuation" },

    ];
    const genderOptions = [
        { value: "", label: "Gender", isDisabled: true },
        { value: "male", label: "Male" },
        { value: "female", label: "Female" }
    ];
    const policyTypeOptions = [
        { value: "", label: "Policy Type", isDisabled: true },
        { value: "Annual", label: "Annual" },
        { value: "Single Trip", label: "Single Trip" }
    ];
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };

    useEffect(() => {
        async function getOptions() {
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + loggedUser.token);
            fetch(getOptionUrl, {
                method: "GET",
                headers: headers,
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    console.log(data);
                    if (data.success) {
                        setcountrytOptions(data.countries);
                        setLoadingData(false);
                    } else {
                        setLoadingData(false);

                    }
                });
        }
        if (loadingData) {
            // if the result is not ready so you make the axios call
            getOptions();
        }
    }, []);
    const MultiValueRemove = (props) => {
        if (props.data.isFixed) {
            return null;
        }
        return <components.MultiValueRemove {...props} />;
    };
    const [formValues, setFormValues] = useState([{ gender: "", age: "", relation_status: "self" }]);
    const switchStep = (step) => {
        if (step == setStep2) {
            if (personNoState !== "has-success") {
                setpersonNoState('has-danger')
            }
            if (destinationState !== "has-success") {
                setdestinationState('has-danger')
            }

            if (personNoState === "has-success" && destinationState === "has-success") {

                //additionlInfo[0] = { gender: "", age: "", relation_status: "" };
                for (let index = 1; index <= person.value; index++) {
                    //additionlInfo[index] = { gender: "", age: "", relation_status: "" };
                    additionlInfo.push({ gender: "", age: "", relation_status: "" });

                }
                setStep1(false);
                step(true);
                // setadditonalpersonform(additionlInfo);
                setFormValues(additionlInfo);
            }
        }
        //addFormFields();
        if (step == setStep1) {
            setStep2(false);
            setStep1(true);
        }
        if (step == setStep3) {
            getProduct();
        }


    };
    const filterArray = (req, value) => {
        for (let index = 0; index <= req.length - 1; index++) {
            //console.log(req[index]['value']);
            if (req[index]['value'] == value) {
                return true;
                break;
            }
        }
    };
    const CreateProposal = () => {
        const createMedicalProposalUrl = process.env.REACT_APP_API_URL + "/create-travel-proposal";

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + loggedUser.token);
        var data = {
            "medical_expenses": filterArray(requirement, 'medical_expenses') ? "yes" : "no",
            "flight_cancelation": filterArray(requirement, 'flight_cancelation') ? "yes" : "no",
            "baggage": filterArray(requirement, 'baggage') ? "yes" : "no",
            "evacuation": filterArray(requirement, 'evacuation') ? "yes" : "no",
            "destination": destination.value,
            "no_of_person": person.value,
            "persons": formValues,
            "policy_type": PolicyType,
            "products": proposal_products,
            "client_id": params.client_id
        }
        if (proposal_products.length > 0) {
            setShowLoader(true);
            fetch(createMedicalProposalUrl, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    console.log(data);
                    if (!data.success) {

                    } else {
                        notify('Proposal Created Successfully.', 2, notificationAlert);
                        setShowLoader(false);
                        setTimeout(function () {
                            history.push("/admin/opportunities/" + params.client_id);
                        }, 2000)
                    }
                });
        } else {
            notify('Please Select a plan to continue.', 3, notificationAlert);
        }

    }
    const getProduct = () => {
        // console.log(requirement);
        let flag = "no-error";
        for (var i = 0; i < formValues.length; i++) {
            if (formValues[i].gender == "" || formValues[i].age == "") {
                flag = "error";
            }
        }
        console.log(flag);
        if (flag === "error") {
            setPersonInfoState("has-danger");
        } else {
            setPersonInfoState("has-success");
        }
        if (nationalityState !== "has-success") {
            setpersonNoState('has-danger')
        }
        if (endDateState !== "has-success") {
            setendDateState('has-danger')
        }
        if (startDateState !== "has-success") {
            setstartDateState('has-danger')
        }
        if (PolicyTypeState !== "has-success") {
            setPolicyTypeState('has-danger')
        }

        if (flag === "no-error" && nationalityState === "has-success" && endDateState === "has-success" && startDateState === "has-success" && PolicyTypeState === "has-success") {
            setShowLoader(true);
            const getMedicalUrl = process.env.REACT_APP_API_URL + "/get-travel-products/ASC/premium/1";
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            var data = {
                "medical_expenses": filterArray(requirement, 'medical_expenses') ? "yes" : "no",
                "flight_cancelation": filterArray(requirement, 'flight_cancelation') ? "yes" : "no",
                "baggage": filterArray(requirement, 'baggage') ? "yes" : "no",
                "evacuation": filterArray(requirement, 'evacuation') ? "yes" : "no",
                "destination": destination.value,
                "no_of_person": person.value,
                "policy_type": PolicyType.value,
                "persons": formValues
            }
            fetch(getMedicalUrl, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setShowLoader(false);
                    if (!data.success) {
                        notify(data.message, 3, notificationAlert);
                    } else {
                        console.log(data);
                        setproductResult(data);
                    }
                });

            setStep3(true);
            setStep2(false);
        }
    }

    let handleChange = (i, e, type, name = "") => {

        let newFormValues = [...formValues];

        if (type === "select") {
            newFormValues[i][name] = e.value;
        } else if (type === "date") {
            newFormValues[i][name] = moment(e.toDate()).format('DD-MM-YYYY');
        } else {
            newFormValues[i][e.target.name] = e.target.value;
        }
        if (i === 0) {
            newFormValues[i]['relation_status'] = "self";
        }
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, { gender: "", age: "" }]);
    }

    return (
        <div className="content">
            <Row>
                <SubmitLoader showLoader={showLoader} />
                <NotificationAlert ref={notificationAlert} />
                {loadingData ? (
                    <p>Loading Please wait...</p>
                ) :
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h5></h5>
                            </CardHeader>
                            <CardBody>

                                <Row className={step1 ? "" : "d-none"}>

                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${personNoState}`}>
                                            <label>How Many People Travelling</label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                onChange={(e) => {
                                                    setperson(e);
                                                    if (e.value === "") {
                                                        setpersonNoState("has-danger");
                                                    } else {
                                                        setpersonNoState("has-success");
                                                    }
                                                }}
                                                options={personOptions}
                                                placeholder="How Many People Travelling"
                                            />
                                            {personNoState === "has-danger" ? (
                                                <label className="error">This field is required.</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>

                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label ${destinationState}`}>
                                            <label>Select Destination</label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                onChange={(e) => {
                                                    setdestination(e);
                                                    if (e.value === "") {
                                                        setdestinationState("has-danger");
                                                    } else {
                                                        setdestinationState("has-success");
                                                    }
                                                }}
                                                options={countrytOptions}
                                                placeholder="Select Location"
                                            />
                                            {destinationState === "has-danger" ? (
                                                <label className="error">This field is required.</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>

                                    <Col className="ml-auto mr-auto" md="4">
                                        <FormGroup className={`has-label`}>
                                            <label>Select Your Requirements:</label>
                                            <Select
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                placeholder="Select Your Requirements"
                                                hideSelectedOptions={false}
                                                isMulti
                                                defaultValue={[requirementOptions[0]]}
                                                isClearable={false}
                                                options={requirementOptions}
                                                components={{ MultiValueRemove }}
                                                onChange={(e) => {
                                                    setrequirement(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" md="12">
                                        <Button color="primary" onClick={(e) => { switchStep(setStep2) }}>Next</Button>
                                    </Col>
                                </Row>

                                <Row className={step2 ? "" : "d-none"}>
                                    <Col md="12">
                                        {PersonInfoState === "has-danger" ? (
                                            <label className="error text-danger">All Fields are required.</label>
                                        ) : null}
                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="2"> </Col>

                                            <Col className="ml-auto mr-auto" md="4">
                                                <FormGroup className={`has-label ${PolicyTypeState}`}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => {
                                                            setPolicyType(e);
                                                            if (e.value === "") {
                                                                setPolicyTypeState("has-danger");
                                                            } else {
                                                                setPolicyTypeState("has-success");
                                                            }
                                                        }}
                                                        options={policyTypeOptions}
                                                        placeholder="Policy Type"
                                                    />
                                                    {PolicyTypeState === "has-danger" ? (
                                                        <label className="error">This field is required.</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>


                                            <Col className="ml-auto mr-auto" md="6">
                                                <FormGroup className={`has-label ${nationalityState}`}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => {
                                                            setnationality(e);
                                                            if (e.value === "") {
                                                                setnationalityState("has-danger");
                                                            } else {
                                                                setnationalityState("has-success");
                                                            }
                                                        }}
                                                        options={countrytOptions}
                                                        placeholder="Select Nationality"
                                                    />
                                                    {nationalityState === "has-danger" ? (
                                                        <label className="error">This field is required.</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="2"> </Col>
                                            <Col className="ml-auto mr-auto" md="4">
                                                <FormGroup className={`has-label ${startDateState}`}>
                                                    <label>Start Date</label>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            className: "form-control",
                                                            placeholder: "Date Picker Here",
                                                            value: startDate || ""
                                                        }}
                                                        isValidDate={disablePastDt}
                                                        timeFormat={false}
                                                        onChange={(e) => {
                                                            setstartDate(moment(e.toDate()).format('DD-MM-YYYY'));
                                                            if (moment(e.toDate()) === "") {
                                                                setstartDateState("has-danger");
                                                            } else {
                                                                setstartDateState("has-success");
                                                            }

                                                        }}
                                                    />
                                                </FormGroup>
                                                {startDateState === "has-danger" ? (
                                                    <label className="error">This field is required.</label>
                                                ) : null}
                                            </Col>

                                            <Col className="ml-auto mr-auto" md="6">
                                                <FormGroup className={`has-label ${endDateState}`}>
                                                    <label>End Date</label>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            className: "form-control",
                                                            placeholder: "Date Picker Here",
                                                            value: endDate || ""
                                                        }}
                                                        isValidDate={disablePastDt}
                                                        timeFormat={false}
                                                        onChange={(e) => {
                                                            setendDate(moment(e.toDate()).format('DD-MM-YYYY'));
                                                            if (moment(e.toDate()) === "") {
                                                                setendDateState("has-danger");
                                                            } else {
                                                                setendDateState("has-success");
                                                            }
                                                        }}

                                                    />
                                                    {endDateState === "has-danger" ? (
                                                        <label className="error">This field is required.</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {formValues.map((element, index) => (

                                            <Row key={index}>
                                                <Col className="ml-auto mr-auto text-center" md="2">
                                                    <FormGroup className={`has-label`}>
                                                        <label>Person {index + 1}</label>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="ml-auto mr-auto" md="4">
                                                    <FormGroup className={`has-label`}>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            onChange={e => {
                                                                handleChange(index, e, "select", "age")
                                                                setPersonInfoState('has-success');
                                                            }}
                                                            options={index === 0 ? policyHolderAgeOption : additionalPersonAge}
                                                            placeholder="Select Age"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col className="ml-auto mr-auto" md="6">
                                                    <FormGroup className={`has-label`}>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            onChange={e => {
                                                                handleChange(index, e, "select", "gender")
                                                                setPersonInfoState('has-success');
                                                            }}
                                                            options={genderOptions}
                                                            placeholder="Select Gender"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        ))}

                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="12">
                                                <Button color="primary" onClick={(e) => { switchStep(setStep1) }}>Previous</Button>
                                                <Button color="primary" onClick={(e) => { switchStep(setStep3) }}>Next</Button>
                                            </Col>

                                        </Row>
                                    </Col>

                                </Row>
                                <Row className={step3 ? "" : "d-none"}>
                                    <Col md="12">
                                        <ul className="list-group" id="id01">
                                            <li className="list-group-item bg-dark text-light">
                                                <Row>
                                                    <Col md="1" className="ml-auto mr-auto text-center font-weight-bold">Insurer</Col>
                                                    <Col md="2" className="ml-auto mr-auto text-center font-weight-bold">Medical Expenses</Col>
                                                    <Col md="2" className="ml-auto mr-auto text-center font-weight-bold">Flight Cancelation</Col>
                                                    <Col md="2" className="ml-auto mr-auto text-center font-weight-bold">Baggage</Col>
                                                    <Col md="1" className="ml-auto mr-auto text-center font-weight-bold">Evacuation</Col>
                                                    <Col md="2" className="ml-auto mr-auto text-center font-weight-bold" onClick={(e) => { sortList('rating') }}>Customer Rating <img src={sortImg}></img></Col>
                                                    <Col md="1" className="ml-auto mr-auto text-center font-weight-bold" onClick={(e) => { sortList('premium') }}>Premium <img src={sortImg}></img></Col>
                                                    <Col md="1" className="ml-auto mr-auto text-center font-weight-bold"></Col>
                                                </Row>
                                            </li>

                                            {
                                                productResult.data.length > 0 ?
                                                    productResult.data.map((element, index) => (

                                                        <li key={index} className="list-group-item bg-light mt-4">
                                                            <Row key={index}>
                                                                <Col md="1" className="ml-auto mr-auto text-center"><img src={element.product.insurer_logo} width="100" height="100"></img></Col>
                                                                <Col md="2" className="ml-auto mr-auto text-center">{element.product.medical_expenses_radio === 'tick' ? <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i> : element.product.medical_expenses_radio === 'text' ? element.product.medical_expenses_text : <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>}</Col>
                                                                <Col md="2" className="ml-auto mr-auto text-center">{element.product.flight_cancelation_radio === 'tick' ? <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i> : element.product.flight_cancelation_radio === 'text' ? element.product.flight_cancelation_text : <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>}</Col>
                                                                <Col md="2" className="ml-auto mr-auto text-center">{element.product.baggage_radio === 'tick' ? <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i> : element.product.baggage_radio === 'text' ? element.product.baggage_text : <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>}</Col>
                                                                <Col md="1" className="ml-auto mr-auto text-center">{element.product.evacuation_repatriation_radio === 'tick' ? <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i> : element.product.evacuation_repatriation_radio === 'text' ? element.product.evacuation_repatriation_text : <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>}</Col>
                                                                <Col md="2" data-val={element.product.customer_rating} className="ml-auto mr-auto text-center ratings"><Doughnut
                                                                    data={drawCanvas(element.product.customer_rating, "data")}
                                                                    options={drawCanvas(element.product.customer_rating, "options")}
                                                                    className="ct-chart ct-perfect-fourth"
                                                                    height={20}
                                                                    width={20}
                                                                /></Col>
                                                                <Col md="1" data-val={element.product.premium} className="ml-auto mr-auto text-center premium">{element.product.premium} USD</Col>
                                                                <Col md="1" className="ml-auto mr-auto text-center">
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input type="checkbox" value={element.product.product_id}
                                                                                onChange={(e) => {
                                                                                    const checked = e.target.checked;
                                                                                    if (checked) {
                                                                                        proposal_products.push(e.target.value);
                                                                                    } else {
                                                                                        var index = proposal_products.indexOf(e.target.value);
                                                                                        if (index > -1) {
                                                                                            proposal_products.splice(index, 1);
                                                                                        }
                                                                                    }
                                                                                }} />
                                                                            <span className="form-check-sign" />

                                                                        </Label>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12" className="text-center">
                                                                    <Card className="card-plain">
                                                                        <CardHeader role="tab">
                                                                            <a
                                                                                className="text-primary font-weight-bold"
                                                                                aria-expanded={openedCollapses.includes("collapseOne")}

                                                                                data-parent="#accordion"
                                                                                data-toggle="collapse"
                                                                                onClick={() => collapsesToggle("collapse" + index)}
                                                                            >
                                                                                See Full Benefits
                                                                                <br />
                                                                                <i className="nc-icon nc-minimal-down" />
                                                                            </a>
                                                                        </CardHeader>
                                                                        <Collapse
                                                                            role="tabpanel"
                                                                            isOpen={openedCollapses.includes("collapse" + index)}
                                                                        >
                                                                            <CardBody className="text-left">
                                                                                <Row>
                                                                                    <Col md="4">
                                                                                        <Row>
                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Provider</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.insurer_title}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Medical Expenses</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.medical_expenses}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Flight Cancelation</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.flight_cancelation}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Personal Effects</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.personal_effects}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Adventure Sport</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.adventure_sport}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Terrorism</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.terrorism}</Col>

                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="4">

                                                                                        <Row>
                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Plan name</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.plan_name}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Deductible/Excess</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.deductible_excess}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Flight Delay</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.flight_delay}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Loss of Passport</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.loss_of_passport}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Skiing</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.skiing}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Evacuation and Repatriation</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.evacuation_repatriation}</Col>

                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="4">
                                                                                        <Row>
                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Product</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.product_type}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Personal Accident</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.personal_accident}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Baggage</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.baggage}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Money</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.money}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Scuba Diving</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.scuba_diving}</Col>

                                                                                            <Col md="6" className="mt-2 h6 text-muted">&#9642; Area of Cover</Col>
                                                                                            <Col md="6" className="mt-2 h6 text-capitalize text-muted">{element.product.area_of_cover}</Col>
                                                                                        </Row>

                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Collapse>
                                                                    </Card>
                                                                </Col>
                                                            </Row>

                                                        </li>



                                                    ))
                                                    :
                                                    <li className="list-group-item">No Data Found !</li>
                                            }
                                        </ul>

                                    </Col>
                                    <Col md="12" className="text-center"><Button color="primary" onClick={(e) => { CreateProposal() }}>Create Proposal</Button></Col>

                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                }
            </Row>
        </div>

    );

}
export default CreateTravelProposal;