/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom';
import SubmitLoader from "components/SubmitLoader";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

function ForgotPassword() {
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  const [loginEmail, setloginEmail] = React.useState("");
  const [loginEmailState, setloginEmailState] = React.useState("");
  const [errorMsg, seterrorMsg] = React.useState(false);
  const [successMsg, setsuccessMsg] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const sendNewPassword = () => {

    if (loginEmailState === "") {
      setloginEmailState("has-danger");
    }

    var data = {
      "email": loginEmail
    }
    setShowLoader(true);
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(process.env.REACT_APP_API_URL + "/forgot-password", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
          seterrorMsg(data.message);
          setsuccessMsg(false);
        } else {
          setsuccessMsg(data.message);
          seterrorMsg(false);
        }
        setShowLoader(false);

      });

  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  return (
    <div className="login-page">
      <SubmitLoader showLoader={showLoader}/>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Forgot Password </h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  {errorMsg ?
                    <div className="text-center alert alert-danger">
                      {errorMsg}
                    </div>
                    :
                    null
                  }
                  {successMsg ?
                    <div className="text-center alert alert-success">
                      {successMsg}
                    </div>
                    :
                    null
                  }
                  <FormGroup className={`has-label ${loginEmailState}`}>
                    <label>Email Address *</label>
                    <Input
                      name="email"
                      type="email"
                      onChange={(e) => {
                        if (!verifyEmail(e.target.value)) {
                          setloginEmailState("has-danger");
                        } else {
                          setloginEmailState("has-success");
                        }
                        setloginEmail(e.target.value);
                      }}
                    />
                    {loginEmailState === "has-danger" ? (
                      <label className="error">
                        Please enter a valid email address.
                      </label>
                    ) : null}
                  </FormGroup>

                  <br />
                  <FormGroup className="text-center">
                    <Link to='/login' className="text-dark">Back to Signin</Link>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => sendNewPassword()}
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      
    </div>
  );
}

export default ForgotPassword;
