import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Row, Col } from "reactstrap";
import { TableSliceAction } from "store/TableDataSlice";
import { GetOppotunitesApi } from "store/FetchOpportunities";
import {
  statusOptions,
  productOptions,
  productGroupOptions,
  generatorOption,
  priorityOptions
} from "variables/productOption";

const OpportunitiesFilter = (props) => {

  console.log("props", props)
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadStatus, setLoadStatus] = useState();

  const [statusFilter, setStatusFilter] = useState();
  const [ProductFilter, setProductFilter] = useState();
  let optArr;

  if (
    (loggedUser.data.role === 3 ||
      loggedUser.data.role === 4 ||
      loggedUser.data.role === 6 ) &&
    props.leadType === "my"
  ) {
    optArr = [
      // { value: "All", label: "All" },
      { value: "Opportunity Open", label: "Opportunity Open" },
      { value: "Account Pending", label: "Account Pending" },
      { value: "Quoting", label: "Quoting" },
    ];
  } else if (
    (loggedUser.data.role === 1 || loggedUser.data.role === 5 || loggedUser.data.role === 7) &&
    props.leadType === "my"
  ) {
    optArr = [
      // { value: "All", label: "All" },
      { value: "Account Pending", label: "Account Pending" },
      { value: "Quoting", label: "Quoting" },
      { value: "Opportunity Open", label: "Opportunity Open" },
    ];
  } else if (loggedUser.data.role === 2 && props.leadType === "my") {
    optArr = [
      // { value: "All", label: "All" },
      { value: "Account Pending", label: "Account Pending" },
      { value: "Quoting", label: "Quoting" },
      { value: "Opportunity Open", label: "Opportunity Open" },
    ];
  } else {
    optArr = [{ value: "All", label: "All" }, ...statusOptions];
  }

  useEffect(() => {
    if (props.leadType === "my") {
      if (
        loggedUser.data.role === 3 ||
        loggedUser.data.role === 4 ||
        loggedUser.data.role === 6 ||
        loggedUser.data.role === 1 ||
        loggedUser.data.role === 5
      ) {
        // dispatch(
        //   TableSliceAction.updateFilterParam({
        //     field: "o_status",
        //     value: "Opportunity Open",
        //   })
        // );

        setLoadStatus([
          { value: "Opportunity Open", label: "Opportunity Open" },
        ]);
      }

      optArr = [
        // { value: "All", label: "All" },
        { value: "Opportunity Open", label: "Opportunity Open" },
        { value: "Account Pending", label: "Account Pending" },
        { value: "Quoting", label: "Quoting" },
      ];
    }
  }, []);
  // await  dispatch(GetOppotunitesApi(e.value, loggedUser.token));
  useEffect(async () => {
    const fillterSearch = async () => {
      if (statusFilter != "" || ProductFilter != "") {
        let newStatus = "";
        let newProduct = "";
        if (statusFilter) {
          newStatus = statusFilter;
        } else {
          newStatus = "Opportunity Open";
        }
        if (ProductFilter) {
          newProduct = ProductFilter;
        } else {
          newProduct = "";
        }
        let newData = {
          o_status: newStatus,
          product_type: newProduct,
        };
        localStorage.setItem('groupstatus', newStatus)
        await dispatch(GetOppotunitesApi(newData, loggedUser.token));
      }
    };

    fillterSearch();
  }, [ProductFilter, statusFilter]);

  return (
    <>
      <Row>
        <Col lg="4">
          <FormGroup className={`has-label`}>
            <label>Status*</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              onChange={ (e) => {
                // setStatusFilter(e.value);
                dispatch(
                  TableSliceAction.updateFilterParam({
                    field: "o_status",
                    value: e.value,
                  })
                );
                setLoadStatus({ value: e.value, label: e.label });
              }}
              // menuContainerStyle={{top: 'auto',	bottom: '100%'}}
              value={loadStatus}
              options={optArr}
              placeholder="Status"
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className={`has-label`}>
            <label>Product*</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              onChange={(e) => {
                // console.log("ProductFilter", e);
                // setProductFilter(e.value);
                dispatch(
                  TableSliceAction.updateFilterParam({
                    field: "product_type",
                    value: e.value,
                  })
                );
              }}
              // options={[{ value: "", label: "All" }, {props.api == "my-group-leads" ? ...productGroupOptions :...productOptions } ]}

              options={[
                { value: "", label: "All" },
                ...(props.productType === "my-group-leads" ? productGroupOptions : productOptions)
              ]}
              placeholder="Select"
            />
            {console.log("propspropspropspropsprops", props)}
          </FormGroup>
        </Col>

        <Col lg="4">
          <FormGroup className={`has-label`}>
            <label>Priority*</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              onChange={(e) => {
                // console.log("ProductFilter", e);
                // setProductFilter(e.value);
                dispatch(
                  TableSliceAction.updateFilterParam({
                    field: "priority",
                    value: e.value,
                  })
                );
              }}
              // options={[{ value: "", label: "All" }, {props.api == "my-group-leads" ? ...productGroupOptions :...productOptions } ]}

              options={[{ value: "", label: "All" }, ...priorityOptions]}
              placeholder="Select"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default OpportunitiesFilter;
