/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import Select from "react-select";
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertToHTML } from 'draft-convert';
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";




function EditInsurer() {
  const params = useParams();
  const notificationAlert = React.useRef();
  const [loadingData, setLoadingData] = React.useState(true);
  const [insurerNameState, setinsurerNameState] = React.useState("has-success");
  const [insurerName, setinsurerName] = React.useState("");
  const [medicalType, setmedicalType] = React.useState("");
  const [travelType, settravelType] = React.useState("");
  const [medicalTypeState, setmedicalTypeState] = React.useState("has-success");
  const [travelTypeState, settravelTypeState] = React.useState("has-success");
  const [linkMedical, setlinkMedical] = React.useState("");
  const [linkTravel, setlinkTravel] = React.useState("");
  const [linkTravelSingle, setlinkTravelSingle] = React.useState("");
  const [textEditor, settextEditor] = React.useState("");
  const [textEditorState, settextEditorState] = React.useState("has-success");
  const [fileState, setFileState] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);
  const getInsurerUrl = process.env.REACT_APP_API_URL+"/get-insurer/"+params.id;
  const editInsurerUrl = process.env.REACT_APP_API_URL+"/update-insurer/";
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));

  const select_options = {
    "No Type" : { value: "No Type", label: "No Type" },
    "A" : { value: "A", label: "A" },
    "B" : { value: "B", label: "B" },
   "C" :{ value: "C", label: "C" } 
  };
  useEffect(() => {
    async function getInsurer(){
      var headers = new Headers();
        headers.append("Content-Type", "application/json"); 
        headers.append("Authorization", "Bearer "+loggedUser.token);
        fetch(getInsurerUrl, {
          method: "GET",
          headers: headers,
        })
        .then(function(response){ 
          return response.json();   
        })
        .then(function(data){ 
          console.log(data);  
          if(!data.success){  
            
          }else{     
            setinsurerName(data.insurer.title);
            setmedicalType(select_options[data.insurer.medical_type]);
            settravelType(select_options[data.insurer.travel_type]);           
            setlinkMedical(data.insurer.link_medical);
            setlinkTravel(data.insurer.link_travel);
            setlinkTravelSingle(data.insurer.link_travel_single);
            settextEditor(getInitialState(data.insurer.subtext));
            /*settextEditor({
              editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(data.insurer.subtext)
                )
              ),
            } );*/
            setFileState("old-file");
            setImagePreviewUrl(process.env.REACT_APP_STORAGE_URL+ "insurer/"+data.insurer.img)
            
            setLoadingData(false);
          }
        });
      
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getInsurer();
    }

    
  }, []);
  const getInitialState = (defaultValue) => {
    if (defaultValue) {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(defaultValue)
        )
      );
           
    } else {
      return EditorState.createEmpty();
    }
  };
  const editInsurer = () => {

    
    if (insurerNameState === "") {
      setinsurerNameState("has-danger");
    }
    if (medicalTypeState === "") {
      setmedicalTypeState("has-danger");
    }
    if (travelTypeState === "") {
      settravelTypeState("has-danger");
    }
    /*if (textEditorState === ""){
      settextEditorState("has-danger");
    }*/
    
   /* if(textEditor !== ""){
      if(convertToHTML(textEditor.getCurrentContent()) === "<p></p>"){
        settextEditorState("has-danger");
      }
    }*/
    
    console.log(textEditor);
    if((insurerNameState === "has-success" && medicalTypeState === "has-success" && travelTypeState === "has-success")){
      let formData = new FormData();
      formData.append('title', insurerName);
      formData.append('medical_type', medicalType.value);
      formData.append('travel_type', travelType.value);
      formData.append('link_medical', linkMedical);
      formData.append('link_travel', linkTravel);
      formData.append('link_travel_single', linkTravelSingle);
      formData.append('description', convertToHTML(textEditor.getCurrentContent()));
      formData.append('insurer_logo', fileState);
      formData.append('insurer_id',params.id);
      
      var headers = new Headers();
     // headers.append("Content-Type", "multipart/form-data"); 
      headers.append("Authorization", "Bearer "+loggedUser.token);
      fetch(editInsurerUrl, {
        method: "POST",
        headers: headers,
        body:  formData
      })
      .then(function(response){ 
        return response.json();   
      })
      .then(function(data){ 
        console.log(data);  
        if(!data.success){  
          notify(data.message,3);
        }else{          
          notify(data.message,2);
          setTimeout(function(){history.go(0)},3000);

        }
      });
    }
    
    
  };
  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(defaultImage);
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const notify = (servermessage,color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b>{servermessage}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };
  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" lg="11" md="11" sm="11">
          {loadingData ? (
                    <p>Loading Please wait...</p>
                  ) : 
            <Card className="card-stats">
            <CardHeader>  
              <CardTitle tag="h4">Add Insurers</CardTitle>
              <Link to="/admin/list-insurers" className="btn btn-primary float-right">Back to List</Link>
            </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" xs="12">
                  
                  <FormGroup className={`has-label ${insurerNameState}`}>
                    <label>Name *</label>
                    <Input
                      name="fullname"
                      type="text"
                      value={insurerName}
                      onChange={(e) => {    
                        if (!verifyLength(e.target.value, 1)) {
                          setinsurerNameState("has-danger");
                        } else {
                          setinsurerNameState("has-success");
                        }                   
                        setinsurerName(e.target.value);
                      }}
                      
                    />
                    {insurerNameState === "has-danger" ? (
                      <label className="error">This field is required.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${medicalTypeState}`}>
                    <label>Type Medical *</label>
                        <Select                          
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="medicalType"
                          
                          onChange={(e) => { 
                            if (e.value === "") {
                              setmedicalTypeState("has-danger");
                            }else{
                              setmedicalTypeState("has-success");
                            }
                            setmedicalType(e);
                          }}
                          
                          options={[
                            {
                              value: "",
                              label: "Select Medical type",
                              isDisabled: true,
                            },
                            { value: "No Type", label: "No Type" },
                            { value: "A", label: "A" },
                            { value: "B", label: "B" },
                            { value: "C", label: "C" },
                          ]}
                          defaultValue={medicalType}
                          placeholder="Select Medical type"
                        />
                        
                        {medicalTypeState === "has-danger" ? (
                          <label className="error">This field is required.</label>
                        ) : null}
                  </FormGroup>

                  <FormGroup className={`has-label ${travelTypeState}`}>
                    <label>Type Travel *</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="travelType"
                          //value={travelType}
                          onChange={(e) => { 
                            if (e.value === "") {
                              settravelTypeState("has-danger");
                            }else{
                              settravelTypeState("has-success");
                            }
                            settravelType(e);
                          }}                          
                          options={[
                            {
                              value: "",
                              label: "Select Travel type",
                              isDisabled: true,
                            },
                            { value: "No Type", label: "No Type" },
                            { value: "A", label: "A" },
                            { value: "B", label: "B" },
                            { value: "C", label: "C" },
                          ]}
                          defaultValue={travelType}
                          placeholder="Select Travel type"
                        />
                        {travelTypeState === "has-danger" ? (
                          <label className="error">This field is required.</label>
                        ) : null}
                  </FormGroup>
                  <FormGroup>
                    <label>Link Medical</label>
                    <Input
                      name="link_medical"
                      type="url"
                      value={linkMedical}
                      onChange={(e) => {                        
                        setlinkMedical(e.target.value);
                      }}
                    />                   
                  </FormGroup>
                  <FormGroup>
                    <label>Link Travel</label>
                    <Input
                      name="link_travel"
                      type="url"
                      value={linkTravel}
                      onChange={(e) => {                        
                        setlinkTravel(e.target.value);
                      }}
                    />                   
                  </FormGroup>
                  <FormGroup>
                    <label>Link Travel Single Trip</label>
                    <Input
                      name="link_travel_single"
                      type="url"
                      value={linkTravelSingle}
                      onChange={(e) => {                        
                        setlinkTravelSingle(e.target.value);
                      }}
                    />                   
                  </FormGroup>
                  <FormGroup className={`has-label ${textEditorState}`}>
                    <label>Text</label>
                    <Editor   
                   editorState={textEditor}
                   wrapperClassName="demo-wrapper"
                   editorClassName="demo-editor"
                   onEditorStateChange={(e) => {
                    if (textEditor === "") {
                      settextEditorState("has-danger");
                    }else{
                      settextEditorState("has-success");
                    }
                     settextEditor(e);
                    }}
                    />
                  {textEditorState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                  </FormGroup>

                  <FormGroup>
                    <div className="fileinput text-center">
                      <input type="file" onChange={handleImageChange} ref={fileInput} />
                      <div className={"thumbnail"}>
                        <img src={imagePreviewUrl} alt="..." />
                      </div>
                      <div>
                        {fileState === null ? (
                          <Button className="btn-round" onClick={() => handleClick()}>
                            {"Select image"}
                          </Button>
                        ) : (
                          <span>
                            <Button className="btn-round" onClick={() => handleClick()}>
                              Change
                            </Button>
                            
                            <Button
                              color="danger"
                              className="btn-round"
                              onClick={() => handleRemove()}
                            >
                              <i className="fa fa-times" />
                              Remove
                            </Button>
                          </span>
                        )}
                      </div>
                    </div>
                  </FormGroup>
                      
                  </Col>                  
                </Row>
              </CardBody>
              <CardFooter>
              <Button color="primary" onClick={editInsurer}>
                   Save
                  </Button>
              </CardFooter>
            </Card>
            }
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditInsurer;
