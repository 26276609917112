/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Opportunities from "components/Leads/Opportunities";
import { monthNames } from "variables/productOption";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

function AddLeads() {
  const [loadingData, setLoadingData] = React.useState(true);
  const [ClientFirstName, setClientFirstName] = useState("");
  const [ClientLastName, setClientLastName] = useState("");
  const [BirthDay, setBirthDay] = useState("");
  const [BirthMonth, setBirthMonth] = useState("");
  const [BirthYear, setBirthYear] = useState("");
  const [PrimaryCC, setPrimaryCC] = useState("");
  const [PrimaryPhone, setPrimaryPhone] = useState("");
  const [SecondaryCC, setSecondaryCC] = useState("");
  const [SecondaryPhone, setSecondaryPhone] = useState("");
  const [ClientLocation, setClientLocation] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [PolicyNumber, setPolicyNumber] = useState("");
  const [searchParams, setsearchParams] = useState({});
  const [searchResult, setsearchResult] = useState(false);
  const [Gender, setGender] = useState("");
  const [nationality, setClientNationality] = useState("");
  const [FirstNameState, setFirstNameState] = useState("");
  const [LastNameState, setLastNameState] = useState("");
  const [CountryCodeState, setCountryCodeState] = useState("");
  const [PhoneState, setPhoneState] = useState("");
  const [EmailState, setEmailState] = useState("");
  const [LocationState, setLocationState] = useState("");
  const [GenderState, setGenderState] = useState("");
  const [PremiumType, setPremiumType] = useState("");
  const [PremiumTypeState, setPremiumTypeState] = useState("");
  const [countryOptions, setcountryOptions] = useState([
    { value: "", label: "Country", isDisabled: true },
  ]);
  const [dialCodeOptions, setdialCodeOptions] = useState([
    { value: "", label: "Dial Code", isDisabled: true },
  ]);
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  let history = useHistory();
  let dayOptions = [];
  let monthOptions = [];
  let yearOptions = [];
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  const date = new Date();
  const GenderOptions = [
    { value: "", label: "Gender", isDisabled: true },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  for (let index = 1; index <= 31; index++) {
    dayOptions.push({ value: index, label: index });
  }
  for (let index = 1; index <= 12; index++) {
    monthOptions.push({ value: index, label: monthNames[index] });
  }
  for (let index = date.getFullYear(); index >= 1901; index--) {
    yearOptions.push({ value: index, label: index });
  }

  useEffect(() => {
    async function getOptions() {
      fetch(process.env.REACT_APP_API_URL + "/get-options", {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            setcountryOptions(data.countries);
            setdialCodeOptions(data.dial_code);
            setLoadingData(false);
          } else {
          }
        });
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getOptions();
    }
  }, []);

  const creatLead = () => {
    if (FirstNameState !== "has-success") {
      setFirstNameState("has-danger");
    }
    if (LastNameState !== "has-success") {
      setLastNameState("has-danger");
    }
    if (CountryCodeState !== "has-success") {
      setCountryCodeState("has-danger");
    }
    if (PhoneState !== "has-success") {
      setPhoneState("has-danger");
    }
    if (EmailState !== "has-success") {
      setEmailState("has-danger");
    }
    if (LocationState !== "has-success") {
      setLocationState("has-danger");
    }
    if (GenderState !== "has-success") {
      setGenderState("has-danger");
    }
    if (PremiumTypeState !== "has-success") {
      setPremiumTypeState("has-danger");
    }

    if (
      PremiumTypeState === "has-success" &&
      GenderState === "has-success" &&
      FirstNameState === "has-success" &&
      LastNameState === "has-success" &&
      CountryCodeState === "has-success" &&
      PhoneState === "has-success" &&
      EmailState === "has-success" &&
      LocationState === "has-success"
    ) {
      let data = {
        first_name: ClientFirstName,
        last_name: ClientLastName,
        email: ClientEmail,
        day: BirthDay,
        month: BirthMonth,
        year: BirthYear,
        policy_number: PolicyNumber,
        PrimaryCC: PrimaryCC,
        PrimaryPhone: PrimaryPhone,
        SecondaryCC: SecondaryCC,
        SecondaryPhone: SecondaryPhone,
        location: ClientLocation,
        gender: Gender,
        nationality: nationality,
        PremiumType: PremiumType,
      };
      fetch(process.env.REACT_APP_API_URL + "/create-new-lead", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            const location = {
              pathname: "/admin/opportunities/" + data.client_id,
              state: { fromDashboard: true },
            };
            history.push(location);
          }
        });
    }
  };

  const searchClient = () => {
    let data = {
      first_name: ClientFirstName,
      last_name: ClientLastName,
      email: ClientEmail,
      day: BirthDay,
      month: BirthMonth,
      year: BirthMonth,
      policy_number: PolicyNumber,
      PrimaryCC: PrimaryCC,
      PrimaryPhone: PrimaryPhone,
      SecondaryCC: SecondaryCC,
      SecondaryPhone: SecondaryPhone,
      location: ClientLocation,
      gender: Gender,
      nationality: nationality,
      PremiumType: PremiumType,
    };
    setsearchParams(data);
    setsearchResult(true);
  };
  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  return (
    <>
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <div className="content">
          {searchResult ? (
            <Row className={searchResult ? "" : "d-none"}>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Search Result</CardTitle>
                    <Button
                      onClick={() => {
                        setsearchResult(false);
                      }}
                      className="btn btn-primary float-right"
                    >
                      Back to Search Form
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <Opportunities
                      api="search"
                      leadType="search"
                      method="POST"
                      filterData={searchParams}
                    ></Opportunities>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className={searchResult ? "d-none" : ""}>
              <Col md="12">
                <Card className="card-stats">
                  <CardHeader>
                    <CardTitle tag="h4">Create New Opportunity</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label ${FirstNameState}`}
                            >
                              <label>First Name</label>
                              <Input
                                type="text"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setFirstNameState("has-danger");
                                  } else {
                                    setFirstNameState("has-success");
                                  }
                                  setClientFirstName(e.target.value);
                                }}
                              />
                              {FirstNameState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label  ${LastNameState}`}
                            >
                              <label>Last Name</label>
                              <Input
                                type="text"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setLastNameState("has-danger");
                                  } else {
                                    setLastNameState("has-success");
                                  }
                                  setClientLastName(e.target.value);
                                }}
                              />
                              {LastNameState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="4">
                            <FormGroup className={`has-label`}>
                              <label>DOB</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  setBirthDay(e.value);
                                }}
                                options={dayOptions}
                                defaultValue={BirthDay}
                                placeholder="DD"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="4">
                            <FormGroup className={`has-label`}>
                              <label>&nbsp;</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  setBirthMonth(e.value);
                                }}
                                options={monthOptions}
                                placeholder="MM"
                                defaultValue={BirthMonth}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="4">
                            <FormGroup className={`has-label`}>
                              <label>&nbsp;</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  setBirthYear(e.value);
                                }}
                                options={yearOptions}
                                placeholder="YYYY"
                                defaultValue={BirthYear}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>Policy Number</label>
                              <Input
                                type="text"
                                onChange={(e) => {
                                  setPolicyNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label`}>
                              <label>Nationality</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  setClientNationality(e.value);
                                }}
                                options={countryOptions}
                                placeholder="Select Nationality"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label  ${PremiumTypeState}`}
                            >
                              <label>Policy</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setPremiumTypeState("has-danger");
                                  } else {
                                    setPremiumTypeState("has-success");
                                  }
                                  setPremiumType(e.value);
                                }}
                                options={[
                                  {
                                    value: "",
                                    label: "Premium type",
                                    isDisabled: true,
                                  },
                                  { value: "individual", label: "Individual" },
                                  { value: "corporate", label: "Corporate" },
                                ]}
                                placeholder="Select Policy"
                              />
                              {PremiumTypeState === "has-danger" ? (
                                <label className="error text-danger">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label ${GenderState}`}>
                              <label>Gender</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setGenderState("has-danger");
                                  } else {
                                    setGenderState("has-success");
                                  }
                                  setGender(e.value);
                                }}
                                options={GenderOptions}
                                placeholder="Select Gender"
                                defaultValue={Gender}
                              />
                              {GenderState === "has-danger" ? (
                                <label className="error text-danger">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="5">
                            <FormGroup
                              className={`has-label  ${CountryCodeState}`}
                            >
                              <label>Primary Number</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setCountryCodeState("has-danger");
                                  } else {
                                    setCountryCodeState("has-success");
                                  }
                                  setPrimaryCC(e.value);
                                }}
                                options={dialCodeOptions}
                                placeholder="Select Dialing code"
                              />
                              {CountryCodeState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="7">
                            <FormGroup className={`has-label  ${PhoneState}`}>
                              <label>&nbsp;</label>
                              <Input
                                type="number"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setPhoneState("has-danger");
                                  } else {
                                    setPhoneState("has-success");
                                  }
                                  setPrimaryPhone(e.target.value);
                                }}
                              />
                              {PhoneState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="5">
                            <FormGroup className={`has-label`}>
                              <label>Secondary Number</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  setSecondaryCC(e.value);
                                }}
                                options={dialCodeOptions}
                                placeholder="Select Dialing code"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="7">
                            <FormGroup className={`has-label`}>
                              <label>&nbsp;</label>
                              <Input
                                type="number"
                                onChange={(e) => {
                                  setSecondaryPhone(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup className={`has-label  ${EmailState}`}>
                              <label>Email</label>
                              <Input
                                type="text"
                                onChange={(e) => {
                                  if (!verifyEmail(e.target.value)) {
                                    setEmailState("has-danger");
                                  } else {
                                    setEmailState("has-success");
                                  }
                                  setClientEmail(e.target.value);
                                }}
                              />
                              {EmailState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="12">
                            <FormGroup
                              className={`has-label  ${LocationState}`}
                            >
                              <label>Location</label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="title"
                                onChange={(e) => {
                                  if (e.value === "") {
                                    setLocationState("has-danger");
                                  } else {
                                    setLocationState("has-success");
                                  }
                                  setClientLocation(e.value);
                                }}
                                options={countryOptions}
                                placeholder="Select Location"
                              />
                              {LocationState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        {/* {loggedUser.data.role != 2 ? ( */}
                        <Button
                          color="primary"
                          onClick={() => {
                            creatLead();
                          }}
                        >
                          Add New
                        </Button>
                        {/* ) : null} */}
                        {/* <Button
                          color="primary"
                          onClick={() => {
                            searchClient();
                          }}
                        >
                          Search
                        </Button> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default AddLeads;
