import React, { useMemo, useState, useEffect } from "react";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
import AppTable from "components/ReactTable/AppTable";
import ServerPagination from "components/ReactTable/ServerPagination";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Row,
  Col,
} from "reactstrap";




function AllTemplates() {
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [loadingData, setLoadingData] = useState(true);
  const [alert, setAlert] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setsortColumn] = useState("name");
  const [sortDirection, setsortDirection] = useState("ASC");
  const [RefreshTable, setRefreshTable] = useState();
  const [searchLocation, setSearchLocation] = useState("");
  const [countryOption, setcountryOption] = useState([]);
  const getCountryOptionsUrl = process.env.REACT_APP_API_URL + "/get-country-options";

  let history = useHistory();
  const columns = useMemo(() => [
    {
      Header: "Template Name",
      accessor: "template_name",
      sortdir: sortColumn === "template_name" ? sortDirection : '',
      sorCol: sortColumn === "template_name" ? 'yes' : 'no',
    },
    {
      Header: "Location",
      accessor: "template_location",
      sortdir: sortColumn === "template_location" ? sortDirection : '',
      sorCol: sortColumn === "template_location" ? 'yes' : 'no',
    },
    {
      Header: "Added By",
      accessor: "added_by",
      sortdir: sortColumn === "name" ? sortDirection : '',
      sorCol: sortColumn === "name" ? 'yes' : 'no',
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ]);
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalRecords: 0,
  });

  const defaultCountry = { value: '', label: 'All' };

  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);

  useEffect(() => {
    async function getTemplates() {
      fetch(process.env.REACT_APP_API_URL + "/list-template/" + currentPage + "/" + sortDirection + "/" + sortColumn+ "?location_filter=" + searchLocation, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (!data.success) {

          } else {
            console.log("template data", data.list);
            setPageData({
              isLoading: false,
              rowData: formatRowData(data.list),
              totalPages: data.totalPages,
              totalRecords: data.totalRecords,
            });
          }
        })
    }
    //if (loadingData) {
      // if the result is not ready so you make the axios call
      getTemplates();
    //}


  }, [currentPage, sortDirection, RefreshTable, searchLocation]);
  useEffect(() => {
    async function getCountryOptions() {
      fetch(getCountryOptionsUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            console.log("country data..", data);
            const countries = data.countries;
            const countryFilterOption = [
              { value: '', label: 'All' }, // Add this option for "All"
              ...countries,               // Spread the existing options
            ];
            setcountryOption(countryFilterOption);
            setLoadingData(false);
          } else {
          }
        });
    }
    if (loadingData) {
      getCountryOptions();
    }
  }, []);

  const warningWithConfirmMessage = (template_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(template_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this Template!
      </ReactBSAlert>
    );
  };
  const successDelete = (template_id) => {
    const deleteUser = process.env.REACT_APP_API_URL + "/delete-template";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    fetch(deleteUser, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ "template_id": template_id })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {

        } else {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert("no")}
              onCancel={() => hideAlert("no")}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
          setRefreshTable(template_id);
        }
      });

  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };
  const formatRowData = (rawData) =>
    rawData.map((info) => ({
      template_name: info.template_name,
      template_location: info.template_location,
      added_by: info.added_by,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">

          {/* use this button to add a edit kind of action */}
          <Button
            onClick={() => {
              editTemplate(info.id)
            }}
            color="warning"
            size="sm"
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              warningWithConfirmMessage(info.id)
            }}
            color="danger"
            size="sm"
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" />
          </Button>
          {" "}
        </div>
      ),
    }));
  const editTemplate = (template_id) => {
    history.push("/admin/edit-template/" + template_id);
  };
  const getData = async (pageNo = 1, sortOrder = 'ASC', column = 'name') => {
    setsortColumn(column);
    setsortDirection(sortOrder);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">All Templates</CardTitle>
              <Link to="/admin/add-template" className="btn btn-primary float-right">Add New Template</Link>
            </CardHeader>
            <CardBody>
              {alert}
              <Row>
                <Col lg="4">
                    <FormGroup className={`has-label`}>
                        <label>Location</label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="location_filter"
                            defaultValue={defaultCountry}
                            options={countryOption}
                            placeholder="Select Location"
                            onChange={(e) => {
                              setSearchLocation(e.value);
                              setLoadingData(true);
                            }}
                        />
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <AppTable
                    columns={columns}
                    data={pageData.rowData}
                    isLoading={pageData.isLoading}
                    sorting={getData}
                    page={currentPage}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="12">
                  <ServerPagination
                    totalRows={pageData.totalRecords}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={50}
                  />
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default AllTemplates;
