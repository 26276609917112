function SubmitLoader(props) {
    return (
        <>
            {
                props.showLoader ?
                    <div className="text-center" id="overlay">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    null
            }
        </>


    );
}
export default SubmitLoader