import React, { useMemo, useEffect, useState } from "react";
import {
  FormGroup,
  Col,
  Row,
  Table,
  Modal,
  Button,
  Input,
  Label,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";
import { notify, download } from "components/functions";
import { AddGroupDocumentApi, FetchAllDocumentApi ,DeleteGroupDocumentApi} from "store/AddGroupAction";
import { useDispatch, useSelector } from "react-redux";
// import DraftEmail from "./DraftEmail";
const AddGroupDocs = (props) => {
  const notificationAlert = React.useRef();
  const [alert, setAlert] = React.useState(null);
  const [docObj, setdocObj] = React.useState([]);
  const [DocTypeOptions, setDocTypeOptions] = useState([]);
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadingData, setLoadingData] = React.useState(true);
  const [addModal, setaddModal] = React.useState(false);
  const [RefreshTable, setRefreshTable] = useState();
  const [SelectedDocs, setSelectedDocs] = useState([]);

  const dispatch = useDispatch();
  const { AddGroupDocumentSuccess } = useSelector(
    (state) => state.getAddGroupDocumentRe
  );
  const { GetAllDocumentDataSuccess } = useSelector(
    (state) => state.getAllDocumentDataRe
  );

  const { DeletegroupDocumentDataSuccess } = useSelector(
    (state) => state.getDeleteGroupDocumentDataRe
  );

  useEffect(() => {
    if (DeletegroupDocumentDataSuccess?.data?.success == true) {
        setLoadingData(true);
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert("no-refresh")}
            onCancel={() => hideAlert("no-refresh")}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            {DeletegroupDocumentDataSuccess?.data?.message}
          </ReactBSAlert>
        );
    }
  }, [DeletegroupDocumentDataSuccess]);

  let selected_docs = useMemo(() => [], [loadingData]);
  const toggleAddModal = () => {
    setaddModal(!addModal);
    setLoadingData(true);
  };
  useEffect(() => {
    const getDocument = async () => {
      await dispatch(FetchAllDocumentApi(props.group_id, loggedUser.token));
    };
    if (loadingData) {
      getDocument();
    }
  }, [loadingData]);

  useEffect(() => {
    if (GetAllDocumentDataSuccess?.data?.success == true) {
      setdocObj(GetAllDocumentDataSuccess?.data?.documents);
      setDocTypeOptions(GetAllDocumentDataSuccess?.data?.document_type);
      setLoadingData(false);
    } else {
      setLoadingData(false);
    }
  }, [GetAllDocumentDataSuccess]);

  useEffect(() => {
    if (AddGroupDocumentSuccess?.data?.success == true) {
      notify(AddGroupDocumentSuccess?.data?.message, 2, notificationAlert);
      setLoadingData(true);
      setaddModal(false);
    }
    if (AddGroupDocumentSuccess?.data?.success == false) {
      notify(AddGroupDocumentSuccess?.data?.message, 3, notificationAlert);
    }
  }, [AddGroupDocumentSuccess]);

  const saveDoc = async () => {
    let formData = new FormData();
    formData.append("group_id", props.group_id);
    for (var i = 0; i < formValues.length; i++) {
      formData.append("document_type[" + i + "]", formValues[i].doctype);
      formData.append("document[" + i + "]", formValues[i].docfile);
      formData.append("description[" + i + "]", formValues[i].description);
    }
    await dispatch(AddGroupDocumentApi(formData, loggedUser.token));
  };

  const [formValues, setFormValues] = useState([
    { doctype: "", description: "", docfile: "" },
  ]);

  let handleChange = (i, e, type) => {
    let newFormValues = [...formValues];
    if (type === "file") {
      newFormValues[i][e.target.name] = e.target.files[0];
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }

    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { doctype: "", description: "", docfile: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const deleteAddtionalUser = (doc_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(doc_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this!
      </ReactBSAlert>
    );
  };
  const successDelete = async (doc_id) => {
    await dispatch(DeleteGroupDocumentApi(doc_id, loggedUser.token));
  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };
  var Apiheaders = new Headers();
  Apiheaders.append("Content-Type", "application/json");
  Apiheaders.append("Authorization", "Bearer " + loggedUser.token);
  return (
    <Row>
      <NotificationAlert ref={notificationAlert} />
      {alert}
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <Col md="12">
          {loggedUser.data.role === 1 ||
          loggedUser.data.role === 2 ||
          loggedUser.data.role === 3 ||
          loggedUser.data.role === 6 ||
          loggedUser.data.role === 7 ||

          loggedUser.data.role === 5 ? (
            <Button
              onClick={(e) => {
                toggleAddModal();
              }}
              color="primary"
            >
              Add Document
            </Button>
          ) : null}

          <div className="table-responsive">
            <Table className="table table-striped">
              <thead>
                <tr>
                  {process.env.REACT_APP_ENVIROMENT === "development" ? (
                    <th>Select to send</th>
                  ) : null}
                  <th>Type</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Download</th>
                  {loggedUser.data.role === 1 || loggedUser.data.role === 2 ? (
                    <th>Remove</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {docObj.length > 0 ? (
                  docObj.map((element, index) => (
                    <tr key={index}>
                      {process.env.REACT_APP_ENVIROMENT === "development" ? (
                        <td>
                          <FormGroup
                            check
                            inline
                            style={{ display: "table-cell" }}
                          >
                            <Label check style={{ marginBottom: "14px" }}>
                              <Input
                                type="checkbox"
                                key={`docObj[index].id`}
                                value={docObj[index].id}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  if (checked) {
                                    selected_docs.push(e.target.value);
                                    setSelectedDocs(selected_docs);
                                  } else {
                                    var index = selected_docs.indexOf(
                                      e.target.value
                                    );
                                    if (index > -1) {
                                      selected_docs.splice(index, 1);
                                      setSelectedDocs(selected_docs);
                                    }
                                  }
                                }}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                      ) : null}
                      <td>{docObj[index]["type"]}</td>
                      <td>{docObj[index]["description"]}</td>
                      <td>{docObj[index]["doc_date"]}</td>
                      <td>
                        {process.env.REACT_APP_ENVIROMENT === "development" ? (
                          docObj[index].document.match("pdf") != null ? (
                            <>
                              <Link
                                target="_blank"
                                to={
                                  "/admin/view-document/?renderer=url&path=client_doc/" +
                                  docObj[index].document
                                }
                              >
                                <i className="nc-icon nc-cloud-download-93"></i>
                              </Link>
                              <Link
                                className="h5 m-2"
                                target="_blank"
                                to={
                                  "/admin/view-document/?renderer=url&path=client_doc/" +
                                  docObj[index].document
                                }
                              >
                                <i className="nc-icon nc-cloud-upload-94"></i>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                target="_blank"
                                to={
                                  "/admin/view-document/?renderer=url&path=client_doc/" +
                                  docObj[index].document
                                }
                              >
                                <i className="nc-icon nc-cloud-download-93"></i>
                              </Link>
                            </>
                          )
                        ) : docObj[index].document.match("pdf") != null ? (
                          <>
                            <a
                              className="h5 m-2"
                              onClick={() =>
                                download(
                                  process.env.REACT_APP_API_URL +
                                    "/download-group-crm-doc/" +
                                    docObj[index].id,
                                  Apiheaders,
                                  docObj[index].document,
                                  "download"
                                )
                              }
                            >
                              <i className="nc-icon nc-cloud-download-93"></i>
                            </a>
                            &nbsp; &nbsp; &nbsp;
                            <a
                              className="h5 m-2"
                              onClick={() =>
                                download(
                                  process.env.REACT_APP_API_URL +
                                    "/download-group-crm-doc/" +
                                    docObj[index].id,
                                  Apiheaders,
                                  docObj[index].document,
                                  "newTab"
                                )
                              }
                            >
                              <i className="nc-icon nc-badge"></i>
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="h5 m-2"
                              onClick={() =>
                                download(
                                  process.env.REACT_APP_API_URL +
                                    "/download-group-crm-doc/" +
                                    docObj[index].id,
                                  Apiheaders,
                                  docObj[index].document,
                                  "download"
                                )
                              }
                            >
                              <i className="nc-icon nc-cloud-download-93"></i>
                            </a>
                          </>
                        )}
                      </td>
                      {loggedUser.data.role === 1 ||
                      loggedUser.data.role === 2 ? (
                        <td>
                          <Button
                            color="danger"
                            className="button remove"
                            onClick={() =>
                              deleteAddtionalUser(docObj[index]["id"])
                            }
                          >
                            Remove
                          </Button>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Record Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* {process.env.REACT_APP_ENVIROMENT === "development" ? (
              <DraftEmail
                client_id={props.client_id}
                attachments={selected_docs}
                loadingData={setLoadingData}
              />
            ) : null} */}
          <Modal size="large" isOpen={addModal} toggle={toggleAddModal}>
            <div className="modal-header justify-content-center">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleAddModal}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h4 className="title title-up">Add Activity</h4>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <Button color="primary" onClick={() => addFormFields()}>
                    Add More Document
                  </Button>
                </Col>
              </Row>
              {formValues.map((element, index) => (
                <Row key={index}>
                  <Col className="ml-auto mr-auto" md="3">
                    <FormGroup>
                      <label>Document Type </label>
                      <select
                        className="form-control"
                        onChange={(e) => handleChange(index, e, "input")}
                        name="doctype"
                      >
                        <option>Select Document Type</option>
                        {DocTypeOptions.map((ele, i) => (
                          <option key={"doctype" + ele.value} value={ele.value}>
                            {ele.label}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="3">
                    <FormGroup>
                      <label>Document Description </label>
                      <Input
                        name="description"
                        type="text"
                        value={element.description || ""}
                        onChange={(e) => handleChange(index, e, "input")}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="3">
                    <label>Upload Document </label>
                    <input
                      type="file"
                      className="form-control"
                      name="docfile"
                      onChange={(e) => handleChange(index, e, "file")}
                    />
                  </Col>
                  <Col className="ml-auto mr-auto" md="3">
                    <label> </label>
                    {index ? (
                      <Button
                        color="danger"
                        className="button remove"
                        onClick={() => removeFormFields(index)}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              ))}
              <Row>
                <Col className="ml-auto mr-auto text-center">
                  <Button color="primary" className="button" onClick={saveDoc}>
                    Save Document
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>
        </Col>
      )}
    </Row>
  );
};

export default AddGroupDocs;
