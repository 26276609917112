const statusOptions = [
  { value: "", label: "", isDisabled: true },
  { value: "Online Application", label: "Online Application" },
  { value: "Opportunity", label: "Opportunity" },
  { value: "Opportunity Open", label: "Opportunity Open" },
  { value: "Opportunity Squandered", label: "Opportunity Squandered" },
  { value: "Account Pending", label: "Account Pending" },
  { value: "Account Open", label: "Account Open" },
  { value: "Account Expired", label: "Account Expired" },
  { value: "Account Lapsed", label: "Account Lapsed" },
];

export const priorityOptions = [
  { value: "", label: "", isDisabled: true },
  { value: "VERY HIGH", label: "VERY HIGH" },
  { value: "HIGH", label: "HIGH" },
  { value: "MEDIUM", label: "MEDIUM" },
  { value: "LOW", label: "LOW" },
];

export const GroupStatusOptions = [
  { value: "", label: "", isDisabled: true },
  // { value: "Opportunity", label: "Opportunity" },
  // { value: "Opportunity Open", label: "Opportunity Open" },
  // { value: "Quoting", label: "Quoting" },
  // { value: "Account Pending", label: "Account Pending" },

  { value: "Online Application", label: "Online Application" },
  { value: "Opportunity", label: "Opportunity" },
  { value: "Opportunity Open", label: "Opportunity Open" },
  { value: "Quoting", label: "Quoting" },
  { value: "Opportunity Squandered", label: "Opportunity Squandered" },
  { value: "Account Pending", label: "Account Pending" },
  { value: "Account Open", label: "Account Open" },
  { value: "Account Expired", label: "Account Expired" },
  { value: "Account Lapsed", label: "Account Lapsed" },
];

const productOptions = [
  { value: "", label: "", isDisabled: true },
  { value: "Life Insurance", label: "Life Insurance" },
  { value: "Medical Insurance", label: "Medical Insurance" },
  { value: "Travel Insurance", label: "Travel Insurance" },
  { value: "Golf", label: "Golf" },
  { value: "Group Medical Insurance", label: "Group Medical Insurance" },
  { value: "Group Travel Insurance", label: "Group Travel Insurance" },
  { value: "Pet", label: "Pet" },
  { value: "Car", label: "Car" },
  {
    value: "Directors and Officers Insurance",
    label: "Directors and Officers Insurance",
  },
  { value: "Office Contents Insurance", label: "Office Contents Insurance" },
  { value: "Home", label: "Home" },
  { value: "Maid", label: "Maid" },
  { value: "Finance", label: "Finance" },
  { value: "Others", label: "Others" },
];

const productGroupOptions = [
  { value: "", label: "", isDisabled: true },
  { value: "Life Insurance", label: "Life Insurance" },
  { value: "Medical Insurance", label: "Medical Insurance" },
  { value: "Travel Insurance", label: "Travel Insurance" },
  { value: "Golf", label: "Golf" },
  { value: "Group Medical Insurance", label: "Group Medical Insurance" },
  { value: "Group Travel Insurance", label: "Group Travel Insurance" },
  { value: "Pet", label: "Pet" },
  {
    value: "Commercial General Liability",
    label: "Commercial General Liability",
  },
  { value: "Professional Indemnity", label: "Professional Indemnity" },
  { value: "Employee's Compensation", label: "Employee's Compensation" },

  { value: "Car", label: "Car" },
  {
    value: "Directors and Officers Insurance",
    label: "Directors and Officers Insurance",
  },
  { value: "Office Contents Insurance", label: "Office Contents Insurance" },
  { value: "Home", label: "Home" },
  { value: "Maid", label: "Maid" },
  { value: "Finance", label: "Finance" },
  { value: "Others", label: "Others" },
];

const generatorOption = [
  { value: "", label: "", isDisabled: true },
  { value: "Addition", label: "Addition" },
  { value: "Call in", label: "Call in" },
  { value: "Cold call", label: "Cold call" },
  { value: "Cross Sale", label: "Cross Sale" },
  { value: "Directory", label: "Directory" },
  { value: "E-prospects", label: "E-prospects" },
  { value: "EDM", label: "EDM" },
  { value: "Event", label: "Event " },
  { value: "Existing Client", label: "Existing Client" },

  { value: "Facebook", label: "Facebook" },
  { value: "Live Chat", label: "Live Chat" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Mail Chimp", label: "Mail Chimp" },

  { value: "i-Brokers", label: "i-Brokers" },
  { value: "Outbound", label: "Outbound" },
  { value: "Past Enquiry", label: "Past Enquiry" },

  { value: "Referral", label: "Referral" },
  { value: "Renewal", label: "Renewal" },
  { value: "SEO", label: "SEO" },
  { value: "TikTok", label: "TikTok" },
  { value: "WhatsApp", label: "WhatsApp" },

  { value: "Old Client", label: "Old Client" },

  { value: "Other", label: "Other" },
];
const periodOption = [
  { value: "", label: "", isDisabled: true },
  { value: "Annually", label: "Annually" },
  { value: "Semi Annually", label: "Semi Annually" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Monthly", label: "Monthly" },
];
const currencyOption = [
  { value: "", label: "", isDisabled: true },
  { value: "HKD", label: "HKD" },
  { value: "USD", label: "USD" },
  { value: "GBP", label: "GBP" },
  { value: "EUR", label: "EUR" },
  { value: "AUD", label: "AUD" },
  { value: "CAD", label: "CAD" },
  { value: "IDR", label: "IDR" },
  { value: "MYR", label: "MYR" },
  { value: "RMB", label: "RMB" },
  { value: "SGD", label: "SGD" },
  { value: "THB", label: "THB" },
];

const ActivityTypeOption = [
  { value: "", label: "", isDisabled: true },
  { value: "Awaiting Application", label: "Awaiting Application" },
  { value: "Awaiting Information", label: "Awaiting Information" },
  { value: "Awaiting census", label: "Awaiting census" },
  {
    value: "Awaiting Claims Data/Benefits",
    label: "Awaiting Claims Data/Benefits",
  },
  { value: "Call Booked", label: "Call Booked" },
  { value: "Called", label: "Called" },
  { value: "Called No Answer", label: "Called No Answer" },
  { value: "Chasing Payment", label: "Chasing Payment" },
  { value: "Claim", label: "Claim" },
  { value: "Closed", label: "Closed" },
  { value: "Complaint", label: "Complaint" },
  { value: "Follow Up 1", label: "Follow Up 1" },
  { value: "Follow Up 2", label: "Follow Up 2" },
  { value: "Follow Up 3", label: "Follow Up 3" },
  { value: "Follow Up 4", label: "Follow Up 4" },
  { value: "Follow Up 5", label: "Follow Up 5" },
  { value: "Follow Up 6", label: "Follow Up 6" },
  { value: "Follow Up Date", label: "Follow Up Date" },
  { value: "Discovery Call", label: "Discovery Call" },
  { value: "Do Not Call", label: "Do Not Call" },
  { value: "Emailed", label: "Emailed" },
  { value: "General", label: "General" },
  { value: "Gone to Market", label: "Gone to Market" },
  { value: "Live Chat", label: "Live Chat" },
  { value: "Meeting Booked", label: "Meeting Booked" },
  { value: "Member Movement", label: "Member Movement" },
  { value: "Messaged", label: "Messaged" },
  { value: "Pitching", label: "Pitching" },
  { value: "Premium Pending", label: "Premium Pending" },
  { value: "Proposal Built", label: "Proposal Built" },
  { value: "Quote Received", label: "Quote Received" },
  { value: "Re Quoting", label: "Re Quoting" },
  { value: "Referral Requested", label: "Referral Requested" },
  { value: "Review Call", label: "Review Call" },
  { value: "Review Follow Up", label: "Review Follow Up" },
  { value: "Sales 1", label: "Sales 1" },
  { value: "Sales 2", label: "Sales 2" },
  { value: "Sales 3", label: "Sales 3" },
  { value: "Sales 4", label: "Sales 4" },
  { value: "Sales 5", label: "Sales 5" },
  { value: "Sales 6", label: "Sales 6" },
  { value: "Sales 7", label: "Sales 7" },
  { value: "Sales 8", label: "Sales 8" },
  { value: "Sales 9", label: "Sales 9" },
  { value: "Sales 10", label: "Sales 10" },
  { value: "Sales 11", label: "Sales 11" },

  { value: "Waiting on Insurer", label: "Waiting on Insurer" },
];
const monthNames = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export {
  statusOptions,
  productOptions,
  productGroupOptions,
  generatorOption,
  periodOption,
  currencyOption,
  ActivityTypeOption,
  monthNames,
};
