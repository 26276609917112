import React, { useMemo, useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
// import Opportunities from "components/Leads/Opportunities";
import GroupOpportunityOpen from "components/GroupLeads/GroupOpportunityOpen";
import GroupQuotingOpportunities from "components/GroupLeads/GroupQuotingOpportunities";
import GroupAccountPandingOpportunities from "components/GroupLeads/GroupAccountPandingOpportunities";
import DataTable from "components/GroupLeads/GroupQuotingOpportunities";
import GroupOpportunities from "components/GroupLeads/GroupOpportunities";
const GroupMyLeads = () => {
  

    return (
  
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">My Group Opportunities</CardTitle>
              </CardHeader>
              <CardBody>
              <GroupOpportunities api="my-group-leads" leadType="my" method="GET" filterData=""></GroupOpportunities>
              {/* <GroupOpportunityOpen api="my-group-leads" leadType="my" method="GET" filterData=""></GroupOpportunityOpen> */}

              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
           
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">My Group Opportunities</CardTitle>
              </CardHeader>
              <CardBody>
                <DataTable api="my-group-leads" leadType="my" method="GET" filterData=""></DataTable>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">My Group Opportunities</CardTitle>
              </CardHeader>
              <CardBody>
                <GroupAccountPandingOpportunities api="my-group-leads" leadType="my" method="GET" filterData=""></GroupAccountPandingOpportunities>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </div>
    );
  
  
  }

export default GroupMyLeads