/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState, useEffect } from "react";
import ServerPagination from "components/ReactTable/ServerPagination";
import AppTable from "components/ReactTable/AppTable";
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
    Row,
    Col,
} from "reactstrap";

function MedicalPrices(props) {
    const notificationAlert = React.useRef();
    const [alert, setAlert] = React.useState(null);
    const loggedUser = JSON.parse(localStorage.getItem('current_users'));
    const [sortColumn, setsortColumn] = useState("age");
    const [sortDirection, setsortDirection] = useState("ASC");
    const [loadingData, setLoadingData] = useState(true);
    const [RefreshTable, setRefreshTable] = useState();
    let options;
    const columns = useMemo(() => [
        {
            Header: "Age",
            accessor: "age",
            sortdir: sortColumn === "age" ? sortDirection : '',
            sorCol: sortColumn === "age" ? 'yes' : 'no',
        },
        {
            Header: "Male Premium Amount",
            accessor: "monthly_male",
            sortdir: sortColumn === "monthly_male" ? sortDirection : '',
            sorCol: sortColumn === "monthly_male" ? 'yes' : 'no',
        },
        {
            Header: "Female Premium Amount",
            accessor: "monthly_female",
            sortdir: sortColumn === "monthly_female" ? sortDirection : '',
            sorCol: sortColumn === "monthly_female" ? 'yes' : 'no',
        },
        {
            Header: "Location",
            accessor: "location"
        }

    ]);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalRecords: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        async function getLeads() {

            options = {
                method: "GET",
                headers: headers,
            }
            fetch(process.env.REACT_APP_API_URL + "/get-medical-prices/" + props.product_id + "/" + currentPage + "/" + sortDirection + "/" + sortColumn, options)
                .then(function (response) {
                    return response.json();
                }).then(function (data) {
                    console.log(data);
                    setPageData({
                        isLoading: false,
                        rowData: formatRowData(data.prices),
                        totalPages: data.totalPages,
                        totalRecords: data.totalRecords,
                    });
                    //setLoadingData(false);
                });
        }
        if (loadingData) {
            getLeads();
        }
    }, [currentPage, sortDirection, RefreshTable]);

    const formatRowData = (rawData) =>
        rawData.map((info) => ({
            age: info.age,
            monthly_male: info.monthly_male,
            monthly_female: info.monthly_female,
            location: info.location
        }));

    const getData = async (pageNo = 1, sortOrder = 'ASC', column = 'created_at') => {
        setsortColumn(column);
        setsortDirection(sortOrder);
    };

    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            {alert}
            <Row>
                <Col md="6">
                    <p>Total Records: {pageData.totalRecords===null ? "Loading..." : pageData.totalRecords}</p>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <div className="table-responsive">
                        <AppTable
                            columns={columns}
                            data={pageData.rowData}
                            isLoading={pageData.isLoading}
                            sorting={getData}
                            page={currentPage}
                        />
                    </div>
                </Col>

            </Row>

            <Row className="mt-4">
                <Col md="12">
                    <ServerPagination
                        totalRows={pageData.totalRecords}
                        pageChangeHandler={setCurrentPage}
                        rowsPerPage={50}
                    />
                </Col>
            </Row>
        </>



    );


}

export default MedicalPrices;
