import React from "react";
//import DocViewer from "react-doc-viewer";
import { DocumentViewer } from 'react-documents';
import { useParams,useLocation } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ViewDoc() {
    let query = useQuery();
    console.log(process.env.REACT_APP_STORAGE_URL +query.get("path"));
    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>

                        <CardBody>
                            <DocumentViewer
                                url={process.env.REACT_APP_STORAGE_URL +query.get("path")}
                                viewer={query.get("renderer")}
                                style={{height:"100vh", width:"100%"}}
                            >
                            </DocumentViewer>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>


    );

}

export default ViewDoc;