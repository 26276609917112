/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from "views/Dashboard.js";
import AddConsultants from "views/dashboard/add-consultants";
import AllConsultants from "views/dashboard/all-consultants";
import EditConsultants from "views/dashboard/edit-consultants";
import EditProfile from "views/dashboard/edit-profile";
import AddInsurers from "views/dashboard/insurers/add-insurers";
import AllInsurer from "views/dashboard/insurers/all-insurer";
import EditInsurer from "views/dashboard/insurers/edit-insurer";
import AddMedical from "views/dashboard/Products/add-medical";
import AddTravel from "views/dashboard/Products/add-travels";
import EditMedical from "views/dashboard/Products/edit-medical";
import EditTravel from "views/dashboard/Products/edit-travels";
import AllProducts from "views/dashboard/Products/prodcut-list";
import AllTemplates from "views/dashboard/templates/template-list";
import AddTemplate from "views/dashboard/templates/add-template";
import EditTemplate from "views/dashboard/templates/edit-template";
import ViewDoc from "views/dashboard/view-doc";
import Download from "views/Download";
import AddLeads from "views/lead/AddLead";
import AllLeads from "views/lead/all-leads";
import CreateMedicalProposal from "views/lead/CreateMedicalProposal";
import CreateTravelProposal from "views/lead/CreateTravelProposal";
import EditLead from "views/lead/lead-detail";
import MyLeads from "views/lead/my-leads";
import Report from "views/lead/reporting";
// import StatusLogs from "views/lead/StatusLogs";
import StatusLogsPage from "views/lead/StatusLogsPage";

//group components
import AddGroupLead from "views/grouplead/AddGroupLead";
import GroupEditLead from "views/grouplead/GroupEditLead";
import GroupAllLeads from "views/grouplead/GroupAllLeads";
import GroupMyLeads from "views/grouplead/GroupMyLeads";
import GroupSearch from "views/grouplead/GroupSearch";
import IndividualSearch from "views/lead/IndividualSearch";
import GroupReporting from "views/lead/GroupReporting";


const loggedUser = JSON.parse(localStorage.getItem("current_users"));
// console.log("loggedUser", loggedUser)
let routes= [];

// if(loggedUser?.data?.email == "steve@admin.com"){
//    routes = [
//     {
//       path: "/dashboard",
//       name: "Dashboard",
//       icon: "nc-icon nc-bank",
//       component: Dashboard,
//       layout: "/admin",
//       role: [1, 2, 3, 4, 5, 6, 7],
//     },
//     {
//       collapse: true,
//       name: "INDIVIDUAL",
//       icon: "nc-icon nc-briefcase-24",
//       state: "OppCollapse",
//       role: [1, 2, 3, 4, 5, 6 ,7],
//       views: [
//         {
//           path: "/individual-opportunities",
//           name: "Search Opportunity",
//           mini: "",
//           component: IndividualSearch,
//           layout: "/admin",
//           role: [1, 2, 3, 4, 5, 6 , 7],
//         },
//         {
//           path: "/add-opportunities",
//           name: "Create Opportunity",
//           mini: "",
//           component: AddLeads,
//           layout: "/admin",
//           role: [1, 2, 3, 4, 5, 6 , 7],
//         },
//         // {
//         //   path: "/add-opportunities",
//         //   name: "Search Opportunities",
//         //   mini: "",
//         //   component: AddLeads,
//         //   layout: "/admin",
//         //   role: [2],
//         // },
  
//         {
//           path: "/all-opportunities",
//           name: "All Opportunities",
//           mini: "",
//           component: AllLeads,
//           layout: "/admin",
//           role: [1, 3, 4, 5, 6 , 7],
//         },
//         {
//           path: "/opportunities/:id",
//           name: "Opportunities",
//           mini: "",
//           redirect: true,
//           component: EditLead,
//           layout: "/admin",
//           role: [1, 2, 3, 4, 5, 6 , 7],
//         },
//         {
//           path: "/create-medical-proposal/:client_id/",
//           name: "Opportunities",
//           mini: "",
//           redirect: true,
//           component: CreateMedicalProposal,
//           layout: "/admin",
//           role: [1, 2, 4, 5, 6 , 7],
//         },
//         {
//           path: "/create-travel-proposal/:client_id/",
//           name: "Opportunities",
//           mini: "",
//           redirect: true,
//           component: CreateTravelProposal,
//           layout: "/admin",
//           role: [1, 2, 4, 5, 6 , 7],
//         },
//         {
//           path: "/download/:id/",
//           name: "Download",
//           mini: "",
//           redirect: true,
//           component: Download,
//           layout: "/admin",
//           role: [1, 2, 3, 4, 5, 6 , 7],
//         },
  
//         {
//           path: "/my-opportunities",
//           name: "My Opportunities",
//           mini: "",
//           component: MyLeads,
//           layout: "/admin",
//           role: [1, 2, 3, 4, 5, 6 , 7],
//         },
//         {
//           path: "/status-logs",
//           name: "Status Logs",
//           mini: "",
//           component: StatusLogsPage,
//           layout: "/admin",
//           role: [1, 2, 3, 4, 5, 6 , 7],
//         },
//       ],
//     },
//   ];
// }else{
   routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "nc-icon nc-bank",
      component: Dashboard,
      layout: "/admin",
      role: [1, 2, 3, 4, 5, 6, 7 ,8 , 58],
    },
    {
      collapse: true,
      name: "INDIVIDUAL",
      icon: "nc-icon nc-briefcase-24",
      state: "OppCollapse",
      role: [1, 2, 3, 4, 5, 6, 8 , 58],
      views: [
        {
          path: "/individual-opportunities",
          name: "Search Opportunity",
          mini: "",
          component: IndividualSearch,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6 , 8 , 58],
        },
        {
          path: "/add-opportunities",
          name: "Create Opportunity",
          mini: "",
          component: AddLeads,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6 , 8 , 58],
        },
        // {
        //   path: "/add-opportunities",
        //   name: "Search Opportunities",
        //   mini: "",
        //   component: AddLeads,
        //   layout: "/admin",
        //   role: [2],
        // },
  
        {
          path: "/all-opportunities",
          name: "All Opportunities",
          mini: "",
          component: AllLeads,
          layout: "/admin",
          role: [1, 3, 4, 5, 6 , 8 , 58],
        },
        {
          path: "/opportunities/:id",
          name: "Opportunities",
          mini: "",
          redirect: true,
          component: EditLead,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6 , 8 , 58],
        },
        {
          path: "/create-medical-proposal/:client_id/",
          name: "Opportunities",
          mini: "",
          redirect: true,
          component: CreateMedicalProposal,
          layout: "/admin",
          role: [1, 2, 4, 5, 6 ,8 , 58],
        },
        {
          path: "/create-travel-proposal/:client_id/",
          name: "Opportunities",
          mini: "",
          redirect: true,
          component: CreateTravelProposal,
          layout: "/admin",
          role: [1, 2, 4, 5, 6 , 8 , 58],
        },
        {
          path: "/download/:id/",
          name: "Download",
          mini: "",
          redirect: true,
          component: Download,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6, 8 , 58],
        },
  
        {
          path: "/my-opportunities",
          name: "My Opportunities",
          mini: "",
          component: MyLeads,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6 , 8 , 58],
        },
        {
          path: "/status-logs",
          name: "Status Logs",
          mini: "",
          component: StatusLogsPage,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6 , 8 , 58],
        },
      ],
    },
  
    ///Group Oppo
  
    {
      collapse: true,
      name: "Group",
      icon: "nc-icon nc-bullet-list-67",
      state: "GroupOppCollapse",
      role: [1, 2, 3, 4, 5, 6, 7],
      views: [
        {
          path: "/search-group-opportunities",
          name: "Search Opportunity",
          mini: "",
          component: GroupSearch,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6, 7],
        },
        {
          path: "/add-group-opportunities",
          name: "Create Opportunity",
          mini: "",
          component: AddGroupLead,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6, 7],
        },
  
        {
          path: "/group-opportunities/:id",
          name: "Opportunities",
          mini: "",
          redirect: true,
          component: GroupEditLead,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6, 7],
        },
        {
          path: "/all-group-opportunities",
          name: "All Opportunities",
          mini: "",
          component: GroupAllLeads,
          layout: "/admin",
          role: [1, 3, 4, 5, 6, 7],
        },
        {
          path: "/my-group-opportunities",
          name: "My Opportunities",
          mini: "",
          component: GroupMyLeads,
          layout: "/admin",
          role: [1, 2, 3, 4, 5, 6, 7],
        },
      ],
    },
    ///Group Oppo end
  
    {
      collapse: true,
      name: "Products",
      icon: "nc-icon nc-cart-simple",
      state: "proCollapse",
      role: [1, 2, 3],
      views: [
        {
          path: "/all-products",
          name: "All Products",
          mini: "",
          component: AllProducts,
          layout: "/admin",
          role: [1, 2, 3],
        },

        {
          path: "/all-templates",
          name: "PDF Templates",
          mini: "",
          component: AllTemplates,
          layout: "/admin",
          role: [1, 2, 3],
        },

        {
          path: "/add-template",
          name: "Add Template",
          mini: "",
          redirect: true,
          component: AddTemplate,
          layout: "/admin",
          role: [1, 2, 3],
        },

        {
          path: "/edit-template/:id",
          name: "Edit Template",
          mini: "",
          redirect: true,
          component: EditTemplate,
          layout: "/admin",
          role: [1, 2, 3],
        },
  
        {
          path: "/add-new-medical",
          name: "Add New Medicals",
          mini: "",
          component: AddMedical,
          layout: "/admin",
          role: [1, 2, 3],
        },
  
        {
          path: "/add-new-travel",
          name: "Add New Travel",
          mini: "",
          component: AddTravel,
          layout: "/admin",
          role: [1, 2, 3],
        },
        {
          path: "/edit-travel/:id/",
          name: "Edit Travel",
          mini: "",
          redirect: true,
          component: EditTravel,
          layout: "/admin",
          role: [1, 2, 3],
        },
        {
          path: "/edit-medical/:id/",
          name: "Edit Medical",
          mini: "",
          redirect: true,
          component: EditMedical,
          layout: "/admin",
          role: [1, 2, 3],
        },
      ],
    },
  
    {
      collapse: true,
      name: "Insurers",
      icon: "nc-icon nc-circle-10",
      state: "insuCollapse",
      role: [1, 2, 3],
      views: [
        {
          path: "/all-insurers",
          name: "All Insurers",
          mini: "",
          component: AllInsurer,
          layout: "/admin",
          role: [1, 2, 3],
        },
  
        {
          path: "/add-insurer",
          name: "Add Insurer",
          mini: "",
          component: AddInsurers,
          layout: "/admin",
          role: [1, 2, 3],
        },
        {
          path: "/edit-insurer/:id",
          name: "Add Insurer",
          mini: "",
          redirect: true,
          component: EditInsurer,
          layout: "/admin",
          role: [1, 2, 3],
        },
      ],
    },
  
    {
      collapse: true,
      name: "Users",
      icon: "nc-icon nc-single-02",
      state: "consultCollapse",
      role: [1, 2, 3],
      views: [
        {
          path: "/all-consultants",
          name: "All Users",
          mini: "",
          component: AllConsultants,
          layout: "/admin",
          role: [1, 2, 3],
        },
  
        {
          path: "/add-consultants",
          name: "Add Users",
          mini: "",
          component: AddConsultants,
          layout: "/admin",
          role: [1, 2, 3],
        },
        {
          path: "/edit-consultants/:id",
          name: "Edit Consultants",
          mini: "",
          redirect: true,
          component: EditConsultants,
          layout: "/admin",
          role: [1, 2],
        },
        {
          path: "/delete-consultants/:id",
          name: "Delete Consultants",
          mini: "",
          redirect: true,
          component: AllConsultants,
          layout: "/admin",
          role: [1],
        },
      ],
    },
  
    {
      // path: "/reporting",
      collapse: true,
      name: "Reporting",
      icon: "nc-icon nc-chart-bar-32",
      state: "reportCollapse",
      // component: Report,
      layout: "/admin",
      role: [1],
      email: ["nim.hiran@i-brokers.com"],
      views: [
        {
          path: "/reporting",
          name: "Individual",
          mini: "",
          component: Report,
          layout: "/admin",
          role: [1, 2, 3],
          email: ["nim.hiran@i-brokers.com"],
        },
  
        {
          path: "/group-reporting",
          name: "Group",
          mini: "",
          component: GroupReporting,
          layout: "/admin",
          role: [1, 2, 3],
          email: ["nim.hiran@i-brokers.com"],
        },
      ],
    },
  
    {
      path: "/view-document",
      name: "ViewDoc",
      icon: "nc-icon nc-chart-bar-32",
      redirect: true,
      component: ViewDoc,
      layout: "/admin",
      role: [1, 2, 3, 4, 5, 6],
    },
  
    {
      path: "/user-profile",
      name: "User Profile",
      icon: "nc-icon nc-chart-bar-32",
      redirect: true,
      component: EditProfile,
      layout: "/admin",
      role: [1, 2, 3, 4, 5, 6, 7 ,8 , 58],
    },
  ];
// }




export default routes;
