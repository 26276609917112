/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import {Link, useParams, useHistory} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";




function EditConsultants() {
  const notificationAlert = React.useRef();
  const [loginFullName, setloginFullName] = React.useState();
  const [loginEmail, setloginEmail] = React.useState();
  const [userRole,  setuserRole] = React.useState();
  const [loginFullNameState, setloginFullNameState] = React.useState("has-success");
  const [loginEmailState, setloginEmailState] = React.useState("has-success");
  const [loadingData, setLoadingData] = React.useState(true);
  const params = useParams();
  const getUserUrl = process.env.REACT_APP_API_URL+"/get-user/"+params.id;
  const editUserUrl = process.env.REACT_APP_API_URL+"/edit-user/";
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  let history = useHistory();
  const editUser = () => {
    if (loginFullNameState === "") {
      setloginFullNameState("has-danger");
    }
    if (loginEmailState === "") {
      setloginEmailState("has-danger");
    }
    if((loginEmailState !== "has-danger" && loginFullNameState !== "has-danger") && (loginEmailState !== "" && loginFullNameState !== "")){
      var data = {
        "fullname": loginFullName,
        "email": loginEmail,
        "role" : userRole,
        "user_id" : params.id
       }
      
      var headers = new Headers();
      headers.append("Content-Type", "application/json"); 
      headers.append("Authorization", "Bearer "+loggedUser.token);
      fetch(editUserUrl, {
        method: "POST",
        headers: headers,
        body:  JSON.stringify(data)
      })
      .then(function(response){ 
        return response.json();   
      })
      .then(function(data){ 
        if(!data.success){            
          notify(data.message,3,notificationAlert);
        }else{          
          notify(data.message,2,notificationAlert);
          setTimeout(function(){history.push('/admin/all-consultants')},3000);
        }
      });
    }
    
  };
  useEffect(() => {
    async function getUser(){      
        var headers = new Headers();
        headers.append("Content-Type", "application/json"); 
        headers.append("Authorization", "Bearer "+loggedUser.token);
        fetch(getUserUrl, {
          method: "GET",
          headers: headers,
        })
        .then(function(response){ 
          return response.json();   
        })
        .then(function(data){  
          if(!data.success){  
            
          }else{                         
            setloginFullName(data.user.name);
            setloginEmail(data.user.email);
            setuserRole(data.user.role);
            setLoadingData(false);
          }
        });
      
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getUser();
    }
  }, []);
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};
  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
          {loadingData ? (
                    <p>Loading Please wait...</p>
                  ) : 
            <Card className="card-stats">
            <CardHeader>  
              <CardTitle tag="h4">Edit Consultants</CardTitle>
              <Link to="/admin/all-consultants" className="btn btn-primary float-right">Back to List</Link>
            </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" xs="12">                  
                  <FormGroup className={`has-label ${loginFullNameState}`}>
                    <label>Full Name *</label>
                    <Input
                      name="fullname"
                      type="text"
                      value={loginFullName}
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 1)) {
                          setloginFullNameState("has-danger");
                        } else {
                          setloginFullNameState("has-success");
                        }
                        setloginFullName(e.target.value);
                      }}
                    />
                    {loginFullNameState === "has-danger" ? (
                      <label className="error">This field is required.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${loginEmailState}`}>
                    <label>Email Address *</label>
                    <Input
                      name="email"
                      type="email"
                      value={loginEmail}
                      onChange={(e) => {
                        if (!verifyEmail(e.target.value)) {
                          setloginEmailState("has-danger");
                        } else {
                          setloginEmailState("has-success");
                        }
                        setloginEmail(e.target.value);
                      }}
                    />
                    {loginEmailState === "has-danger" ? (
                      <label className="error">
                        Please enter a valid email address.
                      </label>
                    ) : null}
                  </FormGroup>
                  <Input
                    value="{params.id}"
                    name="user_id"
                    type="hidden"
                  />
                  
                  <Label>Pleae Select Role for the Consultants</Label>
                      <div className="form-check-radio inline">
                        <Label check>
                        
                          <Input 
                            defaultChecked={userRole === 1 ? true : false}
                            defaultValue="1"
                            id="exampleRadios11"
                            name="role"
                            type="radio"
                            
                            onChange={(e) => {                             
                              setuserRole(e.target.value);
                            }}
                          />
                          Administrator <span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input
                           defaultChecked={userRole === 2 ? true : false}
                            defaultValue="2"
                            id="exampleRadios12"
                            name="role"
                            type="radio"
                            onChange={(e) => {                             
                              setuserRole(e.target.value);
                            }}
                          />
                          Support Admin staff <span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input
                          defaultChecked={userRole === 3 ? true : false}
                            defaultValue="3"
                            id="exampleRadios13"
                            name="role"
                            type="radio"
                            onChange={(e) => {                             
                              setuserRole(e.target.value);
                            }}
                          />
                          Support  <span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio inline">
                        <Label check for="exampleRadios14">
                          <Input
                          defaultChecked={userRole === 4 ? true : false}
                            defaultValue="4"
                            id="exampleRadios14"
                            name="role"
                            type="radio"
                            onChange={(e) => {                             
                              setuserRole(e.target.value);
                            }}
                          />
                          Remote Sales <span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio inline">
                        <Label check for="exampleRadios15">
                          <Input
                          defaultChecked={userRole === 5 ? true : false}
                            defaultValue="5"
                            id="exampleRadios15"
                            name="role"
                            type="radio"
                            onChange={(e) => {                             
                              setuserRole(e.target.value);
                            }}
                          />
                           Sales Consultant<span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio inline">
                        <Label check for="exampleRadios16">
                          <Input
                          defaultChecked={userRole === 6 ? true : false}
                            defaultValue="6"
                            id="exampleRadios16"
                            name="role"
                            type="radio"
                            onChange={(e) => {                             
                              setuserRole(e.target.value);
                            }}
                          />
                          Corporate Sales  <span className="form-check-sign" />
                        </Label>
                      </div>


                      <div className="form-check-radio inline">
                      <Label check for="exampleRadios18">
                        <Input
                         defaultChecked={userRole === 8 ? true : false}
                         defaultValue="8"
                          id="exampleRadios18"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Client Relationship Manager Individual <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>                  
                </Row>
              </CardBody>
              <CardFooter>
              <Button color="primary" onClick={editUser}>
                    Edit Consultant
                  </Button>
              </CardFooter>
            </Card>
              }
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditConsultants;
