/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ServerPagination from "components/ReactTable/ServerPagination";
import AppTable from "components/ReactTable/AppTable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { getProducts } from "store/TableAction";
import { TableSliceAction } from "store/TableDataSlice";
// reactstrap components
import { Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { notify } from "components/functions";
import OpportunityFilter from "components/Filters/opportunity-filter";
import { DeleteGroupOpportunityApi } from "store/AddGroupAction";
import { GET_DELETE_GROUP_OPPORTUNITY_RESET } from "store/GroupLeadOpportunitiesAction";
import OpportunityGroupFilter from "components/Filters/OpportunityGroupFilter";
import ReactDatetime from "react-datetime";
import moment from "moment";
import OpportunitiesFilter from "components/Filters/OpportunitiesFilter";

const GroupOpportunities = (props) => {
  const dispatch = useDispatch();
  const { pageData, currentPage, sortColumn, sortDirection, LeadFilter } =
    useSelector((state) => state.tableData);

  const { DeletegroupOpportunityDataSuccess } = useSelector(
    (state) => state.getDeleteGroupOpportunityDataRe
  );
  const notificationAlert = React.useRef();
  const [alert, setAlert] = React.useState(null);
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));

  const [loadingData, setLoadingData] = useState(true);
  const [SelectedLeads, setSelectedLeads] = useState([]);
  const [RefreshTable, setRefreshTable] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  let options;
  const history = useHistory();
  useEffect(() => {
    dispatch(TableSliceAction.reset());
  }, []);
  useEffect(() => {
    if (props.leadType === "all") {
      dispatch(
        TableSliceAction.updateFilterParam({
          field: "o_status",
          value: "Opportunity",
        })
      );
    }
    if (props.leadType === "my") {
      dispatch(
        TableSliceAction.updateFilterParam({
          field: "o_status",
          value: "Opportunity Open",
        })
      );
    }
  }, []);

  // ENQUIRY DATE
  // CLIENT
  // CONTACT PERSON
  // PRODUCT
  // PHONE
  // GENERATOR
  // ACTIVITY
  // ACTIVITY DATE
  // LOCATION
  // FOLLOW

  // QUOTE DATE
  // PRODUCT
  // CLIENT
  // QUOTE REQUIRED BY
  // CURRENT OWNER
  // RENEWAL/START DATE
  // ACTIVITY
  // ACTIVITY DATE
  // LOCATION
  // FOLLOW UP DA

  //   SALE DATE
  // PRODUCT
  // CLIENT
  // PREMIUM
  // PAYMENT FREQUENCY
  // COMMISSION
  // ACTIVITY
  // ACTIVITY DATE
  // LOCATION
  // FOLLOW UP DA

  const AccountPendingColumn = [
    {
      Header: "Sale Date",
      accessor: "renewal_start_date",
      sortdir: sortColumn === "renewal_start_date" ? sortDirection : "",
      sorCol: sortColumn === "renewal_start_date" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    {
      Header: "Premium",
      accessor: "annual_premium_amount",
      sortdir: sortColumn === "annual_premium_amount" ? sortDirection : "",
      sorCol: sortColumn === "annual_premium_amount" ? "yes" : "no",
    },
    {
      Header: "Payment Frequency",
      accessor: "annual_premium_payment_period",
      sortdir:
        sortColumn === "annual_premium_payment_period" ? sortDirection : "",
      sorCol: sortColumn === "annual_premium_payment_period" ? "yes" : "no",
    },
    {
      Header: "Commission",
      accessor: "total_commission_amount",
      sortdir: sortColumn === "total_commission_amount" ? sortDirection : "",
      sorCol: sortColumn === "total_commission_amount" ? "yes" : "no",
    },
    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
    {
      Header: "Activity Date",
      accessor: "activity_date",
      sortdir: sortColumn === "activity_date" ? sortDirection : "",
      sorCol: sortColumn === "activity_date" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },

    {
      Header: "Follow Up Date",
      accessor: "followUpDate",
      sortdir: sortColumn === "followUpDate" ? sortDirection : "",
      sorCol: sortColumn === "followUpDate" ? "yes" : "no",
    },
    {
      Header: "Priority",
      accessor: "priority",
      sortdir: sortColumn === "priority" ? sortDirection : "",
      sorCol: sortColumn === "priority" ? "yes" : "no",
    },
  ];

  const QuoteingColumn = [
    {
      Header: "Quote Date",
      accessor: "quote_date",
      sortdir: sortColumn === "quote_date" ? sortDirection : "",
      sorCol: sortColumn === "quote_date" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    {
      Header: "Quote Requierd By",
      accessor: "group_administrator",
      sortdir: sortColumn === "group_administrator" ? sortDirection : "",
      sorCol: sortColumn === "group_administrator" ? "yes" : "no",
    },
    {
      Header: "Current Owner",
      accessor: "current_owner",
      sortdir: sortColumn === "current_owner" ? sortDirection : "",
      sorCol: sortColumn === "current_owner" ? "yes" : "no",
    },
    {
      Header: " Renewal/Start Date",
      accessor: "renewal",
      sortdir: sortColumn === "renewal" ? sortDirection : "",
      sorCol: sortColumn === "renewal" ? "yes" : "no",
    },
    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
    {
      Header: "Activity Date",
      accessor: "activity_date",
      sortdir: sortColumn === "activity_date" ? sortDirection : "",
      sorCol: sortColumn === "activity_date" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },

    {
      Header: "Follow Up Date",
      accessor: "followUpDate",
      sortdir: sortColumn === "followUpDate" ? sortDirection : "",
      sorCol: sortColumn === "followUpDate" ? "yes" : "no",
    },
    {
      Header: "Priority",
      accessor: "priority",
      sortdir: sortColumn === "priority" ? sortDirection : "",
      sorCol: sortColumn === "priority" ? "yes" : "no",
    },
  ];

  const OpportunityOpenColumn = [
    {
      Header: "Enquiry Date",
      accessor: "created_at",
      sortdir: sortColumn === "created_at" ? sortDirection : "",
      sorCol: sortColumn === "created_at" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },

    {
      Header: "Contact Person",
      accessor: "contact_person",
      sortdir: sortColumn === "contact_person" ? sortDirection : "",
      sorCol: sortColumn === "contact_person" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Phone",
      accessor: "group_admin_phone",
      sortdir: sortColumn === "group_admin_phone" ? sortDirection : "",
      sorCol: sortColumn === "group_admin_phone" ? "yes" : "no",
    },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },
    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
    {
      Header: "Activity Date",
      accessor: "activity_date",
      sortdir: sortColumn === "activity_date" ? sortDirection : "",
      sorCol: sortColumn === "activity_date" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },

    {
      Header: "Follow Up Date",
      accessor: "followUpDate",
      sortdir: sortColumn === "followUpDate" ? sortDirection : "",
      sorCol: sortColumn === "followUpDate" ? "yes" : "no",
    },
    {
      Header: "Priority",
      accessor: "priority",
      sortdir: sortColumn === "priority" ? sortDirection : "",
      sorCol: sortColumn === "priority" ? "yes" : "no",
    },
  ];

  const AllColumn = [
    {
      Header: "Enquiry Date",
      accessor: "created_at",
      sortdir: sortColumn === "created_at" ? sortDirection : "",
      sorCol: sortColumn === "created_at" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },

    {
      Header: " Renewal/Start Date",
      accessor: "renewal",
      sortdir: sortColumn === "renewal" ? sortDirection : "",
      sorCol: sortColumn === "renewal" ? "yes" : "no",
    },

    {
      Header: "Phone",
      accessor: "phone",
      sortdir: sortColumn === "phone" ? sortDirection : "",
      sorCol: sortColumn === "phone" ? "yes" : "no",
    },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },
    // {
    //   Header: "Priority",
    //   accessor: "priority",
    //   sortdir: sortColumn === "priority" ? sortDirection : "",
    //   sorCol: sortColumn === "priority" ? "yes" : "no",
    // },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];
  

  const columns = useMemo(() => {
    if (props.leadType === "all") {
      return AllColumn;
    }
    if (props.leadType === "my") {
      if (pageData?.status === "Opportunity Open") {
        return OpportunityOpenColumn;
      }
      if (pageData?.status === "Quoting") {
        return QuoteingColumn;
      }
      if (pageData?.status === "Account Pending") {
        return AccountPendingColumn;
      }
    }

    // return AllColumn;
  });

  const getMonthName = (monthname) => {
    const date = new Date();
    date.setMonth(monthname - 1);

    return date.toLocaleString("en-US", { month: "short" });
  };

  const handleRowClick = (rowData) => {
    history.push({
      pathname: "/admin/group-opportunities/" + rowData.group_id,
      opportunitiesStatus: rowData.status,
    });
  };

  const activityDate = (dateActivity) => {
    if (dateActivity) {
      const times = dateActivity.split(" ")[1];
      const dates = dateActivity.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate + " " + times;
      return originalActivityDate;
    }
  };

  const QuoteDate = (dateActivity) => {
    if (dateActivity) {
      const times = dateActivity.split(" ")[1];
      const dates = dateActivity.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate;
      return originalActivityDate;
    }
  };

  const renewalDate = (dateActivity) => {
    if (dateActivity) {
      const dates = dateActivity.split(" ")[0];
      const [dd, mm, yy] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate;
      return originalActivityDate;
    }
  };

  let claim_leads = useMemo(() => [], [RefreshTable]);
  let checked;
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);

  useEffect(() => {
    if (loadingData) {
      dispatch(
        getProducts(props.api, "created_at", "DESC", headers, props.filterData)
      );
    }
  }, [currentPage, sortDirection, LeadFilter, RefreshTable]);


  const handelRenewalFilterDate = (filterDate, dateType) => {
    const getOriginalDate = moment(filterDate.toDate()).format("DD-MM-YYYY");
    if (dateType === "start") {
      setStartDate(getOriginalDate);
    }
    if (dateType === "end") {
      setEndDate(getOriginalDate);
    }
  };

  const filterDataByDateRange = (data) => {
    if (!startDate || !endDate) {
      return data; // Return original data if no date range is selected
    }
    return data.filter((row) => {
      if (row?.renewal_start_date) {
        const renewalDateParts = row?.renewal_start_date?.split("-");

        const rowDate = new Date(
          parseInt(renewalDateParts[2]), // Year
          parseInt(renewalDateParts[1]) - 1, // Month (subtract 1 as months are zero-based)
          parseInt(renewalDateParts[0]) // Day
        );
        const startDateParts = startDate?.split("-");

        const rowStartDate = new Date(
          startDateParts[2], // Year
          startDateParts[1] - 1, // Month (subtract 1 as months are zero-based)
          startDateParts[0] // Day
        );

        const endDateParts = endDate?.split("-");

        const rowEndDate = new Date(
          endDateParts[2], // Year
          endDateParts[1] - 1, // Month (subtract 1 as months are zero-based)
          endDateParts[0] // Day
        );
        return rowDate >= rowStartDate && rowDate <= rowEndDate;
      }
    });
  };

  const formatRowData = (rawData) =>
    rawData.map((info) => ({
      status: info.status,
      product: info.product,
      client: info.group_name,
      contact_person:
        info.group_admin_first_name + " " + info.group_admin_last_name,
      location: info.group_location,
      phone: info.phone,
      group_admin_phone: info.group_admin_phone,
      email: info.email,
      generator: info.generator,
      priority:info?.priority,
      created_at: activityDate(info?.added_at),
      quote_date: QuoteDate(info?.quote_date),
      activity: info.activity,
      renewal: renewalDate(info?.renewal_start_date),
      followUpDate: info.follow_up_date,
      group_id: info.group_id,
      activity_date: activityDate(info?.activity_date),
      group_administrator: info.group_administrator,
      current_owner: info.current_owner,
      renewal_start_date: info?.renewal_start_date,
      annual_premium_amount: info?.annual_premium_amount,
      total_commission_amount: info?.total_commission_amount,
      annual_premium_payment_period: info?.annual_premium_payment_period,
      groupurl: `/admin/group-opportunities/${info?.group_id}`,

      actions: (
        <>
          {props.leadType === "all" && (
            <FormGroup check inline style={{ display: 'flex', alignItems: 'center', marginLeft: '90px',}}>
              <Label check style={{ marginBottom: "14px" }}>
                <Input
                  type="checkbox"
                  key={info.id}
                  value={info.id}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      claim_leads.push(e.target.value);
                      setSelectedLeads(claim_leads);
                    } else {
                      var index = claim_leads.indexOf(e.target.value);
                      if (index > -1) {
                        claim_leads.splice(index, 1);
                        setSelectedLeads(claim_leads);
                      }
                    }
                  }}
                />
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
          )}
        </>
      ),
    }));
  const claimLeads = () => {
    if (SelectedLeads.length > 0) {
      let data = { ids: SelectedLeads };
      fetch(process.env.REACT_APP_API_URL + "/claim-group-leads", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            notify(data.message, 2, notificationAlert);
            setSelectedLeads([]);
            setRefreshTable(data.refresh);
          }
        });
    } else {
      notify("Please select opportunity.", 3, notificationAlert);
    }
  };

  const deleteLeads = () => {
    if (SelectedLeads.length > 0) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete()}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this!
        </ReactBSAlert>
      );
    } else {
      notify("Please select opportunity.", 3, notificationAlert);
    }
  };

  const successDelete = async () => {
    const newId = { ids: SelectedLeads };
    await dispatch(DeleteGroupOpportunityApi(newId, loggedUser.token));
  };

  useEffect(() => {
    if (DeletegroupOpportunityDataSuccess?.data?.success == true) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert("no")}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {DeletegroupOpportunityDataSuccess?.data?.message}
        </ReactBSAlert>
      );
      setRefreshTable(DeletegroupOpportunityDataSuccess?.data?.refresh);
      dispatch({
        type: GET_DELETE_GROUP_OPPORTUNITY_RESET,
        payload: { data: "" },
      });
    }
  }, [DeletegroupOpportunityDataSuccess]);
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };

  const getData = async (pageNo = 1, sortOrder = "ASC", column = "title") => {
    dispatch(
      TableSliceAction.updateTableConfig({ sCol: column, sDir: sortOrder })
    );
  };
  const updatePage = async (pageNo = 1) => {
    dispatch(TableSliceAction.updatePage(pageNo));
  };
  const updateStartDateEndDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      {alert}
      <Row>
        <Col md="6">
          <p>Total Records: {pageData.totalRecords===null ? "Loading..." : pageData.totalRecords}</p>
        </Col>

        {props.leadType === "all" ? (
          <Col md="6" className="text-right">
            <Button color="primary" onClick={() => claimLeads()}>
              Claim Opportunities
            </Button>
            {loggedUser.data.role === 1 ? (
              <Button color="danger" onClick={() => deleteLeads()}>
                Delete Opportunities
              </Button>
            ) : null}
          </Col>
        ) : null}
      </Row>

      {props.leadType === "all" ? (
        <Row>
          <Col md="6"></Col>

          <Col md="6" className="text-right">
            <div className="datetime-container">
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Renewal Start",
                  name: "premium_recived_date",
                  style: { width: "195px" },
                  value: startDate || "",
                }}
                value={startDate || ""}
                timeFormat={false}
                closeOnSelect={true}
                onChange={(selectedDate) => {
                  handelRenewalFilterDate(selectedDate, "start");
                }}
              />

              <ReactDatetime
                className="gautam"
                inputProps={{
                  className: "form-control",
                  placeholder: "Renewal End",
                  name: "premium_recived_date",
                  style: { width: "195px" },
                  value: endDate || "",
                }}
                style={{ marginLeft: "-55px" }}
                value={endDate || ""}
                timeFormat={false}
                closeOnSelect={true}
                onChange={(selectedDate) => {
                  handelRenewalFilterDate(selectedDate, "end");
                }}
              />
            </div>
          </Col>
        </Row>
      ) : pageData?.status === "Quoting" ? 
    
      <Row>
      <Col md="6"></Col>

      <Col md="6" className="text-right">
        <div className="datetime-container">
          <ReactDatetime
            inputProps={{
              className: "form-control",
              placeholder: "Renewal Start",
              name: "premium_recived_date",
              style: { width: "195px" },
              value: startDate || "",
            }}
            value={startDate || ""}
            timeFormat={false}
            closeOnSelect={true}
            onChange={(selectedDate) => {
              handelRenewalFilterDate(selectedDate, "start");
            }}
          />

          <ReactDatetime
            className="gautam"
            inputProps={{
              className: "form-control",
              placeholder: "Renewal End",
              name: "premium_recived_date",
              style: { width: "195px" },
              value: endDate || "",
            }}
            style={{ marginLeft: "-55px" }}
            value={endDate || ""}
            timeFormat={false}
            closeOnSelect={true}
            onChange={(selectedDate) => {
              handelRenewalFilterDate(selectedDate, "end");
            }}
          />
        </div>
      </Col>
    </Row>
    : null
    }
      {props.api === "search" ? null : props.leadType === "my" ? (
        <OpportunitiesFilter leadType={props.leadType} productType={props.api} />
      ) : (
        <OpportunityGroupFilter
          leadType={props.leadType}
          updateStartDateEndDate={updateStartDateEndDate}
        />
      )}
      <Row>
        <Col md="12">
          {columns && (
            <AppTable
              columns={columns}
              data={formatRowData(filterDataByDateRange(pageData.rowData))}
              isLoading={pageData.isLoading}
              sorting={getData}
              page={currentPage}
              onRowClick={handleRowClick}
            />
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md="12">
          <ServerPagination
            totalRows={pageData.totalRecords}
            pageChangeHandler={updatePage}
            rowsPerPage={100}
          />
        </Col>
      </Row>
    </>
  );
};
export default GroupOpportunities;
