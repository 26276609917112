import React, { useMemo, useState, useEffect } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import Opportunities from "components/Leads/Opportunities";

const GroupReporting = () => {
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Group Reports</CardTitle>
            </CardHeader>
            <CardBody>
              <Opportunities
                api="group-report"
                leadType="report-group"
                method="GET"
                filterData=""
              ></Opportunities>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GroupReporting;
