import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import { TableSliceAction } from "store/TableDataSlice";
const InsurerFilters = props => {
    const dispatch = useDispatch();
    return (
        <>
            <Row>
                <Col lg="4">
                    <FormGroup className={`has-label`}>
                        <label>Company name</label>
                        <Input type="text"
                            onChange={(e) => {
                                if (e.target.value.length > 3 || e.target.value.length === 0)
                                    dispatch(TableSliceAction.updateFilterParam({ field: 'title', value: e.target.value }));

                            }} />
                    </FormGroup>
                </Col>
                <Col lg="4">
                    <FormGroup className={`has-label`}>
                        <label>Type Medical *</label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            onChange={(e) => {
                                dispatch(TableSliceAction.updateFilterParam({ field: 'medical_type', value: e.value }));
                                
                            }}
                            options={[
                                {
                                    value: "",
                                    label: "Select Medical type",
                                    isDisabled: true,
                                },
                                { value: "No Type", label: "No Type" },
                                { value: "A", label: "A" },
                                { value: "B", label: "B" },
                                { value: "C", label: "C" },
                            ]}
                            placeholder="Select Medical type"
                        />
                    </FormGroup>
                </Col>
                <Col lg="4">
                    <FormGroup className={`has-label`}>
                        <label>Type Travel *</label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            onChange={(e) => {
                                dispatch(TableSliceAction.updateFilterParam({ field: 'travel_type', value: e.value }));
                                
                            }}
                            options={[
                                {
                                    value: "",
                                    label: "Select Travel type",
                                    isDisabled: true,
                                },
                                { value: "No Type", label: "No Type" },
                                { value: "A", label: "A" },
                                { value: "B", label: "B" },
                                { value: "C", label: "C" },
                            ]}
                            placeholder="Select Travel type"
                        />
                        
                    </FormGroup>
                </Col>
            </Row>

        </>
    )
}

export default InsurerFilters;