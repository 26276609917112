import { React, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { GetStatusLogsApi } from "store/FetchOpportunities";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from 'react';
import "components/ReactTable/styles.css";
// import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import OpportunitiesFilter from "components/Filters/OpportunitiesFilter";
import { Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
import Stack from "@mui/material/Stack";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const StatusLogs = (props) => {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [quotes, setQuotes] = useState([]);
  const [loader, setLoader] = useState(true);
  const { StatusLogsDataSuccess } = useSelector(
    (state) => state.getStatusLogsDataRe
  );

  useEffect(() => {
    const getStatusLogs = async () => {
      await dispatch(GetStatusLogsApi(loggedUser.token));
    };
    getStatusLogs();
  }, []);

  useEffect(() => {
    if (StatusLogsDataSuccess?.data?.success == true) {
      setQuotes(StatusLogsDataSuccess?.data?.statusData);
      setLoader(false);
    }
  }, [StatusLogsDataSuccess]);
  // console.log("StatusLogsDataSuccess", StatusLogsDataSuccess);

  let rows = [];

  const getMonthName = (monthname) => {
    const date = new Date();
    date.setMonth(monthname - 1);

    return date.toLocaleString("en-US", { month: "short" });
  };
  const activityDate = (dateActivity) => {
    if (dateActivity) {
      const times = dateActivity.split(" ")[1];
      const dates = dateActivity.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate + " " + times;
      return originalActivityDate;
    }
  };

  const columns = [
    { field: "product_name", headerName: "PRODUCT", width: 330 },
    { field: "user_name", headerName: "UPDATED BY", width: 330 },
    { field: "new_status", headerName: "NEW STATUS", width: 330 },
    { field: "old_status", headerName: "OLD STATUS", width: 330 },

    {
      field: "updated_at",
      headerName: "UPDATED DATE",
      width: 330,
      renderCell: (cellValues) => {
        // let updateDate = activityDate(cellValues.row.added_at);I
        return <>{`${activityDate(cellValues.row.updated_at)}`}</>;
      },
    },

    // {
    //   field: "ACTIONS",
    //   headerName: "ACTIONS",
    //   renderCell: (cellValues) => {
    //     return (
    //       <Link
    //         className="btn btn-primary btn-sm"
    //         to={{
    //           pathname: "/admin/group-opportunities/" + cellValues.row.group_id,
    //           opportunitiesStatus: cellValues.row.status,
    //         }}
    //       >
    //         {/* {console.log("cellValues", cellValues)} */}
    //         Detail
    //       </Link>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Row>
        <Col md="6">
          <p>
            Total Records:{" "}
            {StatusLogsDataSuccess?.data?.statusData?.length || "Loading..."}
          </p>
        </Col>
      </Row>
      {/* {props.api === "search" ? null : (
        <OpportunitiesFilter leadType={props.leadType} />
      )} */}
      <div style={{ height: 800, width: "100%" }} className="datagridcss">
        <StripedDataGrid
          //   className={quotes?.length > 0 ? "" : "product-name" }
          rows={quotes}
          columns={columns}
          // loading={ StatusLogsDataSuccess?.data?.list?.length === 0}
          // pageSizeOptions={[5]}
          rowsPerPageOptions={[10, 20]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          // components={{  NoRowsOverlay }}
          pageSizeOptions={[5, 10, 15, 20, 25]}
          disableColumnMenu
          disableUnderline
          disableRowSelectionOnClick
          loading={loader}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
      </div>
    </>
  );
};

export default StatusLogs;
