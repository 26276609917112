import axios from "axios";
import * as groupOpportunityAction from "./GroupLeadOpportunitiesAction";

const URL = process.env.REACT_APP_API_URL;

export const GetQuotingApi = (product, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(
      `${URL}/my-Quoting-leads`,
      { fetch_for: "Quoting", product_type: product },
      {
        headers: headers,
      }
    );
    // console.log("checking the GetQuotingApi", product);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_QUOTING_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_QUOTING_FAIELD,
      payload: error.message,
    });
  }
};

export const GetAccountPandingApi = (product, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(
      `${URL}/my-Account-leads`,
      { fetch_for: "Account Pending", product_type: product },
      {
        headers: headers,
      }
    );
    // console.log("checking the GetAccountPandingApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_ACCOUNT_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_ACCOUNT_FAIELD,
      payload: error.message,
    });
  }
};

export const GetOppotunitesApi = (newData, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(`${URL}/my-group-leads`, newData, {
      headers: headers,
    });
    // console.log("checking the GetOppotunitesApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_OPPORTUNITY_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_OPPORTUNITY_FAIELD,
      payload: error.message,
    });
  }
};

export const GetStatusLogsApi = (token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.get(`${URL}/status-logs`, {
      headers: headers,
    });
    // console.log("checking the GetOppotunitesApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_STATUS_LOGS_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_STATUS_LOGS_FAIELD,
      payload: error.message,
    });
  }
};

export const GetIndividualStatusLogsApi =
  (product_id, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/individual-status-logs`,
        { product_id: product_id },
        {
          headers: headers,
        }
      );
      // console.log("checking the GetOppotunitesApi", data);

      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_INDIVIDUAL_SEARCH_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_INDIVIDUAL_SEARCH_FAIELD,
        payload: error.message,
      });
    }
  };

export const getGroupSearchApi = (dataToSearch, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(`${URL}/group/search`, dataToSearch, {
      headers: headers,
    });
    // console.log("checking the getGroupSearchApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_GROUP_SEARCH_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_GROUP_SEARCH_FAIELD,
      payload: error.message,
    });
  }
};

export const getIndividualSearchApi =
  (individualSearchData, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/search`,
        individualSearchData,
        {
          headers: headers,
        }
      );
      // console.log("checking the getIndividualSearchApi", data);

      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_INDIVIDUAL_SEARCH_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_INDIVIDUAL_SEARCH_FAIELD,
        payload: error.message,
      });
    }
  };
