import React, { useState, useEffect } from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
} from "reactstrap";

function ServerPagination({
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  fixiedNumberOfPage,
}) {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  const pagesArr = [...new Array(noOfPages)];

  const [countLimitValue, setCountLimitValue] = useState(false);

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(1);

  const [maxLimit, setMaxLimit] = useState(1);

  const [countLimit, setCountLimit] = useState("");

  const [lastPage, setLastPage] = useState(lastPage ? lastPage : "");

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // These variables give the first and last record/row number
  // with respect to the current page
  const [pageFirstRecord, setPageFirstRecord] = useState(1);
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage);

  const onPrevPage = () => {
    if (maxLimit > 1) {
      setMaxLimit(maxLimit - 1);
      setCountLimit(countLimit - 1);
    }
  };

  const onPageSelect = (pageNo) => setCurrentPage(pageNo);

  const onNextPage = () => {
    if (maxLimit <= noOfPages) {
      if (countLimit != noOfPages) {
        setMaxLimit(maxLimit + 1);
        setCountLimit(countLimit + 1);
      }
    }
  };

  const pages = [];
  for (let i = maxLimit; i <= noOfPages; i++) {
    pages.push(i);
  }

  // pageChangeHandler, totalRows, rowsPerPage
  // console.log("pageChangeHandler", pageChangeHandler);
  // console.log("totalRows", totalRows);
  // console.log("rowsPerPage", rowsPerPage);
  // console.log("pages", pages);
  // console.log("countLimit", countLimit);

  // Disable previous and next buttons in the first and last page
  // respectively

  // useEffect (()=>{
  //       if(noOfPages > 1) {
  //         setCurrentList(5)
  //       }
  // }, [])

  useEffect(() => {
    if (!countLimitValue) {
      // console.log("working" , "it is working");
      if (noOfPages > 10) {
        // console.log("in if");
        setCountLimit(10);
      }
      if (noOfPages < 10) {
        // console.log("in 2nd if", noOfPages);
        setCountLimit(noOfPages);
      }
    }
  });

  useEffect(() => {
    // if(noOfPages > 10){
    //   console.log("in if")
    //   setCountLimitValue(10)
    // }if(noOfPages < 10){
    //   console.log("in 2nd if" ,noOfPages)

    //   setCountLimitValue(noOfPages)
    // }
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    // Some APIs require skip for paginaiton. If needed use that instead
    // pageChangeHandler(skipFactor);
    pageChangeHandler(currentPage);
    setPageFirstRecord(skipFactor + 1);
  }, [currentPage]);

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  return (
    <>
      {noOfPages > 1 ? (
        <Row>
          <Col md="10">
            {/* {console.log("countLimitValue", countLimitValue)}

            {console.log("countLimit", countLimit)}
            {console.log("noOfPages", noOfPages)} */}

            <Pagination>
              <PaginationItem>
                <PaginationLink
                  aria-label="Previous"
                  onClick={() => {
                    if (currentPage > 1) {
                      setCountLimitValue(true)
                      onPageSelect(currentPage - 1);
                      onPrevPage();
                    }
                  }}
                  // onClick={onPrevPage}
                  disabled={!canGoBack}
                >
                  <span aria-hidden={true}>
                    <i aria-hidden={true} className="fa fa-angle-double-left" />
                  </span>
                </PaginationLink>
              </PaginationItem>

              {pages.map((page) =>
                page <= countLimit && pages.length ? (
                  <>
                    {/* {onSetLastCount(page)} */}
                    <PaginationItem
                      key={page}
                      className={`${currentPage === page ? "active" : ""}`}
                    >
                      <PaginationLink onClick={() => onPageSelect(page)}>
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null
              )}
              <PaginationItem>
                <PaginationLink
                  aria-label="Next"
                  // onClick={onNextPage}
                  onClick={() => {
                    setCountLimitValue(true)
                    onPageSelect(currentPage + 1);
                    onNextPage();
                  }}
                  disabled={!canGoNext}
                >
                  <span aria-hidden={true}>
                    <i
                      aria-hidden={true}
                      className="fa fa-angle-double-right"
                    />
                  </span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
          <Col md="2" className="text-right">
            Showing {pageFirstRecord} - {pageLastRecord} of {totalRows}
          </Col>
        </Row>
      ) : null}
    </>
  );
}

export default ServerPagination;
