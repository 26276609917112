import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FormGroup, Row, Col } from "reactstrap";
import { TableSliceAction } from "store/TableDataSlice";
import {
  statusOptions,
  productGroupOptions,
  generatorOption,
} from "variables/productOption";

const OpportunityGroupFilter = (props) => {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadStatus, setLoadStatus] = useState();
  let optArr;
  if (
    (loggedUser.data.role === 3 ||
      loggedUser.data.role === 4 ||
      loggedUser.data.role === 6 ||
      loggedUser.data.role === 7) &&
    props.leadType === "my"
  ) {
    optArr = [
      { value: "Opportunity Open", label: "Opportunity Open" },
      { value: "Quoting", label: "Quoting" },
      { value: "Account Pending", label: "Account Pending" },
    ];
  } else if (
    (loggedUser.data.role === 1 ||
      loggedUser.data.role === 2 ||
      loggedUser.data.role === 3 ||
      loggedUser.data.role === 4 ||
      loggedUser.data.role === 5 ||
      loggedUser.data.role === 6 ||
      loggedUser.data.role === 7) &&
    props.leadType === "all"
  ) {
    //   if (loggedUser.data.role === 2) {
    //     optArr = [
    //       { value: "Online Application", label: "Online Application" },
    //       { value: "Opportunity", label: "Opportunity" },
    //       { value: "Account Pending", label: "Account Pending" },
    //     ];
    //   } else {
    //     optArr = [
    //       { value: "Online Application", label: "Online Application" },
    //       { value: "Opportunity", label: "Opportunity" },
    //     ];
    //   }
    optArr = [
      { value: "All", label: "All" },
      { value: "Opportunity", label: "Opportunity" },
      { value: "Online Application", label: "Online Application" },
      // { value: "Opportunity Open", label: "Opportunity Open" },
      // { value: "Quoting", label: "Quoting" },
      // { value: "Opportunity Squandered", label: "Opportunity Squandered" },
      // { value: "Account Pending", label: "Account Pending" },
      // { value: "Account Open", label: "Account Open" },
      // { value: "Account Expired", label: "Account Expired" },
      // { value: "Account Lapsed", label: "Account Lapsed" },
    ];

  }  else {
    // optArr = [{ value: "", label: "All" }, ...statusOptions];
  }

  useEffect(() => {
    if (props.leadType === "all") {
      if (
        loggedUser.data.role === 3 ||
        loggedUser.data.role === 4 ||
        loggedUser.data.role === 6 ||
        loggedUser.data.role === 1 ||
        loggedUser.data.role === 5 ||
        loggedUser.data.role === 7
      ) {
        dispatch(
          TableSliceAction.updateFilterParam({
            field: "o_status",
            value: "",
          })
        );

        setLoadStatus([
          { value: "Opportunity", label: "Opportunity" },
        ]);
      }

      optArr = [
        { value: "Opportunity", label: "Opportunity" },
        // { value: "Account Pending", label: "Account Pending" },
      ];
    }
  }, []);
// console.log("dfjuidhfhgdfhgdjgfgdfjgfdgggdfgfd")
  return (
    <>
      <Row>
        <Col lg="4">
          <FormGroup className={`has-label`}>
            <label>Status*</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              onChange={(e) => {
                props.updateStartDateEndDate()
                dispatch(
                  TableSliceAction.updateFilterParam({
                    field: "o_status",
                    value: e.value,
                  })
                );
                // setLoadStatus({
                //   value: "Opportunity Open",
                //   label: "Opportunity Open",
                // });
                setLoadStatus({ value: e.value, label: e.label });
              }}
              value={loadStatus}
              options={optArr}
              placeholder="Status"
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className={`has-label`}>
            <label>Product*</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              onChange={(e) => {
                dispatch(
                  TableSliceAction.updateFilterParam({
                    field: "product_type",
                    value: e.value,
                  })
                );
              }}
              options={[{ value: "", label: "All" }, ...productGroupOptions]}
              placeholder="Select"
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className={`has-label`}>
            <label>Generator*</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              onChange={(e) => {
                dispatch(
                  TableSliceAction.updateFilterParam({
                    field: "generator",
                    value: e.value,
                  })
                );
              }}
              options={[{ value: "", label: "All" }, ...generatorOption]}
              placeholder="Select"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default OpportunityGroupFilter;
