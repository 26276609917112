import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
// reactstrap components
import { FormGroup, Input, Col, Table, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPremiumDetailApi,
  FetchAllGroupPremiumDetailsApi,
  DeleteGroupPremiumDetailApi,
} from "store/AddGroupAction";
import { GET_ADD_GROUP_PREMIUMM_DETAILS_RESET } from "store/GroupLeadOpportunitiesAction";
import { GET_DELETE_GROUP_PREMIUMM_DETAILS_RESET } from "store/GroupLeadOpportunitiesAction";
import { GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_RESET } from "store/GroupLeadOpportunitiesAction";
const GroupPremiumDetails = (props) => {
  let newDate = moment(new Date()).format("DD-MM-YYYY");
  const notificationAlert = React.useRef();
  const [formValues, setFormValues] = useState([
    {
      p_amt: "",
      p_rec: "yes",
      p_currency: "USD",
      p_recived_date: newDate,
      c_amt: "",
      c_rec: "yes",
      c_currency: "USD",
      p_date: "",
    },
  ]);
  // p_currency: "",
  const [oldFormValue, setOldFormValue] = useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [alert, setAlert] = React.useState(null);
  const [premiumError, setPremiumError] = useState(false);
  const [CommissionError, setCommissionError] = useState(false);
  const [UpdateApi, setUpdateApi] = useState([]);
  const dispatch = useDispatch();

  const { AddGroupPremiumDataSuccess } = useSelector(
    (state) => state.getAddGroupPremiumDetailDataRe
  );

  const { GetAllGroupPremiumDataSuccess } = useSelector(
    (state) => state.getAllGroupPremiumDetailDataRe
  );

  const { DeleteroupPremiumDataSuccess } = useSelector(
    (state) => state.getDeleteGroupPremiumDetailDataRe
  );



// console.log("loadingData" , loadingData)

useEffect(() => {
    const getPremium = async () => {
      await dispatch(
        FetchAllGroupPremiumDetailsApi(props.product_id, loggedUser.token)
      );
    };
    getPremium();
  }, [loadingData]);

  useEffect(() => {
    if (DeleteroupPremiumDataSuccess?.data?.success == true) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert("no-refresh")}
          onCancel={() => hideAlert("no-refresh")}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {DeleteroupPremiumDataSuccess?.data?.message}
        </ReactBSAlert>
      );

      dispatch({
        type: GET_DELETE_GROUP_PREMIUMM_DETAILS_RESET,
        payload: { data: "" },
      });
    }
  }, [DeleteroupPremiumDataSuccess]);

  useEffect(() => {
    if (GetAllGroupPremiumDataSuccess?.data?.success == true) {
    dispatch({type:GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_RESET , payload:{data:""}})

      setOldFormValue(GetAllGroupPremiumDataSuccess?.data?.list);
      setLoadingData(false);
    }
    if (GetAllGroupPremiumDataSuccess?.data?.success == false) {
      setLoadingData(false);
    }
  }, [GetAllGroupPremiumDataSuccess]);

  useEffect(() => {
    if (AddGroupPremiumDataSuccess?.data?.success == true) {
      notify(AddGroupPremiumDataSuccess?.data?.message, 2, notificationAlert);
      setShowLoader(false);
      setLoadingData(true);

      dispatch({
        type: GET_ADD_GROUP_PREMIUMM_DETAILS_RESET,
        payload: { data: "" },
      });
      setFormValues([
        {
          p_amt: "",
          p_rec: "yes",
          p_currency: "USD",
          c_amt: "",
          p_recived_date: newDate,
          c_currency: "USD",
          c_rec: "yes",
          p_date: "",
        },
      ]);
    }

    if (AddGroupPremiumDataSuccess?.data?.success == false) {
      notify(AddGroupPremiumDataSuccess?.data?.message, 3, notificationAlert);
      setShowLoader(false);
      setLoadingData(false);
      setFormValues([
        {
          p_amt: "",
          p_rec: "yes",
          p_currency: "USD",
          c_amt: "",
          p_recived_date: newDate,
          c_currency: "USD",
          c_rec: "yes",
          p_date: "",
        },
      ]);
    }
  }, [AddGroupPremiumDataSuccess]);

  let handleChange = (i, e, type, name = "") => {
    let newFormValues = [...formValues];
    if (type === "select") {
      newFormValues[i][e.target.name] = e.target.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else if (type === "recived_date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      const checkNewNumber = /^[0-9\b]+$/;

      if (e.target.value === "" || checkNewNumber.test(e.target.value)) {
        newFormValues[i][e.target.name] = e.target.value;

        if (e.target.name === "p_amt") {
          setPremiumError(false);
        } else {
          setCommissionError(false);
        }
      } else {
        if (e.target.name === "p_amt") {
          setPremiumError(true);
          setCommissionError(false);
        } else if (e.target.name === "c_amt") {
          setPremiumError(false);
          setCommissionError(true);
        }
      }
    }

    setFormValues(newFormValues);
  };

  let handleExistingChange = (i, e, type, name = "") => {
    let newFormValues = [...oldFormValue];
    if (type === "select") {
      newFormValues[i][e.target.name] = e.target.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else if (type === "recived_date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      const checkNumber = /^[0-9\b]+$/;
      if (e.target.value === "" || checkNumber.test(e.target.value)) {
        newFormValues[i][e.target.name] = e.target.value;
      }
    }

    setOldFormValue(newFormValues);
  };

  const savePremiumData = async () => {
    if (
      formValues[0].p_amt === "" ||
      formValues[0].c_amt === "" ||
      formValues[0].p_date == ""
    ) {
      notify("Fields makerd with (*) are required.", 3, notificationAlert);
    } else {
      // console.log("ormValues[0].p_currency" , formValues[0].p_currency)
      var data = {
        group_id: props.group_id,
        product_id: props.product_id,
        premium_amount: formValues[0].p_amt,
        premium_currency: formValues[0].p_currency,
        premium_recived_date: formValues[0].p_recived_date,
        commission_amount: formValues[0].c_amt,
        premium_recievd: formValues[0].p_rec,
        commission_recieved: formValues[0].c_rec,
        commission_currency: formValues[0].c_currency,
        recieved_date: formValues[0].p_date,
        premium_detail_id: 0,
      };

      setShowLoader(true);
      await dispatch(AddPremiumDetailApi(data, loggedUser.token));
    }
  };

  const updatePremiumData = async (premium_detail_id) => {
    let abc = [...oldFormValue];
    abc.map(async (elem, index) => {
      if (elem.id === premium_detail_id) {
        if (
          elem.premium_amount === "" ||
          elem.commission_amount === "" ||
          elem.recieved_date == ""
        ) {
          notify("Fields makerd with (*) are required.", 3, notificationAlert);
        } else {
          var data = {
            group_id: props.group_id,
            product_id: props.product_id,
            premium_amount: elem.premium_amount,
            commission_amount: elem.commission_amount,
            premium_recived_date: elem.premium_recived_date,
            premium_recievd: elem.premium_recievd,
            premium_currency: elem.premium_currency,
            commission_recieved: elem.commission_recieved,
            commission_currency: elem.commission_currency,
            recieved_date: elem.recieved_date,
            premium_detail_id: premium_detail_id,
          };
          setShowLoader(true);

          dispatch({
            type: GET_ADD_GROUP_PREMIUMM_DETAILS_RESET,
            payload: { data: "" },
          });
          await dispatch(AddPremiumDetailApi(data, loggedUser.token));
        }
      }
    });
  };

  const deletePremiumData = (premium_detail_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(premium_detail_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this!
      </ReactBSAlert>
    );
  };
  const successDelete = async (premium_detail_id) => {
    await dispatch(
      DeleteGroupPremiumDetailApi(premium_detail_id, loggedUser.token)
    );
  };
  const hideAlert = (mode) => {
    setAlert(null);
    setShowLoader(false);
    setLoadingData(true);
    if (mode === "refresh") {
      window.location.reload();
    }
  };

  return (
    <Col md="12">
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      {alert}
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <Table className="table">
          <thead>
            <tr>
              <th width="12%">Premium Amount *</th>
              <th width="13%">Premium Received *</th>
              <th width="13%">Premium Currency *</th>
              <th width="13%">Premium Received Date</th>

              <th width="13%">Commission Amount *</th>
              <th width="13%">Commission Received *</th>
              <th width="13%">Commission Currency *</th>

              <th width="11%">Recieved On *</th>
              <th width="10%">Added By</th>
              <th width="10%">Update By</th>
              <th width="10%">Created At</th>
              <th width="10%">Update At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {oldFormValue.length > 0
              ? oldFormValue.map((elem, index) => (
                  <tr key={elem.id}>
                    <td>
                      <FormGroup className={`has-label`}>
                        <Input
                          type="text"
                          name="premium_amount"
                          value={elem.premium_amount}
                          onChange={(e) =>
                            handleExistingChange(
                              index,
                              e,
                              "input",
                              "premium_amount"
                            )
                          }
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup className={`has-label`}>
                        <select
                          name="premium_recievd"
                          className="form-control"
                          value={elem.premium_recievd}
                          onChange={(e) =>
                            handleExistingChange(
                              index,
                              e,
                              "select",
                              "premium_recievd"
                            )
                          }
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup className={`has-label`}>
                        <select
                          name="premium_currency"
                          value={elem.premium_currency}
                          className="form-control"
                          onChange={(e) =>
                            handleExistingChange(index, e, "select")
                          }
                        >
                          <option value="USD" selected="selected">
                            USD{" "}
                          </option>
                          <option value="EUR">EUR </option>
                          <option value="GBP">GBP </option>
                          <option value="DZD">DZD </option>
                          <option value="ARP">ARP </option>
                          <option value="AUD">AUD </option>
                          <option value="ATS">ATS </option>
                          <option value="BSD">BSD </option>
                          <option value="BBD">BBD </option>
                          <option value="BEF">BEF </option>
                          <option value="BMD">BMD </option>
                          <option value="BRR">BRR </option>
                          <option value="BGL">BGL </option>
                          <option value="CAD">CAD </option>
                          <option value="CLP">CLP </option>
                          <option value="CNY">CNY </option>
                          <option value="CYP">CYP </option>
                          <option value="CSK">CSK </option>
                          <option value="DKK">DKK </option>
                          <option value="NLG">NLG </option>
                          <option value="XCD">XCD </option>
                          <option value="EGP">EGP </option>
                          <option value="FJD">FJD </option>
                          <option value="FIM">FIM </option>
                          <option value="FRF">FRF </option>
                          <option value="DEM">DEM </option>
                          <option value="XAU">XAU </option>
                          <option value="GRD">GRD </option>
                          <option value="HKD">HKD </option>
                          <option value="HUF">HUF </option>
                          <option value="ISK">ISK </option>
                          <option value="INR">INR </option>
                          <option value="IDR">IDR </option>
                          <option value="IEP">IEP </option>
                          <option value="ILS">ILS </option>
                          <option value="ITL">ITL </option>
                          <option value="JMD">JMD </option>
                          <option value="JPY">JPY </option>
                          <option value="JOD">JOD </option>
                          <option value="KRW">KRW </option>
                          <option value="LBP">LBP </option>
                          <option value="LUF">LUF </option>
                          <option value="MYR">MYR </option>
                          <option value="MXP">MXP </option>
                          <option value="NLG">NLG </option>
                          <option value="NZD">NZD </option>
                          <option value="NOK">NOK </option>
                          <option value="PKR">PKR </option>
                          <option value="XPD">XPD </option>
                          <option value="PHP">PHP </option>
                          <option value="XPT">XPT </option>
                          <option value="PLZ">PLZ </option>
                          <option value="PTE">PTE </option>
                          <option value="ROL">ROL </option>
                          <option value="RUR">RUR </option>
                          <option value="SAR">SAR </option>
                          <option value="XAG">XAG </option>
                          <option value="SGD">SGD </option>
                          <option value="SKK">SKK </option>
                          <option value="ZAR">ZAR </option>
                          <option value="KRW">KRW </option>
                          <option value="ESP">ESP </option>
                          <option value="XDR">XDR </option>
                          <option value="SDD">SDD </option>
                          <option value="SEK">SEK </option>
                          <option value="CHF">CHF </option>
                          <option value="TWD">TWD </option>
                          <option value="THB">THB </option>
                          <option value="TTD">TTD </option>
                          <option value="TRL">TRL </option>
                          <option value="VEB">VEB </option>
                          <option value="ZMK">ZMK </option>
                          <option value="EUR">EUR </option>
                          <option value="XCD">XCD </option>
                          <option value="XDR">XDR </option>
                          <option value="XAG">XAG </option>
                          <option value="XAU">XAU </option>
                          <option value="XPD">XPD </option>
                          <option value="XPT">XPT </option>
                        </select>
                      </FormGroup>
                    </td>

                    <td>
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Date Picker Here",
                            name: "premium_recived_date",
                            readOnly: true,
                            value: elem.premium_recived_date,
                          }}
                          timeFormat={false}
                          onChange={(e) =>
                            handleExistingChange(
                              index,
                              e,
                              "recived_date",
                              "premium_recived_date"
                            )
                          }
                        />
                      </FormGroup>
                    </td>

                    <td>
                      <FormGroup className={`has-label`}>
                        <Input
                          type="text"
                          name="commission_amount"
                          value={elem.commission_amount}
                          onChange={(e) =>
                            handleExistingChange(
                              index,
                              e,
                              "input",
                              "commission_amount"
                            )
                          }
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup className={`has-label`}>
                        <select
                          name="commission_recieved"
                          className="form-control"
                          value={elem.commission_recieved}
                          onChange={(e) =>
                            handleExistingChange(
                              index,
                              e,
                              "select",
                              "commission_recieved"
                            )
                          }
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup className={`has-label`}>
                        <select
                          name="commission_currency"
                          value={elem.commission_currency}
                          className="form-control"
                          onChange={(e) =>
                            handleExistingChange(index, e, "select")
                          }
                        >
                          <option value="USD" selected="selected">
                            USD{" "}
                          </option>
                          <option value="EUR">EUR </option>
                          <option value="GBP">GBP </option>
                          <option value="DZD">DZD </option>
                          <option value="ARP">ARP </option>
                          <option value="AUD">AUD </option>
                          <option value="ATS">ATS </option>
                          <option value="BSD">BSD </option>
                          <option value="BBD">BBD </option>
                          <option value="BEF">BEF </option>
                          <option value="BMD">BMD </option>
                          <option value="BRR">BRR </option>
                          <option value="BGL">BGL </option>
                          <option value="CAD">CAD </option>
                          <option value="CLP">CLP </option>
                          <option value="CNY">CNY </option>
                          <option value="CYP">CYP </option>
                          <option value="CSK">CSK </option>
                          <option value="DKK">DKK </option>
                          <option value="NLG">NLG </option>
                          <option value="XCD">XCD </option>
                          <option value="EGP">EGP </option>
                          <option value="FJD">FJD </option>
                          <option value="FIM">FIM </option>
                          <option value="FRF">FRF </option>
                          <option value="DEM">DEM </option>
                          <option value="XAU">XAU </option>
                          <option value="GRD">GRD </option>
                          <option value="HKD">HKD </option>
                          <option value="HUF">HUF </option>
                          <option value="ISK">ISK </option>
                          <option value="INR">INR </option>
                          <option value="IDR">IDR </option>
                          <option value="IEP">IEP </option>
                          <option value="ILS">ILS </option>
                          <option value="ITL">ITL </option>
                          <option value="JMD">JMD </option>
                          <option value="JPY">JPY </option>
                          <option value="JOD">JOD </option>
                          <option value="KRW">KRW </option>
                          <option value="LBP">LBP </option>
                          <option value="LUF">LUF </option>
                          <option value="MYR">MYR </option>
                          <option value="MXP">MXP </option>
                          <option value="NLG">NLG </option>
                          <option value="NZD">NZD </option>
                          <option value="NOK">NOK </option>
                          <option value="PKR">PKR </option>
                          <option value="XPD">XPD </option>
                          <option value="PHP">PHP </option>
                          <option value="XPT">XPT </option>
                          <option value="PLZ">PLZ </option>
                          <option value="PTE">PTE </option>
                          <option value="ROL">ROL </option>
                          <option value="RUR">RUR </option>
                          <option valuformValuese="SAR">SAR </option>
                          <option value="XAG">XAG </option>
                          <option value="SGD">SGD </option>
                          <option value="SKK">SKK </option>
                          <option value="ZAR">ZAR </option>
                          <option value="KRW">KRW </option>
                          <option value="ESP">ESP </option>
                          <option value="XDR">XDR </option>
                          <option value="SDD">SDD </option>
                          <option value="SEK">SEK </option>
                          <option value="CHF">CHF </option>
                          <option value="TWD">TWD </option>
                          <option value="THB">THB </option>
                          <option value="TTD">TTD </option>
                          <option value="TRL">TRL </option>
                          <option value="VEB">VEB </option>
                          <option value="ZMK">ZMK </option>
                          <option value="EUR">EUR </option>
                          <option value="XCD">XCD </option>
                          <option value="XDR">XDR </option>
                          <option value="XAG">XAG </option>
                          <option value="XAU">XAU </option>
                          <option value="XPD">XPD </option>
                          <option value="XPT">XPT </option>
                        </select>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Date Picker Here",
                            name: "p_date",
                            readOnly: true,
                            value: elem.recieved_date,
                          }}
                          timeFormat={false}
                          onChange={(e) =>
                            handleExistingChange(
                              index,
                              e,
                              "date",
                              "recieved_date"
                            )
                          }
                        />
                      </FormGroup>
                    </td>
                    <td>{elem.added_user}</td>
                    <td>{elem.modified_user}</td>
                    <td>{elem.created_at.split("T")[0]} </td>
                    <td> {elem.updated_at.split("T")[0]}</td>

                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          updatePremiumData(elem.id);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => {
                          deletePremiumData(elem.id);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              : null}
            <tr>
              <td>
                <FormGroup className={`has-label`}>
                  <Input
                    type="text"
                    name="p_amt"
                    onChange={(e) => handleChange(0, e, "input")}
                  />
                </FormGroup>
                {premiumError ? (
                  <p style={{ color: "red" }}>Provide valid amount</p>
                ) : null}
              </td>
              <td>
                <FormGroup
                  className={`has-label`}
                  onChange={(e) => handleChange(0, e, "select")}
                >
                  <select name="p_rec" className="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </FormGroup>
              </td>
              <td>
                <FormGroup
                  className={`has-label`}
                  onChange={(e) => handleChange(0, e, "select")}
                >
                  <select name="p_currency" className="form-control">
                    <option value="USD" selected="selected">
                      USD{" "}
                    </option>
                    <option value="EUR">EUR </option>
                    <option value="GBP">GBP </option>
                    <option value="DZD">DZD </option>
                    <option value="ARP">ARP </option>
                    <option value="AUD">AUD </option>
                    <option value="ATS">ATS </option>
                    <option value="BSD">BSD </option>
                    <option value="BBD">BBD </option>
                    <option value="BEF">BEF </option>
                    <option value="BMD">BMD </option>
                    <option value="BRR">BRR </option>
                    <option value="BGL">BGL </option>
                    <option value="CAD">CAD </option>
                    <option value="CLP">CLP </option>
                    <option value="CNY">CNY </option>
                    <option value="CYP">CYP </option>
                    <option value="CSK">CSK </option>
                    <option value="DKK">DKK </option>
                    <option value="NLG">NLG </option>
                    <option value="XCD">XCD </option>
                    <option value="EGP">EGP </option>
                    <option value="FJD">FJD </option>
                    <option value="FIM">FIM </option>
                    <option value="FRF">FRF </option>
                    <option value="DEM">DEM </option>
                    <option value="XAU">XAU </option>
                    <option value="GRD">GRD </option>
                    <option value="HKD">HKD </option>
                    <option value="HUF">HUF </option>
                    <option value="ISK">ISK </option>
                    <option value="INR">INR </option>
                    <option value="IDR">IDR </option>
                    <option value="IEP">IEP </option>
                    <option value="ILS">ILS </option>
                    <option value="ITL">ITL </option>
                    <option value="JMD">JMD </option>
                    <option value="JPY">JPY </option>
                    <option value="JOD">JOD </option>
                    <option value="KRW">KRW </option>
                    <option value="LBP">LBP </option>
                    <option value="LUF">LUF </option>
                    <option value="MYR">MYR </option>
                    <option value="MXP">MXP </option>
                    <option value="NLG">NLG </option>
                    <option value="NZD">NZD </option>
                    <option value="NOK">NOK </option>
                    <option value="PKR">PKR </option>
                    <option value="XPD">XPD </option>
                    <option value="PHP">PHP </option>
                    <option value="XPT">XPT </option>
                    <option value="PLZ">PLZ </option>
                    <option value="PTE">PTE </option>
                    <option value="ROL">ROL </option>
                    <option value="RUR">RUR </option>
                    <option value="SAR">SAR </option>
                    <option value="XAG">XAG </option>
                    <option value="SGD">SGD </option>
                    <option value="SKK">SKK </option>
                    <option value="ZAR">ZAR </option>
                    <option value="KRW">KRW </option>
                    <option value="ESP">ESP </option>
                    <option value="XDR">XDR </option>
                    <option value="SDD">SDD </option>
                    <option value="SEK">SEK </option>
                    <option value="CHF">CHF </option>
                    <option value="TWD">TWD </option>
                    <option value="THB">THB </option>
                    <option value="TTD">TTD </option>
                    <option value="TRL">TRL </option>
                    <option value="VEB">VEB </option>
                    <option value="ZMK">ZMK </option>
                    <option value="EUR">EUR </option>
                    <option value="XCD">XCD </option>
                    <option value="XDR">XDR </option>
                    <option value="XAG">XAG </option>
                    <option value="XAU">XAU </option>
                    <option value="XPD">XPD </option>
                    <option value="XPT">XPT </option>
                  </select>
                </FormGroup>
              </td>

              <td>
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      name: "p_recived_date",
                      readOnly: true,
                      value: formValues[0].p_recived_date
                        ? formValues[0].p_recived_date
                        : moment(new Date()).format("DD-MM-YYYY"),
                    }}
                    timeFormat={false}
                    onChange={(e) =>
                      handleChange(0, e, "recived_date", "p_recived_date")
                    }
                  />
                </FormGroup>
              </td>

              <td>
                <FormGroup className={`has-label`}>
                  <Input
                    type="text"
                    name="c_amt"
                    onChange={(e) => handleChange(0, e, "input")}
                  />
                </FormGroup>
                {CommissionError ? (
                  <p style={{ color: "red" }}>Provide valid amount</p>
                ) : null}
              </td>
              <td>
                <FormGroup
                  className={`has-label`}
                  onChange={(e) => handleChange(0, e, "select")}
                >
                  <select name="c_rec" className="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </FormGroup>
              </td>
              <td>
                <FormGroup
                  className={`has-label`}
                  onChange={(e) => handleChange(0, e, "select")}
                >
                  <select name="c_currency" className="form-control">
                    <option value="USD" selected="selected">
                      USD{" "}
                    </option>
                    <option value="EUR">EUR </option>
                    <option value="GBP">GBP </option>
                    <option value="DZD">DZD </option>
                    <option value="ARP">ARP </option>
                    <option value="AUD">AUD </option>
                    <option value="ATS">ATS </option>
                    <option value="BSD">BSD </option>
                    <option value="BBD">BBD </option>
                    <option value="BEF">BEF </option>
                    <option value="BMD">BMD </option>
                    <option value="BRR">BRR </option>
                    <option value="BGL">BGL </option>
                    <option value="CAD">CAD </option>
                    <option value="CLP">CLP </option>
                    <option value="CNY">CNY </option>
                    <option value="CYP">CYP </option>
                    <option value="CSK">CSK </option>
                    <option value="DKK">DKK </option>
                    <option value="NLG">NLG </option>
                    <option value="XCD">XCD </option>
                    <option value="EGP">EGP </option>
                    <option value="FJD">FJD </option>
                    <option value="FIM">FIM </option>
                    <option value="FRF">FRF </option>
                    <option value="DEM">DEM </option>
                    <option value="XAU">XAU </option>
                    <option value="GRD">GRD </option>
                    <option value="HKD">HKD </option>
                    <option value="HUF">HUF </option>
                    <option value="ISK">ISK </option>
                    <option value="INR">INR </option>
                    <option value="IDR">IDR </option>
                    <option value="IEP">IEP </option>
                    <option value="ILS">ILS </option>
                    <option value="ITL">ITL </option>
                    <option value="JMD">JMD </option>
                    <option value="JPY">JPY </option>
                    <option value="JOD">JOD </option>
                    <option value="KRW">KRW </option>
                    <option value="LBP">LBP </option>
                    <option value="LUF">LUF </option>
                    <option value="MYR">MYR </option>
                    <option value="MXP">MXP </option>
                    <option value="NLG">NLG </option>
                    <option value="NZD">NZD </option>
                    <option value="NOK">NOK </option>
                    <option value="PKR">PKR </option>
                    <option value="XPD">XPD </option>
                    <option value="PHP">PHP </option>
                    <option value="XPT">XPT </option>
                    <option value="PLZ">PLZ </option>
                    <option value="PTE">PTE </option>
                    <option value="ROL">ROL </option>
                    <option value="RUR">RUR </option>
                    <option value="SAR">SAR </option>
                    <option value="XAG">XAG </option>
                    <option value="SGD">SGD </option>
                    <option value="SKK">SKK </option>
                    <option value="ZAR">ZAR </option>
                    <option value="KRW">KRW </option>
                    <option value="ESP">ESP </option>
                    <option value="XDR">XDR </option>
                    <option value="SDD">SDD </option>
                    <option value="SEK">SEK </option>
                    <option value="CHF">CHF </option>
                    <option value="TWD">TWD </option>
                    <option value="THB">THB </option>
                    <option value="TTD">TTD </option>
                    <option value="TRL">TRL </option>
                    <option value="VEB">VEB </option>
                    <option value="ZMK">ZMK </option>
                    <option value="EUR">EUR </option>
                    <option value="XCD">XCD </option>
                    <option value="XDR">XDR </option>
                    <option value="XAG">XAG </option>
                    <option value="XAU">XAU </option>
                    <option value="XPD">XPD </option>
                    <option value="XPT">XPT </option>
                  </select>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      name: "p_date",
                      readOnly: true,
                    }}
                    dateFormat="DD-MM-YYYY"
                    timeFormat={false}
                    onChange={(e) => handleChange(0, e, "date", "p_date")}
                  />
                </FormGroup>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    savePremiumData();
                  }}
                >
                  Add
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </Col>
  );
};

export default GroupPremiumDetails;
