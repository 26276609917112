/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import logo from "assets/img/logo/i-brokers.png";

var ps;

function Sidebar(props) {
  // debugger
  let history = useHistory();
  if (localStorage.getItem("current_users") === null) {
    history.push("/login");
    return false;
  } else {
    const loggedUser = JSON.parse(localStorage.getItem("current_users"));
    const checkUser = process.env.REACT_APP_API_URL + "/get-current-user";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    fetch(checkUser, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
          localStorage.removeItem("current_users");
          history.push("/login");
          return false;
        }
      });
  }
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();

  const textStyle = {
    textTransform: 'capitalize',
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      let allowedRoles = typeof prop.role != "undefined" ? prop.role : [];
      let allowedEmails = typeof prop.email != "undefined" ? prop.email : [];
      if(loggedUser?.data?.email == "darren.wise@i-brokers.comfggggggg"){
        if (prop.redirect || allowedRoles.indexOf(loggedUser.data.id) === -1) {
          return null;
        }
      }else{
        // if (prop.redirect || allowedRoles.indexOf(loggedUser.data.role) === -1) {
        //   return null;
        // }
        if (prop.redirect) {
          return null;
        }
        else if(allowedRoles.indexOf(loggedUser.data.role) === -1 && allowedEmails.indexOf(loggedUser.data.email) === -1){
          return null;
        }
        
      }
 
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        if (!prop.hide)
          return (
            <li
              className={getCollapseInitialState(prop.views) ? "active" : ""}
              key={key}
            >
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={collapseStates[prop.state]}
                onClick={(e) => {
                  e.preventDefault();
                  setCollapseStates(st);
                }}
              >
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>
                      {prop.name}
                      <b className="caret" />
                    </p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
              </a>
              <Collapse isOpen={collapseStates[prop.state]}>
                <ul
                  className="nav"
                  onClick={() => {
                    setCollapseStates({
                      consultCollapse: false,
                      insuCollapse: false,
                      proCollapse: false,
                      GroupOppCollapse: false,
                      OppCollapse: false,
                    });
                  }}
                >
                  {createLinks(prop.views)}
                </ul>
              </Collapse>
            </li>
          );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal ml-5">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates({
      consultCollapse: false,
      insuCollapse: false,
      proCollapse: false,
      GroupOppCollapse: false,
      OppCollapse: false,
    });
    // setCollapseStates(getCollapseStates(props.routes));
  }, []);

  const logoutUser = process.env.REACT_APP_API_URL + "/logout";
  const logout = () => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    fetch(logoutUser, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
        } else {
          localStorage.removeItem("current_users");
          history.push("/login");
        }
      });
  };
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href="#" className="simple-text logo-normal">
          <img src={logo} alt="i-broker" />
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <div className="user">
          <div className="info">
            <a
              data-toggle="collapse sdsdsdsdsds"
              aria-expanded={openAvatar}
              onClick={() => setOpenAvatar(!openAvatar)}
            >
              <span style={{ cursor: "pointer" }}>
                Welcome {loggedUser.data.name}
                <b className="caret" />
              </span>
            </a>
            <Collapse isOpen={openAvatar}>
              <ul className="nav">
                <li>
                  <NavLink to="/admin/user-profile" activeClassName="">
                    {/* <span className="sidebar-mini-icon">EP</span> */}
                    <span className="sidebar-mini-icon">&nbsp;</span>

                    <span className="sidebar-normal" style={textStyle}>Edit Profile</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={logout} to="" activeClassName="">
                    <span className="sidebar-mini-icon">&nbsp;</span>
                    <span className="sidebar-normal" style={textStyle} onClick={logout}>
                      Logout
                    </span>
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </div>
        </div>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
}

export default Sidebar;
