/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import { notify, download } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
import TravelAnnualPrices from "components/products/travel-annual-prices";
import TravelDailyPrices from "components/products/travel-daily-prices";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Table,
  Input,
  Row,
  Col,
} from "reactstrap";




function EditTravel() {
  const notificationAlert = React.useRef();
  const [showLoader, setShowLoader] = React.useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [documentObj, setdocumentObj] = useState([]);
  const [InsurancePeriodType, setInsurancePeriodType] = useState([]);
  let insurrer_array = {};
  let delete_document = [];
  const params = useParams();
  const getProductUrl = process.env.REACT_APP_API_URL + "/get-product/travel/" + params.id;
  let history = useHistory();
  var headers = new Headers();
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  headers.append("Authorization", "Bearer " + loggedUser.token);

  const saveTravelDraft = () => {
    let formData = new FormData();
    formData.append('policy_type', policyType);
    formData.append('deductible_excess', deductible);
    formData.append('medical_expenses', medicalExpenses);
    formData.append('flight_cancelation', flightCancelation);
    formData.append('personal_accident', personalAccident);
    formData.append('baggage', baggage);
    formData.append('adventure_sport', adventureSport);
    formData.append('money', money);
    formData.append('scuba_diving', scubaDiving);
    formData.append('skiing', skiing);
    formData.append('evacuation_repatriation', evacuationRepatriation);
    formData.append('terrorism', terrorism);
    formData.append('insurer_id', insurer.value);
    formData.append('plan_name', PlanName);
    formData.append('flight_delay', flightDelay);
    formData.append('loss_of_passport', lossPassport);
    formData.append('personal_effects', personalEffects);
    formData.append('customer_rating', customerRating);
    formData.append('area_of_cover', areaCover);
    formData.append('product_type', "travel");
    formData.append('insurance_period_type', periodType);
    formData.append('medical_expenses_radio', MedicalTypeRadio);
    formData.append('medical_expenses_text', medicalTypeText);
    formData.append('flight_cancelation_radio', FlightCancelationRadio);
    formData.append('flight_cancelation_text', flightCancelationText);
    formData.append('baggage_radio', BaggageRadio);
    formData.append('baggage_text', baggageText);
    formData.append('evacuation_repatriation_radio', EvacuationRadio);
    formData.append('evacuation_repatriation_text', evacuationText);
    formData.append('excle_doc', fileState === null ? "" : fileState);
    formData.append('status', "0");
    formData.append('product_id', params.id);
    for (var i = 0; i < formValues.length; i++) {
      formData.append('document_type[' + i + ']', formValues[i].doctype);
      formData.append('document[' + i + ']', formValues[i].docfile);
      formData.append('description[' + i + ']', formValues[i].description);
    }
    sendData(formData, 'save-travel-draft');
  };
  const saveTravel = () => {
    setDocumentState("has-success");
    /*for (var i = 0; i < formValues.length; i++) {
      if (formValues[i].description == "" || formValues[i].docfile == "" || formValues[i].doctype == "") {
        setDocumentState("has-danger");
      }
    }*/
    policyType === "" ? setPolicyTypeState("has-danger") : setPolicyTypeState("has-success");
    deductible === "" ? setDeductibleState("has-danger") : setDeductibleState("has-success");
    medicalExpenses === "" ? setMedicalExpensesState("has-danger") : setMedicalExpensesState("has-success");
    flightCancelation === "" ? setFlightCancelationState("has-danger") : setFlightCancelationState("has-success");
    personalAccident === "" ? setPersonalAccidentState("has-danger") : setPersonalAccidentState("has-success");
    baggage === "" ? setBaggageState("has-danger") : setBaggageState("has-success");
    flightDelay === "" ? setFlightDelayState("has-danger") : setFlightDelayState("has-success");
    lossPassport === "" ? setLossPassportState("has-danger") : setLossPassportState("has-success");
    personalEffects === "" ? setPersonalEffectsState("has-danger") : setPersonalEffectsState("has-success");
    adventureSport === "" ? setAdventureSportState("has-danger") : setAdventureSportState("has-success");
    money === "" ? setMoneyState("has-danger") : setMoneyState("has-success");
    scubaDiving === "" ? setScubaDivingState("has-danger") : setScubaDivingState("has-success");
    skiing === "" ? setSkiingState("has-danger") : setSkiingState("has-success");
    evacuationRepatriation === "" ? setEvacuationRepatriationState("has-danger") : setEvacuationRepatriationState("has-success");
    terrorism === "" ? setTerrorismState("has-danger") : setTerrorismState("has-success");
    customerRating === "" ? setCustomerRatingState("has-danger") : setCustomerRatingState("has-success");
    areaCover === "" ? setAreaCoverState("has-danger") : setAreaCoverState("has-success");
    insurer.value === "" ? setinsurerState("has-danger") : setinsurerState("has-success");
    PlanName === "" ? setPlanNameState("has-danger") : setPlanNameState("has-success");


    if (PolicyTypeState === "has-success" && DeductibleState === "has-success" && MedicalExpensesState === "has-success" &&
      FlightCancelationState === "has-success" && PersonalAccidentState === "has-success" && BaggageState === "has-success" &&
      FlightDelayState === "has-success" && LossPassportState === "has-success" && PersonalEffectsState === "has-success" &&
      AdventureSportState === "has-success" && MoneyState === "has-success" && ScubaDivingState === "has-success" &&
      SkiingState === "has-success" && EvacuationRepatriationState === "has-success" && TerrorismState === "has-success" &&
      CustomerRatingState == "has-success" && AreaCoverState === "has-success" && insurerState === "has-success" &&
      PlanNameState === "has-success" && DocumentState === "has-success") {
      setShowLoader(true);
      let formData = new FormData();
      formData.append('policy_type', policyType);
      formData.append('deductible_excess', deductible);
      formData.append('medical_expenses', medicalExpenses);
      formData.append('flight_cancelation', flightCancelation);
      formData.append('personal_accident', personalAccident);
      formData.append('baggage', baggage);
      formData.append('adventure_sport', adventureSport);
      formData.append('money', money);
      formData.append('scuba_diving', scubaDiving);
      formData.append('skiing', skiing);
      formData.append('evacuation_repatriation', evacuationRepatriation);
      formData.append('terrorism', terrorism);
      formData.append('insurer_id', insurer.value);
      formData.append('plan_name', PlanName);
      formData.append('flight_delay', flightDelay);
      formData.append('loss_of_passport', lossPassport);
      formData.append('personal_effects', personalEffects);
      formData.append('customer_rating', customerRating);
      formData.append('area_of_cover', areaCover);
      formData.append('product_type', "travel");
      formData.append('insurance_period_type', periodType);
      formData.append('medical_expenses_radio', MedicalTypeRadio);
      formData.append('medical_expenses_text', medicalTypeText);
      formData.append('flight_cancelation_radio', FlightCancelationRadio);
      formData.append('flight_cancelation_text', flightCancelationText);
      formData.append('baggage_radio', BaggageRadio);
      formData.append('baggage_text', baggageText);
      formData.append('evacuation_repatriation_radio', EvacuationRadio);
      formData.append('evacuation_repatriation_text', evacuationText);
      formData.append('excle_doc', fileState);
      formData.append('status', "1");
      formData.append('product_id', params.id);
      formData.append('delete_documents', JSON.stringify(delete_document));
      for (var i = 0; i < formValues.length; i++) {
        formData.append('document_type[' + i + ']', formValues[i].doctype);
        formData.append('document[' + i + ']', formValues[i].docfile);
        formData.append('description[' + i + ']', formValues[i].description);
      }
      sendData(formData, 'update-travel');
    }
  };
  const sendData = (data, url) => {
    setShowLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/" + url, {
      method: "POST",
      headers: headers,
      body: data
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
          notify(data.message, 3, notificationAlert);
        } else {
          console.log(data);

          notify(data.message, 2, notificationAlert);
          setTimeout(function () { history.push('/admin/all-products') }, 3000);

        }
        setShowLoader(false);
      });
  }
  useEffect(() => {
    async function getFormData() {

      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);

      fetch(getProductUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (data.success) {
            insurrer_array = data.insurer_list_key;
            setinsurerOptions(data.insurer_list);
            setdoctypeOptions(data.document_list);
            setPolicyType(data.product.policy_type);
            setPlanName(data.product.plan_name);
            setDeductible(data.product.deductible_excess);
            setMedicalExpenses(data.product.medical_expenses);
            setFlightCancelation(data.product.flight_cancelation);
            setPersonalAccident(data.product.personal_accident);
            setBaggage(data.product.baggage);
            setFlightDelay(data.product.flight_delay);
            setLossPassport(data.product.loss_of_passport);
            setPersonalEffects(data.product.personal_effects);
            setAdventureSport(data.product.adventure_sport);
            setMoney(data.product.money);
            setScubaDiving(data.product.scuba_diving);
            setSkiing(data.product.skiing);
            setEvacuationRepatriation(data.product.evacuation_repatriation);
            setTerrorism(data.product.terrorism);
            setCustomerRating(data.product.customer_rating);
            setAreaCover(data.product.area_of_cover);
            setPeriodType(data.product.insurance_period_type);
            setMedicalTypeRadio(data.product.medical_expenses_radio);
            setMedicalTypeText(data.product.medical_expenses_text);
            setFlightCancelationRadio(data.product.flight_cancelation_radio);
            setFlightCancelationText(data.product.flight_cancelation_text);
            setBaggageRadio(data.product.baggage_radio);
            setBaggageText(data.product.baggage_text);
            setEvacuationRadio(data.product.evacuation_repatriation_radio);
            setEvacuationText(data.product.evacuation_repatriation_text);
            setinsurer(data.product.insurer_id !== 0 ? insurrer_array[data.product.insurer_id] : { value: "", label: "" });
            setdoctypeOptions(data.document_list);
            setdocumentObj(data.document);
            setpriceDoc(data.product.excle_doc);
            setLoadingData(false);
            setInsurancePeriodType(data.product.insurance_period_type);
          } else {

          }
        });

    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getFormData();
    }
  }, []);
  const [DocumentState, setDocumentState] = useState("has-success");
  const [PlanNameState, setPlanNameState] = useState("");
  const [PlanName, setPlanName] = useState("");
  const [insurer, setinsurer] = useState("");
  const [insurerOptions, setinsurerOptions] = useState("");
  const [doctypeOptions, setdoctypeOptions] = useState([]);
  const [policyType, setPolicyType] = useState("");
  const [PolicyTypeState, setPolicyTypeState] = useState("");
  const [deductible, setDeductible] = useState("");
  const [DeductibleState, setDeductibleState] = useState("");
  const [medicalExpenses, setMedicalExpenses] = useState("");
  const [MedicalExpensesState, setMedicalExpensesState] = useState("");
  const [flightCancelation, setFlightCancelation] = useState("");
  const [FlightCancelationState, setFlightCancelationState] = useState("");
  const [personalAccident, setPersonalAccident] = useState("");
  const [PersonalAccidentState, setPersonalAccidentState] = useState("");
  const [baggage, setBaggage] = useState("");
  const [BaggageState, setBaggageState] = useState("");
  const [flightDelay, setFlightDelay] = useState("");
  const [FlightDelayState, setFlightDelayState] = useState("");
  const [lossPassport, setLossPassport] = useState("");
  const [LossPassportState, setLossPassportState] = useState("");
  const [personalEffects, setPersonalEffects] = useState("");
  const [PersonalEffectsState, setPersonalEffectsState] = useState("");
  const [adventureSport, setAdventureSport] = useState("");
  const [AdventureSportState, setAdventureSportState] = useState("");
  const [money, setMoney] = useState("");
  const [MoneyState, setMoneyState] = useState("");
  const [scubaDiving, setScubaDiving] = useState("");
  const [ScubaDivingState, setScubaDivingState] = useState("");
  const [skiing, setSkiing] = useState("");
  const [SkiingState, setSkiingState] = useState("");
  const [evacuationRepatriation, setEvacuationRepatriation] = useState("");
  const [EvacuationRepatriationState, setEvacuationRepatriationState] = useState("");
  const [terrorism, setTerrorism] = useState("");
  const [TerrorismState, setTerrorismState] = useState("");
  const [customerRating, setCustomerRating] = useState("");
  const [CustomerRatingState, setCustomerRatingState] = useState("");
  const [areaCover, setAreaCover] = useState("");
  const [AreaCoverState, setAreaCoverState] = useState("");
  const [periodType, setPeriodType] = useState("");
  const [insurerState, setinsurerState] = useState("");
  const [evacuationText, setEvacuationText] = useState("");
  const [etReadonly, setetReadonly] = useState(true);
  const [baggageText, setBaggageText] = useState("");
  const [bReadonly, setbReadonly] = useState(true);
  const [flightCancelationText, setFlightCancelationText] = useState("");
  const [fcReadonly, setfcReadonly] = useState(true);
  const [medicalTypeText, setMedicalTypeText] = useState("");
  const [mReadonly, setmReadonly] = useState(true);
  const [MedicalTypeRadio, setMedicalTypeRadio] = useState();
  const [BaggageRadio, setBaggageRadio] = useState();
  const [FlightCancelationRadio, setFlightCancelationRadio] = useState();
  const [EvacuationRadio, setEvacuationRadio] = useState();
  const [fileState, setFileState] = React.useState(null);
  const [priceDoc, setpriceDoc] = useState("");
  const removePrice = () => {
    setpriceDoc("");
  }
  const verifyLength = (value, fieldState) => {
    if (value.length >= 1) {
      fieldState("has-success");
    } else {
      fieldState("has-danger");
    }

  };
  const getReadOnlyStatus = (field_type, status) => {
    console.log(field_type, status);
    if (status) {
      if (field_type == "EvacuationText") {
        setetReadonly(true);
      }
      if (field_type == "BaggageText") {
        setbReadonly(true);
      }
      if (field_type == "FlightCancelationText") {
        setfcReadonly(true);
      }
      if (field_type == "MedicalTypeText") {
        setmReadonly(true);
      }

    } else {
      if (field_type == "EvacuationText") {
        setetReadonly(false);
      }
      if (field_type == "BaggageText") {
        setbReadonly(false);
      }
      if (field_type == "FlightCancelationText") {
        setfcReadonly(false);
      }
      if (field_type == "MedicalTypeText") {
        setmReadonly(false);
      }
    }
  };

  const [formValues, setFormValues] = useState([])

  let handleChange = (i, e, type) => {
    let newFormValues = [...formValues];
    if (type === "file") {
      newFormValues[i][e.target.name] = e.target.files[0];
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }

    setFormValues(newFormValues);
  }

  let addFormFields = () => {
    setDocumentState("has-danger");
    setFormValues([...formValues, { doctype: "", description: "", docfile: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    if (newFormValues.length === 0) {
      setDocumentState("has-success");
    }
  }

  let handleSubmit = (event) => {
    //event.preventDefault();


    console.log(formValues);
  }
  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setFileState(file);
  };
  var Apiheaders = new Headers();
  Apiheaders.append("Content-Type", "application/json");
  Apiheaders.append("Authorization", "Bearer " + loggedUser.token);


  return (
    <>
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" lg="11" md="11" sm="11">
            <Card className="card-stats">
              <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">Add New Travel </CardTitle>
                  </Col>
                  <Col md="2">
                    <Link to="/admin/all-products" className="btn btn-primary float-right">Back to List</Link>
                  </Col>
                </Row>


              </CardHeader>
              {loadingData ? (
                <p>Loading Please wait...</p>
              ) :
                <CardBody>
                  <h3>Section 1: Benefit Details</h3>
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${insurerState}`}>
                        <label>Insurer *</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="medicalType"
                          onChange={(e) => {
                            if (e.value === "") {
                              setinsurerState("has-danger");
                            } else {
                              setinsurerState("has-success");
                            }
                            setinsurer(e);
                          }}
                          options={insurerOptions}
                          defaultValue={insurer}
                          placeholder="Select Insurer"
                        />

                        {insurerState === "has-danger" ? (
                          <label className="error">This field is required.</label>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${PolicyTypeState}`}>
                        <label>Policy Type *</label>
                        <Input type="text" value={policyType || ""} onChange={(e) => { verifyLength(e.target.value, setPolicyTypeState); setPolicyType(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${PlanNameState}`}>
                        <label>Plan Name *</label>
                        <Input type="text" value={PlanName || ""} onChange={(e) => { verifyLength(e.target.value, setPlanNameState); setPlanName(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${DeductibleState}`}>
                        <label>Deductible/ Excess *</label>
                        <Input type="text" value={deductible || ""} onChange={(e) => { verifyLength(e.target.value, setDeductibleState); setDeductible(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${MedicalExpensesState}`}>
                        <label>Medical Expenses *</label>
                        <Input type="text" value={medicalExpenses || ""} onChange={(e) => { verifyLength(e.target.value, setMedicalExpensesState); setMedicalExpenses(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${FlightCancelationState}`}>
                        <label>Flight Cancelation *</label>
                        <Input type="text" value={flightCancelation || ""} onChange={(e) => { verifyLength(e.target.value, setFlightCancelationState); setFlightCancelation(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${PersonalAccidentState}`}>
                        <label>Personal Accident *</label>
                        <Input value={personalAccident || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setPersonalAccidentState); setPersonalAccident(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${BaggageState}`}>
                        <label>Baggage *</label>
                        <Input value={baggage || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setBaggageState); setBaggage(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${FlightDelayState}`}>
                        <label>Flight Delay *</label>
                        <Input value={flightDelay || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setFlightDelayState); setFlightDelay(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${LossPassportState}`}>
                        <label>Loss of Passport *</label>
                        <Input value={lossPassport || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setLossPassportState); setLossPassport(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${PersonalEffectsState}`}>
                        <label>Personal Effects *</label>
                        <Input value={personalEffects || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setPersonalEffectsState); setPersonalEffects(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${AdventureSportState}`}>
                        <label>Adventure Sport *</label>
                        <Input value={adventureSport || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setAdventureSportState); setAdventureSport(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${MoneyState}`}>
                        <label>Money *</label>
                        <Input value={money || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setMoneyState); setMoney(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${ScubaDivingState}`}>
                        <label>Scuba Diving *</label>
                        <Input value={scubaDiving || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setScubaDivingState); setScubaDiving(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${SkiingState}`}>
                        <label>Skiing *</label>
                        <Input value={skiing || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setSkiingState); setSkiing(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${EvacuationRepatriationState}`}>
                        <label>Evacuation and Repatriation *</label>
                        <Input value={evacuationRepatriation || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setEvacuationRepatriationState); setEvacuationRepatriation(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${TerrorismState}`}>
                        <label>Terrorism *</label>
                        <Input value={terrorism || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setTerrorismState); setTerrorism(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${CustomerRatingState}`}>
                        <label>Customer Rating *</label>
                        <Input value={customerRating || ""} type="number" onChange={(e) => { verifyLength(e.target.value, setCustomerRatingState); setCustomerRating(e.target.value); }} />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup className={`has-label ${AreaCoverState}`}>
                        <label>Area of Cover *</label>
                        <Input value={areaCover || ""} type="text" onChange={(e) => { verifyLength(e.target.value, setAreaCoverState); setAreaCover(e.target.value); }} />
                      </FormGroup>
                    </Col>

                    <Col className="ml-auto mr-auto" md="4">
                      <FormGroup>
                        <label>Insurance Period Type *</label>
                        <br />
                        <div className="form-check-radio form-check-inline">
                          <Label check>
                            <Input defaultChecked={periodType === "Annual" ? true : false} defaultValue="Annual" id="exampleRadios11" name="period_type" type="radio" onChange={(e) => {
                              setPeriodType(e.target.value);
                            }}
                            />
                            Anual <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio form-check-inline">
                          <Label check>
                            <Input
                              defaultChecked={periodType === "Single Trip" ? true : false} defaultValue="Single Trip" id="exampleRadios12" name="period_type" type="radio"
                              onChange={(e) => {
                                setPeriodType(e.target.value);
                              }}
                            />
                            Single Trip <span className="form-check-sign" />
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="4"></Col>


                  </Row>
                  <h3>Section 2: Benefits</h3>
                  <Row>
                    <Col className="ml-auto mr-auto" md="2">
                      Medical Expenses
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={MedicalTypeRadio === "cross" ? true : false} defaultValue="cross" id="exampleRadios12" name="medical_expenses_radio" type="radio"
                            onChange={(e) => {
                              setMedicalTypeRadio(e.target.value);
                              getReadOnlyStatus("MedicalTypeText", true);
                            }}
                          />
                          No(show cross icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={MedicalTypeRadio === "tick" ? true : false}
                            defaultValue="tick" id="exampleRadios12" name="medical_expenses_radio" type="radio"
                            onChange={(e) => {
                              setMedicalTypeRadio(e.target.value);
                              getReadOnlyStatus("MedicalTypeText", true);
                            }}
                          />
                          Yes(show tick icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={MedicalTypeRadio === "text" ? true : false}
                            defaultValue="text" id="exampleRadios12" name="medical_expenses_radio" type="radio"
                            onChange={(e) => {
                              setMedicalTypeRadio(e.target.value);
                              getReadOnlyStatus("MedicalTypeText", false);
                            }}
                          />
                          Yes(show text) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <Input type="text" value={MedicalTypeRadio === "text" ? medicalTypeText : ""} readOnly={MedicalTypeRadio === "text" ? false : mReadonly} onChange={(e) => { setMedicalTypeText(e.target.value); }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-auto mr-auto" md="2">
                      Flight Cancelation
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={FlightCancelationRadio === "cross" ? true : false} defaultValue="cross" id="exampleRadios12" name="flight_cancelation_radio" type="radio"
                            onChange={(e) => {
                              setFlightCancelationRadio(e.target.value);
                              getReadOnlyStatus("FlightCancelationText", true);
                            }}
                          />
                          No(show cross icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={FlightCancelationRadio === "tick" ? true : false} defaultValue="tick" id="exampleRadios12" name="flight_cancelation_radio" type="radio"
                            onChange={(e) => {
                              setFlightCancelationRadio(e.target.value);
                              getReadOnlyStatus("FlightCancelationText", true);
                            }}
                          />
                          Yes(show tick icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={FlightCancelationRadio === "text" ? true : false} defaultValue="text" id="exampleRadios12" name="flight_cancelation_radio" type="radio"
                            onChange={(e) => {
                              setFlightCancelationRadio(e.target.value);
                              getReadOnlyStatus("FlightCancelationText", false);
                            }}
                          />
                          Yes(show text) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <Input type="text" value={FlightCancelationRadio === "text" ? flightCancelationText : ""} readOnly={FlightCancelationRadio === "text" ? false : fcReadonly} onChange={(e) => { setFlightCancelationText(e.target.value); }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-auto mr-auto" md="2">
                      Baggage
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={BaggageRadio === "cross" ? true : false} defaultValue="cross" id="exampleRadios12" name="baggage_radio" type="radio"
                            onChange={(e) => {
                              setBaggageRadio(e.target.value);
                              getReadOnlyStatus("BaggageText", true);
                            }}
                          />
                          No(show cross icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={BaggageRadio === "tick" ? true : false} defaultValue="tick" id="exampleRadios12" name="baggage_radio" type="radio"
                            onChange={(e) => {
                              setBaggageRadio(e.target.value);
                              getReadOnlyStatus("BaggageText", true);
                            }}
                          />
                          Yes(show tick icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={BaggageRadio === "text" ? true : false} defaultValue="text" id="exampleRadios12" name="baggage_radio" type="radio"
                            onChange={(e) => {
                              setBaggageRadio(e.target.value);
                              getReadOnlyStatus("BaggageText", false);
                            }}
                          />
                          Yes(show text) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <Input type="text" value={BaggageRadio === "text" ? baggageText : ""} readOnly={BaggageRadio === "text" ? false : bReadonly} onChange={(e) => { setBaggageText(e.target.value); }} />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="ml-auto mr-auto" md="2">
                      Evacuation and Repatriation
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={EvacuationRadio === "cross" ? true : false} defaultValue="cross" id="exampleRadios12" name="evacuation_repatriation" type="radio"
                            onChange={(e) => {
                              setEvacuationRadio(e.target.value);
                              getReadOnlyStatus("EvacuationText", true);
                            }}
                          />
                          No(show cross icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={EvacuationRadio === "tick" ? true : false} defaultValue="tick" id="exampleRadios12" name="evacuation_repatriation" type="radio"
                            onChange={(e) => {
                              setEvacuationRadio(e.target.value);
                              getReadOnlyStatus("EvacuationText", true);
                            }}
                          />
                          Yes(show tick icon) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <div className="form-check-radio inline">
                        <Label check>
                          <Input defaultChecked={EvacuationRadio === "text" ? true : false} defaultValue="text" id="exampleRadios12" name="evacuation_repatriation" type="radio"
                            onChange={(e) => {
                              setEvacuationRadio(e.target.value);
                              getReadOnlyStatus("EvacuationText", false);
                            }}
                          />
                          Yes(show text) <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" md="2">
                      <Input type="text" value={EvacuationRadio === "text" ? evacuationText : ""} readOnly={EvacuationRadio === "text" ? false : etReadonly} onChange={(e) => { setEvacuationText(e.target.value); }} />
                    </Col>
                  </Row>
                  <Row className="bg-light p-3">
                    <Col md="12">
                      <h3>Section 3: Documents</h3>
                      <Row className="mt-5">
                        <Col className="" md="9">
                          <h5>You can upload more document from here</h5>

                        </Col>
                        <Col className="" md="3">
                          <Button color="primary" onClick={() => addFormFields()}>Add More Document</Button>
                        </Col>
                      </Row>
                      {formValues.map((element, index) => (
                        <Row key={index}>
                          <Col className="ml-auto mr-auto" md="3">
                            <FormGroup>
                              <label>Document Type </label>
                              <select className="form-control" onChange={e => handleChange(index, e, "input")} name="doctype">
                                <option>Select Document Type</option>
                                {doctypeOptions.map((ele, i) => (
                                  <option key={"doctype" + ele.value} value={ele.value}>{ele.label}</option>
                                ))}

                              </select>
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="3">
                            <FormGroup>
                              <label>Document Description </label>
                              <Input name="description" type="text" onChange={e => handleChange(index, e, "input")} />
                            </FormGroup>

                          </Col>
                          <Col className="ml-auto mr-auto" md="3">
                            <label>Upload Document </label>
                            <input type="file" className="form-control" name="docfile" onChange={e => handleChange(index, e, "file")} />
                          </Col>
                          <Col className="ml-auto mr-auto" md="3">
                            <label> </label>


                            <Button color="danger" className="button remove" onClick={() => removeFormFields(index)}>Remove</Button>


                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col md="12">{DocumentState === "has-danger" ? (
                          <label className="error text-danger">
                            All Fields are required.
                          </label>
                        ) : null}</Col>
                      </Row>
                      {
                        documentObj.length > 0 ?
                          <Row>
                            <Col>
                              <Table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Document Type</th>
                                    <th>Description</th>
                                    <th>Download</th>
                                    <th>Select to Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {documentObj.map((element, index) => (
                                    <tr key={index}>
                                      <td>{documentObj[index].type}</td>
                                      <td>{documentObj[index].description}</td>
                                      <td>
                                        {
                                          process.env.REACT_APP_ENVIROMENT === 'development'
                                            ?
                                            <Link target="_blank" to={"/admin/view-document/?renderer=url&path=" + documentObj[index].document}><i className="nc-icon nc-cloud-download-93"></i></Link>
                                            :
                                            <a className="h5 m-2" onClick={() => download(process.env.REACT_APP_API_URL + '/download-product-doc/' + documentObj[index].id, Apiheaders, documentObj[index].document)}
                                            ><i className="nc-icon nc-cloud-download-93"></i>
                                            </a>
                                        }
                                      </td>
                                      <td>
                                        <FormGroup check>
                                          <Label check>
                                            <Input type="checkbox" value={documentObj[index].id}
                                              onChange={(e) => {
                                                const checked = e.target.checked;
                                                if (checked) {
                                                  delete_document.push(e.target.value);
                                                } else {
                                                  var index = delete_document.indexOf(e.target.value);
                                                  if (index > -1) {
                                                    delete_document.splice(index, 1);
                                                  }
                                                }
                                                console.log(delete_document);
                                              }} />
                                            <span className="form-check-sign" />
                                            Delete document
                                          </Label>
                                        </FormGroup>

                                      </td>
                                    </tr>
                                  ))
                                  }

                                </tbody>

                              </Table>
                            </Col>
                          </Row>
                          : ""
                      }
                      <Row>
                        <Col className="" md="12">

                          <h5>Please select an excel document with prices*</h5>
                          {priceDoc !== null && priceDoc
                            ?
                            <FormGroup>
                              {
                                process.env.REACT_APP_ENVIROMENT === 'development'
                                  ?
                                  <Link className="btn btn-primary" target="_blank" to={"/admin/view-document/?renderer=office&path=" + priceDoc}>Download</Link>
                                  :
                                  <Button color="info" onClick={() => download(process.env.REACT_APP_API_URL + '/download-excel/' + params.id, Apiheaders, priceDoc)}>Donwload</Button>
                              }

                            </FormGroup>
                            :
                            null
                          }
                          <FormGroup>
                            <label>Upload New file.</label>
                            <input type="file" onChange={e => handleImageChange(e)} ref={fileInput} />
                            <p className="small text-muted">This will overwrite the existing pricing.</p>
                          </FormGroup>
                          <p >If it for annual insurance, this columns are required in excel file:</p>
                          <ul>
                            <li><p>Annual Premium Male</p></li>
                            <li><p>Annual Premium Female</p></li>
                            <li><p>Age</p></li>
                            <li><p>Destination</p></li>
                          </ul>
                          <p>If it for annual insurance, this columns are required in excel file:</p>
                          <ul>
                            <li><p>Daily Premium Male</p></li>
                            <li><p>Daily Premium Female</p></li>
                            <li><p>Base Premium</p></li>
                            <li><p>Age</p></li>
                            <li><p>Destination</p></li>
                          </ul>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-auto mr-auto text-center m-2" md="12">
                      <Button color="primary" size="lg" onClick={() => saveTravel()}>
                        Save & Publish
                      </Button>
                      <Button color="info" size="lg" onClick={() => saveTravelDraft()}>
                        Save as Draft
                      </Button>
                    </Col>
                  </Row>
                  {
                    InsurancePeriodType === "Annual"
                      ?
                      <TravelAnnualPrices product_id={params.id} policy_type={InsurancePeriodType} />
                      :
                      InsurancePeriodType === "Single Trip"
                        ?
                        <TravelDailyPrices product_id={params.id} policy_type={InsurancePeriodType} />
                        :
                        null
                  }

                </CardBody>
              }

            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditTravel;
