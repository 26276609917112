import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {
  GroupStatusOptions,
  productGroupOptions,
  generatorOption,
  periodOption,
  currencyOption,
} from "variables/productOption";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
import {
  FetchSingleGroupProductDetailsApi,
  UpdateGRoupProductApi,
  deleteGroupAdditionalPersonApi,
  FetchGroupMemberOptionApi,
} from "store/AddGroupAction";
// reactstrap components
import { FormGroup, Input, Row, Col, Label, Table, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { GET_UPDATE_GROUP_PRODUCT_DATA_RESET } from "store/GroupLeadOpportunitiesAction";
import { GET_DELETE_ADDITIONAL_GROUP_DATA_RESET } from "store/GroupLeadOpportunitiesAction";
import { GET_SINGLE_GROUP_PRODUCT_DATA_RESET } from "store/GroupLeadOpportunitiesAction";
import GroupPremiumDetails from "./GroupPremiumDetails";

const EditGroupProduct = (props) => {
  const notificationAlert = React.useRef();
  const [loadingData, setLoadingData] = React.useState(true);
  const [Status, setStatus] = useState("");
  const [Consultant, setConsultant] = useState("");
  const [AccountDirector, setAccountDirector] = useState("");
  const [AccountManager, setAccountManager] = useState("");
  const [ClientDevelopmentManager, setClientDevelopmentManager] = useState("");
  const [SupportManagerTwo, setSupportManagerTwo] = useState("");
  const [Insurer, setInsurer] = useState("");
  const [Product, setProduct] = useState("");
  const [staffUsers, setStaffUsers] = useState("");
  const [consultantOptions, setconsultantOptions] = useState([]);
  const [staffUsersOptions, setStaffUsersOptions] = useState([]);
  const [InsurerOptions, setInsurerOptions] = useState([]);
  const [RefrralOptions, setRefrralOptions] = useState([]);
  const [additional, setadditional] = useState([]);
  const [countryOption, setcountryOption] = useState([]);
  const [Generator, setGenerator] = useState("");
  const [PolicyNumber, setPolicyNumber] = useState("");
  const [Discount, setDiscount] = useState("");
  const [PremiumCurrency, setPremiumCurrency] = useState("");
  const [CommCurrency, setCommCurrency] = useState("");
  const [AnnualPremium, setAnnualPremium] = useState("");
  const [commission, setcommission] = useState("");
  const [Period, setPeriod] = useState("");
  const [alert, setAlert] = React.useState(null);
  const [totalComm, settotalComm] = useState("");
  const [payment, setPayment] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [InitialPremiumPaid, setInitialPremiumPaid] = useState("");
  const getLeadUrl =
    process.env.REACT_APP_API_URL + "/get-product-detail/" + props.product_id;
  const updateProductUrl =
    process.env.REACT_APP_API_URL + "/update-product-detail";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [showLoader, setShowLoader] = React.useState(false);
  const [displayOption, setdisplayOption] = useState("d-none");
  const [gridSize, setgridSize] = useState("12");
  const [Refrral, setRefrral] = useState("");
  // const [FollowUpDay, setFollowUpDay] = useState("");
  // const [FollowUpMonth, setFollowUpMonth] = useState("");
  // const [FollowUpYear, setFollowUpYear] = useState("");
  const [UpdatedAt, setUpdatedAt] = useState("");
  const [QuoteDate, setQuoteDate] = useState("");
  const [groupUser, setgroupUser] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);

  let paymentOptions = [];
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  for (let index = 1; index <= 48; index++) {
    paymentOptions.push({
      value: index + " payments",
      label: index + " payments",
    });
  }
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };
  // console.log("Insurer", Insurer);
  let dayOptions = [];
  let monthOptions = [];
  let yearOptions = [];
  const date = new Date();
  let followYearOptions = [];

  for (let index = 1; index <= 31; index++) {
    dayOptions.push({ value: index, label: index });
  }
  for (let index = 1; index <= 12; index++) {
    monthOptions.push({ value: index, label: index });
  }
  for (
    let index = date.getFullYear();
    index <= date.getFullYear() + 10;
    index++
  ) {
    followYearOptions.push({ value: index, label: index });
  }

  const dispatch = useDispatch();
  const { SingleGroupProductData } = useSelector(
    (state) => state.getSingleGroupProductDataRe
  );
  // console.log("SingleGroupProductData", SingleGroupProductData);
  const { UpdateGroupProductSuccess } = useSelector(
    (state) => state.getUpdateGroupProductDataRe
  );
  const { GroupMembersDataSuccess } = useSelector(
    (state) => state.getGroupMemberDataRe
  );
  const { DeleteAdditionalGroupDataSuccess } = useSelector(
    (state) => state.getDeleteGroupAdditionalDataRe
  );

  const getMonthName = (monthname) => {
    const date = new Date();
    date.setMonth(monthname - 1);

    return date.toLocaleString("en-US", { month: "short" });
  };

  const activityDate = (dateUpdate) => {
    if (dateUpdate) {
      const times = dateUpdate.split(" ")[1];
      const dates = dateUpdate.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalUpdateDate = originalDate + " " + times;
      return originalUpdateDate;
    }
  };

  useEffect(() => {
    if (DeleteAdditionalGroupDataSuccess?.data?.success == true) {
      setadditional(DeleteAdditionalGroupDataSuccess?.data?.additional);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert("no-refresh")}
          onCancel={() => hideAlert("no-refresh")}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {DeleteAdditionalGroupDataSuccess?.data?.message}
        </ReactBSAlert>
      );
      dispatch({
        type: GET_DELETE_ADDITIONAL_GROUP_DATA_RESET,
        payload: { data: "" },
      });
    }
  }, [DeleteAdditionalGroupDataSuccess]);

  useEffect(() => {
    if (UpdateGroupProductSuccess?.data?.success == true) {
      notify(UpdateGroupProductSuccess?.data?.message, 2, notificationAlert);
      setShowLoader(false);

      dispatch({
        type: GET_UPDATE_GROUP_PRODUCT_DATA_RESET,
        payload: { data: "" },
      });
    }
    if (UpdateGroupProductSuccess?.data?.success == false) {
      notify(UpdateGroupProductSuccess?.data?.message, 3, notificationAlert);
    }
  }, [UpdateGroupProductSuccess]);

  useEffect(() => {
    const getSingleGroupProduct = async () => {
      await dispatch(
        FetchSingleGroupProductDetailsApi(props.product_id, loggedUser.token)
      );
    };
    const getGroupMemberOptions = async () => {
      await dispatch(
        FetchGroupMemberOptionApi(loggedUser.token, props.group_id)
      );
    };
    getSingleGroupProduct();
    getGroupMemberOptions();
  }, []);

  useEffect(() => {
    if (GroupMembersDataSuccess?.data?.success == true) {
      setGroupMembers(GroupMembersDataSuccess?.data?.group_members);
      setLoadingData(false);
    }
    if (GroupMembersDataSuccess?.data?.success == false) {
      setLoadingData(true);
    }
  }, [GroupMembersDataSuccess]);

  useEffect(() => {
    if (SingleGroupProductData?.data?.success == true) {
      dispatch({
        type: GET_SINGLE_GROUP_PRODUCT_DATA_RESET,
        payload: { data: "" },
      });

      const apiResponse = SingleGroupProductData?.data?.users;
      const sortedOptions = [...apiResponse].sort((a, b) => {
        if (a.label === "N/A") {
          return -1;
        }
        if (b.label === "N/A") {
          return 1;
        }

        return a.label.localeCompare(b.label);
      });
      const newSortedOptions = sortedOptions.filter((val) => {
        if (
         val.label === "N/A" ||
          val.label === "Claire Dimla" ||
          val.label === "Matthew Thomson" ||
          val.label === "Michael Smith" ||
          val.label === "Nim Hiran" ||
          val.label === "Onnapa Sangin" ||
          val.label === "Peach nante" ||
          val.label === "Sue Soe" ||
          val.label === "Sunny Sakchira" ||
          val.label === "Tristan Slock" ||
          val.label === "Turk Rirksom" ||
          val.label == "Sally Skeggs"
        ) {
          return val;
        }
        return val;

      });
      const staffUsers = SingleGroupProductData?.data?.staffUsers;
      const staffUserssortedOptions = [...staffUsers].sort((a, b) => {
        if (a.label === "N/A") {
          return -1;
        }
        if (b.label === "N/A") {
          return 1;
        }

        return a.label.localeCompare(b.label);
      });
      // "Claire Dimla", "Michael Smith", "Nim Hiran", "Onnapa Sangin", "Peach nante", "Sue Soe", "Sunny Sakchira", "Turk Rirksom".


      // val.label !== "Demo" &&
      // val.label !== "Alla Visum" &&
      // val.label !== "Steve" &&
      // val.label !== "Developer" &&
      // val.label !== "Steve Solanki admin" &&
      // val.label !== "Didier Bottin" &&
      // val.label !== "Kavi Iqbal" &&
      // val.label !== "steveremote" &&
      // val.label !== "Jordan Gall Sales Consultant" &&
      // val.label !== "Tim Proudlock" &&
      // val.label !== "demo" &&
      // val.label !== "Steve CRM" &&
      // val.label !== "steve"

      const newStaffUserssortedOptions = staffUserssortedOptions.filter(
        (val) => {
          if (
            val.label === "N/A" ||
            val.label === "Amy Singh" ||
            val.label === "Claire Dimla" ||
            val.label === "Matthew Thomson" ||
            val.label === "Michael Smith" ||
            val.label === "Nim Hiran" ||
            val.label === "Onnapa Sangin" ||
            val.label === "Peach nante" ||
            val.label === "Sue Soe" ||
            val.label === "Sunny Sakchira" ||
            val.label === "Turk Rirksom" ||
            val.label === "Varissara Onsri" ||
            val.label == "Sally Skeggs"
          ) {
            return val;
          }
          return val;

        }
      );

      setconsultantOptions(newSortedOptions);
      setStaffUsersOptions(newStaffUserssortedOptions);
      // setconsultantOptions(SingleGroupProductData?.data?.users);
      // setStaffUsersOptions(SingleGroupProductData?.data?.staffUsers);
      setInsurerOptions(SingleGroupProductData?.data?.insurer);
      setcountryOption(SingleGroupProductData?.data?.countries);
      setadditional(SingleGroupProductData?.data?.additional);
      setStatus(SingleGroupProductData?.data?.product?.status);
      setProduct(
        SingleGroupProductData?.data?.product?.product || {
          value: "",
          label: "Product",
        }
      );
      setConsultant(SingleGroupProductData?.data?.product?.user_id);
      setAccountManager(
        SingleGroupProductData?.data?.product?.account_manager_id
      );
      setAccountDirector(
        SingleGroupProductData?.data?.product?.account_director_id
      );
      setClientDevelopmentManager(
        SingleGroupProductData?.data?.product?.client_development_manager_id
      );
      setSupportManagerTwo(
        SingleGroupProductData?.data?.product?.support_manager_two_id
      );

      setGenerator(SingleGroupProductData?.data?.product?.generator);
      setStaffUsers(SingleGroupProductData?.data?.product?.staff_user_id);
      setInsurer(
        SingleGroupProductData?.data?.product?.insurer || {
          value: "",
          label: "Insurer",
        }
      );
      setPolicyNumber(SingleGroupProductData?.data?.product?.["policy-number"]);
      setDiscount(SingleGroupProductData?.data?.product?.["discount-rate"]);
      setPremiumCurrency(
        SingleGroupProductData?.data?.product?.["annual-premium"]
      );
      setAnnualPremium(
        SingleGroupProductData?.data?.product?.["annual-premium-amount"]
      );
      setPeriod(
        SingleGroupProductData?.data?.product?.["annual-premium-payment-period"]
      );
      setcommission(SingleGroupProductData?.data?.product?.["commission-rate"]);
      setCommCurrency(
        SingleGroupProductData?.data?.product?.["total-commission-currency"]
      );
      settotalComm(
        SingleGroupProductData?.data?.product?.["total-commission-amount"]
      );
      setPayment(
        SingleGroupProductData?.data?.product?.[
          "total-commission-payment-period"
        ]
      );

      setQuoteDate(SingleGroupProductData?.data?.product?.["quote_date"]);

      setstartDate(SingleGroupProductData?.data?.product?.["start-date"]);
      setendDate(SingleGroupProductData?.data?.product?.["end-date"]);
      setInitialPremiumPaid(
        SingleGroupProductData?.data?.product?.["initial-premium-paid"]
      );

      setgroupUser(
        SingleGroupProductData?.data?.product["group_administrator"] !== null
          ? {
              value:
                SingleGroupProductData?.data?.product["group_administrator"],
              label:
                SingleGroupProductData?.data?.product["group_administrator"],
            }
          : { value: "", label: "", isDisabled: true }
      );

      // setFollowUpDay(
      //   SingleGroupProductData?.data?.product["followup-day"] !== null
      //     ? {
      //         value: SingleGroupProductData?.data?.product["followup-day"],
      //         label: SingleGroupProductData?.data?.product["followup-day"],
      //       }
      //     : { value: "", label: "DD", isDisabled: true }
      // );
      // setFollowUpMonth(
      //   SingleGroupProductData?.data?.product["followup-month"] !== null
      //     ? {
      //         value: SingleGroupProductData?.data?.product["followup-month"],
      //         label: SingleGroupProductData?.data?.product["followup-month"],
      //       }
      //     : { value: "", label: "MM", isDisabled: true }
      // );
      // setFollowUpYear(
      //   SingleGroupProductData?.data?.product["followup-year"] !== null
      //     ? {
      //         value: SingleGroupProductData?.data?.product["followup-year"],
      //         label: SingleGroupProductData?.data?.product["followup-year"],
      //       }
      //     : { value: "", label: "YYYY", isDisabled: true }
      // );
      setUpdatedAt(SingleGroupProductData?.data?.product?.updated_at);
      setLoadingData(false);
    }
    if (SingleGroupProductData?.data?.success == false) {
      setLoadingData(true);
    }
  }, [SingleGroupProductData]);

  const updateProduct = async () => {
    // console.log("working in updateprodcut" ,Product)

    var data = {
      status: Status,
      product: Product,
      insurer: Insurer,
      generator: Generator,
      policy_number: PolicyNumber,
      discount_rate: Discount,
      annual_premium: PremiumCurrency || "",
      annual_premium_amount: AnnualPremium || "",
      annual_premium_payment_period: Period || "",
      commission_rate: commission || "",
      total_commission_currency: CommCurrency || "",
      total_commission_amount: totalComm || "",
      total_commission_payment_period: payment,
      initial_premium_paid: InitialPremiumPaid,
      start_date: startDate,
      end_date: endDate,
      // followup_day: FollowUpDay?.value,
      // followup_month: FollowUpMonth?.value,
      // followup_year: FollowUpYear?.value,
      groupAdministrator: groupUser.value,
      quote_date: QuoteDate,
      user_id: Consultant,
      account_director_id: AccountDirector || "",
      account_manager_id: AccountManager || "",
      client_development_manager_id: ClientDevelopmentManager || "",
      support_manager_two_id: SupportManagerTwo || "",
      staff_user_id: staffUsers || "",
      // addtional_person: additional,
      // new_addtional_person: formValues,
      product_id: props.product_id,
      group_id: props.group_id,
      referral: !Refrral ? 0 : Refrral.value !== "" ? Refrral.value : 0,
    };
    // console.log("data", data);
    if (
      Status.value !== "" &&
      Product.value !== "" &&
      Insurer.value !== "" &&
      Generator.value !== "" &&
      Consultant.value != ""
    ) {
      setShowLoader(true);
      await dispatch(UpdateGRoupProductApi(data, loggedUser.token));
    } else {
      notify("Fields makerd with (*) are required.", 3, notificationAlert);
    }
  };

  const deleteAddtionalUser = (user_id, product_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(user_id, product_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this!
      </ReactBSAlert>
    );
  };
  const successDelete = async (user_id, product_id) => {
    const deleteAddtional = {
      user_id: user_id,
      product_id: product_id,
    };
    await dispatch(
      deleteGroupAdditionalPersonApi(deleteAddtional, loggedUser.token)
    );

    // const deleteInsurerUrl =
    //   process.env.REACT_APP_API_URL + "/delete-addtional-person";
    // var headers = new Headers();
    // headers.append("Content-Type", "application/json");
    // headers.append("Authorization", "Bearer " + loggedUser.token);
    // fetch(deleteInsurerUrl, {
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({ user_id: user_id, product_id: product_id }),
    // })
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (data) {
    //     if (!data.success) {
    //     } else {
    //       setadditional(data.additional);
    //       setAlert(
    //         <ReactBSAlert
    //           success
    //           style={{ display: "block", marginTop: "-100px" }}
    //           title="Deleted!"
    //           onConfirm={() => hideAlert("no-refresh")}
    //           onCancel={() => hideAlert("no-refresh")}
    //           confirmBtnBsStyle="info"
    //           btnSize=""
    //         >
    //           {data.message}
    //         </ReactBSAlert>
    //       );
    //     }
    //   });
  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };
  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, fieldState) => {
    if (value.length >= 1) {
      fieldState("has-success");
    } else {
      fieldState("has-danger");
    }
  };

  const [formValues, setFormValues] = useState([
    {
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      rStatus: "",
      nationality: "",
    },
  ]);

  let handleExistingChange = (i, e, type, name = "") => {
    let newFormValues = [...additional];
    if (type === "select") {
      newFormValues[i][name] = e.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    setadditional(newFormValues);
  };

  let handleChange = (i, e, type, name = "") => {
    let newFormValues = [...formValues];
    if (type === "select") {
      newFormValues[i][name] = e.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }

    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        rStatus: "",
        nationality: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const statusDefaultValue = (statusVal, groupStatusArray) => {
    if (statusVal && groupStatusArray) {
      return groupStatusArray.filter((item) => item.value === statusVal);
    }
  };

  const productDefaultValue = (productVal, productArray) => {
    if (productVal && productArray) {
      return productArray.filter((item) => item.value === productVal);
    }
  };

  const consultantDefaultValue = (consultantVal, consultantArray) => {
    if ((consultantVal, consultantArray)) {
      return consultantArray.filter((items) => items.value === consultantVal);
    }
  };
  const generatorDefaultValue = (generatorVal, generatorArray) => {
    if (generatorArray && generatorArray) {
      return generatorArray.filter((items) => items.value === generatorVal);
    }
  };

  const supportManagerDefaultValue = (
    supportManagerVal,
    supportManagerArray
  ) => {
    if ((supportManagerVal, supportManagerArray)) {
      return supportManagerArray.filter(
        (items) => items.value === supportManagerVal
      );
    }
  };

  const insureDefaultValue = (insurerVal, insurerArray) => {
    if (insurerVal && insurerArray) {
      return insurerArray.filter((items) => items.value == insurerVal);
    }
  };

  const premiumCurrencyDefaultValue = (
    premiumCurrencyVal,
    premiumCurrencyArray
  ) => {
    // console.log("premiumCurrencyVal", premiumCurrencyVal);
    // console.log("premiumCurrencyVal1234", premiumCurrencyArray);

    if (premiumCurrencyVal && premiumCurrencyArray) {
      return premiumCurrencyArray.filter(
        (items) => items.value == premiumCurrencyVal
      );
    }
  };
  //   console.log("staffUsers" , staffUsers)
  // console.log("staffUsersOptions" , InsurerOptions);
  const periodDefaultValue = (periodVal, periodArray) => {
    if (periodVal && periodArray) {
      return periodArray.filter((items) => items.value == periodVal);
    }
  };

  const CommCurrencyDefaulValue = (commcurrencyVal, commCurrencyArray) => {
    if (commcurrencyVal && commCurrencyArray) {
      return commCurrencyArray.filter(
        (items) => items.value == commcurrencyVal
      );
    }
  };

  const periodsPaymentDefaultValue = (paymentVal, paymentArray) => {
    if (paymentVal && paymentArray) {
      return paymentArray.filter((items) => items.value == paymentVal);
    }
  };

  return (
    <>
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            {alert}
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <Row>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Status*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={statusDefaultValue(Status, GroupStatusOptions)}
                          onChange={(e) => {
                            setStatus(e.value);
                          }}
                          options={GroupStatusOptions}
                          //   defaultValue={Status}
                          placeholder="Status"
                        />
                      </FormGroup>
                    </Col>
                    {Status == "Quoting" ? (
                      <>
                        <Col md="12">
                          <FormGroup>
                            <label>Quote Date</label>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Quote Date Here",
                                value: QuoteDate || "",
                              }}
                              // isValidDate={disablePastDt}
                              timeFormat={false}
                              onChange={(e) => {
                                setQuoteDate(
                                  moment(e.toDate()).format("DD-MM-YYYY")
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* <Col className="ml-auto mr-auto" lg="4">
                        <label>Follow Up Date</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="title"
                          onChange={(e) => {
                            setFollowUpDay(e);
                          }}
                          options={dayOptions}
                          placeholder="DD"
                          defaultValue={FollowUpDay}
                        />
                      </Col>
                      <Col className="ml-auto mr-auto" lg="4">
                        <label>&nbsp;</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="title"
                          onChange={(e) => {
                            setFollowUpMonth(e);
                          }}
                          options={monthOptions}
                          placeholder="MM"
                          defaultValue={FollowUpMonth}
                        />
                      </Col>
                      <Col className="ml-auto mr-auto" lg="4">
                        <label>&nbsp;</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="title"
                          onChange={(e) => {
                            setFollowUpYear(e);
                          }}
                          options={followYearOptions}
                          placeholder="YYYY"
                          defaultValue={FollowUpYear}
                        />
                      </Col> */}
                      </>
                    ) : null}
                    {/* staffUsersOptions */}
                    {/* &nbsp; */}{" "}
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup
                        style={{ marginTop: "1.5%" }}
                        className={`has-label`}
                      >
                        <label>Insurer*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={insureDefaultValue(Insurer, InsurerOptions)}
                          onChange={(e) => {
                            // console.log("e", e);
                            setInsurer(e.value);
                          }}
                          options={InsurerOptions}
                          placeholder="Select Provider"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Client Relationship Manager*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={consultantDefaultValue(
                            Consultant,
                            consultantOptions
                          )}
                          onChange={(e) => {
                            setConsultant(e.value);
                          }}
                          options={consultantOptions}
                          placeholder="Select Consultant"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Account Manager</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={consultantDefaultValue(
                            AccountManager,
                            consultantOptions
                          )}
                          onChange={(e) => {
                            setAccountManager(e.value);
                          }}
                          options={consultantOptions}
                          placeholder="Select Consultant"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Support Manager*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={supportManagerDefaultValue(
                            staffUsers,
                            staffUsersOptions
                          )}
                          onChange={(e) => {
                            setStaffUsers(e.value);
                          }}
                          options={staffUsersOptions}
                          placeholder="Support Manager"
                        />
                      </FormGroup>
                    </Col>
                    {Status == "Quoting" ? (
                      <Col className="ml-auto mr-auto" md="12">
                        <FormGroup className={`has-label`}>
                          <label>Quote required by*</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            onChange={(e) => {
                              setgroupUser(e);
                            }}
                            options={groupMembers}
                            defaultValue={groupUser}
                            placeholder="Quote required by"
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col className="ml-auto mr-auto" md="12">
                      <br />
                      <div style={{ display: "flex" }}>
                        <label>Status Date: </label>&nbsp;&nbsp;{" "}
                        <p style={{ color: "#3fa9f5" }}>
                          {activityDate(UpdatedAt)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="ml-auto mr-auto" md="6">
                  <Row>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Product*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={productDefaultValue(Product, productGroupOptions)}
                          onChange={(e) => {
                            setProduct(e.value);
                          }}
                          options={productGroupOptions}
                          placeholder="Select"
                          isMulti={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md={`${gridSize}`}>
                      <FormGroup className={`has-label`}>
                        <label>Generator*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={generatorDefaultValue(
                            Generator,
                            generatorOption
                          )}
                          onChange={(e) => {
                            setGenerator(e.value);
                            // if (e.value === "Referral") {
                            //   setgridSize(6);
                            //   setdisplayOption("d-block");
                            //   setRefrral({ value: "", label: "" });
                            // } else {
                            //   setgridSize(12);
                            //   setdisplayOption("d-none");
                            // }
                          }}
                          options={generatorOption}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col className={`ml-auto mr-auto ${displayOption}`} md="6">
                      <FormGroup className={`has-label`}>
                        <label>Referral</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setRefrral(e);
                          }}
                          options={Object.values(RefrralOptions)}
                          defaultValue={Refrral}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </Col> */}
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Policy No.</label>
                        <Input
                          type="text"
                          value={PolicyNumber || ""}
                          onChange={(e) => {
                            setPolicyNumber(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Account Director</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={consultantDefaultValue(
                            AccountDirector,
                            consultantOptions
                          )}
                          onChange={(e) => {
                            setAccountDirector(e.value);
                          }}
                          options={consultantOptions}
                          placeholder="Select Consultant"
                        />
                      </FormGroup>
                    </Col>

                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Client Development Manager</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={consultantDefaultValue(
                            ClientDevelopmentManager,
                            consultantOptions
                          )}
                          onChange={(e) => {
                            setClientDevelopmentManager(e.value);
                          }}
                          options={consultantOptions}
                          placeholder="Select Consultant"
                        />
                      </FormGroup>
                    </Col>

                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Support Manager 2</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={consultantDefaultValue(
                            SupportManagerTwo,
                            consultantOptions
                          )}
                          onChange={(e) => {
                            setSupportManagerTwo(e.value);
                          }}
                          options={consultantOptions}
                          placeholder="Select Consultant"
                        />
                      </FormGroup>
                    </Col>

                    {/*  */}
                  </Row>
                </Col>
                <Col md="12">
                  <hr className="m-3" />
                </Col>

                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Discount(%)</label>
                    <Input
                      type="number"
                      value={Discount || ""}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Premium Currency</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={premiumCurrencyDefaultValue(
                        PremiumCurrency,
                        currencyOption
                      )}
                      onChange={(e) => {
                        // console.log("premiumcurr", e);
                        setPremiumCurrency(e.value);
                      }}
                      options={currencyOption}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Annual Premium</label>
                    <Input
                      type="number"
                      value={AnnualPremium || ""}
                      onChange={(e) => {
                        setAnnualPremium(e.target.value);
                        if (commission != "" && e.target.value != "") {
                          settotalComm(
                            (e.target.value * (commission / 100)).toFixed(2)
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Premium Frequency</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={periodDefaultValue(Period, periodOption)}
                      onChange={(e) => {
                        setPeriod(e.value);
                      }}
                      options={periodOption}
                      //   defaultValue={Period}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>

                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Comm(%)</label>
                    <Input
                      type="number"
                      value={commission || ""}
                      onChange={(e) => {
                        setcommission(e.target.value);
                        if (e.target.value != "" && AnnualPremium != "") {
                          settotalComm(
                            (AnnualPremium * (e.target.value / 100)).toFixed(2)
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Commission Currency</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={CommCurrencyDefaulValue(
                        CommCurrency,
                        currencyOption
                      )}
                      onChange={(e) => {
                        setCommCurrency(e.value);
                      }}
                      options={currencyOption}
                      //   defaultValue={CommCurrency}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Total Commission</label>
                    <Input
                      type="number"
                      value={totalComm || ""}
                      readOnly={true}
                      placeholder="[Calculation: (Annual Premium) x (Comm(%))]"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Period</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={periodsPaymentDefaultValue(
                        payment,
                        paymentOptions
                      )}
                      onChange={(e) => {
                        setPayment(e.value);
                      }}
                      options={paymentOptions}
                      defaultValue={payment}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Start Date</label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        value: startDate || "",
                      }}
                      // isValidDate={disablePastDt}
                      timeFormat={false}
                      onChange={(e) => {
                        setstartDate(moment(e.toDate()).format("DD-MM-YYYY"));
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>End Date</label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        value: endDate || "",
                      }}
                      // isValidDate={disablePastDt}
                      timeFormat={false}
                      onChange={(e) => {
                        setendDate(moment(e.toDate()).format("DD-MM-YYYY"));
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={1}
                        defaultChecked={InitialPremiumPaid == 1 ? true : false}
                        disabled={
                          loggedUser.data.role === 1 ||
                          loggedUser.data.role === 2
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            setInitialPremiumPaid(e.target.value);
                          } else {
                            setInitialPremiumPaid("");
                          }
                        }}
                      />
                      <span className="form-check-sign" />
                      Initial premium paid
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <hr className="m-3" />
                </Col>

                {/* <Col md="12">
                  <Table className="table">
                    <thead>
                      <tr>
                        <th width="12%">First Name</th>
                        <th width="12%">Last Name</th>
                        <th width="12%">Gender</th>
                        <th width="12%">DOB</th>
                        <th width="20%">Location</th>
                        <th width="20%">Nationality</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {additional.length > 0
                        ? additional.map((element, index) => (
                            <tr key={additional[index]["id"] + "-" + index}>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Input
                                    type="text"
                                    name="first-name"
                                    value={
                                      additional[index]["first-name"] || ""
                                    }
                                    onChange={(e) =>
                                      handleExistingChange(index, e, "input")
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Input
                                    type="text"
                                    name="last-name"
                                    value={additional[index]["last-name"] || ""}
                                    onChange={(e) =>
                                      handleExistingChange(index, e, "input")
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Select
                                    name="gender"
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={[
                                      { value: "male", label: "male" },
                                      { value: "female", label: "female" },
                                    ]}
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "select",
                                        "gender"
                                      )
                                    }
                                    defaultValue={
                                      additional[index]["gender"] !== null
                                        ? {
                                            value: additional[index]["gender"],
                                            label: additional[index]["gender"],
                                          }
                                        : { value: "", label: "Gender" }
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup>
                                  <ReactDatetime
                                    inputProps={{
                                      className: "form-control",
                                      placeholder: "Date Picker Here",
                                      value: additional[index]["date"] || "",
                                      name: "date",
                                    }}
                                    timeFormat={false}
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "date",
                                        "date"
                                      )
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Select
                                    name="rStatus"
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={countryOption}
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "select",
                                        "r-status"
                                      )
                                    }
                                    defaultValue={
                                      additional[index]["r-status"] !== null
                                        ? {
                                            value:
                                              additional[index]["r-status"],
                                            label:
                                              additional[index]["r-status"],
                                          }
                                        : { value: "", label: "R Status" }
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Select
                                    name="nationality"
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={countryOption}
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "select",
                                        "nationality"
                                      )
                                    }
                                    defaultValue={
                                      additional[index]["nationality"] !== null
                                        ? {
                                            value:
                                              additional[index]["nationality"],
                                            label:
                                              additional[index]["nationality"],
                                          }
                                        : { value: "", label: "Nationality" }
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                {loggedUser.data.role === 1 ||
                                loggedUser.data.role === 2 ? (
                                  <Button
                                    color="danger"
                                    className="button remove"
                                    onClick={() =>
                                      deleteAddtionalUser(
                                        additional[index]["id"],
                                        additional[index]["product_id"]
                                      )
                                    }
                                  >
                                    Remove
                                  </Button>
                                ) : null}
                              </td>
                            </tr>
                          ))
                        : null}
                      {formValues.map((element, index) => (
                        <tr key={index}>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Input
                                type="text"
                                name="firstName"
                                value={element.firstName || ""}
                                onChange={(e) =>
                                  handleChange(index, e, "input")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Input
                                type="text"
                                name="lastName"
                                value={element.lastName || ""}
                                onChange={(e) =>
                                  handleChange(index, e, "input")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Select
                                name="gender"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={[
                                  { value: "male", label: "male" },
                                  { value: "female", label: "female" },
                                ]}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleChange(index, e, "select", "gender")
                                }
                                value={{
                                  value: element.gender,
                                  label: element.gender,
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  name: "dob",
                                }}
                                timeFormat={false}
                                onChange={(e) =>
                                  handleChange(index, e, "date", "dob")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Select
                                name="rStatus"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={countryOption}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleChange(index, e, "select", "rStatus")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Select
                                name="nationality"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={countryOption}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    e,
                                    "select",
                                    "nationality"
                                  )
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            {index === formValues.length - 1 ? (
                              <Button
                                color="primary"
                                onClick={() => addFormFields()}
                              >
                                Add More
                              </Button>
                            ) : (
                              <Button
                                color="danger"
                                className="button remove"
                                onClick={() => removeFormFields(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col> */}
              </Row>
            )}
          </Col>
        </Row>

        {loggedUser.data.role === 1 ||
        loggedUser.data.role === 2 ||
        loggedUser.data.role === 3 ||
        loggedUser.data.role === 5 ||
        loggedUser.data.role === 7 ||

        loggedUser.data.role === 6 ? (
          <Col className="text-center ml-auto mr-auto" md="12">
            <Button color="primary" onClick={updateProduct}>
              Save
            </Button>
          </Col>
        ) : null}
        <div className="modal-header justify-content-center">
          <h4 className="title title-up">Premium Details</h4>
        </div>

        <GroupPremiumDetails
          product_id={props.product_id}
          group_id={props.group_id}
        ></GroupPremiumDetails>
      </div>
    </>
  );
};

export default EditGroupProduct;
