/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";




function AddTravel() {
  const notificationAlert = React.useRef();
  const [showLoader, setShowLoader] = React.useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const getFormDataUrl = process.env.REACT_APP_API_URL + "/get-insurer-and-doctype/";
  let history = useHistory();
  var headers = new Headers();
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  headers.append("Authorization", "Bearer " + loggedUser.token);

  const saveTravelDraft = () => {
    let formData = new FormData();
    formData.append('policy_type', policyType);
    formData.append('deductible_excess', deductible);
    formData.append('medical_expenses', medicalExpenses);
    formData.append('flight_cancelation', flightCancelation);
    formData.append('personal_accident', personalAccident);
    formData.append('baggage', baggage);
    formData.append('adventure_sport', adventureSport);
    formData.append('money', money);
    formData.append('scuba_diving', scubaDiving);
    formData.append('skiing', skiing);
    formData.append('evacuation_repatriation', evacuationRepatriation);
    formData.append('terrorism', terrorism);
    formData.append('insurer_id', insurer.value);
    formData.append('plan_name', PlanName);
    formData.append('flight_delay', flightDelay);
    formData.append('loss_of_passport', lossPassport);
    formData.append('personal_effects', personalEffects);
    formData.append('customer_rating', customerRating);
    formData.append('area_of_cover', areaCover);
    formData.append('product_type', "travel");
    formData.append('insurance_period_type', periodType);
    formData.append('medical_expenses_radio', MedicalTypeRadio);
    formData.append('medical_expenses_text', medicalTypeText);
    formData.append('flight_cancelation_radio', FlightCancelationRadio);
    formData.append('flight_cancelation_text', flightCancelationText);
    formData.append('baggage_radio', BaggageRadio);
    formData.append('baggage_text', baggageText);
    formData.append('evacuation_repatriation_radio', EvacuationRadio);
    formData.append('evacuation_repatriation_text', evacuationText);
    formData.append('excle_doc', fileState === null ? "" : fileState);
    formData.append('status', "0");
    for (var i = 0; i < formValues.length; i++) {
      formData.append('document_type[' + i + ']', formValues[i].doctype);
      formData.append('document[' + i + ']', formValues[i].docfile);
      formData.append('description[' + i + ']', formValues[i].description);
    }
    sendData(formData, 'save-travel-draft');
  };
  const saveTravel = () => {
    setDocumentState("has-success");
    /*for (var i = 0; i < formValues.length; i++) {
      if (formValues[i].description == "" || formValues[i].docfile == "" || formValues[i].doctype == "") {
        setDocumentState("has-danger");
      }
    }*/
    PolicyTypeState === "" ? setPolicyTypeState("has-danger") : "";
    DeductibleState === "" ? setDeductibleState("has-danger") : "";
    MedicalExpensesState === "" ? setMedicalExpensesState("has-danger") : "";
    FlightCancelationState === "" ? setFlightCancelationState("has-danger") : "";
    PersonalAccidentState === "" ? setPersonalAccidentState("has-danger") : "";
    BaggageState === "" ? setBaggageState("has-danger") : "";
    FlightDelayState === "" ? setFlightDelayState("has-danger") : "";
    LossPassportState === "" ? setLossPassportState("has-danger") : "";
    PersonalEffectsState === "" ? setPersonalEffectsState("has-danger") : "";
    AdventureSportState === "" ? setAdventureSportState("has-danger") : "";
    MoneyState === "" ? setMoneyState("has-danger") : "";
    ScubaDivingState === "" ? setScubaDivingState("has-danger") : "";
    SkiingState === "" ? setSkiingState("has-danger") : "";
    EvacuationRepatriationState === "" ? setEvacuationRepatriationState("has-danger") : "";
    TerrorismState === "" ? setTerrorismState("has-danger") : "";
    CustomerRatingState === "" ? setCustomerRatingState("has-danger") : "";
    AreaCoverState === "" ? setAreaCoverState("has-danger") : "";
    insurerState === "" ? setinsurerState("has-danger") : "";
    PlanNameState === "" ? setPlanNameState("has-danger") : "";

    if (PolicyTypeState === "has-success" && DeductibleState === "has-success" && MedicalExpensesState === "has-success" &&
      FlightCancelationState === "has-success" && PersonalAccidentState === "has-success" && BaggageState === "has-success" &&
      FlightDelayState === "has-success" && LossPassportState === "has-success" && PersonalEffectsState === "has-success" &&
      AdventureSportState === "has-success" && MoneyState === "has-success" && ScubaDivingState === "has-success" &&
      SkiingState === "has-success" && EvacuationRepatriationState === "has-success" && TerrorismState === "has-success" &&
      CustomerRatingState == "has-success" && AreaCoverState === "has-success" && insurerState === "has-success" &&
      PlanNameState === "has-success" && DocumentState === "has-success") {
      let formData = new FormData();
      formData.append('policy_type', policyType);
      formData.append('deductible_excess', deductible);
      formData.append('medical_expenses', medicalExpenses);
      formData.append('flight_cancelation', flightCancelation);
      formData.append('personal_accident', personalAccident);
      formData.append('baggage', baggage);
      formData.append('adventure_sport', adventureSport);
      formData.append('money', money);
      formData.append('scuba_diving', scubaDiving);
      formData.append('skiing', skiing);
      formData.append('evacuation_repatriation', evacuationRepatriation);
      formData.append('terrorism', terrorism);
      formData.append('insurer_id', insurer.value);
      formData.append('plan_name', PlanName);
      formData.append('flight_delay', flightDelay);
      formData.append('loss_of_passport', lossPassport);
      formData.append('personal_effects', personalEffects);
      formData.append('customer_rating', customerRating);
      formData.append('area_of_cover', areaCover);
      formData.append('product_type', "travel");
      formData.append('insurance_period_type', periodType);
      formData.append('medical_expenses_radio', MedicalTypeRadio);
      formData.append('medical_expenses_text', medicalTypeText);
      formData.append('flight_cancelation_radio', FlightCancelationRadio);
      formData.append('flight_cancelation_text', flightCancelationText);
      formData.append('baggage_radio', BaggageRadio);
      formData.append('baggage_text', baggageText);
      formData.append('evacuation_repatriation_radio', EvacuationRadio);
      formData.append('evacuation_repatriation_text', evacuationText);
      formData.append('excle_doc', fileState);
      formData.append('status', "1");
      for (var i = 0; i < formValues.length; i++) {
        formData.append('document_type[' + i + ']', formValues[i].doctype);
        formData.append('document[' + i + ']', formValues[i].docfile);
        formData.append('description[' + i + ']', formValues[i].description);
      }
      sendData(formData, 'save-travel');

    }
  };
  const sendData = (data, url) => {
    setShowLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/" + url, {
      method: "POST",
      headers: headers,
      body: data
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
          notify(data.message, 3, notificationAlert);
        } else {
          console.log(data);

          notify(data.message, 2, notificationAlert);
          setTimeout(function () { history.push('/admin/all-products') }, 3000);

        }
        setShowLoader(false);
      });
  }
  useEffect(() => {
    async function getFormData() {

      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(getFormDataUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (!data.success) {

          } else {
            setLoadingData(false);
            setinsurerOptions(data.insurer_list);
            setdoctypeOptions(data.document_list);
          }
        });

    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getFormData();
    }
  }, []);


  const [DocumentState, setDocumentState] = useState("");
  const [PlanNameState, setPlanNameState] = useState("");
  const [PlanName, setPlanName] = useState("");
  const [insurer, setinsurer] = useState("");
  const [insurerOptions, setinsurerOptions] = useState("");
  const [doctypeOptions, setdoctypeOptions] = useState([]);
  const [policyType, setPolicyType] = useState("");
  const [PolicyTypeState, setPolicyTypeState] = useState("");
  const [deductible, setDeductible] = useState("");
  const [DeductibleState, setDeductibleState] = useState("");
  const [medicalExpenses, setMedicalExpenses] = useState("");
  const [MedicalExpensesState, setMedicalExpensesState] = useState("");
  const [flightCancelation, setFlightCancelation] = useState("");
  const [FlightCancelationState, setFlightCancelationState] = useState("");
  const [personalAccident, setPersonalAccident] = useState("");
  const [PersonalAccidentState, setPersonalAccidentState] = useState("");
  const [baggage, setBaggage] = useState("");
  const [BaggageState, setBaggageState] = useState("");
  const [flightDelay, setFlightDelay] = useState("");
  const [FlightDelayState, setFlightDelayState] = useState("");
  const [lossPassport, setLossPassport] = useState("");
  const [LossPassportState, setLossPassportState] = useState("");
  const [personalEffects, setPersonalEffects] = useState("");
  const [PersonalEffectsState, setPersonalEffectsState] = useState("");
  const [adventureSport, setAdventureSport] = useState("");
  const [AdventureSportState, setAdventureSportState] = useState("");
  const [money, setMoney] = useState("");
  const [MoneyState, setMoneyState] = useState("");
  const [scubaDiving, setScubaDiving] = useState("");
  const [ScubaDivingState, setScubaDivingState] = useState("");
  const [skiing, setSkiing] = useState("");
  const [SkiingState, setSkiingState] = useState("");
  const [evacuationRepatriation, setEvacuationRepatriation] = useState("");
  const [EvacuationRepatriationState, setEvacuationRepatriationState] = useState("");
  const [terrorism, setTerrorism] = useState("");
  const [TerrorismState, setTerrorismState] = useState("");
  const [customerRating, setCustomerRating] = useState("");
  const [CustomerRatingState, setCustomerRatingState] = useState("");
  const [areaCover, setAreaCover] = useState("");
  const [AreaCoverState, setAreaCoverState] = useState("");
  const [periodType, setPeriodType] = useState("Annual");
  const [insurerState, setinsurerState] = useState("");
  const [evacuationText, setEvacuationText] = useState("");
  const [etReadonly, setetReadonly] = useState(true);
  const [baggageText, setBaggageText] = useState("");
  const [bReadonly, setbReadonly] = useState(true);
  const [flightCancelationText, setFlightCancelationText] = useState("");
  const [fcReadonly, setfcReadonly] = useState(true);
  const [medicalTypeText, setMedicalTypeText] = useState("");
  const [mReadonly, setmReadonly] = useState(true);
  const [MedicalTypeRadio, setMedicalTypeRadio] = useState("cross");
  const [BaggageRadio, setBaggageRadio] = useState("cross");
  const [FlightCancelationRadio, setFlightCancelationRadio] = useState("cross");
  const [EvacuationRadio, setEvacuationRadio] = useState("cross");
  const [fileState, setFileState] = React.useState(null);

  const verifyLength = (value, fieldState) => {
    if (value.length >= 1) {
      fieldState("has-success");
    } else {
      fieldState("has-danger");
    }

  };
  const getReadOnlyStatus = (field_type, status) => {
    console.log(field_type, status);
    if (status) {
      if (field_type == "EvacuationText") {
        setetReadonly(true);
      }
      if (field_type == "BaggageText") {
        setbReadonly(true);
      }
      if (field_type == "FlightCancelationText") {
        setfcReadonly(true);
      }
      if (field_type == "MedicalTypeText") {
        setmReadonly(true);
      }

    } else {
      if (field_type == "EvacuationText") {
        setetReadonly(false);
      }
      if (field_type == "BaggageText") {
        setbReadonly(false);
      }
      if (field_type == "FlightCancelationText") {
        setfcReadonly(false);
      }
      if (field_type == "MedicalTypeText") {
        setmReadonly(false);
      }
    }
  };

  const [formValues, setFormValues] = useState([{ doctype: "", description: "", docfile: "" }])

  let handleChange = (i, e, type) => {
    let newFormValues = [...formValues];
    if (type === "file") {
      newFormValues[i][e.target.name] = e.target.files[0];
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }

    setFormValues(newFormValues);
  }

  let addFormFields = () => {
    setFormValues([...formValues, { doctype: "", description: "", docfile: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  let handleSubmit = (event) => {
    //event.preventDefault();


    console.log(formValues);
  }
  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setFileState(file);
  };



  return (
    <>
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" lg="11" md="11" sm="11">
            <Card className="card-stats">
              <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">Add New Travel </CardTitle>
                  </Col>
                  <Col md="2">
                    <Link to="/admin/all-products" className="btn btn-primary float-right">Back to List</Link>
                  </Col>
                </Row>


              </CardHeader>
              <CardBody>
                <h3>Section 1: Benefit Details</h3>
                <Row>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${insurerState}`}>
                      <label>Insurer *</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="medicalType"
                        value={insurer}
                        onChange={(e) => {
                          if (e.value === "") {
                            setinsurerState("has-danger");
                          } else {
                            setinsurerState("has-success");
                          }
                          setinsurer(e);
                        }}
                        options={insurerOptions}
                        placeholder="Select Insurer"
                      />

                      {insurerState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${PolicyTypeState}`}>
                      <label>Policy Type *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setPolicyTypeState); setPolicyType(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${PlanNameState}`}>
                      <label>Plan Name *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setPlanNameState); setPlanName(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${DeductibleState}`}>
                      <label>Deductible/ Excess *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setDeductibleState); setDeductible(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${MedicalExpensesState}`}>
                      <label>Medical Expenses *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setMedicalExpensesState); setMedicalExpenses(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${FlightCancelationState}`}>
                      <label>Flight Cancelation *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setFlightCancelationState); setFlightCancelation(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${PersonalAccidentState}`}>
                      <label>Personal Accident *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setPersonalAccidentState); setPersonalAccident(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${BaggageState}`}>
                      <label>Baggage *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setBaggageState); setBaggage(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${FlightDelayState}`}>
                      <label>Flight Delay *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setFlightDelayState); setFlightDelay(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${LossPassportState}`}>
                      <label>Loss of Passport *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setLossPassportState); setLossPassport(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${PersonalEffectsState}`}>
                      <label>Personal Effects *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setPersonalEffectsState); setPersonalEffects(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${AdventureSportState}`}>
                      <label>Adventure Sport *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setAdventureSportState); setAdventureSport(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${MoneyState}`}>
                      <label>Money *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setMoneyState); setMoney(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${ScubaDivingState}`}>
                      <label>Scuba Diving *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setScubaDivingState); setScubaDiving(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${SkiingState}`}>
                      <label>Skiing *</label>
                      <Input name="policy_type" type="text" onChange={(e) => { verifyLength(e.target.value, setSkiingState); setSkiing(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${EvacuationRepatriationState}`}>
                      <label>Evacuation and Repatriation *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setEvacuationRepatriationState); setEvacuationRepatriation(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${TerrorismState}`}>
                      <label>Terrorism *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setTerrorismState); setTerrorism(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${CustomerRatingState}`}>
                      <label>Customer Rating *</label>
                      <Input type="number" onChange={(e) => { verifyLength(e.target.value, setCustomerRatingState); setCustomerRating(e.target.value); }} />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${AreaCoverState}`}>
                      <label>Area of Cover *</label>
                      <Input type="text" onChange={(e) => { verifyLength(e.target.value, setAreaCoverState); setAreaCover(e.target.value); }} />
                    </FormGroup>
                  </Col>

                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup>
                      <label>Insurance Period Type *</label>
                      <br />
                      <div className="form-check-radio form-check-inline">
                        <Label check>
                          <Input defaultValue="Annual" defaultChecked id="exampleRadios11" name="period_type" type="radio" onChange={(e) => {
                            setPeriodType(e.target.value);
                          }}
                          />
                          Anual <span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio form-check-inline">
                        <Label check>
                          <Input
                            defaultValue="Single Trip" id="exampleRadios12" name="period_type" type="radio"
                            onChange={(e) => {
                              setPeriodType(e.target.value);
                            }}
                          />
                          Single Trip <span className="form-check-sign" />
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4"></Col>


                </Row>
                <h3>Section 2: Benefits</h3>
                <Row>
                  <Col className="ml-auto mr-auto" md="2">
                    Medical Expenses
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultChecked defaultValue="cross" id="exampleRadios12" name="medical_expenses_radio" type="radio"
                          onChange={(e) => {
                            setMedicalTypeRadio(e.target.value);
                            getReadOnlyStatus("MedicalTypeText", true);
                          }}
                        />
                        No(show cross icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="tick" id="exampleRadios12" name="medical_expenses_radio" type="radio"
                          onChange={(e) => {
                            setMedicalTypeRadio(e.target.value);
                            getReadOnlyStatus("MedicalTypeText", true);
                          }}
                        />
                        Yes(show tick icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="text" id="exampleRadios12" name="medical_expenses_radio" type="radio"
                          onChange={(e) => {
                            setMedicalTypeRadio(e.target.value);
                            getReadOnlyStatus("MedicalTypeText", false);
                          }}
                        />
                        Yes(show text) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <Input type="text" readOnly={mReadonly} onChange={(e) => { setMedicalTypeText(e.target.value); }} />
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="2">
                    Flight Cancelation
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultChecked defaultValue="cross" id="exampleRadios12" name="flight_cancelation_radio" type="radio"
                          onChange={(e) => {
                            setFlightCancelationRadio(e.target.value);
                            getReadOnlyStatus("FlightCancelationText", true);
                          }}
                        />
                        No(show cross icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="tick" id="exampleRadios12" name="flight_cancelation_radio" type="radio"
                          onChange={(e) => {
                            setFlightCancelationRadio(e.target.value);
                            getReadOnlyStatus("FlightCancelationText", true);
                          }}
                        />
                        Yes(show tick icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="text" id="exampleRadios12" name="flight_cancelation_radio" type="radio"
                          onChange={(e) => {
                            setFlightCancelationRadio(e.target.value);
                            getReadOnlyStatus("FlightCancelationText", false);
                          }}
                        />
                        Yes(show text) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <Input type="text" readOnly={fcReadonly} onChange={(e) => { setFlightCancelationText(e.target.value); }} />
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="2">
                    Baggage
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input

                          defaultChecked defaultValue="cross" id="exampleRadios12" name="baggage_radio" type="radio"
                          onChange={(e) => {
                            setBaggageRadio(e.target.value);
                            getReadOnlyStatus("BaggageText", true);
                          }}
                        />
                        No(show cross icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="tick" id="exampleRadios12" name="baggage_radio" type="radio"
                          onChange={(e) => {
                            setBaggageRadio(e.target.value);
                            getReadOnlyStatus("BaggageText", true);
                          }}
                        />
                        Yes(show tick icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="text" id="exampleRadios12" name="baggage_radio" type="radio"
                          onChange={(e) => {
                            setBaggageRadio(e.target.value);
                            getReadOnlyStatus("BaggageText", false);
                          }}
                        />
                        Yes(show text) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <Input type="text" readOnly={bReadonly} onChange={(e) => { setBaggageText(e.target.value); }} />
                  </Col>
                </Row>

                <Row>
                  <Col className="ml-auto mr-auto" md="2">
                    Evacuation and Repatriation
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultChecked defaultValue="cross" id="exampleRadios12" name="evacuation_repatriation" type="radio"
                          onChange={(e) => {
                            setEvacuationRadio(e.target.value);
                            getReadOnlyStatus("EvacuationText", true);
                          }}
                        />
                        No(show cross icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="tick" id="exampleRadios12" name="evacuation_repatriation" type="radio"
                          onChange={(e) => {
                            setEvacuationRadio(e.target.value);
                            getReadOnlyStatus("EvacuationText", true);
                          }}
                        />
                        Yes(show tick icon) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="text" id="exampleRadios12" name="evacuation_repatriation" type="radio"
                          onChange={(e) => {
                            setEvacuationRadio(e.target.value);
                            getReadOnlyStatus("EvacuationText", false);
                          }}
                        />
                        Yes(show text) <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="2">
                    <Input type="text" readOnly={etReadonly} onChange={(e) => { setEvacuationText(e.target.value); }} />
                  </Col>
                </Row>
                <Row className="bg-light p-3">
                  <Col md="12">
                    <h3>Section 3: Documents</h3>

                    <Row className="mt-5">
                      <Col className="" md="9">
                        <h5>You can upload more document from here</h5>

                      </Col>
                      <Col className="" md="3">
                        <Button color="primary" onClick={() => addFormFields()}>Add More Document</Button>
                      </Col>
                    </Row>
                    {formValues.map((element, index) => (
                      <Row key={index}>
                        <Col className="ml-auto mr-auto" md="3">
                          <FormGroup>
                            <label>Document Type </label>
                            <select className="form-control" onChange={e => handleChange(index, e, "input")} name="doctype">
                              <option>Select Document Type</option>
                              {doctypeOptions.map((ele, i) => (
                                <option key={"doctype" + ele.value} value={ele.value}>{ele.label}</option>
                              ))}

                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3">
                          <FormGroup>
                            <label>Document Description </label>
                            <Input name="description" type="text" onChange={e => handleChange(index, e, "input")} />
                          </FormGroup>

                        </Col>
                        <Col className="ml-auto mr-auto" md="3">
                          <label>Upload Document </label>
                          <input type="file" className="form-control" name="docfile" onChange={e => handleChange(index, e, "file")} />
                        </Col>
                        <Col className="ml-auto mr-auto" md="3">
                          <label> </label>
                          {
                            index ?

                              <Button color="danger" className="button remove" onClick={() => removeFormFields(index)}>Remove</Button>

                              : null
                          }
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Col md="12">{DocumentState === "has-danger" ? (
                        <label className="error text-danger">
                          All Fields are required.
                        </label>
                      ) : null}</Col>
                    </Row>
                    <Row>
                      <Col className="" md="12">
                        <FormGroup>
                          <Label>Please select an excel document with prices*</Label>
                          <input type="file" onChange={e => handleImageChange(e)} ref={fileInput} />
                        </FormGroup>

                        <p >If it for annual insurance, this columns are required in excel file:</p>
                        <ul>
                          <li><p>Annual Premium Male</p></li>
                          <li><p>Annual Premium Female</p></li>
                          <li><p>Age</p></li>
                          <li><p>Destination</p></li>
                        </ul>
                        <p>If it for annual insurance, this columns are required in excel file:</p>
                        <ul>
                          <li><p>Daily Premium Male</p></li>
                          <li><p>Daily Premium Female</p></li>
                          <li><p>Base Premium</p></li>
                          <li><p>Age</p></li>
                          <li><p>Destination</p></li>
                        </ul>

                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col className="ml-auto mr-auto text-center m-2" md="12">
                    <Button color="primary" size="lg" onClick={() => saveTravel()}>
                      Save & Publish
                    </Button>
                    <Button color="info" size="lg" onClick={() => saveTravelDraft()}>
                      Save as Draft
                    </Button>
                  </Col>
                </Row>
              </CardBody>

            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddTravel;
