/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";




function EditProfile() {
  let history = useHistory();
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [serverError, setserverError] = React.useState("");
  const [passwordError, setpasswordError] = React.useState("");
  const [serverSuccess, setserverSuccess] = React.useState("");
  const [passwordSuccess, setpasswordSuccess] = React.useState("");
  const [loginFullName, setloginFullName] = React.useState(loggedUser.data.name);
  const [loginEmail, setloginEmail] = React.useState(loggedUser.data.email);
  const [loginFullNameState, setloginFullNameState] = React.useState("has-success");
  const [loginEmailState, setloginEmailState] = React.useState("has-success");
  const [TwoFAStatus, set2FAStatus] = React.useState("");
  const [loadingData, setLoadingData] = React.useState(true);
  const editUserUrl = process.env.REACT_APP_API_URL + "/update-profile/";
  const changePasswordUrl = process.env.REACT_APP_API_URL + "/change-password/";


  const [oldPassword, setoldPassword] = React.useState("");
  const [registerPassword, setregisterPassword] = React.useState("");
  const [registerConfirmPassword, setregisterConfirmPassword] = React.useState(
    ""
  );
  const [oldPasswordState, setoldPasswordState] = React.useState("");
  const [registerPasswordState, setregisterPasswordState] = React.useState("");
  const [
    registerConfirmPasswordState,
    setregisterConfirmPasswordState,
  ] = React.useState("");
  const changePassword = () => {

    if (oldPasswordState === "") {
      setoldPasswordState("has-danger");
    }

    if (registerPasswordState === "" || registerConfirmPasswordState === "") {
      setregisterPasswordState("has-danger");
      setregisterConfirmPasswordState("has-danger");
    }
    if ((oldPasswordState !== "has-danger" && registerPasswordState !== "has-danger" && registerConfirmPasswordState !== "has-danger") && (oldPasswordState !== "" && registerPasswordState !== "" && registerConfirmPasswordState !== "")) {
      var data = {
        "current_password": oldPassword,
        "new_password": registerPassword,
        "new_password_confirmation": registerConfirmPassword
      }

      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(changePasswordUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (!data.success) {
            setpasswordError(data.message);
            setpasswordSuccess("");
          } else {
            setpasswordError("");
            setpasswordSuccess(data.message);

          }
        });
    }
  }
  const editUser = () => {
    if (loginFullNameState === "") {
      setloginFullNameState("has-danger");
    }
    if (loginEmailState === "") {
      setloginEmailState("has-danger");
    }
    if ((loginEmailState !== "has-danger" && loginFullNameState !== "has-danger") && (loginEmailState !== "" && loginFullNameState !== "")) {
      var data = {
        "fullname": loginFullName,
        "email": loginEmail,
        "status_2fa": TwoFAStatus,
      }
      console.log(TwoFAStatus.value);
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(editUserUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (!data.success) {
            setserverError(data.message);
            setserverSuccess("");
          } else {

            setserverError("");
            setserverSuccess("Your Profile is update Successfully");
            localStorage.removeItem("current_users");
            localStorage.setItem("current_users", JSON.stringify(data));
            history.go(0)

          }
        });
    }

  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
  const compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="6" md="6" sm="6">

            <Card className="card-stats">
              <CardHeader>
                <CardTitle tag="h4">My Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" xs="12">
                    {serverError === "" ? (
                      null
                    ) : <div className="text-center alert alert-danger">
                      {serverError}
                    </div>}
                    {serverSuccess === "" ? (
                      null
                    ) : <div className="text-center alert alert-success">
                      {serverSuccess}
                    </div>}
                    <FormGroup className={`has-label ${loginFullNameState}`}>
                      <label>Full Name *</label>
                      <Input
                        name="fullname"
                        type="text"
                        value={loginFullName}
                        onChange={(e) => {
                          if (!verifyLength(e.target.value, 1)) {
                            setloginFullNameState("has-danger");
                          } else {
                            setloginFullNameState("has-success");
                          }
                          setloginFullName(e.target.value);
                        }}
                      />
                      {loginFullNameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${loginEmailState}`}>
                      <label>Email Address *</label>
                      <Input
                        name="email"
                        type="email"
                        value={loginEmail}
                        onChange={(e) => {
                          if (!verifyEmail(e.target.value)) {
                            setloginEmailState("has-danger");
                          } else {
                            setloginEmailState("has-success");
                          }
                          setloginEmail(e.target.value);
                        }}
                      />
                      {loginEmailState === "has-danger" ? (
                        <label className="error">
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label`}>
                      <label>Enable/Disable 2 FA*</label>
                      <br/>
                      <Switch
                        defaultValue={loggedUser.data.status_2fa === 1 ? true : false}
                        offColor="success"
                        offText="OFF"
                        onColor="success"
                        onText="ON"
                        onChange={(e) => set2FAStatus(e.state.value)}
                      />
                    </FormGroup>

                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={editUser}>
                  Update Profile
                </Button>
              </CardFooter>
            </Card>

          </Col>

          <Col lg="6" md="6" sm="6">

            <Card className="card-stats">
              <CardHeader>
                <CardTitle tag="h4">Change Password</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" xs="12">
                    {passwordError === "" ? (
                      null
                    ) : <div className="text-center alert alert-danger">
                      {passwordError}
                    </div>}
                    {passwordSuccess === "" ? (
                      null
                    ) : <div className="text-center alert alert-success">
                      {passwordSuccess}
                    </div>}
                    <FormGroup className={`has-label ${oldPasswordState}`}>
                      <label>Old Password*</label>
                      <Input
                        name="old_password"
                        type="password"
                        onChange={(e) => {
                          if (!verifyLength(e.target.value, 1)) {
                            setoldPasswordState("has-danger");
                          } else {
                            setoldPasswordState("has-success");
                          }
                          setoldPassword(e.target.value);
                        }}
                      />
                      {oldPasswordState === "has-danger" ? (
                        <label className="error">
                          This field is required.
                        </label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${registerPasswordState}`}>
                      <label>Password *</label>
                      <Input
                        id="registerPassword"
                        name="password"
                        type="password"
                        autoComplete="off"
                        onChange={(e) => {
                          if (!verifyLength(e.target.value, 1)) {
                            setregisterPasswordState("has-danger");
                          } else {
                            setregisterPasswordState("has-success");
                          }
                          setregisterPassword(e.target.value);
                        }}
                      />
                      {registerPasswordState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup
                      className={`has-label ${registerConfirmPasswordState}`}
                    >
                      <label>Confirm Password *</label>
                      <Input
                        equalto="#registerPassword"
                        id="registerPasswordConfirmation"
                        name="password_confirmation"
                        type="password"
                        autoComplete="off"
                        onChange={(e) => {
                          if (!compare(e.target.value, registerPassword)) {
                            setregisterConfirmPasswordState("has-danger");
                            setregisterPasswordState("has-danger");
                          } else {
                            setregisterConfirmPasswordState("has-success");
                            setregisterPasswordState("has-success");
                          }
                          setregisterConfirmPassword(e.target.value);
                        }}
                      />
                      {registerConfirmPasswordState === "has-danger" ? (
                        <label className="error">Password Not Matched with new password</label>
                      ) : null}
                    </FormGroup>

                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={changePassword}>
                  Change Password
                </Button>
              </CardFooter>
            </Card>

          </Col>

        </Row>
      </div>
    </>
  );
}

export default EditProfile;
