/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import Opportunities from "components/Leads/Opportunities";

function AllLeads() {
  

  return (

    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">All Opportunities</CardTitle>
            </CardHeader>
            <CardBody>
              <Opportunities api="all-leads" leadType="all" method="GET" filterData=""></Opportunities>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default AllLeads;
