import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle
} from "reactstrap";
function Statistics(props) {
    return (
        <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                                <i className={`${props.color} ${props.icon}`} />
                            </div>
                        </Col>
                        <Col md="8" xs="7">
                            <div className="numbers">
                                <p className="card-category">{props.head}</p>
                                <CardTitle tag="p">{props.stat}</CardTitle>
                                <p />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <hr />
                    <div className="stats">
                    {props.foot}
                    </div>
                </CardFooter>
            </Card>
        </Col>


    );
}
export default Statistics