export const ADD_GROUP_OPPORTUNITIES_SUCCESS = "getRegisterSucess";
export const ADD_GROUP_OPPORTUNITIES_RESET = "getRegisterReset";
export const ADD_GROUP_OPPORTUNITIES_FAIELD = "getRegisterFaield";

export const GET_SINGLE_LEAD_DATA_SUCCESS = "getSingleLeadDataSucess";
export const GET_SINGLE_LEAD_DATA_RESET = "getSingleLeadDataReset";
export const GET_SINGLE_LEAD_DATA_FAIELD = "getSingleLeadDataFaield";

export const UPADTE_GROUP_DATA_SUCCESS = "getUpdateGroupDataSucess";
export const UPADTE_GROUP_DATA_RESET = "getUpdateGroupDataReset";
export const UPADTE_GROUP_DATA_FAIELD = "getUpdateGroupDataFaield";

export const GET_GROUP_PRODUCT_DATA_SUCCESS = "getGroupProductDataSucess";
export const GET_GROUP_PRODUCT_DATA_RESET = "getGroupProductDataReset";
export const GET_GROUP_PRODUCT_DATA_FAIELD = "getGroupProductDataFaield";

export const GET_ALL_OPTIONS_DATA_SUCCESS = "getGetAllOptionsDataSucess";
export const GET_ALL_OPTIONS_DATA_FAIELD = "getGetAllOptionsDataFaield";

export const POST_ADD_GROUP_PRODUCT_DATA_SUCCESS =
  "getAddGroupProductDataSucess";
export const POST_ADD_GROUP_PRODUCT_DATA_FAIELD =
  "getAddGroupProductDataFaield";
export const POST_ADD_GROUP_PRODUCT_DATA_RESET = "getAddGroupProductDataReset";

export const GET_SINGLE_GROUP_PRODUCT_DATA_SUCCESS =
  "getSingleGroupProductDataSuccess";
export const GET_SINGLE_GROUP_PRODUCT_DATA_FAIELD =
  "getSingleGroupProductDataFaield";
export const GET_SINGLE_GROUP_PRODUCT_DATA_RESET =
  "getSingleGroupProductDataReset";

export const GET_UPDATE_GROUP_PRODUCT_DATA_SUCCESS =
  "getUpdateGroupProductDataSuccess";
export const GET_UPDATE_GROUP_PRODUCT_DATA_FAIELD =
  "getUpdateGroupProductDataFaield";
export const GET_UPDATE_GROUP_PRODUCT_DATA_RESET =
  "getUpdateGroupProductDataReset";

export const GET_DELETE_ADDITIONAL_GROUP_DATA_SUCCESS =
  "getDeleteAdditionalGroupDataSuccess";
export const GET_DELETE_ADDITIONAL_GROUP_DATA_FAIELD =
  "getDeleteAdditionalGroupDataFaield";
export const GET_DELETE_ADDITIONAL_GROUP_DATA_RESET =
  "getDeleteAdditionalGroupDataReset";

export const GET_ADD_GROUP_PREMIUMM_DETAILS_SUCCESS =
  "getAddGroupPremiumDetailsDataSuccess";
export const GET_ADD_GROUP_PREMIUMM_DETAILS_FAIELD =
  "getAddGroupPremiumDetailsDataFaield";
export const GET_ADD_GROUP_PREMIUMM_DETAILS_RESET =
  "getAddGroupPremiumDetailsDataReset";

export const GET_DELETE_GROUP_PREMIUMM_DETAILS_SUCCESS =
  "getDeleteGroupPremiumDetailsDataSuccess";
export const GET_DELETE_GROUP_PREMIUMM_DETAILS_FAIELD =
  "getDeleteGroupPremiumDetailsDataFaield";
export const GET_DELETE_GROUP_PREMIUMM_DETAILS_RESET =
  "getDeleteGroupPremiumDetailsDataReset";

export const GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_SUCCESS =
  "getAllGroupPremiumDetailsDataSuccess";
export const GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_FAIELD =
  "getAllGroupPremiumDetailsDataFaield";
export const GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_RESET =
  "getAllGroupPremiumDetailsDataReset";

export const GET_ALL_GROUP_FETCH_ACTIVITY_SUCCESS = "getAllActivityDataSuccess";
export const GET_ALL_GROUP_FETCH_ACTIVITY_FAIELD = "getAllActivityDataFaield";
export const GET_ALL_GROUP_FETCH_ACTIVITY_RESET = "getAllActivityDataReset";

export const GET_ADD_GROUP_ACTIVITY_SUCCESS = "getAddGroupActivityDataSuccess";
export const GET_ADD_GROUP_ACTIVITY_FAIELD = "getAddGroupActivityDataFaield";
export const GET_ADD_GROUP_ACTIVITY_RESET = "getAddGroupActivityDataReset";

export const GET_ADD_GROUP_DOCUMENT_SUCCESS = "getAddGroupDocumentDataSuccess";
export const GET_ADD_GROUP_DOCUMENT_FAIELD = "getAddGroupDocumentDataFaield";
export const GET_ADD_GROUP_DOCUMENT_RESET = "getAddGroupDocumentDataReset";

export const GET_ALL_GROUP_FETCH_DOCUMENT_SUCCESS = "getAllDocumentDataSuccess";
export const GET_ALL_GROUP_FETCH_DOCUMENT_FAIELD = "getAllDocumentDataFaield";
export const GET_ALL_GROUP_FETCH_DOCUMENT_RESET = "getAllDocumentDataReset";

export const GET_DELETE_GROUP_DOCUMENT_SUCCESS =
  "getDeleteGroupDocumentDataSuccess";
export const GET_DELETE_GROUP_DOCUMENT_FAIELD =
  "getDeleteGroupDocumentDataFaield";
export const GET_DELETE_GROUP_DOCUMENT_RESET =
  "getDeleteGroupDocumentDataReset";

export const GET_DELETE_GROUP_OPPORTUNITY_SUCCESS =
  "getDeleteGroupOpportunityDataSuccess";
export const GET_DELETE_GROUP_OPPORTUNITY_FAIELD =
  "getDeleteGroupOpportunityDataFaield";
export const GET_DELETE_GROUP_OPPORTUNITY_RESET =
  "getDeleteGroupOpportunityDataReset";

export const GET_QUOTING_SUCCESS = "getQuotingDataSuccess";
export const GET_QUOTING_FAIELD = "getQuotingDataFaield";
export const GET_QUOTING_RESET = "getQuotingDataReset";

export const GET_ACCOUNT_SUCCESS = "getAccountDataSuccess";
export const GET_ACCOUNT_FAIELD = "getAccountDataFaield";
export const GET_ACCOUNT_RESET = "getAccountDataReset";

export const GET_OPPORTUNITY_SUCCESS = "getOpportunityDataSuccess";
export const GET_OPPORTUNITY_FAIELD = "getOpportunityDataFaield";
export const GET_OPPORTUNITY_RESET = "getOpportunityDataReset";

export const GET_STATUS_LOGS_SUCCESS = "getStatusLogsDataSuccess";
export const GET_STATUS_LOGS_FAIELD = "getStatusLogsDataFaield";
export const GET_STATUS_LOGS_RESET = "getStatusLogsDataReset";

export const GET_INDIVIDUAL_STATUS_LOGS_SUCCESS =
  "getIndividualStatusLogsDataSuccess";
export const GET_INDIVIDUAL_STATUS_LOGS_FAIELD =
  "getIndividualStatusLogsDataFaield";
export const GET_INDIVIDUAL_STATUS_LOGS_RESET =
  "getIndividualStatusLogsDataReset";

export const GET_ALL_PRODUCT_COUNT_SUCCESS = "geAllProductCountDataSuccess";
export const GET_ALL_PRODUCT_COUNT_FAIELD = "geAllProductCountDataFaield";
export const GET_ALL_PRODUCT_COUNT_RESET = "geAllProductCountDataReset";

export const GET_GROUP_SEARCH_SUCCESS = "getGroupSearchDataSuccess";
export const GET_GROUP_SEARCH_FAIELD = "getGroupSearchDataFaield";
export const GET_GROUP_SEARCH_RESET = "getGroupSearchDataReset";

export const GET_INDIVIDUAL_SEARCH_SUCCESS = "getIndividualSearchDataSuccess";
export const GET_INDIVIDUAL_SEARCH_FAIELD = "getIndividualSearchDataFaield";
export const GET_INDIVIDUAL_SEARCH_RESET = "getIndividualSearchDataReset";

export const GET_GROUP_MEMBERS_SUCCESS = "getGroupMembersDataSuccess";
export const GET_GROUP_MEMBERS_FAIELD = "getGroupMembersDataFaield";
export const GET_GROUP_MEMBERS_RESET = "getGroupMembersDataReset";



export const GET_PRODUCT_DELETE_SUCCESS = "getProductDeleteDataSuccess";
export const GET_PRODUCT_DELETE_FAIELD = "getProductDeleteDataFaield";
export const GET_PRODUCT_DELETE_RESET = "getProductDeleteDataReset";



export const GET_GROUP_PRODUCT_DELETE_SUCCESS = "getGroupProductDeleteDataSuccess";
export const GET_GROUP_PRODUCT_DELETE_FAIELD = "getGroupProductDeleteDataFaield";
export const GET_GROUP_PRODUCT_DELETE_RESET = "getGroupProductDeleteDataReset";