import React, { useRef, useEffect, useState } from "react";
import {Link, useParams, useHistory} from "react-router-dom";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

function EditTemplate() {
  const params = useParams();
  const history = useHistory();
  const notificationAlert = React.useRef();
  const [loadingData, setLoadingData] = React.useState(true);
  const [countryOption, setcountryOption] = useState([]);
  const [productList, setProductList] = useState([]);
  const [errors, setErrors] = useState({});
  const getCountryOptionsUrl = process.env.REACT_APP_API_URL + "/get-country-options";
  const getProductOptionsUrl = process.env.REACT_APP_API_URL + "/get-products-by-country";
  const getTemplateUrl = process.env.REACT_APP_API_URL+"/get-template/"+params.id;
  const editTemplateUrl = process.env.REACT_APP_API_URL+"/update-template";
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [formData, setFormData] = useState({
    template_id: params.id,
    name: '',
    description: '',
    location: '',
    selectedProducts: [],
  });

  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);

  useEffect(() => {
    async function getTemplate(){
      fetch(getTemplateUrl, {
        method: "GET",
        headers: headers,
      })
      .then(function(response){ 
        return response.json();   
      })
      .then(function(data){ 
        console.log(data);  
        if(!data.success){  
          
        }else{     
          let templateLoc = data.template.template_location;
          setSelectedLocation({value:templateLoc, label:templateLoc});
          setFormData({
            template_id: params.id,
            name: data.template.template_name,
            description: data.template.template_description,
            location: templateLoc,
            selectedProducts: data.selectedProducts.map((product) => ({id:product.product_id, is_favourite: product.is_favourite || false })) 
          });
          fetchProductsByCountry(templateLoc);
          setLoadingData(false);
        }
      });
      
    }
    async function getCountryOptions() {
      fetch(getCountryOptionsUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            console.log("country data..", data);
            setcountryOption(data.countries);
            setLoadingData(false);
          } else {
          }
        });
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getCountryOptions();
      getTemplate();
    }
  }, []);

  const fetchProductsByCountry = async (location) => {
    fetch(`${getProductOptionsUrl}?location=${location}`, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success) {
          console.log("product data..", data);
          setProductList(data.products);
          //setcountryOption(data.countries);
          setLoadingData(false);
        } else {
        }
      });
  };

  const handleInputChange = (e, type, name = "") => {
    console.log("event", e);
    if (name === 'location') {
      setFormData({ ...formData, location:e.value,selectedProducts: [] }); // Reset products selection
      fetchProductsByCountry(e.value);
      setSelectedLocation({value:e.value, label:e.value})
    }
    else{
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleProductSelection = (productId) => {
    const updatedProducts = formData.selectedProducts.find((product) => product.id === productId)
      ? formData.selectedProducts.filter((product) => product.id !== productId)
      : [...formData.selectedProducts, { id: productId, is_favourite: false }];
    setFormData({ ...formData, selectedProducts: updatedProducts });
  };

  const handleFavouriteChange = (productId) => {
    const updatedProducts = formData.selectedProducts.map((product) =>
      product.id === productId ? { ...product, is_favourite: !product.is_favourite } : product
    );
    setFormData({ ...formData, selectedProducts: updatedProducts });
  };

  const validateForm = () => {
    console.log("form data", formData);
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = 'Name is required';
    if (!formData.location) formErrors.location = 'Please select a location';
    if (formData.selectedProducts.length === 0) formErrors.selectedProducts = 'Please select at least one product';
    if (formData.selectedProducts.length > 7) formErrors.selectedProducts = 'You can not select more than 7 Products per template';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const editTemplate = (e) => {
    e.preventDefault();
    if (validateForm()) {
      fetch(editTemplateUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(formData)
      })
      .then(function(response){ 
        return response.json();   
      })
      .then(function(data){ 
        console.log(data);  
        if(!data.success){  
          notify(data.message,3);
        }else{          
          notify(data.message,2);
          setTimeout(function(){{history.push("/admin/all-templates");}},3000);
        }
      });
    }
  };
  
  const notify = (servermessage,color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b>{servermessage}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };
  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" lg="11" md="11" sm="11">
          {loadingData ? (
                    <p>Loading Please wait...</p>
                  ) : 
            <Card className="card-stats">
            <CardHeader>  
              <CardTitle tag="h4">Edit Template</CardTitle>
              <Link to="/admin/all-templates" className="btn btn-primary float-right">Back to List</Link>
            </CardHeader>
              <CardBody>
              <Row>
                  <Col md="6" xs="12">
                  <FormGroup className={`has-label ${errors.name?'has-danger':''}`}>
                    <label>Name *</label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      //onChange={handleInputChange}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "input",
                          "name"
                        )
                      }
                    />
                    {errors.name ? (
                      <label className="error">{errors.name}</label>
                    ) : null}
                  </FormGroup>
                  </Col>
                  <Col md="6" xs="12">
                  <FormGroup className={`has-label ${errors.location?'has-danger':''}`}>
                  <label>Location *</label>
                    <Select
                      id="location"
                      name="location"
                      value={selectedLocation}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      options={countryOption}
                      //placeholder="Select"
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "select",
                          "location"
                        )
                      }
                    />
                    {errors.location ? (
                      <label className="error">{errors.location}</label>
                    ) : null}
                  </FormGroup>
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12" xs="12">
                  <FormGroup>
                    <label>Description</label>
                    <Input
                      id="description"
                      name="description"
                      type="textarea"
                      value={formData.description}
                      //onChange={handleInputChange}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "input",
                          "description"
                        )
                      }
                      placeholder="Type your description here..."
                    />
                  </FormGroup>
                  </Col>                  
                </Row>
                {productList.length>0 && 
                <Row>
                  <Col md="12" xs="12">
                    <FormGroup>
                      <label>Select Products *</label>
                      {errors.selectedProducts && <label className="error">{errors.selectedProducts}</label>}
                      <table className="table">
                      <thead>
                        <tr>
                          <th width="10%">Select</th>
                          <th width="10%">Favorite</th>
                          <th width="15%">Insurer</th>
                          <th width="25%">Plan</th>
                          <th width="30%">Area of Cover</th>
                          <th width="10%">Deductible</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList.map((product) => {
                          const isSelected = formData.selectedProducts.find((p) => p.id === product.id);
                          return (
                          <tr key={product.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleProductSelection(product.id)}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={isSelected ? isSelected.is_favourite : false}
                                onChange={() => handleFavouriteChange(product.id)}
                                disabled={!isSelected} // Disable favorite checkbox if product is not selected
                              />
                            </td>
                            <td>{product.insurer_name}</td>
                            <td>{product.plan_name}</td>
                            <td>{product.area_of_cover}</td>
                            <td>{product.deductible_excess}</td>
                          </tr>
                        )})}
                      </tbody>
                    </table>
                    </FormGroup>
                  </Col>                  
                </Row>
              }
              </CardBody>
              <CardFooter>
              <Button color="primary" onClick={editTemplate}>
                   Save
                  </Button>
              </CardFooter>
            </Card>
            }
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditTemplate;
