import { TableSliceAction } from "./TableDataSlice";

export const getProducts = (apiPath, sCol, sDir, headers, advanceSearch) => {
  return async (dispatch, getState) => {
    let initSCol, initSDir;
    const {
      currentPage,
      sortColumn,
      sortDirection,
      ProdcutFilter,
      InsurerFilter,
      LeadFilter,
    } = getState().tableData;
    if (sortColumn === "") {
      initSCol = sCol;
    } else {
      initSCol = sortColumn;
    }
    if (sortDirection === "") {
      initSDir = sDir;
    } else {
      initSDir = sortDirection;
    }
    let filterParam;
    if (apiPath === "get-products") {
      filterParam = ProdcutFilter;
    } else if (apiPath === "list-insurer") {
      filterParam = InsurerFilter;
    } else if (apiPath === "search") {
      filterParam = advanceSearch;
    } else {
      filterParam = LeadFilter;
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        "/" +
        apiPath +
        "/" +
        currentPage +
        "/" +
        initSDir +
        "/" +
        initSCol,
      {
        method: "post",
        headers: headers,
        body: JSON.stringify(filterParam),
      }
    );
    const data = await response.json();
    console.log(data);
    //  if (data.success) {
    dispatch(
      TableSliceAction.renderTableData({
        isLoading: false,
        rowData: data.list,
        totalPages: data.totalPages,
        totalRecords: data.totalRecords,
        status: data?.status,
      })
    );
    //}
  };
};

export const getInsurer = (headers) => {
  return async (dispatch) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/get-insurer-and-doctype",
      {
        headers: headers,
      }
    );
    const data = await response.json();
    if (data.success) {
      dispatch(TableSliceAction.getInsurers(data.insurer_list));
    }
  };
};
