import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
// import ClientProducts from "components/Leads/ClientProducts.js";
import NotificationAlert from "react-notification-alert";
import ActivityList from "components/Leads/ActivityList";
import AddDoc from "components/Leads/AddDoc";
import { notify } from "components/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleGroupLeadDataAPI,
  UpdateGroupDataApi,
} from "store/AddGroupAction";
import GroupProduct from "components/GroupLeads/GroupProduct";
import GroupActivity from "components/GroupLeads/GroupActivity";
import AddGroupDocs from "components/GroupLeads/AddGroupDocs";
import { UPADTE_GROUP_DATA_RESET ,GET_SINGLE_LEAD_DATA_RESET} from "store/GroupLeadOpportunitiesAction";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
const GroupEditLead = (props) => {
  const notificationAlert = React.useRef();
  const [verticalTabs, setVerticalTabs] = React.useState("group-details");
  const [loadingData, setLoadingData] = React.useState(true);
  const [ClientTitle, setClientTitle] = useState("");
  const [ClientFirstName, setClientFirstName] = useState("");
  const [ClientLastName, setClientLastName] = useState("");
  const [BirthDay, setBirthDay] = useState("");
  const [BirthMonth, setBirthMonth] = useState("");
  const [BirthYear, setBirthYear] = useState("");
  const [FollowUpDay, setFollowUpDay] = useState("");
  const [FollowUpMonth, setFollowUpMonth] = useState("");
  const [FollowUpYear, setFollowUpYear] = useState("");
  const [ClientGender, setClientGender] = useState("");
  const [ClientNationality, setClientNationality] = useState("");
  const [DoNotCall, setDoNotCall] = useState("");
  const [PensionOpportunity, setPensionOpportunity] = useState("");
  const [PrimaryCC, setPrimaryCC] = useState("");
  const [PrimaryPhone, setPrimaryPhone] = useState("");
  const [SecondaryCC, setSecondaryCC] = useState("");
  const [SecondaryPhone, setSecondaryPhone] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [ClientOtherEmail, setClientOtherEmail] = useState("");
  const [ClientAddress, setClientAddress] = useState("");
  const [ClientLocation, setClientLocation] = useState("");

  ///group opportunity details state
  const [DefaultGroupLocation, setDefaultGroupLocation] = useState("");
  const [GroupAddress, setGroupAddress] = useState("");
  const [GroupName, setGroupName] = useState("");
  const [GroupPolicyNumber, setGroupPolicyNumber] = useState("");

  ///for group administrator ///
  // group admin state 1
  const [GroupAdminstratorFirstname_1, setGroupAdminstratorFirstname_1] =
    useState("");
  const [GroupAdminstratorLastname_1, setGroupAdminstratorLastname_1] =
    useState("");
  const [GroupAdminstratorCountrycode_1, setGroupAdminstratorCountrycode_1] =
    useState("");
  const [GroupAdminstratorPhonenumber_1, setGroupAdminstratorPhonenumber_1] =
    useState("");
  const [GroupAdminstratorEmail_1, setGroupAdminstratorEmail_1] = useState("");

  // group admin state 2

  const [GroupAdminstratorFirstname_2, setGroupAdminstratorFirstname_2] =
    useState("");
  const [GroupAdminstratorLastname_2, setGroupAdminstratorLastname_2] =
    useState("");
  const [GroupAdminstratorCountrycode_2, setGroupAdminstratorCountrycode_2] =
    useState("");
  const [GroupAdminstratorPhonenumber_2, setGroupAdminstratorPhonenumber_2] =
    useState("");
  const [GroupAdminstratorEmail_2, setGroupAdminstratorEmail_2] = useState("");

  // group admin state 3

  const [GroupAdminstratorFirstname_3, setGroupAdminstratorFirstname_3] =
    useState("");
  const [GroupAdminstratorLastname_3, setGroupAdminstratorLastname_3] =
    useState("");
  const [GroupAdminstratorCountrycode_3, setGroupAdminstratorCountrycode_3] =
    useState("");
  const [GroupAdminstratorPhonenumber_3, setGroupAdminstratorPhonenumber_3] =
    useState("");
  const [GroupAdminstratorEmail_3, setGroupAdminstratorEmail_3] = useState("");

  // group admin state 4

  const [GroupAdminstratorFirstname_4, setGroupAdminstratorFirstname_4] =
    useState("");
  const [GroupAdminstratorLastname_4, setGroupAdminstratorLastname_4] =
    useState("");
  const [GroupAdminstratorCountrycode_4, setGroupAdminstratorCountrycode_4] =
    useState("");
  const [GroupAdminstratorPhonenumber_4, setGroupAdminstratorPhonenumber_4] =
    useState("");
  const [GroupAdminstratorEmail_4, setGroupAdminstratorEmail_4] = useState("");

  // group admin state 5

  const [GroupAdminstratorFirstname_5, setGroupAdminstratorFirstname_5] =
    useState("");
  const [GroupAdminstratorLastname_5, setGroupAdminstratorLastname_5] =
    useState("");
  const [GroupAdminstratorCountrycode_5, setGroupAdminstratorCountrycode_5] =
    useState("");
  const [GroupAdminstratorPhonenumber_5, setGroupAdminstratorPhonenumber_5] =
    useState("");
  const [GroupAdminstratorEmail_5, setGroupAdminstratorEmail_5] = useState("");

  const [GroupId, setGroupId] = useState([]);

  const [countryOptions, setcountryOptions] = useState([
    { value: "", label: "Country", isDisabled: true },
  ]);
  const [dialCodeOptions, setdialCodeOptions] = useState();
  const [PremiumType, setPremiumType] = useState("");
  const [PremiumTypeState, setPremiumTypeState] = useState("");
  const [fromDashboard, setfromDashboard] = useState(false);
  const params = useParams();
  const getGroupLeadUrl =
    process.env.REACT_APP_API_URL + "/get-lead/" + params.id;
  const updateClientUrl = process.env.REACT_APP_API_URL + "/update-client/";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));

  const { opportunitiesStatus } = props.location;
  useEffect(() => {
    if (opportunitiesStatus) {
      localStorage.setItem("opportunities_Status", opportunitiesStatus);
    }
  }, [opportunitiesStatus]);

  // var opportunities_Status_value = localStorage.getItem("opportunities_Status");

  // const titleOptions = [
  //   {
  //     value: "",
  //     label: "Title",
  //     isDisabled: true,
  //   },
  //   { value: "Mr", label: "Mr" },
  //   { value: "Mrs", label: "Mrs" },
  //   { value: "Miss", label: "Miss" },
  //   { value: "Ms", label: "Ms" },
  // ];
  // const genderOptions = [
  //   { value: "", label: "Gender", isDisabled: true },
  //   { value: "male", label: "Male" },
  //   { value: "female", label: "female" },
  // ];

  // let dayOptions = [];
  // let monthOptions = [];
  // let yearOptions = [];

  // let followYearOptions = [];
  // const date = new Date();

  // for (let index = 1; index <= 31; index++) {
  //   dayOptions.push({ value: index, label: index });
  // }
  // for (let index = 1; index <= 12; index++) {
  //   monthOptions.push({ value: index, label: index });
  // }
  // for (let index = date.getFullYear(); index >= 1901; index--) {
  //   yearOptions.push({ value: index, label: index });
  // }
  // for (
  //   let index = date.getFullYear();
  //   index <= date.getFullYear() + 10;
  //   index++
  // ) {
  //   followYearOptions.push({ value: index, label: index });
  // }
  if (fromDashboard) {
    notify("Opportunity created Successfully.", 2, notificationAlert);
  }
  const dispatch = useDispatch();
  const { GetSingleLeadData } = useSelector(
    (state) => state?.getSingleLeadDataRe
  );
  const { UpdateGroupDataSuccess } = useSelector(
    (state) => state?.getUpdateGroupDataRe
  );
  // console.log("UpdateGroupDataSuccess", UpdateGroupDataSuccess);
  useEffect(() => {
    const getGroupLeadData = async () => {
      let hittingUrl = "get-group-lead/" + params.id;
      await dispatch(getSingleGroupLeadDataAPI(hittingUrl, loggedUser.token));
    };
    getGroupLeadData();
  }, []);

  useEffect(() => {
    if (UpdateGroupDataSuccess?.data?.success == true) {
      notify(UpdateGroupDataSuccess?.data?.message, 2, notificationAlert);
      dispatch({ type: UPADTE_GROUP_DATA_RESET, payload: { data: "" } });
    }
    if (UpdateGroupDataSuccess?.data?.success == false) {
      notify(UpdateGroupDataSuccess?.data?.message, 3, notificationAlert);
      dispatch({ type: UPADTE_GROUP_DATA_RESET, payload: { data: "" } });
    }
  }, [UpdateGroupDataSuccess]);

  useEffect(() => {
    if (GetSingleLeadData?.data) {
      if (GetSingleLeadData?.data?.success == true) {
        setcountryOptions(GetSingleLeadData?.data?.countries);
        setDefaultGroupLocation({
          value: GetSingleLeadData?.data?.group?.group_location,
          label: GetSingleLeadData?.data?.group?.group_location,
        });
        setGroupAddress(GetSingleLeadData?.data?.group?.group_address);
        setGroupName(GetSingleLeadData?.data?.group?.group_name);
        setGroupPolicyNumber(GetSingleLeadData?.data?.group["policy-number"]);
        setGroupAdminstratorFirstname_1(
          GetSingleLeadData?.data?.group["group_administrator1_firstname"]
        );
        setGroupAdminstratorLastname_1(
          GetSingleLeadData?.data?.group["group_administrator1_lastname"]
        );
        setGroupAdminstratorCountrycode_1(
          GetSingleLeadData?.data?.group["group_administrator1_country_code"]
        );
        setGroupAdminstratorPhonenumber_1(
          GetSingleLeadData?.data?.group["group_administrator1_phone_number"]
        );
        setGroupAdminstratorEmail_1(
          GetSingleLeadData?.data?.group["group_administrator1_email"]
        );

        setGroupAdminstratorFirstname_2(
          GetSingleLeadData?.data?.group["group_administrator2_firstname"]
        );
        setGroupAdminstratorLastname_2(
          GetSingleLeadData?.data?.group["group_administrator2_lastname"]
        );
        setGroupAdminstratorCountrycode_2(
          GetSingleLeadData?.data?.group["group_administrator2_country_code"]
        );
        setGroupAdminstratorPhonenumber_2(
          GetSingleLeadData?.data?.group["group_administrator2_phone_number"]
        );
        setGroupAdminstratorEmail_2(
          GetSingleLeadData?.data?.group["group_administrator2_email"]
        );

        setGroupAdminstratorFirstname_3(
          GetSingleLeadData?.data?.group["group_administrator3_firstname"]
        );
        setGroupAdminstratorLastname_3(
          GetSingleLeadData?.data?.group["group_administrator3_lastname"]
        );
        setGroupAdminstratorCountrycode_3(
          GetSingleLeadData?.data?.group["group_administrator3_country_code"]
        );
        setGroupAdminstratorPhonenumber_3(
          GetSingleLeadData?.data?.group["group_administrator3_phone_number"]
        );
        setGroupAdminstratorEmail_3(
          GetSingleLeadData?.data?.group["group_administrator3_email"]
        );

        setGroupAdminstratorFirstname_4(
          GetSingleLeadData?.data?.group["group_administrator4_firstname"]
        );
        setGroupAdminstratorLastname_4(
          GetSingleLeadData?.data?.group["group_administrator4_lastname"]
        );
        setGroupAdminstratorCountrycode_4(
          GetSingleLeadData?.data?.group["group_administrator4_country_code"]
        );
        setGroupAdminstratorPhonenumber_4(
          GetSingleLeadData?.data?.group["group_administrator4_phone_number"]
        );
        setGroupAdminstratorEmail_4(
          GetSingleLeadData?.data?.group["group_administrator4_email"]
        );

        setGroupAdminstratorFirstname_5(
          GetSingleLeadData?.data?.group["group_administrator5_firstname"]
        );
        setGroupAdminstratorLastname_5(
          GetSingleLeadData?.data?.group["group_administrator5_lastname"]
        );
        setGroupAdminstratorCountrycode_5(
          GetSingleLeadData?.data?.group["group_administrator5_country_code"]
        );
        setGroupAdminstratorPhonenumber_5(
          GetSingleLeadData?.data?.group["group_administrator5_phone_number"]
        );
        setGroupAdminstratorEmail_5(
          GetSingleLeadData?.data?.group["group_administrator5_email"]
        );
        setGroupId(GetSingleLeadData?.data?.group["id"]);
        //option state
        setdialCodeOptions(GetSingleLeadData?.data?.dial_codes);
        setLoadingData(false);

        // dispatch({ type: GET_SINGLE_LEAD_DATA_RESET, payload: { data: "" } });
        
      } else {
        setLoadingData(true);
      }
    } else {
      setLoadingData(true);
    }
  }, [GetSingleLeadData]);

  const updateClient = async () => {
    var UpdateGroupData = {
      GroupName: GroupName,
      GroupAddress: GroupAddress,
      GroupPolicyNumber: GroupPolicyNumber,
      GroupLocation: DefaultGroupLocation.value,
      GroupAdminstratorFirstname_1: GroupAdminstratorFirstname_1,
      GroupAdminstratorLastname_1: GroupAdminstratorLastname_1,
      GroupAdminstratorCountrycode_1: GroupAdminstratorCountrycode_1,
      GroupAdminstratorPhonenumber_1: GroupAdminstratorPhonenumber_1,
      GroupAdminstratorEmail_1: GroupAdminstratorEmail_1,

      GroupAdminstratorFirstname_2: GroupAdminstratorFirstname_2,
      GroupAdminstratorLastname_2: GroupAdminstratorLastname_2,
      GroupAdminstratorCountrycode_2: GroupAdminstratorCountrycode_2,
      GroupAdminstratorPhonenumber_2: GroupAdminstratorPhonenumber_2,
      GroupAdminstratorEmail_2: GroupAdminstratorEmail_2,

      GroupAdminstratorFirstname_3: GroupAdminstratorFirstname_3,
      GroupAdminstratorLastname_3: GroupAdminstratorLastname_3,
      GroupAdminstratorCountrycode_3: GroupAdminstratorCountrycode_3,
      GroupAdminstratorPhonenumber_3: GroupAdminstratorPhonenumber_3,
      GroupAdminstratorEmail_3: GroupAdminstratorEmail_3,

      GroupAdminstratorFirstname_4: GroupAdminstratorFirstname_4,
      GroupAdminstratorLastname_4: GroupAdminstratorLastname_4,
      GroupAdminstratorCountrycode_4: GroupAdminstratorCountrycode_4,
      GroupAdminstratorPhonenumber_4: GroupAdminstratorPhonenumber_4,
      GroupAdminstratorEmail_4: GroupAdminstratorEmail_4,

      GroupAdminstratorFirstname_5: GroupAdminstratorFirstname_5,
      GroupAdminstratorLastname_5: GroupAdminstratorLastname_5,
      GroupAdminstratorCountrycode_5: GroupAdminstratorCountrycode_5,
      GroupAdminstratorPhonenumber_5: GroupAdminstratorPhonenumber_5,
      GroupAdminstratorEmail_5: GroupAdminstratorEmail_5,
      group_id: params.id,
    };

    await dispatch(UpdateGroupDataApi(UpdateGroupData, loggedUser.token));
  };

  // const verifyEmail = (value) => {
  //   var emailRex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (emailRex.test(value)) {
  //     return true;
  //   }
  //   return false;
  // };
  // const verifyLength = (value, fieldState) => {
  //   if (value.length >= 1) {
  //     fieldState("has-success");
  //   } else {
  //     fieldState("has-danger");
  //   }
  // };

  const getDefaultDialCode = (defValue, dialCodeOtionArray) => {
    if (defValue && dialCodeOptions) {
      return dialCodeOtionArray.filter((item) => item.value === defValue);
    }
  };

  const getDefaultCountryCode = (defCountryVal, countryCodeOtionArray) => {
    if ((defCountryVal, countryCodeOtionArray)) {
      return countryCodeOtionArray.filter(
        (item) => item.value === defCountryVal.value
      );
    }
  };
  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <Card>
                <CardHeader>
                  <h5></h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="2" md="5" sm="4" xs="6">
                      <div className="nav-tabs-navigation verical-navs p-0">
                        <div className="nav-tabs-wrapper">
                          <Nav
                            className="flex-column nav-stacked"
                            role="tablist"
                            tabs
                          >
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "group-details"
                                    ? "active"
                                    : ""
                                }
                                onClick={() => setVerticalTabs("group-details")}
                              >
                                Group Details
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "group-products"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  setVerticalTabs("group-products")
                                }
                              >
                                Products
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "group-activity"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  setVerticalTabs("group-activity")
                                }
                              >
                                Activities
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "group-documents"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  setVerticalTabs("group-documents")
                                }
                              >
                                Documents
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>
                    </Col>
                    <Col lg="10" md="7" sm="8" xs="6">
                      {/* Tab panes */}
                      <TabContent activeTab={verticalTabs}>
                        <TabPane tabId="group-details">
                          <Row>
                            <Col className="ml-auto mr-auto" lg="6">
                              <Row>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Group Name</label>
                                    <Input
                                      type="text"
                                      value={GroupName || ""}
                                      placeholder="Group Name"
                                      onChange={(e) => {
                                        setGroupName(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Policy Number</label>
                                    <Input
                                      type="text"
                                      value={GroupPolicyNumber || ""}
                                      placeholder="Policy Numbe"
                                      onChange={(e) => {
                                        setGroupPolicyNumber(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>

                            <Col className="ml-auto mr-auto" lg="6">
                              <Row>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Address</label>
                                    <Input
                                      type="text"
                                      value={GroupAddress || ""}
                                      onChange={(e) => {
                                        setGroupAddress(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col className="ml-auto mr-auto" lg="12">
                                  <label>Location</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={getDefaultCountryCode(
                                      DefaultGroupLocation,
                                      countryOptions
                                    )}
                                    onChange={(e) => {
                                      setDefaultGroupLocation(e);
                                    }}
                                    options={countryOptions}
                                    placeholder="Select Nationality"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            {/* group admin 1 */}
                            <div style={{ display: "flex" , marginLeft:"0.3%" , marginRight: "20%" }}>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Group Administrator 1{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="First Name"
                                    value={GroupAdminstratorFirstname_1}
                                    onChange={(e) => {
                                      setGroupAdminstratorFirstname_1(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label`}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Last Name"
                                    value={GroupAdminstratorLastname_1}
                                    onChange={(e) => {
                                      setGroupAdminstratorLastname_1(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    {" "}
                                    Contact Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={getDefaultDialCode(
                                      GroupAdminstratorCountrycode_1,
                                      dialCodeOptions
                                    )}
                                    placeholder="Select Dialing code"
                                    options={dialCodeOptions}
                                    onChange={(e) => {
                                      setGroupAdminstratorCountrycode_1(
                                        e.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="number"
                                    value={GroupAdminstratorPhonenumber_1}
                                    onChange={(e) => {
                                      setGroupAdminstratorPhonenumber_1(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    value={GroupAdminstratorEmail_1}
                                    onChange={(e) => {
                                      setGroupAdminstratorEmail_1(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </div>

                            {/* group admin 2 */}
                            <div style={{ display: "flex" , marginLeft:"0.3%" , marginRight: "20%" }}>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Group Administrator 2{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="First Name"
                                    value={GroupAdminstratorFirstname_2}
                                    onChange={(e) => {
                                      setGroupAdminstratorFirstname_2(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label`}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Last Name"
                                    value={GroupAdminstratorLastname_2}
                                    onChange={(e) => {
                                      setGroupAdminstratorLastname_2(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    {" "}
                                    Contact Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={getDefaultDialCode(
                                      GroupAdminstratorCountrycode_2,
                                      dialCodeOptions
                                    )}
                                    placeholder="Select Dialing code"
                                    options={dialCodeOptions}
                                    onChange={(e) => {
                                      setGroupAdminstratorCountrycode_2(
                                        e.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="number"
                                    value={GroupAdminstratorPhonenumber_2}
                                    onChange={(e) => {
                                      setGroupAdminstratorPhonenumber_2(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    value={GroupAdminstratorEmail_2}
                                    onChange={(e) => {
                                      setGroupAdminstratorEmail_2(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </div>

                            {/* group admin 3*/}
                            <div style={{ display: "flex" , marginLeft:"0.3%" , marginRight: "20%" }}>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Group Administrator 3{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="First Name"
                                    value={GroupAdminstratorFirstname_3}
                                    onChange={(e) => {
                                      setGroupAdminstratorFirstname_3(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label`}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Last Name"
                                    value={GroupAdminstratorLastname_3}
                                    onChange={(e) => {
                                      setGroupAdminstratorLastname_3(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    {" "}
                                    Contact Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={getDefaultDialCode(
                                      GroupAdminstratorCountrycode_3,
                                      dialCodeOptions
                                    )}
                                    placeholder="Select Dialing code"
                                    options={dialCodeOptions}
                                    onChange={(e) => {
                                      setGroupAdminstratorCountrycode_3(
                                        e.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="number"
                                    value={GroupAdminstratorPhonenumber_3}
                                    onChange={(e) => {
                                      setGroupAdminstratorPhonenumber_3(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    value={GroupAdminstratorEmail_3}
                                    onChange={(e) => {
                                      setGroupAdminstratorEmail_3(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </div>

                            {/* group admin 4*/}
                            <div style={{ display: "flex" , marginLeft:"0.3%" , marginRight: "20%" }}>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Group Administrator 4{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="First Name"
                                    value={GroupAdminstratorFirstname_4}
                                    onChange={(e) => {
                                      setGroupAdminstratorFirstname_4(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label`}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Last Name"
                                    value={GroupAdminstratorLastname_4}
                                    onChange={(e) => {
                                      setGroupAdminstratorLastname_4(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    {" "}
                                    Contact Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={getDefaultDialCode(
                                      GroupAdminstratorCountrycode_4,
                                      dialCodeOptions
                                    )}
                                    placeholder="Select Dialing code"
                                    options={dialCodeOptions}
                                    onChange={(e) => {
                                      setGroupAdminstratorCountrycode_4(
                                        e.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="number"
                                    value={GroupAdminstratorPhonenumber_4}
                                    onChange={(e) => {
                                      setGroupAdminstratorPhonenumber_4(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    value={GroupAdminstratorEmail_4}
                                    onChange={(e) => {
                                      setGroupAdminstratorEmail_4(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </div>

                            {/* group admin 5 */}
                            <div style={{ display: "flex" , marginLeft:"0.3%" , marginRight: "20%" }}>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Group Administrator 5{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="First Name"
                                    value={GroupAdminstratorFirstname_5}
                                    onChange={(e) => {
                                      setGroupAdminstratorFirstname_5(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label`}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Last Name"
                                    value={GroupAdminstratorLastname_5}
                                    onChange={(e) => {
                                      setGroupAdminstratorLastname_5(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    {" "}
                                    Contact Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={getDefaultDialCode(
                                      GroupAdminstratorCountrycode_5,
                                      dialCodeOptions
                                    )}
                                    placeholder="Select Dialing code"
                                    options={dialCodeOptions}
                                    onChange={(e) => {
                                      setGroupAdminstratorCountrycode_5(
                                        e.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    <br></br>{" "}
                                  </label>
                                  <Input
                                    type="number"
                                    value={GroupAdminstratorPhonenumber_5}
                                    onChange={(e) => {
                                      setGroupAdminstratorPhonenumber_5(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className={`has-label `}>
                                  <label>
                                    Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Input
                                    type="text"
                                    value={GroupAdminstratorEmail_5}
                                    onChange={(e) => {
                                      setGroupAdminstratorEmail_5(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </div>
                            <Col className="ml-auto mr-auto" lg="12">
                              <Button onClick={updateClient}>Save</Button>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="group-products">
                          <GroupProduct group_id={GroupId}></GroupProduct>
                        </TabPane>

                        <TabPane tabId="group-activity">
                          <GroupActivity group_id={GroupId}></GroupActivity>
                        </TabPane>

                        <TabPane tabId="group-documents">
                          <AddGroupDocs group_id={GroupId}></AddGroupDocs>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GroupEditLead;
