import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import Opportunities from "components/Leads/Opportunities";
import GroupOpportunities from 'components/GroupLeads/GroupOpportunities';
const GroupAllLeads = () => {
    return (

        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">All Group Opportunities</CardTitle>
                </CardHeader>
                <CardBody>
                  <GroupOpportunities api="all-group-leads" leadType="all" method="GET" filterData=""></GroupOpportunities>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    
}

export default GroupAllLeads