import React from "react";
import mime from 'mime-types';
const notify = (servermessage, color, notificationAlert) => {
  var type;
  switch (color) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      break;
  }
  var options = {};
  options = {
    place: "tr",
    message: (
      <div>
        <div>
          <b>{servermessage}</b>
        </div>
      </div>
    ),
    type: type,
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 7,
  };
  notificationAlert.current.notificationAlert(options);
};

const sortList = (n) => {
  var list,
    i,
    switching,
    b,
    shouldSwitch,
    dir,
    switchcount = 0;
  list = document.getElementById("id01");
  switching = true;
  // Set the sorting direction to ascending:
  dir = "asc";

  // Make a loop that will continue until no switching has been done:
  while (switching) {
    // start by saying: no switching is done:
    switching = false;
    b = list.getElementsByTagName("LI");
    // Loop through all list-items:
    for (i = 1; i < b.length - 1; i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /* check if the next item should switch place with the current item,
            based on the sorting direction (asc or desc): */
      //console.log(Number(b[i].getElementsByClassName('newdata')[i].innerHTML.toLowerCase()));
      if (dir == "asc") {
        if (n === "premium") {
          if (
            Number(b[i].getElementsByClassName("premium")[0].dataset.val) >
            Number(b[i + 1].getElementsByClassName("premium")[0].dataset.val)
          ) {
            /* if next item is alphabetically lower than current item,
                        mark as a switch and break the loop: */
            shouldSwitch = true;
            break;
          }
        } else if (n === "annual_premium") {
          if (
            Number(b[i].getElementsByClassName("premium")[0].dataset.val1) >
            Number(b[i + 1].getElementsByClassName("premium")[0].dataset.val1)
          ) {
            /* if next item is alphabetically lower than current item,
                        mark as a switch and break the loop: */
            shouldSwitch = true;
            break;
          }
        } else {
          if (
            Number(b[i].getElementsByClassName("ratings")[0].dataset.val) >
            Number(b[i + 1].getElementsByClassName("ratings")[0].dataset.val)
          ) {
            /* if next item is alphabetically lower than current item,
                        mark as a switch and break the loop: */
            shouldSwitch = true;
            break;
          }
        }
      } else if (dir == "desc") {
        if (n === "premium") {
          if (
            Number(b[i].getElementsByClassName("premium")[0].dataset.val) <
            Number(b[i + 1].getElementsByClassName("premium")[0].dataset.val)
          ) {
            /* if next item is alphabetically higher than current item,
                        mark as a switch and break the loop: */
            shouldSwitch = true;
            break;
          }
        } else if (n === "annual_premium") {
          if (
            Number(b[i].getElementsByClassName("premium")[0].dataset.val1) <
            Number(b[i + 1].getElementsByClassName("premium")[0].dataset.val1)
          ) {
            /* if next item is alphabetically higher than current item,
                        mark as a switch and break the loop: */
            shouldSwitch = true;
            break;
          }
        } else {
          if (
            Number(b[i].getElementsByClassName("ratings")[0].dataset.val) <
            Number(b[i + 1].getElementsByClassName("ratings")[0].dataset.val)
          ) {
            /* if next item is alphabetically higher than current item,
                        mark as a switch and break the loop: */
            shouldSwitch = true;
            break;
          }
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
            and mark that a switch has been done: */
      b[i].parentNode.insertBefore(b[i + 1], b[i]);
      switching = true;
      // Each time a switch is done, increase switchcount by 1:
      switchcount++;
    } else {
      /* If no switching has been done AND the direction is "asc",
            set the direction to "desc" and run the while loop again. */
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
};

// Commented By Payal

// const download = (url, headers, fileName ,type) => {
//     fetch(url, {
//         method: 'GET',
//         headers: headers,
//     })
//         .then((response) => response.blob()
//         )
//         .then((blob) => {
//             console.log(blob);
//             // Create blob link to download
//             const url = window.URL.createObjectURL(
//                 new Blob([blob]),
//             );
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute(
//                 'download',
//                 fileName,
//             );

//             // Append to html link element page
//             document.body.appendChild(link);

//             // Start download
//             link.click();

//             // Clean up and remove the link
//             link.parentNode.removeChild(link);
//         });

// }

// Added By Payal

const download = (url, headers, fileName, type) => {
  fetch(url, {
    method: "GET",
    headers: headers,
  })  
  //   .then(function (response) {
  //   return response.json();
  // })
  // .then(function (data) {
  //  console.log("data" , data);
  // })
    .then((response) => response.blob())

    .then((blob) => {
      console.log("blob" , blob);
      const documentMimeType = mime.lookup(fileName) || 'application/pdf';
      //console.log(documentMimeType);
      // if(blob?.type == "text/html"){
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: documentMimeType })
        );
        const link = document.createElement("a");
        link.href = url;
  
        if (type == "download") {
          link.setAttribute("download", fileName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
        } else if (type == "newTab") {
          window.open(link);
        }
  
        link.parentNode.removeChild(link);
      // }

    })
    .catch((error) => {
      console.log("error", error);
    });
};

const drawCanvas = (percent, data) => {
  let colorCode;
  if ((percent) => 90) {
    colorCode = "#3fa9f5";
  }
  if (percent > 80 && percent < 90) {
    colorCode = "#4fdf4f";
  }
  if (percent > 50 && percent < 80) {
    colorCode = "#ffff66";
  }

  if (percent < 50) {
    colorCode = "#f90606";
  }
  let options = {
    data: {
      labels: [1, 2],
      datasets: [
        {
          label: "Customer Rating",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: [colorCode, "#f4f3ef"],
          borderWidth: 0,
          barPercentage: 1.6,
          data: [percent, 100 - percent],
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        title: {
          display: true,
          text: percent + "%",
          position: "bottom",
          color: "#66615c",
          font: {
            weight: 400,
            size: 10,
          },
        },
      },
      maintainAspectRatio: false,
      cutout: "70%",
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
  if (data === "data") {
    return options.data;
  } else {
    return options.options;
  }
};
export { notify, sortList, drawCanvas, download };
