import React, { useEffect, useState } from "react";
import EditProducts from "components/Leads/EditProducts.js";
// import AddProducts from "components/Leads/AddProducts";
import { Col, Row, Table, Modal, Button } from "reactstrap";
import PremiumDetails from "components/Leads/PremiumDetails";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { FetchGroupProductApi } from "store/AddGroupAction";
import AddGroupProducts from "./AddGroupProducts";
import EditGroupProduct from "./EditGroupProduct";
import GroupPremiumDetails from "./GroupPremiumDetails";
import { GET_GROUP_PRODUCT_DATA_RESET } from "store/GroupLeadOpportunitiesAction";
import { DeleteGroupProductsApi } from "store/AddGroupAction";
import { GET_GROUP_PRODUCT_DELETE_RESET } from "store/GroupLeadOpportunitiesAction";
const GroupProduct = (props) => {


  const { GroupProductDeleteDataSuccess } = useSelector(
    (state) => state.getGroupProductDeleteDataRe
  );
  // const [productObj, setproductObj] = React.useState([]);
  // const getProductUrl =
  //   process.env.REACT_APP_API_URL + "/get-products/" + props.client_id;
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [loadingData, setLoadingData] = React.useState(true);
  const [editModal, seteditModal] = React.useState(false);
  const [addModal, setaddModal] = React.useState(false);
  const [PremiumModal, setPremiumModal] = React.useState(false);
  const [clikedProduct, setclikedProduct] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [GroupProductObj, setGroupProductObj] = React.useState([]);
  const toggleEditModal = () => {
    seteditModal(!editModal);
    setLoadingData(true);
  };
  const toggleAddModal = () => {
    setaddModal(!addModal);
    setLoadingData(true);
  };
  const togglePremiumModal = () => {
    setPremiumModal(!PremiumModal);
    setLoadingData(true);
  };
  const dispatch = useDispatch();

  const { GroupProductData } = useSelector(
    (state) => state?.getGroupProductDaraRe
  );
  useEffect(() => {
    console.log("1111")
    const getProducts = async () => {
      await dispatch(FetchGroupProductApi(props.group_id, loggedUser.token));
    };
    getProducts();
    setLoadingData(false);
    console.log("2222")

    if(GroupProductDeleteDataSuccess?.data?.success){
      console.log("3333")
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert("no")}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {GroupProductDeleteDataSuccess?.data?.message}
        </ReactBSAlert>
    
      );
      dispatch({type:GET_GROUP_PRODUCT_DELETE_RESET , payload:{data:""}})
      console.log("4444")
      getProducts();
      setLoadingData(false);
    }else{
      // notify("Something Wrong", 3, notificationAlert);
    }

  }, [loadingData , GroupProductDeleteDataSuccess]);

  useEffect(() => {
    if (GroupProductData?.data?.success == true) {
      dispatch({type:GET_GROUP_PRODUCT_DATA_RESET , payload:{data:""}})
      setLoadingData(false);
      setGroupProductObj(GroupProductData?.data?.products);
    }
    if (GroupProductData?.data?.success == false) {
      setGroupProductObj(GroupProductData?.data?.products)
      setLoadingData(false);
    }
  }, [GroupProductData]);



  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };

  const successDelete = async (productId) => {
    console.log("sjghdgfshdgfsghdfgsdhgfshfjgsd" ,productId)
    if(productId){
      await dispatch(DeleteGroupProductsApi(loggedUser?.token, productId));

    }
  };

  const deleteProduct = (productId) => {
    console.log("delteProductId", productId)
      console.log("working in deleteproduct");
      // setDelteProductId(productId);
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete(productId)}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this!
        </ReactBSAlert>
      );
    // } else {
    //   notify("Please select opportunity.", 3, notificationAlert);
    // }
  };

console.log("loadingData", loadingData)
  return (
   
    <>
    
    {alert}
    <Row>
      {loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <Col md="12">
          {loggedUser.data.role === 1 ||
          loggedUser.data.role === 2 ||
          loggedUser.data.role === 3 ||
          loggedUser.data.role === 5 ||
          loggedUser.data.role === 7 ||

          loggedUser.data.role === 6 ? (
            <Button
              onClick={(e) => {
                toggleAddModal();
              }}
              color="primary"
            >
              Add Product
            </Button>
          ) : null}
          <div className="table-responsive">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Status</th>
                  <th>Consultant</th>
                  <th>Insurer</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                  {/* <th>Premiums</th> */}
                </tr>
              </thead>
              <tbody>
                {GroupProductObj?.map((element, index) => (
                  <tr key={index}>
                    <td>{GroupProductObj[index]?.product}</td>
                    <td>{GroupProductObj[index]?.status}</td>
                    <td>{GroupProductObj[index]?.name}</td>
                    <td>{GroupProductObj[index]?.title}</td>
                    <td>{GroupProductObj[index]?.["start-date"]}</td>
                    <td>{GroupProductObj[index]?.["end-date"]}</td>

                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={(e) => {
                          toggleEditModal();
                          setclikedProduct(GroupProductObj[index]["id"]);
                        }}
                      >
                        View
                      </Button>

                      <Button
                        color="danger"
                        size="sm"
                        onClick={(e) => {
                    
                          deleteProduct(GroupProductObj[index]["id"]);
                          
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                    {/* {process.env.REACT_APP_ENVIROMENT === "development" ? (
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={(e) => {
                            togglePremiumModal();
                            setclikedProduct(GroupProductObj[index]["id"]);
                          }}
                        >
                          Premiums
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={(e) => {
                            togglePremiumModal();
                            setclikedProduct(GroupProductObj[index]["id"]);
                          }}
                        >
                          Premiums
                        </Button>
                      </td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      )}
      <Modal size="large" isOpen={editModal} toggle={toggleEditModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleEditModal}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Edit Product</h4>
        </div>
        <div className="modal-body">
          <EditGroupProduct
            product_id={clikedProduct}
            group_id={props.group_id}
          ></EditGroupProduct>
        </div>
      </Modal>
      <Modal size="large" isOpen={addModal} toggle={toggleAddModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleAddModal}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Add Product</h4>
        </div>
        <div className="modal-body">
          <AddGroupProducts
            group_id={props.group_id}
            setaddModal={setaddModal}
            setLoadingData={setLoadingData}
          ></AddGroupProducts>
        </div>
      </Modal>

      <Modal size="large" isOpen={PremiumModal} toggle={togglePremiumModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={togglePremiumModal}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Premium Details</h4>
        </div>
        <div className="modal-body">
          <GroupPremiumDetails
            product_id={clikedProduct}
            group_id={props.group_id}
          ></GroupPremiumDetails>
        </div>
      </Modal>
    </Row>
    </>
    // <>{props.group_id}</>
  );
};

export default GroupProduct;
