import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    pageData: {
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalRecords: null,
    },
    currentPage : 1,
    sortColumn : "",
    sortDirection : "",
    insurerList : [{ value: "", label: "All" }],
    ProdcutFilter : {
        insurer : "",
        product : "",
        type : "",
        status : ""
    },
    InsurerFilter : {
        title : "",
        medical_type : "",
        travel_type : ""
    },
    LeadFilter : {
        o_status : "",
        product_type : "",
        generator : ""
    }
};
const TableSlice = createSlice(
    {
        name: 'TableData',
        initialState,
        reducers: {
            reset : () => initialState,
            renderTableData: (state,action) => {
                state.pageData = action.payload;
            },
            updateTableConfig : (state,action) => {
                state.sortColumn = action.payload.sCol;
                state.sortDirection = action.payload.sDir;
            },
            updatePage : (state,action) => {
                state.currentPage = action.payload;
            },
            getInsurers : (state,action) => {
                const currentList = action.payload;
                state.insurerList = [{ value: "", label: "All" }, ...currentList]
            },
            updateFilterParam : (state,action) => {
                console.log("--");
                if(action.payload.field === "insurer"){
                    state.ProdcutFilter.insurer = action.payload.value;
                }
                if(action.payload.field === "product"){
                    state.ProdcutFilter.product = action.payload.value;
                }
                if(action.payload.field === "type"){
                    state.ProdcutFilter.type = action.payload.value;
                }
                if(action.payload.field === "status"){
                    state.ProdcutFilter.status = action.payload.value;
                }
                if(action.payload.field === "title"){
                    state.InsurerFilter.title = action.payload.value;
                }
                if(action.payload.field === "medical_type"){
                    state.InsurerFilter.medical_type = action.payload.value;
                }
                if(action.payload.field === "travel_type"){
                    state.InsurerFilter.travel_type = action.payload.value;
                }  
                if(action.payload.field === "o_status"){
                    state.LeadFilter.o_status = action.payload.value;
                }   
                if(action.payload.field === "product_type"){
                    state.LeadFilter.product_type = action.payload.value;
                }  
                if(action.payload.field === "generator"){
                    state.LeadFilter.generator = action.payload.value;
                }  
                if(action.payload.field === "priority"){
                    state.LeadFilter.priority = action.payload.value;
                }  

                if(action.payload.field === "client_relatioship_manager"){
                    state.LeadFilter.client_relatioship_manager = action.payload.value;
                }  

                if(action.payload.field === "account_manager"){
                    state.LeadFilter.account_manager = action.payload.value;
                }  

                if(action.payload.field === "account_director"){
                    state.LeadFilter.account_director = action.payload.value;
                }  
                
                if(action.payload.field === "support_manager"){
                    state.LeadFilter.support_manager = action.payload.value;
                }  

                if(action.payload.field === "client_development_manager"){
                    state.LeadFilter.client_development_manager = action.payload.value;
                }  

                if(action.payload.field === "support_manager2"){
                    state.LeadFilter.support_manager2 = action.payload.value;
                }  

                if(action.payload.field === "location"){
                    state.LeadFilter.location = action.payload.value;
                }  
                
            }
        }
    }
);



export default TableSlice.reducer;
export const TableSliceAction = TableSlice.actions;