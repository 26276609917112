/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import SubmitLoader from "components/SubmitLoader";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Container,
    Col,
    Row,
} from "reactstrap";

function VerifyOTP() {
    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
    });
    const [loginOtp, setloginOtp] = React.useState("");
    const [loginOtpState, setloginOtpState] = React.useState("");
    const [errorMsg, seterrorMsg] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const loggedUser = JSON.parse(localStorage.getItem('userData'));

    let history = useHistory();
    const verifyLength = (value, length) => {
        if (value.length === length) {
            return true;
        }
        return false;
    };
    const ConfirmOtp = () => {
        setShowLoader(true);
        var data = {
            "one_time_password": loginOtp
        }
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + loggedUser.user.token);
        fetch(process.env.REACT_APP_API_URL + "/confirm-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    localStorage.setItem("current_users", JSON.stringify(data));
                    history.push("/admin/dashboard");
                } else {
                    seterrorMsg(data.message);
                }
                setShowLoader(false);
            });

    };
    return (
        <div className="login-page">
            <SubmitLoader showLoader={showLoader} />
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" lg="4" md="4">
                        <Form action="" className="form" method="">
                            <Card className="card-login">
                                <CardHeader>

                                    <CardHeader>
                                        <h3 className="header text-center">Verify OTP</h3>
                                    </CardHeader>
                                </CardHeader>
                                <CardBody className="text-center">
                                    {errorMsg === "" ? (
                                        null
                                    ) : <div className="text-center alert alert-danger">
                                        {errorMsg}
                                    </div>}
                                    <FormGroup className={`has-label ${loginOtpState}`}>
                                        <label>Enter OTP *</label>
                                        <Input
                                            name="one_time_password"
                                            type="number"
                                            min={6}
                                            max={6}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                if (!verifyLength(e.target.value, 6)) {
                                                    setloginOtpState("has-danger");
                                                } else {
                                                    setloginOtpState("has-success");
                                                }
                                                setloginOtp(e.target.value);
                                            }}
                                        />
                                        {loginOtpState === "has-danger" ? (
                                            <label className="error">Please Enter 6 digit OTP.</label>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="text-center">
                                        <Link to='/setup-fa' className="text-dark">Scan QR Code Again</Link>
                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" onClick={ConfirmOtp}>
                                        Verify OTP
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            
        </div>
    );
}

export default VerifyOTP;
