/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState, useEffect,useCallback } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
import AppTable from "components/ReactTable/AppTable";
import ServerPagination from "components/ReactTable/ServerPagination";
import Switch from "react-bootstrap-switch";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
import NotificationAlert from "react-notification-alert";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, getInsurer } from "store/TableAction";
import { TableSliceAction } from "store/TableDataSlice";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import ProductFilter from "components/Filters/product-filter";

function AllProducts() {
  const dispatch = useDispatch();
  const notificationAlert = React.useRef();
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [loadingData, setLoadingData] = useState(true);
  const [alert, setAlert] = React.useState(null);
  const [RefreshTable, setRefreshTable] = useState();
  const [showLoader, setShowLoader] = React.useState(false);
  let history = useHistory();
  var headers = new Headers();
  
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  const { pageData, currentPage, sortColumn, sortDirection, ProdcutFilter } = useSelector(state => state.tableData);
  const columns = useMemo(() => [
    {
      Header: "Insurer",
      accessor: "title",
      sortdir: sortColumn === "title" ? sortDirection : '',
      sorCol: sortColumn === "title" ? 'yes' : 'no',
    },
    {
      Header: "Product",
      accessor: "plan_name",
      sortdir: sortColumn === "plan_name" ? sortDirection : '',
      sorCol: sortColumn === "plan_name" ? 'yes' : 'no',
    },
    {
      Header: "Type",
      accessor: "product_type",
      sortdir: sortColumn === "product_type" ? sortDirection : '',
      sorCol: sortColumn === "product_type" ? 'yes' : 'no',
    },
    {
      Header: "Added By",
      accessor: "added_user",
      sortdir: sortColumn === "added_user" ? sortDirection : '',
      sorCol: sortColumn === "added_user" ? 'yes' : 'no',
    },
    {
      Header: "Last update By",
      accessor: "modified_user",
      sortdir: sortColumn === "modified_user" ? sortDirection : '',
      sorCol: sortColumn === "modified_user" ? 'yes' : 'no',
    },
    {
      Header: "Created At",
      accessor: "created_at",
      sortdir: sortColumn === "created_at" ? sortDirection : '',
      sorCol: sortColumn === "created_at" ? 'yes' : 'no',
    },
    {
      Header: "Updated At",
      accessor: "updated_at",
      sortdir: sortColumn === "updated_at" ? sortDirection : '',
      sorCol: sortColumn === "updated_at" ? 'yes' : 'no',
    },
    {
      Header: "Status",
      accessor: "status",
      sortdir: sortColumn === "status" ? sortDirection : '',
      sorCol: sortColumn === "status" ? 'yes' : 'no',
    },
    {
      Header: "Enable/Disable",
      accessor: "enable_disable",
      sortable: false,
      filterable: false,
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ]);

  const formatRowData = (rawData) =>
    rawData.map((info) => ({
      id: info[0],
      title: info[1],
      plan_name: info[2],
      product_type: info[3],
      added_user: info[4],
      modified_user: info[5],
      created_at: info[6],
      updated_at: info[7],
      status: info[8] === 0 ? "Draft" : info[8] === 1 ? "Published" : "Disabled",
      enable_disable: (
        <>
          {
            info[8] !== 0
              ?
              <Switch key={"switch" + info[0]}
                defaultValue={info[8] === 1 ? true : false}
                offColor="success"
                offText={<i className="nc-icon nc-simple-remove" />}
                onColor="success"
                onText={<i className="nc-icon nc-check-2" />}
                onChange={() => changeStatusHandler(info[0], info[8] === 1 ? 2 : 1)}
              />
              : null
          }
        </>
      ),
      actions: (
        // we've added some custom button actions
        <div className="actions-right">

          {/* use this button to add a edit kind of action */}

          <Button
            onClick={() => {
              editProducts(info[0], info[3])
            }}
            color="primary"
            size=""
            className="btn-icon btn-link edit"
          >
            <i className="fa fa-edit" />
          </Button>{" "}
          {/* use this button to remove the data row */}
          <Button
            onClick={() => {
              warningWithConfirmMessage(info[0], info[3])
            }}
            color="danger"
            size=""
            className="btn-icon btn-link remove"
          >
            <i className="fa fa-times" />
          </Button>{" "}
        </div>
      ),
    }));


  useEffect(() => {
    if (loadingData) {
     
      dispatch(getProducts('get-products','created_at','DESC',headers));
      
    }
  }, [currentPage, sortDirection, ProdcutFilter, RefreshTable]);

  useEffect(() => {
    dispatch(TableSliceAction.reset() ) ;
    dispatch(getInsurer(headers));
  }, [])

  const changeStatusHandler = (product_id, status) => {
    setShowLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/change-products-status/", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ "product_id": product_id, "status": status })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {

        notify(data.message, 2, notificationAlert);
        setRefreshTable(product_id + "-" + status);
        setShowLoader(false);
      })

  };
  const warningWithConfirmMessage = (product_id, type) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(product_id, type)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this Product!
      </ReactBSAlert>
    );
  };
  const successDelete = (product_id, type) => {
    const deleteProduct = process.env.REACT_APP_API_URL + "/delete-product";

    fetch(deleteProduct, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ "product_id": product_id, "type": type })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {

        } else {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert("no")}
              onCancel={() => hideAlert("no")}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
          setRefreshTable(product_id + type);
        }
      });

  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };

  const editProducts = (product_id, product_type) => {
    if (product_type === "medical") {
      history.push("/admin/edit-medical/" + product_id);
    } else {
      history.push("/admin/edit-travel/" + product_id);
    }
  }
  const getData = async (pageNo = 1, sortOrder = 'ASC', column = 'created_at') => {
    dispatch(TableSliceAction.updateTableConfig({ sCol: column, sDir: sortOrder }));
  };
  const updatePage = async (pageNo = 1) => {
    dispatch(TableSliceAction.updatePage(pageNo));
  };

  return (
    <div className="content">
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">All Products</CardTitle>
              <Link to="/admin/add-new-travel" className="btn btn-primary float-right">Add Travel</Link>
              <Link to="/admin/add-new-medical" className="btn btn-primary float-right">Add Medical</Link>
            </CardHeader>
            <CardBody>
              {alert}
              <ProductFilter />
              <Row>

                <Col md="12">
                  <AppTable
                    columns={columns}
                    data={formatRowData(pageData.rowData)}
                    isLoading={pageData.isLoading}
                    sorting={getData}
                    page={currentPage}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="12">
                  <ServerPagination
                    totalRows={pageData.totalRecords}
                    pageChangeHandler={updatePage}
                    rowsPerPage={100}
                  />
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default AllProducts;
