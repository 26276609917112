import { React, useEffect, useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GetAccountPandingApi } from "store/FetchOpportunities";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from 'react';
import "components/ReactTable/styles.css";
// import { Button, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AccountPandingFilter from "components/Filters/AccountPandingFilter";
import { Button, Row, Col, FormGroup, Label, Input } from "reactstrap";


const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const GroupAccountPandingOpportunities = (props) => {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [quotes, setQuotes] = useState([]);
  const [loader,setLoader] = useState(true)
  const { AccountDataSuccess } = useSelector((state) => state.getAccountDataRe);

  // console.log("AccountDataSuccess", AccountDataSuccess);
  useEffect(() => {
    const getQuating = async () => {
      var product ="";
      await dispatch(GetAccountPandingApi(product,loggedUser.token));
    };
    getQuating();
  }, []);
  let rows = [];
  useEffect(() => {
    if (AccountDataSuccess?.data?.success == true) {
      // console.log(
      //   "AccountDataSuccess?.data?.list",
      //   AccountDataSuccess?.data?.list
      // );
      setQuotes(AccountDataSuccess?.data?.list);
      setLoader(false);

    }
  }, [AccountDataSuccess]);

  const getMonthName = (monthname) => {
    const date = new Date();
    date.setMonth(monthname - 1);

    return date.toLocaleString("en-US", { month: "short" });
  };

  const activityDate = (dateActivity) => {
    if (dateActivity) {
      const times = dateActivity.split(" ")[1];
      const dates = dateActivity.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate + " " + times;
      return originalActivityDate;
    }
  };

  const columns = [
    {
      field: "added_at",
      headerName: "ENQUIRY DATE",
      width: 165,
      renderCell: (cellValues) => {
        let updateDate = activityDate(cellValues.row.added_at);
        return <>{updateDate}</>;
      },
    },
    { field: "status", headerName: "STATUS", width: 150 ,  },
    { field: "product", headerName: "PRODUCT", width: 150 },
    { field: "group_name", headerName: "GROUP NAME", width: 150 },
    { field: "group_location", headerName: "LOCATION", width: 150 },
    { field: "generator", headerName: "GENERATOR", width: 150 },
    { field: "activity", headerName: "ACTIVITY", width: 150 },

    { field: "follow_up_date", headerName: "FOLLOW UP DATE", width: 150,
    renderCell: (cellValues) => {
      // let updateDate = activityDate(cellValues.row.added_at);I
      return <>{

          `${
   cellValues.row.followup_day != null
      ?cellValues.row.followup_day +
        "-" +
        getMonthName(cellValues.row.followup_month) +
        "-" +
       cellValues.row.followup_year
      : ""
  }`
      }</>;
    }, },
    { field: "activity_date", headerName: "ACTIVITY DATE", width: 150,   renderCell: (cellValues) => {
      // let updateDate = activityDate(cellValues.row.added_at);I
      return <>{`${activityDate(cellValues.row.added_at)}`}</>;
    }, },

    {
      field: "ACTIONS",
      headerName: "ACTIONS",
      renderCell: (cellValues) => {
        return (
          <Link
            className="btn btn-primary btn-sm"
            to={{
              pathname: "/admin/group-opportunities/" + cellValues.row.group_id,
              opportunitiesStatus: cellValues.row.status,
            }}
          >
          {/* {console.log("cellValues", cellValues)} */}
            Detail
          </Link>
        );
      },
    },

  ];

  return (
    <>
    <Row>
          <Col md="6">
          <p>Total Records: {AccountDataSuccess?.data?.totalRecords || "Loading..."}</p>
        </Col>
        </Row>
    {props.api === "search" ? null : (
      <AccountPandingFilter leadType={props.leadType} />
    )}
  
    <div style={{ height: 400, width: "100%" }}>
      <StripedDataGrid
        rows={quotes}
        columns={columns}
        // loading={ AccountDataSuccess?.data?.list?.length === 0}
        // pageSizeOptions={[5]}
        rowsPerPageOptions={[10,20]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableColumnMenu
        disableUnderline
        disableRowSelectionOnClick
        loading={loader}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </div>
    </>
  );
};


export default GroupAccountPandingOpportunities