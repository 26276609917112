import { React, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { GetOppotunitesApi } from "store/FetchOpportunities";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from 'react';
import "components/ReactTable/styles.css";
// import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import OpportunitiesFilter from "components/Filters/OpportunitiesFilter";
import OpportunityGroupFilter from "components/Filters/OpportunityGroupFilter";
import { Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
import Stack from "@mui/material/Stack";
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

// const newgrid = styled(StripedDataGrid)(({ theme }) => ({

// "css-axafay-MuiDataGrid-virtualScroller:{
//   height: "0% !important";
// }

// }));

const GroupOpportunityOpen = (props) => {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [quotes, setQuotes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [columns, setColumns] = useState([]);
  const { OpportunityDataSuccess } = useSelector(
    (state) => state.getOpportunityDataRe
  );
  // var columns ;
  const newStatus = localStorage.getItem("groupstatus");
  // console.log("OpportunityDataSuccess", quotes);
  useEffect(() => {
    const getQuating = async () => {
      let newData = {
        o_status: "Opportunity Open",
        product_type: "",
      };
      await dispatch(GetOppotunitesApi(newData, loggedUser.token));
    };
    getQuating();
  }, []);
  let rows = [];
  // console.log("OpportunityDataSuccess", newStatus);
  useEffect(() => {
    if (OpportunityDataSuccess?.data?.success == true) {
      setQuotes(OpportunityDataSuccess?.data?.list);
      setLoader(false);
    }
  }, [OpportunityDataSuccess]);

  const getMonthName = (monthname) => {
    const date = new Date();
    date.setMonth(monthname - 1);

    return date.toLocaleString("en-US", { month: "short" });
  };
  const activityDate = (dateActivity) => {
    if (dateActivity) {
      const times = dateActivity.split(" ")[1];
      const dates = dateActivity.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate + " " + times;
      return originalActivityDate;
    }
  };
  // console.log("newStatus", newStatus);
  useEffect(() => {
    if (newStatus == "Opportunity Open") {
      setColumns([
        {
          field: "added_at",
          headerName: "ENQUIRY DATE",
          width: 165,
          renderCell: (cellValues) => {
            let updateDate = activityDate(cellValues.row.added_at);
            return <>{updateDate}</>;
          },
        },
        { field: "group_name", headerName: "CLIENT", width: 185 },
        {
          field: "group_admin_first_name",
          headerName: "CONTACT PERSON",
          width: 185,
          renderCell: (cellValues) => {
            return `${cellValues.row.group_admin_first_name} ${cellValues.row.group_admin_last_name}`;
          },
        },

        { field: "product", headerName: "PRODUCT", width: 185 },
        { field: "group_admin_phone", headerName: "PHONE", width: 185 },
        { field: "generator", headerName: "GENERATOR", width: 185 },
        { field: "activity", headerName: "ACTIVITY", width: 185 },
        {
          field: "activity_date",
          headerName: "ACTIVITY DATE",
          width: 185,
          renderCell: (cellValues) => {
            // let updateDate = activityDate(cellValues.row.added_at);I
            return <>{`${activityDate(cellValues.row.activity_date)}`}</>;
          },
        },
        { field: "group_location", headerName: "LOCATION", width: 185 },
        {
          field: "follow_up_date",
          headerName: "FOLLOW UP DATE",
          width: 185,
        },

        {
          field: "ACTIONS",
          headerName: "ACTIONS",
          renderCell: (cellValues) => {
            return (
              <Link
                className="btn btn-primary btn-sm"
                to={{
                  pathname:
                    "/admin/group-opportunities/" + cellValues.row.group_id,
                  opportunitiesStatus: cellValues.row.status,
                }}
              >
                {/* {console.log("cellValues", cellValues)} */}
                Detail
              </Link>
            );
          },
        },
      ]);
    }

    if (newStatus == "Quoting") {
      // console.log("Quoting");
      setColumns([
        {
          field: "quote_date",
          headerName: "QUOTE DATE",
          width: 120,
          // renderCell: (cellValues) => {
          //   let updateDate = activityDate(cellValues.row.quote_date);
          //   return <>{updateDate}</>;
          // },
        },
        { field: "product", headerName: "PRODUCT", width: 185 },
        { field: "group_name", headerName: "CLIENT", width: 185 },
        {
          field: "group_administrator",
          headerName: "QUOTE REQUIRED BY",
          width: 185,
        },
        { field: "current_owner", headerName: "CURRENT OWNER", width: 185 },
        {
          field: "renewal_start_date",
          headerName: "RENEWAL/START DATE",
          width: 185,
        },
        { field: "activity", headerName: "ACTIVITY", width: 185 },

        {
          field: "activity_date",
          headerName: "ACTIVITY DATE",
          width: 185,
          renderCell: (cellValues) => {
            // let updateDate = activityDate(cellValues.row.added_at);I
            return <>{`${activityDate(cellValues.row.activity_date)}`}</>;
          },
        },
        { field: "group_location", headerName: "LOCATION", width: 185 },
        {
          field: "follow_up_date",
          headerName: "FOLLOW UP DATE",
          width: 185,
        },
        {
          field: "ACTIONS",
          headerName: "ACTIONS",
          renderCell: (cellValues) => {
            return (
              <Link
                className="btn btn-primary btn-sm"
                to={{
                  pathname:
                    "/admin/group-opportunities/" + cellValues.row.group_id,
                  opportunitiesStatus: cellValues.row.status,
                }}
              >
                {/* {console.log("cellValues", cellValues)} */}
                Detail
              </Link>
            );
          },
        },
      ]);
    }

    if (newStatus == "Account Pending") {
      setColumns([
        {
          field: "renewal_start_date",
          headerName: "SALE DATE",
          width: 120,
          // renderCell: (cellValues) => {
          //   let updateDate = activityDate(cellValues.row.quote_date);
          //   return <>{updateDate}</>;
          // },
        },
        { field: "product", headerName: "PRODUCT", width: 185 },
        { field: "group_name", headerName: "CLIENT", width: 185 },
        {
          field: "annual_premium_amount",
          headerName: "PREMIUM",
          width: 185,
        },

        {
          field: "annual_premium_payment_period",
          headerName: "PAYMENT FREQUENCY",
          width: 185,
        },
        {
          field: "total_commission_amount",
          headerName: "COMMISSION",
          width: 185,
        },
        { field: "activity", headerName: "ACTIVITY", width: 185 },

        {
          field: "activity_date",
          headerName: "ACTIVITY DATE",
          width: 185,
          renderCell: (cellValues) => {
            // let updateDate = activityDate(cellValues.row.added_at);I
            return <>{`${activityDate(cellValues.row.activity_date)}`}</>;
          },
        },
        { field: "group_location", headerName: "LOCATION", width: 185 },
        {
          field: "follow_up_date",
          headerName: "FOLLOW UP DATE",
          width: 185,
        },
        {
          field: "ACTIONS",
          headerName: "ACTIONS",
          renderCell: (cellValues) => {
            return (
              <Link
                className="btn btn-primary btn-sm"
                to={{
                  pathname:
                    "/admin/group-opportunities/" + cellValues.row.group_id,
                  opportunitiesStatus: cellValues.row.status,
                }}
              >
                {/* {console.log("cellValues", cellValues)} */}
                Detail
              </Link>
            );
          },
        },
      ]);
    }
  }, [newStatus]);

  // console.log("columns", columns);

  function NoRowsOverlay() {
    return (
      <Stack height="90%" alignItems="center" justifyContent="center">
        {/* No rows in DataGrid */}
        {/* <pre>(rows=&#123;[]&#125;)</pre> */}
      </Stack>
    );
  }
  return (
    <>
      <Row>
        <Col md="6">
          <p>
            Total Records:{" "}
            {OpportunityDataSuccess?.data?.totalRecords || "Loading..."}
          </p>
        </Col>
      </Row>
      {props.api === "search" ? null : (
        <OpportunitiesFilter leadType={props.leadType} />
      )}
      {columns.length > 0 ? (
        <div style={{ height: 800, width: "100%" }} className="datagridcss">
          <StripedDataGrid
            className={quotes?.length > 0 ? "" : "product-name"}
            rows={quotes}
            columns={columns}
            // loading={ OpportunityDataSuccess?.data?.list?.length === 0}
            // pageSizeOptions={[5]}
            rowsPerPageOptions={[10, 20]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            // components={{  NoRowsOverlay }}
            pageSizeOptions={[5, 10, 15, 20, 25]}
            disableColumnMenu
            disableUnderline
            disableRowSelectionOnClick
            loading={loader}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </div>
      ) : (
        <div style={{ height: 800, width: "100%" }} className="datagridcss">
          <StripedDataGrid
            className={quotes?.length > 0 ? "" : "product-name"}
            rows={quotes}
            columns={columns}
            // loading={ OpportunityDataSuccess?.data?.list?.length === 0}
            // pageSizeOptions={[5]}
            rowsPerPageOptions={[10, 20]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            // components={{  NoRowsOverlay }}
            pageSizeOptions={[5, 10, 15, 20, 25]}
            disableColumnMenu
            disableUnderline
            disableRowSelectionOnClick
            loading={loader}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </div>
      )}
    </>
  );
};

export default GroupOpportunityOpen;
